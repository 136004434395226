
import { defineComponent } from "vue";
import { Dinero as DineroType } from "dinero.js";
import IQDEasyTotal from "./IQDEasyTotal.vue";
import USDEasyTotal from "./USDEasyTotal.vue";

export default defineComponent({
  components: {
    "iqd-easy-total": IQDEasyTotal,
    "usd-easy-total": USDEasyTotal
  },

  props: {
    modelValue: {
      required: false,
      type: Object as () => DineroType
    }
  },

  emits: ["update:modelValue"],

  computed: {
    inputVal: {
      get(): DineroType | undefined {
        return this.modelValue;
      },

      set(val: DineroType) {
        this.$emit("update:modelValue", val);
      }
    }
  }
});
