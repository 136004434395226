export default class Timestamps {
  createdAt: Date | undefined = undefined;
  deletedAt: Date | undefined = undefined;
  updatedAt: Date | undefined = undefined;

  parseTime(createdAt: unknown, updatedAt: unknown, deletedAt?: unknown) {
    this.createdAt = new Date(createdAt as string);
    this.updatedAt = new Date(updatedAt as string);

    if (deletedAt) {
      this.deletedAt = new Date(deletedAt as string);
    }
  }

  formatTimeStamp(date?: Date): string {
    if (date) {
      return `${date.toLocaleDateString("en-GB")} ${date.toLocaleTimeString(
        "en-US"
      )}`;
    }

    return "";
  }

  formatCreatedAt() {
    return this.formatTimeStamp(this.createdAt);
  }

  formatUpdatedAt() {
    return this.formatTimeStamp(this.updatedAt);
  }

  formatDeletedAt() {
    return this.formatTimeStamp(this.deletedAt);
  }
}
