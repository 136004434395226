import path from "path";

// The logic in this file is copied from here https://github.com/ganeshrvel/npm-electron-root-path/
// The code has been TypeScriptifed since the library doesn't define typing.
const electronEnv = {
  isRenderer: process.type === "renderer",
  isWindows: process.platform === "win32"
};

const IS_PROD = process.env.NODE_ENV === "development";

// isPackaged logic is copied from here https://github.com/ganeshrvel/npm-electron-is-packaged/blob/master/lib/index.js
let isPackaged = false;
if (
  process.mainModule &&
  process.mainModule.filename.indexOf("app.asar") !== -1
) {
  isPackaged = true;
} else if (process.argv.filter(a => a.indexOf("app.asar") !== -1).length > 0) {
  isPackaged = true;
}

function stripTrailingSlashes(str: string) {
  return str.replace(/\/+$/, "");
}

function doesStringEndsWith(str: string, target: string) {
  if (target.length < 1) {
    return str.length < 1;
  }

  const last = str.substring(str.length - target.length);

  return last === target;
}

let _rootPath: string;

if (isPackaged) {
  const packagedNodeModulesPath = "/node_modules/electron-root-path/lib";

  let dirname = __dirname;
  if (!doesStringEndsWith(dirname, packagedNodeModulesPath)) {
    dirname = path.join(__dirname, packagedNodeModulesPath);
  }

  // renderer and main process - packaged build
  if (electronEnv.isWindows) {
    // windows platform
    _rootPath = path.join(dirname, "..", "../../../../");
  } else {
    // non windows platform
    _rootPath = path.join(dirname, "..", "../../../../../");
  }
} else if (IS_PROD) {
  // renderer and main process - prod build
  if (electronEnv.isRenderer) {
    // renderer process - prod build
    _rootPath = path.join(__dirname, "..", "..", "..");
  } else if (!module.parent) {
    // main process - prod build (case: run "start")
    _rootPath = path.join(__dirname, "..", "..", "..");
  } else {
    // main process - prod (case: run "build")
    _rootPath = path.join(__dirname, "..", "..", "..");
  }
} else if (electronEnv.isRenderer) {
  // renderer process - dev build
  _rootPath = path.join(__dirname, "..", "..", "..");
} else {
  // main process - dev build
  _rootPath = path.join(__dirname, "..", "..", "..");
}

export const rootPath = path.resolve(_rootPath);
