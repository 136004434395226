
import { defineComponent } from "vue";

import ActionButton from "@/components/ActionButton.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "inventory",
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          items: "Items",
          inventory: "Inventory",
          shortcuts: "Useful Shortcuts"
        },
        ar: {
          items: "المنتجات",
          inventory: "البضاعة",
          shortcuts: "المختصرات المفيدة"
        }
      }
    });

    return { t };
  },

  components: {
    ActionButton
  }
});
