<template>
  <div class="m-3" style="height:90vh">
    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <el-select
        class="input-label md:w-2/12"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/inventory/inventorytransfers')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full md:w-8/12"
        prefix-icon="el-icon-search"
        v-model="searchTerm"
      />

      <div class="w-full m-2 md:w-3/12">
        <template v-if="$can('create', '/inventory/inventorytransfers')">
          <horizontal-button
            :title="t('newTransfer')"
            @click="
              $router.push(
                $Route.INVENTORY_INVENTORY_TRANSFERS_NEW_INVENTORY_TRANSFER
              )
            "
          />
        </template>
      </div>

      <!--     indirect transfer is not supported yet -->
      <!--      <div class="w-full md:w-2/12">-->
      <!--        <template v-if="$can('create', '/inventory/inventorytransfers')">-->
      <!--          <el-dropdown class="w-full">-->
      <!--            <el-button type="primary" class="w-full">-->
      <!--              {{ t("newTransfer") }}-->
      <!--            </el-button>-->
      <!--            <template #dropdown>-->
      <!--              <el-dropdown-menu>-->
      <!--                <el-dropdown-item-->
      <!--                  @click="-->
      <!--                    $router.push(-->
      <!--                      $Route.INVENTORY_INVENTORY_TRANSFERS_NEW_INVENTORY_TRANSFER-->
      <!--                    )-->
      <!--                  "-->
      <!--                >-->
      <!--                  {{-->
      <!--                    $t(-->
      <!--                      "views.inventory.inventoryTransfers.newInventoryTransfer"-->
      <!--                    )-->
      <!--                  }}-->
      <!--                </el-dropdown-item>-->
      <!--                <el-dropdown-item-->
      <!--                  @click="-->
      <!--                    $router.push(-->
      <!--                      $Route.INVENTORY_INVENTORY_TRANSFERS_RECEIVE_INVENTORY_TRANSFER-->
      <!--                    )-->
      <!--                  "-->
      <!--                >-->
      <!--                  {{-->
      <!--                    $t(-->
      <!--                      "views.inventory.inventoryTransfers.receiveInventoryTransfer"-->
      <!--                    )-->
      <!--                  }}-->
      <!--                </el-dropdown-item>-->
      <!--              </el-dropdown-menu>-->
      <!--            </template>-->
      <!--          </el-dropdown>-->
      <!--        </template>-->
      <!--      </div>-->
    </div>

    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import { ColDef, GridOptions } from "ag-grid-community";
import { useI18n } from "vue-i18n";
import InventoryTransfer, {
  inventoryTransferI18nMessages
} from "@/models/inventory/InventoryTransfer";
import HorizontalButton from "@/components/HorizontalButton.vue";

export default defineComponent({
  name: "inventory-transfers",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryTransferI18nMessages.en,
          newTransfer: "New Transfer"
        },
        ar: { ...inventoryTransferI18nMessages.ar, newTransfer: "نقل جديد" }
      }
    });

    return { t };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: InventoryTransfer[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.t("id"),
        field: "externalID",
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.$t("models.common.createdAt"),
        field: "createdAt",
        cellStyle: { direction: "ltr" },
        valueFormatter: params => {
          const createdAt = new Date(params.value);
          return (
            createdAt.toLocaleDateString("en-GB") +
            " " +
            createdAt.toLocaleTimeString("en-US")
          );
        }
      },
      {
        headerName: this.t("status"),
        field: "status"
      },
      {
        headerName: this.t("user"),
        field: "user.fullName"
      },
      {
        headerName: this.t("fromLocation"),
        field:
          this.$ctx.locale == "ar"
            ? "fromLocation.arName"
            : "fromLocation.enName"
      },
      {
        headerName: this.t("toLocation"),
        field:
          this.$ctx.locale == "ar" ? "toLocation.arName" : "toLocation.enName"
      },
      {
        headerName: this.t("receivedAt"),
        field: "receivedAt",
        cellStyle: { direction: "ltr" },
        valueFormatter: params => {
          const date = params.value ? new Date(params.value) : "";
          return date
            ? date.toLocaleDateString("en-GB") +
                " " +
                date.toLocaleTimeString("en-US")
            : "";
        }
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();

    // go to view item
    this.gridOptions.onCellDoubleClicked = event => {
      this.$router.push(
        this.$Route.INVENTORY_INVENTORY_TRANSFERS_INVENTORY_TRANSFER.replace(
          ":id",
          event.data.id
        )
      );
    };
  },

  watch: {
    searchTerm() {
      this.filterResult();
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();

      try {
        const data = await this.$http.get<InventoryTransfer[]>(
          InventoryTransfer.ENDPOINT
        );
        this.rowData = data.map(inventoryTransfer =>
          InventoryTransfer.from(inventoryTransfer)
        );
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    },

    async applyToSelected() {
      if (this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();

        if (selected?.length) {
          this.gridOptions.api?.exportDataAsCsv({ onlySelected: true });
          this.$alertModal.showSuccess({
            title: this.$t("views.common.listView.selectedRows.exportSuccess")
          });

          // deselect
          selected.length = 0;
        }
      }

      this.selectedValue = "";
    },

    filterResult() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    }
  }
});
</script>

<style lang="scss">
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #f5f5f5,
      row-border-color: transparent,
      row-hover-color: null,
      checkbox-checked-color: var(--primary-bg-color),
      range-selection-border-color: var(--primary-bg-color)
    )
  );
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-content-cell-label {
  justify-content: center;
}
</style>
