
import { defineComponent } from "vue";
import VendorForm from "@/views/vendors/VendorForm.vue";

import Vendor from "@/models/vendor/Vendor";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "new-vendor",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          created: "Vendor was created successfully"
        },
        ar: {
          created: "تم انشاء الموزع بنجاح"
        }
      }
    });

    return { t };
  },

  components: {
    VendorForm
  },
  data() {
    return {
      initVendor: new Vendor()
    };
  },

  methods: {
    async submit(vendor: Vendor) {
      try {
        await this.$http.post(Vendor.ENDPOINT, vendor);
        await this.$router.push(this.$Route.VENDORS);
      } catch (error) {
        return error;
      }
    }
  }
});
