<template>
  <div>
    <div v-if="loading" class="flex h-full justify-center items-center">
      <loading-spinner diameter="64px" />
    </div>

    <alert-badge
      v-else-if="error.title"
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <alert-badge
      v-else-if="successMsg"
      isSuccess
      :title="successMsg"
      @dismissed="
        () => {
          this.successMsg = '';
        }
      "
    />

    <div dir="rtl" class="flex justify-center flex-col items-center">
      <div class="m-12">
        <clock />
      </div>

      <div
        v-if="lastStatus.id"
        class="flex flex-col items-center justify-center my-8"
      >
        <div>{{ t("lastStatusType") }}: {{ t(lastStatus.clockType) }}</div>
        <div>
          {{ t("lastStatusTime") }}: {{ parseFormat(lastStatus.updatedAt) }}
        </div>
      </div>

      <div class="flex mx-2">
        <el-button type="primary" @click="clockIn">
          {{ t("clockIn") }}</el-button
        >
        <el-button type="danger" @click="clockOut">{{
          t("clockOut")
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";

import AlertBadge from "@/components/AlertBadge.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Clock from "@/components/Clock.vue";

import { useHTTP } from "@/plugins/http";
import { useContext } from "@/plugins/context";
import { useI18n } from "vue-i18n";
import { parseFormat } from "@/utils/date";

import EmployeeClock from "@/models/hr/EmployeeClock";

export default defineComponent({
  setup() {
    const loading = ref(false);
    const error = reactive({ title: "", body: "" });

    const { get, post } = useHTTP();
    const ctx = useContext();

    const { t } = useI18n({
      messages: {
        en: {
          clockIn: "Clock in",
          clockOut: "Clock out",
          clockInSuccess: "Clocked in successfully",
          clockOutSuccess: "Clocked out successfully",
          in: "Clocked in",
          out: "Clocked out",
          lastStatusType: "Last log type",
          lastStatusTime: "Last log time"
        },

        ar: {
          clockIn: "تسجيل الدخول",
          clockOut: "تسجيل الخروج",
          clockInSuccess: "تم تسجيل الدخول بنجاح",
          clockOutSuccess: "تم تسجيل الخروج بنجاح",
          in: "دخول",
          out: "خروج",
          lastStatusType: "نوع اخر تسجيل",
          lastStatusTime: "تاريخ اخر تسجيل"
        }
      }
    });

    const successMsg = ref("");

    const resetError = () => {
      error.title = "";
      error.body = "";
    };

    const resetSuccessMsg = () => {
      successMsg.value = "";
    };

    const clockIn = async () => {
      loading.value = true;
      resetError();
      resetSuccessMsg();

      try {
        await post<EmployeeClock>(
          EmployeeClock.ENDPOINT,
          EmployeeClock.from({
            clockType: "in",
            userID: ctx.currentUser.id,
            locationID: ctx.currentLocation.id,
            registerID: ctx.currentRegister.id
          })
        );

        successMsg.value = t("clockInSuccess");
      } catch (err) {
        error.title = err?.title;
        error.body = err?.body;
      }

      loading.value = false;
    };

    const clockOut = async () => {
      loading.value = true;
      resetError();
      resetSuccessMsg();

      try {
        await post<EmployeeClock>(
          EmployeeClock.ENDPOINT,
          EmployeeClock.from({
            clockType: "out",
            userID: ctx.currentUser.id,
            locationID: ctx.currentLocation.id,
            registerID: ctx.currentRegister.id
          })
        );

        successMsg.value = t("clockOutSuccess");
      } catch (err) {
        error.title = err?.title;
        error.body = err?.body;
      }

      loading.value = false;
    };

    const lastStatus = ref(new EmployeeClock());
    const fetchLastEmployeeClockStatus = async () => {
      loading.value = true;
      resetError();
      resetSuccessMsg();
      const userID = ctx.currentUser.id;
      if (!userID) {
        return;
      }

      try {
        const resp = await get<EmployeeClock[]>(
          `${EmployeeClock.ENDPOINT}?user_id=${userID}&limit=1`
        );
        if (resp.length) lastStatus.value = EmployeeClock.from(resp[0]);
      } catch (err) {
        if (err.code != 404) {
          error.title = err?.title;
          error.body = err?.body;
        }
      }

      loading.value = false;
    };

    onMounted(() => {
      fetchLastEmployeeClockStatus();
    });

    return {
      loading,
      error,
      clockIn,
      clockOut,
      t,
      successMsg,
      lastStatus
    };
  },

  components: {
    AlertBadge,
    LoadingSpinner,
    Clock
  },

  methods: {
    parseFormat
  }
});
</script>
