import Tab from "@/plugins/navigation/Tab";
import View from "@/plugins/navigation/View";
import errors from "@/errors";
import { reactive } from "vue";
import { useI18nInstance } from "@/plugins/i18n";
import { logError } from "@/utils/log";

const t = useI18nInstance().global.t;

// /*
//   Register possible errors of this module
//  */
// const errs: Errors = Errors.getInstance();
// errs.registerErrors([
//   { code: 10, desc: "# of maxTabs exceeded" },
//   { code: 11, desc: "unexpected system error" },
//   {
//     code: 12,
//     desc: "cannot close main window",
//     runtimeMsg: "لايمكن اغلاق النافذة الرئيسية"
//   }
// ]);

/* begin type declarations */
/**
 * @deprecated This code should not be used
 */
export interface TabsState {
  // all the currently opened tabs
  all: Tab[];
  // max number of tabs allowed to be open at the same time
  maxTabs: number;
  // the index of the tab that the user is currently using
  selectedTabIndex: number;
}
/* end type declarations */

/**
 * @deprecated This code should not be used
 */
const newDefaultTab = () =>
  new Tab(new View("home", 0, t("views.home"), "home"), true);

/* initial state */
/**
 * @deprecated This code should not be used
 */
const state = reactive({
  all: [newDefaultTab()],
  // 6 is arbitrary, it could be any number desired by the product vision
  maxTabs: 6,
  // the default selected tab is the first tab
  selectedTabIndex: 0
});

/**
 * @deprecated This code should not be used
 */
export function selectTab(index: number) {
  // validate index
  if (index >= 0 && index < state.all.length) {
    // update and focus the selected tab
    state.selectedTabIndex = index;

    state.all.forEach((tab, i) => {
      // make sure that previously selected tabs are now unselected
      // and focus the tab with index
      tab.isSelected = i === index;
    });
  } else {
    const err = errors.system.SYSTEM_UNEXPECTED_ERR.bindDebugInfo({
      scope: "selectTab",
      invalidIndex: index,
      tabsLength: state.all.length
    });
    logError(err.dumpDebugInfo());
    throw err;
  }
}

/**
 * @deprecated This code should not be used
 */
export function insertTab() {
  // insert is we user hasn't exceeded the max num of tabs
  if (state.all.length < state.maxTabs) {
    // insert the tab
    state.all.push(newDefaultTab());

    // select the new tab
    selectTab(state.all.length - 1);
  } else {
    throw t("errors.client.tabs.maxNumExceeded", { tabsNum: state.maxTabs });
  }
}

/**
 * @deprecated This code should not be used
 */
export function removeTab(index: number) {
  // first validate index for errors
  if (index < 0 || index >= state.all.length) {
    const err = errors.system.SYSTEM_UNEXPECTED_ERR.bindDebugInfo({
      scope: "remoteTab",
      invalidIndex: index,
      tabsLength: state.all.length
    });
    logError(err.dumpDebugInfo());
    throw err;
  } else if (state.all.length <= 1) {
    throw t("errors.client.tabs.cannotCloseMainWindow");
  } else {
    // all checks passed, no errors
    // alright so remove the tab
    state.all.splice(index, 1);

    /*
        the question: which tab do we switch select state to?
        there are 3 major cases for the index we're trying to remove
        1. (index == 0): the first tab.
          nested cases
          |- if (index == selectedTabIndex), then keep the selectedTabIndex == 0. This will naturally switch selection to the tab to the left
          |- else, some other tab is selected. decrement the selectedTabIndex by 1 to account for shifting indices
        2. (index == lastIndex): the last tab
          nested cases:
          |- if (index == selectedTabIndex), then decrement selectedTabIndex by 1, switch focus to the tab to the right
          |- else keep the selectedTabIndex to its original value. Removing the last element won't shift indices.
        3. (index > 0 && index < lastIndex): a tab in the middle
          |- if (index == selectedTabIndex), then decrement selectedTabIndex by 1, switch selection to the tab to the right
          |- else if (selectedTabIndex > index), then decrement selectedTabIndex by 1 to account for shifting indices
          |- else keep the selectedTabIndex the same


        as you can see, there are some cases with shared outcome.
        so the code I'm going to write below will factor out those cases to reduce nested if statements
        although I want to keep my code as clear as possible, the comments above should be sufficient
        to explain what's going on below
      */

    let newTabIndex;
    // then there are a few cases where we decrement by 1
    if (
      // case 1: nested else, case 3: nested else if
      state.selectedTabIndex > index ||
      // case 2 & case 3, nested if in each
      (index > 0 && index === state.selectedTabIndex)
    ) {
      newTabIndex = state.selectedTabIndex - 1;
    } else {
      // otherwise, we want to keep the selectedTabIndex to its original value
      // case 1: nested if, case 2: nested else, case 3: nested else
      newTabIndex = state.selectedTabIndex;
    }

    if (newTabIndex >= 0 && newTabIndex < state.all.length) {
      selectTab(newTabIndex);
    } else {
      const err = errors.system.SYSTEM_UNEXPECTED_ERR.bindDebugInfo({
        scope: "removeTab",
        invalidIndex: index,
        tabsLength: state.all.length
      });
      logError(err.dumpDebugInfo());
      throw err;
    }
  }
}

/**
 * @deprecated This code should not be used
 */
export function pushView(newView: View) {
  const focusedTab = state.all[state.selectedTabIndex];
  const currentView = focusedTab.view;
  let ok = true;

  if (
    currentView.isGuarded() &&
    !confirm(
      "changes will be discarded if you leave this window. Are you sure you want to proceed?"
    )
  ) {
    ok = false;
  }

  if (ok) {
    // update the view as well as the selectedNavIndex of that focusedTab
    focusedTab.pushView(newView);
    focusedTab.view = newView;
  }
}

/**
 * @deprecated This code should not be used
 */
export function popView() {
  const focusedTab = state.all[state.selectedTabIndex];
  const currentView = focusedTab.view;
  let ok = true;

  if (
    currentView.isGuarded() &&
    !confirm(
      "changes will be discarded if you leave this window. Are you sure you want to proceed?"
    )
  ) {
    ok = false;
  }

  if (ok) {
    focusedTab.popView();
  }
}

// when changes are made to a view, for example, @input
// the view should be guarded
/**
 * @deprecated This code should not be used
 */
export function guardCurrentView() {
  const focusedTab = state.all[state.selectedTabIndex];
  const currentView = focusedTab.view;
  currentView.guarded = true;
}

export function getSelectedNavIndex() {
  return state.all[state.selectedTabIndex].view.navIndex;
}

export function getSelectedView() {
  return state.all[state.selectedTabIndex].view;
}

export function getTabs() {
  return state.all;
}

export function useTabs() {
  return {
    insertTab,
    selectTab,
    removeTab,
    getSelectedNavIndex,
    guardCurrentView,
    getSelectedView,
    getTabs,
    pushView,
    popView
  };
}
