
import { defineComponent } from "vue";

// models
import Item, { itemI18nMessages } from "@/models/inventory/Item";
import Tag from "@/models/inventory/Tag";

// components
import HorizontalButton from "@/components/HorizontalButton.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { ElForm } from "element-plus";
import MoneyInput from "@/components/form/MoneyInput.vue";

import Category from "@/models/inventory/Category";
import Brand from "@/models/inventory/Brand";
import Vendor from "@/models/vendor/Vendor";

import { useI18n } from "vue-i18n";

interface Option {
  name: string;
  values: string[];
}

export default defineComponent({
  name: "simple-item-form",

  setup() {
    const messages = {
      en: {
        ...itemI18nMessages.en,

        sections: {
          itemInfo: "Item Info",
          pricing: "Pricing",
          options: "Item options",
          stockInfo: "Stock Info",
          descriptiveInfo: "Descriptive Info",
          generalInfo: "Item Info"
        },

        placeholders: {
          item: "Example: backpack",
          description: "Example: backpack with smooth fabric and life warranty",
          price: "Example: 15000",
          cost: "Example: 5000",
          barcode: "Example: 1339620142715",
          sku: "Example: 2392",
          dragDrop: "Drop file here or click to upload",
          fileTypes: "jpg/png files with a size less than 500kb"
        },

        select: {
          skipVendor: "Skip vendor",
          skipBrand: "Skip Brand",
          skipCategory: "Skip Category"
        },

        tooltips: {
          cost: "Cost is used in accounting",
          serialized: "This feature is used to track items individually",
          unlimitedStock: "Services have unlimited stock",
          discountEligible: "Sales reps can apply discount at checkout",
          barcode: "Must be unique",
          sku: "Must be unique, used for internal tracking purposes",
          tags: "To select more than one tag, click Ctrl"
        },

        createItem: "Save Item",
        location: "Location",
        serialNumbers: "Serial Numbers",
        stockQuantity: "Stock Quanity"
      },

      ar: {
        ...itemI18nMessages.ar,

        sections: {
          itemInfo: "معلومات المنتج",
          pricing: "التسعيير",
          options: "اعدادات المنتج",
          stockInfo: "بيانات التخزين",
          descriptiveInfo: "البيانات الوصفية",
          generalInfo: "معلومات المنتج"
        },

        placeholders: {
          item: "مثلا: حقيبة ظهر",
          description: "مثلا: حقيبة ظهر بخام ناعم",
          price: "مثلا: 15000",
          cost: "مثلا: 5000",
          barcode: "مثلا: 1339620142715",
          sku: "مثلا: 2392",
          dragDrop: "اسحب ملف الصورة هنا أو اضغط لاختيار ملف",
          fileTypes: "الملفات المدعومة: jpeg png"
        },

        select: {
          skipVendor: "تخطي الموزع",
          skipBrand: "تخطي العلامة التجارية",
          skipCategory: "تخطي المجموعة"
        },

        tooltips: {
          cost: "يستخدم في حسابات الارباح وغيرها من التقارير",
          serialized: "يستخدم الترقيم في متابعة المنتجات بشكل فردي",
          unlimitedStock: "مثلا: الخدمات لاتخضع للمخزون",
          discountEligible:
            "يمكن لمندوب المبيعات ان يقوم بتخفيض المنتجات عند البيع",
          barcode: "يجب ان يكون فريدا",
          sku: "يجب ان يكون فريدا - يستخدم كرقم تخزين داخلي",
          tags: "اضغط على ctrl لاختيار اكثر  من وسم"
        },

        createItem: "حفظ المنتج",
        location: "الموقع",
        serialNumbers: "Serial Numbers",
        stockQuantity: "الكمية"
      }
    };

    const { t } = useI18n({ messages, useScope: "global" });

    const rules = {
      name: [
        { required: true, message: t("validation.required") },
        { max: 255, message: t("validation.maxLength", { len: 255 }) }
      ],
      listPriceAmount: [{ required: true, message: t("validation.required") }],
      salePriceAmount: [{ required: true, message: t("validation.required") }],
      costAmount: [{ required: true, message: t("validation.required") }],
      barcode: [{ max: 63, message: t("validation.maxLength", { len: 63 }) }],
      sku: [{ max: 63, message: t("validation.maxLength", { len: 63 }) }]
    };

    return { t, rules };
  },

  components: {
    HorizontalButton,
    AlertBadge,
    MoneyInput
  },
  props: {
    initItem: {
      type: Item,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      default: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },
  // I have the choice to use v-model but would make this code
  // incredibly complicated!
  // I chose to alias/copy props, even though it might be against vue style guide
  // the parent hence passes an initial copy of the item and variants and DOES NOT
  // expect those inital copies to be synchronized!
  data() {
    return {
      item: this.initItem,
      tags: new Array<Tag>(),
      selectedTags: new Array<string | undefined>(),
      categories: new Array<Category>(),
      brands: new Array<Brand>(),
      vendors: new Array<Vendor>(),

      error: { title: "", body: "" }
    };
  },
  async beforeMount() {
    this.selectedTags = this.item.itemTags.map(tag => tag.id);

    // get categories, brands, vendors, tags for the dropdown menu
    try {
      // let url = this.$http.BASE_URL + `/inventory/categories`;
      const catResp = await this.$http.get<Category[]>(Category.ENDPOINT);
      this.categories = catResp.map(cat => Category.from(cat));

      const tagResp = await this.$http.get<Tag[]>(Tag.ENDPOINT);
      this.tags = tagResp.map(tag => Tag.from(tag));

      const brandResp = await this.$http.get<Brand[]>(Brand.ENDPOINT);
      this.brands = brandResp.map(brand => Brand.from(brand));

      const vendorResp = await this.$http.get<Vendor[]>(Vendor.ENDPOINT);
      this.vendors = vendorResp.map(vendor => Vendor.from(vendor));
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },
  methods: {
    updateSelectedTags() {
      this.item.itemTags = this.selectedTags.map(tagID =>
        Tag.from({ id: tagID })
      );
    },

    handleImage(target: HTMLInputElement) {
      // won't work!!!!! need to change this to a put request
      if (target.files?.length) {
        this.item.imageURL = target.files[0].path;
        this.item.imageType = target.files[0].type;
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;

      this.selectedTags = new Array<string | undefined>();
      this.item = new Item();
      this.$globalEmitter.emit("money-input-reset");
      form.resetFields();
    },

    async validateSubmit(formName: string) {
      // attach the price and cost values
      this.item.listPriceAmount = this.item.listPriceDinero?.getAmount();
      this.item.listPriceCurrency = this.item.listPriceDinero?.getCurrency();
      this.item.listPricePrecision = this.item.listPriceDinero?.getPrecision();

      this.item.salePriceAmount = this.item.salePriceDinero?.getAmount();
      this.item.salePriceCurrency = this.item.salePriceDinero?.getCurrency();
      this.item.salePricePrecision = this.item.salePriceDinero?.getPrecision();

      this.item.wholesalePriceAmount = this.item.wholesalePriceDinero?.getAmount();
      this.item.wholesalePriceCurrency = this.item.wholesalePriceDinero?.getCurrency();
      this.item.wholesalePricePrecision = this.item.wholesalePriceDinero?.getPrecision();

      this.item.costAmount = this.item.costDinero?.getAmount();
      this.item.costCurrency = this.item.costDinero?.getCurrency();
      this.item.costPrecision = this.item.costDinero?.getPrecision();

      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.item);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    }
  }
});
