<template>
  <div>
    <div class="w-full mx-8 my-2">
      {{ t("main") }}
    </div>
    <div
      class="flex flex-col flex-wrap content-center m-3 md:flex-row lg:justify-start"
    >
      <template v-if="$can('read', '/accounting/accounts')">
        <!-- we're gonna change the routes when building each view -->
        <action-button
          :title="$t('views.vendors.vendors')"
          faIcon="truck"
          @click.prevent="$router.push($Route.ACCOUNTING_VENDORS)"
        >
        </action-button>
      </template>

      <template v-if="$can('read', '/accounting/transactions')">
        <action-button
          :title="$t('views.customers.customers')"
          faIcon="users"
          @click.prevent="$router.push($Route.ACCOUNTING_CUSTOMERS)"
        >
        </action-button>
      </template>

      <template v-if="$can('read', '/accounting/accounts')">
        <action-button
          :title="$t('views.hr.hr')"
          faIcon="address-book"
          @click.prevent="$router.push($Route.ACCOUNTING_HR)"
        >
        </action-button>
      </template>

      <template v-if="$can('read', '/accounting/accounts')">
        <action-button
          :title="$t('registers')"
          faIcon="cash-register"
          @click.prevent="$router.push($Route.ACCOUNTING_REGISTERS)"
        >
        </action-button>
      </template>

      <template v-if="$can('read', '/accounting/accounts')">
        <action-button
          :title="$t('company')"
          faIcon="store"
          @click.prevent="$router.push($Route.ACCOUNTING_COMPANY)"
        >
        </action-button>
      </template>
    </div>

    <div class="w-full mx-8 my-2">
      {{ t("all") }}
    </div>
    <div
      class="flex flex-col flex-wrap content-center m-3 md:flex-row lg:justify-start"
    >
      <template v-if="$can('read', '/accounting/accounts')">
        <action-button
          :title="$t('views.accounting.accounts.accounts')"
          faIcon="user"
          @click.prevent="$router.push($Route.ACCOUNTING_ACCOUNTS)"
        >
        </action-button>
      </template>

      <template v-if="$can('read', '/accounting/transactions')">
        <action-button
          :title="$t('views.accounting.transactions.transactions')"
          faIcon="exchange-alt"
          @click.prevent="$router.push($Route.ACCOUNTING_TRANSACTIONS)"
        >
        </action-button>
      </template>
    </div>

    <!-- assets to be supported later -->
    <!--    <div class="w-full mx-8 my-2">-->
    <!--      {{ t("other") }}-->
    <!--    </div>-->
    <!--    <div-->
    <!--      class="flex flex-col flex-wrap content-center m-3 md:flex-row lg:justify-start"-->
    <!--    >-->
    <!--      <template v-if="$can('read', '/accounting/transactions')">-->
    <!--        <action-button-->
    <!--          :title="$t('assets')"-->
    <!--          faIcon="landmark"-->
    <!--          @click.prevent="$router.push($Route.ACCOUNTING_TRANSACTIONS)"-->
    <!--        >-->
    <!--        </action-button>-->
    <!--      </template>-->
    <!--    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ActionButton from "@/components/ActionButton.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "inventory",
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          accounting: "Accounting Records",
          shortcuts: "Useful Shortcuts",
          registers: "Registers",
          company: "Company",
          assets: "Assets",
          main: "Main",
          all: "All",
          other: "Other"
        },
        ar: {
          accounting: "سجلات المحاسبة",
          shortcuts: "المختصرات المفيدة",
          registers: "صناديق النقد",
          company: "الشركة",
          assets: "الموجودات",
          main: "الحسابات الرئيسية",
          all: "الكل",
          other: "اخرى"
        }
      },
      useScope: "global"
    });

    return { t };
  },

  components: {
    ActionButton
  }
});
</script>

<style></style>
