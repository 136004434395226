
import { defineComponent, onBeforeMount, ref } from "vue";

import { useHTTP } from "@/plugins/http";

import User from "@/models/user/User";
import PermissionGroup from "@/models/hr/Permission";

// components
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import EmployeeForm from "@/views/hr/employees/EmployeeForm.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "employee",
  components: {
    LoadingSpinner,
    EmployeeForm,
    AlertBadge
  },
  setup() {
    const initEmployee = ref(new User());
    const permissionGroups = ref(new Array<PermissionGroup>());
    const error = ref({ title: "", body: "" });
    const http = useHTTP();
    const loading = ref(true);
    const route = useRoute();

    const loadPermissions = async () => {
      const url = `${PermissionGroup.ENDPOINT}`;

      try {
        const resp = await http.get<PermissionGroup[]>(url);
        permissionGroups.value = resp.map(e => PermissionGroup.from(e));
      } catch (errorResp) {
        error.value.title = errorResp.title;
        error.value.body = errorResp.body;
      }
    }

    onBeforeMount(async () => {
      await loadPermissions();

      const url = `${User.ENDPOINT}/${route.params.id}`;

      try {
        const resp = await http.get<User>(url);
        initEmployee.value = User.from(resp);
      } catch (errorResp) {
        error.value.title = errorResp.title;
        error.value.body = errorResp.body;
      }

      loading.value = false;
    });


    const update = async (employee: User) => {
      try {
        const url = `${User.ENDPOINT}/${employee.id}`;
        const resp = await http.put<User>(url, employee);
        initEmployee.value = User.from(resp);
      } catch (error) {
        return error;
      }
    };

    return {
      initEmployee,
      permissionGroups,
      error,
      loading,
      update
    };
  }
});
