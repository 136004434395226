<template>
  <div>
    <el-form
      @submit.prevent="validateSubmit('barcodeForm')"
      novalidate
      ref="barcodeForm"
      class="flex flex-col items-center"
    >
      <div class="input-section">
        <el-form-item :label="t('itemLookup')" class="input-label">
          <el-autocomplete
            id="item"
            v-model="itermSearchTerm"
            :placeholder="t('placeholders.item')"
            :fetch-suggestions="searchItems"
            value-key="name"
            :trigger-on-focus="false"
            class="w-full"
            @select="chooseItem"
            @keypress.enter.prevent="pressedEnter = true"
            highlight-first-item
          >
          </el-autocomplete>
        </el-form-item>

        <el-form-item
          :label="t('enterBarcode')"
          prop="barcode"
          class="input-label"
        >
          <el-input
            id="barcode-value"
            v-model="barcode"
            maxlength="255"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('chooseBarcode')" class="input-label">
          <el-select
            v-model="barcodeType"
            default-first-option="Choose type"
            filterable
            clearable
            class="w-full"
          >
            <el-option
              v-for="type in barcodeTypes"
              :key="type.id"
              :label="type.name"
              :value="type.name"
            >
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                {{ type.name }}
              </span>
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div class="input-section">
        <div class="input-group">
          <el-form-item prop="printItemName" class="input-label">
            <el-checkbox id="print-item-name" v-model="printItemName">
              {{ t("printItemName") }}
            </el-checkbox>
          </el-form-item>
          <el-form-item prop="printItemSku" class="input-label">
            <el-checkbox id="print-item-sku" v-model="printItemSku">
              {{ t("printItemSKU") }}
            </el-checkbox>
          </el-form-item>
        </div>

        <div class="input-group">
          <el-form-item :label="t('width')" prop="width" class="input-label">
            <el-input id="barcode-width" v-model="width" clearable>
              <template #append>{{ t("metric") }}</template>
            </el-input>
          </el-form-item>

          <el-form-item :label="t('height')" prop="height" class="input-label">
            <el-input id="barcode-height" v-model="height" def clearable>
              <template #append>{{ t("metric") }}</template>
            </el-input>
          </el-form-item>
        </div>
      </div>

      <svg id="barcode"></svg>

      <div class="input-section">
        <div class="flex flex-col md:flex-row justify-end">
          <div class="md:w-3/12 m-2">
            <horizontal-button
              :title="$t('actions.print')"
              isSuccess
              faIcon="save"
              @click.prevent="print()"
            />
          </div>

          <div class="md:w-3/12 m-2">
            <horizontal-button
              :title="$t('actions.clearButton')"
              isDanger
              faIcon="trash"
              @click.prevent="clearForm('barcodeForm')"
            />
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import JsBarcode from "jsbarcode";
import printJS from "print-js";

import Item from "@/models/inventory/Item";

import HorizontalButton from "@/components/HorizontalButton.vue";

import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "print-barcode",

  setup() {
    const messages = {
      en: {
        placeholders: {
          item: "Example: backpack"
        },
        itemLookup: "Lookup item to quickly fill the barcode field below",
        enterBarcode: "Enter barcode (may be filled by the field above)",
        chooseBarcode: "Choose barcode type",
        printItemName: "Print item name",
        printItemSKU: "Print item sku",
        width: "Width",
        height: "Height",
        metric: "mm"
      },
      ar: {
        placeholders: {
          item: "مثال: حقيبة ظهر"
        },
        itemLookup: "أبحث عن منتج لملء حقل الباركود أدناه",
        enterBarcode: "أدخل الباركود (يمكن ملؤه بالحقل أعلاه)",
        chooseBarcode: "أختر نوع الباركود",
        printItemName: "أطبع اسم المنتج",
        printItemSKU: "أطبع ال sku",
        width: "العرض",
        height: "الارتفاع",
        metric: "mm"
      }
    };

    const { t } = useI18n({ messages, useScope: "global" });

    return { t };
  },

  components: {
    HorizontalButton
  },
  data() {
    return {
      item: new Item(),
      barcode: "",
      barcodeType: "",
      barcodeTypes: [
        { id: "code128", name: "code128" },
        { id: "ean13", name: "EAN13" },
        { id: "upc", name: "UPC" }
      ],
      itemName: "",
      width: 2,
      height: 100,
      printItemName: false,
      printItemSku: false,
      itermSearchTerm: "",
      pressedEnter: false
    };
  },
  watch: {
    barcode() {
      this.renderBarcode();
    },
    barcodeType() {
      this.renderBarcode();
    },
    height() {
      this.renderBarcode();
    },
    width() {
      this.renderBarcode();
    }
  },
  methods: {
    renderBarcode() {
      try {
        JsBarcode("#barcode", this.barcode, {
          format: this.barcodeType,
          height: this.height,
          width: this.width,
          valid: valid => {
            if (!valid) {
              this.$alertModal.showDanger({
                title: `not a valid input for ${this.barcodeType}`,
                body: ""
              });
            }
          }
        });
      } catch (error) {
        //
      }
    },

    chooseItem(selected: Item) {
      this.item = selected;
      this.barcode = this.item.barcode || "";
    },

    async searchItems(query: string, cb: Function) {
      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        const items = await this.$http.get<Item[]>(url);
        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && items.length === 1) {
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }

      this.pressedEnter = false;
    },

    async print() {
      printJS({ printable: "barcode", type: "html", targetStyles: ["*"] });
    }
  }
});
</script>

<style scoped lang="postcss"></style>
