import UUIDBase from "@/models/UUIDBase";
import { useI18nInstance } from "@/plugins/i18n";
import Dinero, { Currency, Dinero as DineroType } from "dinero.js";
import { Transaction } from "../accounting/Transaction";
import Vendor from "./Vendor";

const { t } = useI18nInstance().global;

export const vendorBillI18nMessages = {
  en: {
    vendorName: "Vendor Name",
    dueDate: "Due Date",
    createdAt: "Created At",
    totalAmount: "Total Amount",
    paidAmount: "Paid Amount",
    balance: "Balance",
    notes: "Notes"
  },
  ar: {
    vendorName: "اسم الموزع",
    dueDate: "تاريخ الاستحقاق",
    createdAt: "تاريخ الاصدار",
    totalAmount: "المبلغ الإجمالي",
    paidAmount: "المبلغ المدفوع",
    balance: "الرصيد",
    notes: "الملاحظات"
  }
};

export class VendorBillPayment extends UUIDBase {
  static ENDPOINT = "/accounting/vendorbills/payments";

  vendorBillID?: string;
  vendorBill?: VendorBill;
  transactionID?: string;
  transaction?: Transaction;
  totalDueAmount = 0;
  changeDueAmount = 0;
  paidBeforeChangeAmount = 0;
  paymentAmount?: number;
  currency = "";
  precision = 0;
}

export default class VendorBill extends UUIDBase {
  static from(json: Record<string, unknown> | VendorBill) {
    const o = Object.assign(new VendorBill(), json);

    o.totalDueDinero = Dinero({
      amount: o.totalDueAmount,
      currency: o.currency as Currency,
      precision: o.precision
    });

    o.totalPaidDinero = Dinero({
      amount: o.totalPaidAmount,
      currency: o.currency as Currency,
      precision: o.precision
    });

    o.totalBalanceDinero = Dinero({
      amount: o.totalBalanceAmount,
      currency: o.currency as Currency,
      precision: o.precision
    });

    return o;
  }
  static ENDPOINT = "/accounting/vendorbills";
  vendorName = "";
  vendorID?: string;
  vendor?: Vendor;
  externalID = ""; // Bill #
  billDueDate?: Date;
  billDate?: Date;

  totalDueAmount = 0;
  totalDueDinero?: DineroType;

  totalPaidAmount = 0;
  totalPaidDinero?: DineroType;

  totalBalanceAmount = 0;
  totalBalanceDinero?: DineroType;

  otherCostsAmount?: number;
  otherCostsDinero?: DineroType;

  currency?: string;
  precision?: number;

  vendorBillPayments?: VendorBillPayment[]; //this should be a vendorBillPayment[]
}
