<template>
  <receipt
    :operationTitle="
      isOrderPayment
        ? $t('views.sales.newSale')
        : $t('views.sales.orderPayment')
    "
    :externalID="order.externalID"
    :date="order.createdAt || new Date()"
    :userFullName="order?.user?.fullName || ''"
    :customerFullName="order?.customer?.contactDisplayName"
    :locationName="
      $ctx.locale === 'en' ? order?.location?.enName : order?.location?.arName
    "
  >
    <template v-slot:extraActions>
      <action-button
        :title="t('newSale')"
        faIcon="new-sale"
        faPrefix="fac"
        isSuccess
        @click="$router.push($Route.SALES_NEW_SALE)"
      >
      </action-button>
    </template>

    <template v-slot:receiptContent>
      <table class="receipt-table" v-if="isOrderPayment">
        <thead>
          <tr class="text-sm">
            <th>{{ t("itemName") }}</th>
            <th>{{ t("quantity") }}</th>
            <th>{{ t("price") }}</th>
            <th>{{ t("itemTotal") }}</th>
          </tr>
        </thead>

        <tbody v-if="order.orderItems">
          <tr
            v-for="(oi, i) in order.orderItems"
            :key="i"
            class="text-sm"
            :class="{ 'receipt-row-border': !oi.item.isSerialized }"
          >
            <td class="text-sm reciept-cell">
              {{ truncateItemName(oi.item.name) }}
            </td>

            <td>{{ oi.quantity }}</td>
            <td>
              {{ formatDinero(oi.itemSalePriceDinero) }}
            </td>
            <td>{{ formatDinero(oi.itemTotal) }}</td>
          </tr>
        </tbody>
      </table>

      <template v-if="!order.empty() && isOrderPayment">
        <div class="flex justify-between w-5/6 mt-2">
          <p>{{ t("totalBeforeDiscounts") }}</p>
          <p>{{ formatDinero(order.invoice?.totalBeforeDiscountsDinero) }}</p>
        </div>

        <div
          class="flex justify-between w-5/6 "
          v-if="order.totalDiscountAmount"
        >
          <p>{{ t("totalAdditionalDiscount") }}</p>
          <p>{{ formatDinero(order.invoice?.totalDiscountDinero) }}</p>
        </div>

        <div class="w-full p-2">
          <hr class="solid" />
        </div>

        <div class="flex justify-between w-5/6 ">
          <p>{{ t("totalDue") }}</p>
          <p>
            {{ formatDinero(order.invoice?.totalDueDinero) }}
          </p>
        </div>

        <div class="w-full p-2">
          <hr class="solid" />
        </div>

        <div class="flex justify-between w-5/6">
          <p>{{ t("totalPaid") }}</p>
          <p>
            {{ formatDinero(order.invoice?.totalPaidDinero) }}
          </p>
        </div>

        <div class="w-full p-2">
          <hr class="solid" />
        </div>

        <div class="flex justify-between w-5/6">
          <p>{{ t("totalKept") }}</p>
          <p>
            {{ formatDinero(order.invoice?.totalPaidDinero) }}
          </p>
        </div>
      </template>

      <div
        v-if="order.invoice?.customerInvoicePayments"
        class="flex justify-center items-center m-4"
      >
        <h1>{{ t("orderPayments") }}</h1>
      </div>
      <div class="w-full mb-6" v-if="isOrderPayment">
        <table
          class="payment-table"
          v-for="customerPayment in order.invoice?.customerInvoicePayments"
          :key="customerPayment.id"
        >
          <tr>
            <td>{{ t("date") }}</td>
            <td>{{ parseFormat(customerPayment.createdAt) }}</td>
          </tr>
          <tr>
            <td>{{ t("totalDue") }}</td>
            <td>{{ formatDinero(customerPayment.totalDueDinero) }}</td>
          </tr>
          <tr>
            <td>{{ t("totalPaidBeforeChange") }}</td>
            <td>{{ formatDinero(customerPayment.paidBeforeChangeDinero) }}</td>
          </tr>
          <tr>
            <td>{{ t("changeDue") }}</td>
            <td>{{ formatDinero(customerPayment.changeDueDinero) }}</td>
          </tr>
          <tr>
            <td>{{ t("totalPaid") }}</td>
            <td>{{ formatDinero(customerPayment.totalPaidDinero) }}</td>
          </tr>
        </table>
      </div>
      <div class="w-full mb-6" v-else>
        <table class="w-full">
          <tr>
            <td>{{ t("date") }}</td>
            <td>
              {{ parseFormat(getLastCustomerInvoicePayment().createdAt) }}
            </td>
          </tr>
          <tr>
            <td>{{ t("totalDue") }}</td>
            <td>
              {{ formatDinero(getLastCustomerInvoicePayment().totalDueDinero) }}
            </td>
          </tr>
          <tr>
            <td>{{ t("totalPaidBeforeChange") }}</td>
            <td>
              {{
                formatDinero(
                  getLastCustomerInvoicePayment().paidBeforeChangeDinero
                )
              }}
            </td>
          </tr>
          <tr>
            <td>{{ t("changeDue") }}</td>
            <td>
              {{
                formatDinero(getLastCustomerInvoicePayment().changeDueDinero)
              }}
            </td>
          </tr>
          <tr>
            <td>{{ t("totalPaid") }}</td>
            <td>
              {{
                formatDinero(getLastCustomerInvoicePayment().totalPaidDinero)
              }}
            </td>
          </tr>
        </table>
      </div>
    </template>
  </receipt>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { i18nOrderMessages, Order } from "@/models/sales/Order";
import { parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero, formatMoney } from "@/utils/money";

import Receipt from "@/components/Receipt.vue";

export default defineComponent({
  components: {
    Receipt
  },

  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          totalDue: "Total Due",
          totalPaid: "Total Paid",
          changeDue: "Change Due",
          totalKept: "Total Received",
          serialNumbers: "Serial Numbers",
          print: "Print Receipt",
          newSale: "New Sale",
          totalPaidBeforeChange: "Total Paid Before Change",
          orderPayments: "Order Payments"
        },
        ar: {
          ...i18nOrderMessages.ar,
          totalDue: "المبلغ المطلوب",
          totalPaid: "المبلغ المدفوع",
          changeDue: "الباقي",
          totalKept: "الصافي",
          serialNumbers: "الارقام التسلسلية",
          print: "اطبع الفاتورة",
          newSale: "بيع جديد",
          totalPaidBeforeChange: "المبلغ المدفوع قبل الباقي",
          orderPayments: "المدفوعات"
        }
      }
    });

    function truncateItemName(itemName: string) {
      const maxLength = Math.min(itemName.length, 40);
      return maxLength >= 40
        ? `${itemName.substring(0, maxLength)}...`
        : itemName;
    }

    function getLastCustomerInvoicePayment() {
      const arrayLength =
        props.order.invoice.customerInvoicePayments.length - 1;
      return props.order.invoice?.customerInvoicePayments[arrayLength];
    }
    return {
      t,
      formatDinero,
      formatMoney,
      parseFormat,
      truncateItemName,
      getLastCustomerInvoicePayment
    };
  },

  props: {
    order: {
      required: true,
      type: Order
    },
    isOrderPayment: Boolean
  }
});
</script>

<style>
.payment-table {
  margin: 0 auto;
  width: 91.666667%;
}
</style>
