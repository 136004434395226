<template>
  <el-form
    @submit.prevent="validateSubmit('employeeScheduleForm')"
    novalidate
    :model="formModel"
    :rules="rules"
    label-position="top"
    ref="employeeScheduleForm"
  >
    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('employeeScheduleForm')"
          />
        </div>

        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('employeeScheduleForm')"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <input-section>
      <h1>{{ t("assignNewSchedule") }}</h1>

      <el-form-item
        :label="t('selectUsers')"
        prop="selectedUsers"
        class="input-label"
      >
        <el-select
          filterable
          clearable
          class="w-full"
          v-model="selectedUsers"
          value-key="id"
          remote
          multiple
          :remote-method="searchUsers"
        >
          <el-option
            v-for="option in users"
            :key="option.id"
            :label="option.fullName"
            :value="option.id"
          >
            <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
              {{ option.fullName }}
            </span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="t('selectLocation')"
        prop="selectedLocationID"
        class="input-label"
      >
        <el-select
          v-model="selectedLocationID"
          filterable
          clearable
          class="w-full"
        >
          <el-option
            v-for="location in locations"
            :key="location.id"
            :label="$ctx.locale === 'en' ? location.enName : location.arName"
            :value="location.id"
          >
            <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">{{
              $ctx.locale === "en" ? location.enName : location.arName
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="t('selectDates')"
        prop="selectedDates"
        class="input-label"
      >
        <el-date-picker
          v-model="selectedDates"
          type="dates"
          :placeholder="t('pickDate')"
          size="large"
          :popper-class="$ctx.getDir() === 'rtl' ? 'rtl' : ''"
        >
        </el-date-picker>
      </el-form-item>

      <div class="flex">
        <el-form-item :label="t('startTime')" prop="startTime">
          <el-select v-model="startTime" dir="ltr">
            <el-option
              v-for="(option, i) in timeOptions"
              :key="i"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="t('endTime')" prop="endTime">
          <el-select v-model="endTime" dir="ltr">
            <el-option
              v-for="(option, i) in timeOptions"
              :key="i"
              :label="option.label"
              :value="option.value"
              :disabled="isDisabledEndTime(option.value)"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </input-section>
  </el-form>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import { ElDatePicker, ElForm } from "element-plus";

// models
import { useI18n } from "vue-i18n";
import User from "@/models/user/User";

// util
import dayjs from "dayjs";
import Location from "@/models/company/Location";
import EmployeeSchedule from "@/models/hr/EmployeeSchedule";
import { ExecuteValidator } from "async-validator";

import { useContext } from "@/plugins/context";

type TimeOption = {
  label: string;
  value: string;
};

export default defineComponent({
  name: "employee-schedule-form",
  components: {
    AlertBadge,
    HorizontalButton,
    InputSection,
    ElDatePicker
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          assignNewSchedule: "Assign new schedule",

          selectUsers: "Assign schedule to users...",
          selectDates: "Select dates",
          pickDate: "Pick one or more dates",
          selectTimes: "Select start and end times",

          startTime: "Shift start time",
          endTime: "Shift end time",

          selectLocation: "Select shift location",

          success: "Shifts have been assigned successfully"
        },
        ar: {
          assignNewSchedule: "انشاء جدول جديد",

          selectUsers: "حدد الجدول للمستخدمين",
          selectDates: "حدد الايام",
          pickDate: "اختر يوم او اكثر",
          selectTimes: "حدد الوقت",

          startTime: "وقت بدأ المناوبة",
          endTime: "وقت انتهاء المناوبة",

          selectLocation: "اختر مكان المناوبة",

          success: "تم تعيين المناوبة بنجاح"
        }
      },
      useScope: "global"
    });

    const timeOptions: TimeOption[] = [];
    // generate 30 minute increments for each hour.
    // Year, month, and date are irrelevant.
    // Date is used for formatting purposes.
    for (let i = 0; i < 24; i++) {
      const hour = dayjs(new Date(0, 0, 0, i, 0)).format("hh:mm a");
      const hourThirty = dayjs(new Date(0, 0, 0, i, 30)).format("hh:mm a");
      timeOptions.push(
        { label: hour, value: `${i}:00` },
        { label: hourThirty, value: `${i}:30` }
      );
    }

    const state = reactive({
      selectedDates: new Array<Date>(),
      users: new Array<User>(),
      selectedUsers: new Array<User>(),
      locations: new Array<Location>(),
      selectedLocationID: useContext().currentLocation.id,
      startTime: "9:00",
      endTime: "17:00"
    });

    // stupid thing for the validator
    const formModel = toRefs(state);

    return {
      t,
      timeOptions,
      formModel,
      ...toRefs(state)
    };
  },

  async beforeMount() {
    try {
      const locResp = await this.$http.get<Location[]>(Location.ENDPOINT);
      this.locations = locResp.map(loc => Location.from(loc));
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },

  // mounted() {
  // code to trigger opening a pop up on launch
  //   const datePicker = document.querySelector(".el-date-editor");
  //   const input = datePicker?.querySelector("input");
  //   input?.dispatchEvent(new Event("blur"));
  //   input?.dispatchEvent(new Event("focus"));
  // },

  data() {
    const validateArrayLength: ExecuteValidator = (rule, value, callback) => {
      if (value.value.length === 0) {
        callback([this.$t("validation.required")]);
      } else {
        callback();
      }
    };

    return {
      error: { title: "", body: "" },
      // year, month, and day don't really matter here. We just care about hours and minutes
      rules: {
        selectedUsers: [
          {
            required: true,
            validator: validateArrayLength,
            trigger: "blur"
          }
        ],
        selectedLocationID: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        selectedDates: [
          {
            required: true,
            validator: validateArrayLength,
            trigger: "blur"
          }
        ],
        startTime: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        endTime: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    isDisabledEndTime(endTime: string) {
      const [startTimeHour, startTimeMinute] = this.startTime.split(":");
      const [endTimeHour, endTimeMinute] = endTime.split(":");
      return (
        +endTimeHour < +startTimeHour ||
        (+startTimeHour == +endTimeHour && +endTimeMinute <= +startTimeMinute)
      );
    },

    async searchUsers(query: string) {
      try {
        const url = `${User.ENDPOINT}?q=${query}`;
        const users = await this.$http.get<User[]>(url);
        this.users = users.map(user => User.from(user));
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        this.users = [];
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const payload = new Array<EmployeeSchedule>();
        const [startTimeHour, startTimeMinute] = this.startTime.split(":");
        const [endTimeHour, endTimeMinute] = this.endTime.split(":");

        this.selectedUsers.forEach(user => {
          this.selectedDates.forEach(date => {
            payload.push(
              EmployeeSchedule.from({
                userID: user,
                locationID: this.selectedLocationID,
                workDate: date,
                startWorkHour: +startTimeHour,
                startWorkMinute: +startTimeMinute,
                endWorkHour: +endTimeHour,
                endWorkMinute: +endTimeMinute
              })
            );
          });
        });

        try {
          await this.$http.post(EmployeeSchedule.ENDPOINT, payload);
          this.$alertModal.showSuccess({
            title: this.t("success"),
            body: ""
          });
          try {
            return true;
          } finally {
            this.$router.push(this.$Route.HR);
          }
        } catch (error) {
          this.error.title = error?.title;
          this.error.body = error?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }
      });
    }
  }
});
</script>

<style lang="postcss">
form > h1 {
  @apply text-lg py-3 font-bold;
}

form > h2 {
  @apply py-3 font-bold;
}

formt > h3 {
  @apply py-3 font-bold;
}

form {
  @apply flex flex-col items-center;
}
</style>
