
import { defineComponent } from "vue";

// components
// import Alert from "@/components/Alert.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import AlertBadge from "@/components/AlertBadge.vue";

import { ElForm } from "element-plus";

// models
import Category, { categoryI18nMessages } from "@/models/inventory/Category";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "category-form",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...categoryI18nMessages.en,
          section: "Category Info",
          namePlaceholder: "For example, women clothing",
          skipParent: "Skip"
        },
        ar: {
          ...categoryI18nMessages.ar,
          section: "معلومات المجموعة",
          namePlaceholder: "مثلا: ملابس نسائية",
          skipParent: "تخطي"
        }
      },
      unescape: "global"
    });

    return { t };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    // CustomTextInput,
    InputSection
  },
  props: {
    initCategory: {
      type: Category,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  created() {
    if (!this.category.parentCategory) {
      // create an empty parent
      this.category.parentCategory = new Category();
    }
  },

  async beforeMount() {
    try {
      const catResp = await this.$http.get<Category[]>(Category.ENDPOINT);
      this.categories = catResp.map(cat => Category.from(cat));
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },

  data() {
    return {
      category: this.initCategory,
      categories: new Array<Category>(),
      error: { title: "", body: "" },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          },
          {
            max: 255,
            message: this.$t("validation.maxLength", { len: 255 }),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.category);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    }
  }
});
