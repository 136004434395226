
import { defineComponent } from "vue";
import Dinero, { Dinero as DineroType } from "dinero.js";
import { formatDinero, sumDineros } from "@/utils/money";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "usd-easy-total",
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          group: "Group",
          quantity: "Quantity",
          total: "Total"
        },
        ar: {
          group: "الفئة",
          quantity: "التعداد",
          total: "المجموع"
        }
      }
    });

    return { t };
  },

  props: {
    modelValue: {
      required: false,
      type: Object as () => DineroType
    }
  },

  data() {
    return {
      m1: 0,
      m5: 0,
      m10: 0,
      m20: 0,
      m50: 0,
      m100: 0
    };
  },

  emits: ["update:modelValue"],

  methods: {
    formatDinero
  },

  computed: {
    inputVal: {
      get(): DineroType | undefined {
        return this.modelValue;
      },

      set(val: DineroType) {
        this.$emit("update:modelValue", val);
      }
    },

    total(): string {
      const currency = "USD";
      const precision = 2;
      // USD is represented in cents
      const mult = [
        Dinero({ amount: 100, currency, precision }).multiply(this.m1),
        Dinero({ amount: 500, currency, precision }).multiply(this.m5),
        Dinero({ amount: 1000, currency, precision }).multiply(this.m10),
        Dinero({ amount: 2000, currency, precision }).multiply(this.m20),
        Dinero({ amount: 5000, currency, precision }).multiply(this.m50),
        Dinero({ amount: 10000, currency, precision }).multiply(this.m100)
      ];

      const total = sumDineros(mult, currency, precision);

      this.$emit("update:modelValue", total);

      return formatDinero(total);
    }
  }
});
