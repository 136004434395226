
import { defineComponent } from "vue";
import CustomerForm from "@/views/customers/CustomerForm.vue";

import Customer from "@/models/customer/Customer";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "new-customer",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          created: "Customer was created successfully"
        },
        ar: {
          created: "تم انشاءالزبون بنجاح"
        }
      }
    });

    return { t };
  },

  components: {
    CustomerForm
  },
  data() {
    return {
      initCustomer: new Customer()
    };
  },

  methods: {
    async submit(customer: Customer) {
      try {
        await this.$http.post(Customer.ENDPOINT, customer);
        await this.$router.push(this.$Route.CUSTOMERS);
      } catch (error) {
        return error;
      }
    }
  }
});
