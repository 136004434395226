import { RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Inventory from "@/views/inventory/Inventory.vue";
import Sales from "@/views/sales/Sales.vue";
import Customers from "@/views/customers/Customers.vue";
import HumanResources from "@/views/hr/HumanResources.vue";
import Accounting from "@/views/accounting/Accounting.vue";
import Settings from "@/views/settings/Settings.vue";
import Vendors from "@/views/vendors/Vendors.vue";

export enum Route {
  HOME = "/",

  LOGIN = "/login",

  SALES = "/sales",
  SALES_NEW_SALE = "/sales/new-sale",
  SALES_ORDERS = "/sales/orders",
  SALES_ORDERS_ORDER = "/sales/orders/order/:id",
  SALES_ORDERS_ORDER_RECEIPT = "/sales/orders/order/:id/receipt",
  SALES_ORDERS_RETURN_ORDER = "/sales/orders/return-orders",
  SALES_ORDERS_RETURN_ORDER_RECEIPT = "/sales/orders/return-orders/:orderID/receipt/:id",
  SALES_ORDERS_ORDER_PAYMENT = "/sales/orders/order/:id/order-payment",
  SALES_ORDERS_ORDER_PAYMENT_RECEIPT = "/sales/orders/order-payment/:id/receipt",
  SALES_REGISTERS_OPEN_REGISTER = "/sales/registers/open-register",
  SALES_REGISTERS_CLOSE_REGISTER = "/sales/registers/close-register",

  INVENTORY = "/inventory",
  INVENTORY_ITEMS = "/inventory/items",
  INVENTORY_ITEMS_SIMPLE_ITEM = "/inventory/items/simple-item/:id",
  INVENTORY_ITEMS_NEW_SIMPLE_ITEM = "/inventory/items/new-simple-item",
  INVENTORY_ITEM_BRANDS = "/inventory/item-brands",
  INVENTORY_ITEM_BRANDS_BRAND = "/inventory/item-brands/brand/:id",
  INVENTORY_ITEM_BRANDS_NEW_BRAND = "/inventory/item-brands/new-brand",
  INVENTORY_ITEM_CATEGORIES = "/inventory/item-categories",
  INVENTORY_ITEM_CATEGORIES_CATEGORY = "/inventory/item-categories/category/:id",
  INVENTORY_ITEM_CATEGORIES_NEW_CATEGORY = "/inventory/item-categories/new-category",
  INVENTORY_ITEM_TAGS = "/inventory/item-tags",
  INVENTORY_ITEM_TAGS_TAG = "/inventory/item-tags/tag/:id",
  INVENTORY_ITEM_TAGS_NEW_TAG = "/inventory/item-tags/new-tag",
  INVENTORY_INVENTORY_RECEIVINGS = "/inventory/inventory-receivings",
  INVENTORY_INVENTORY_RECEIVINGS_INVENTORY_RECEIVING = "/inventory/inventory-receivings/inventory-receiving/:id",
  INVENTORY_INVENTORY_RECEIVINGS_NEW_INVENTORY_RECEIVING = "/inventory/inventory-receivings/new-inventory-receiving",
  INVENTORY_INVENTORY_ADJUSTMENTS = "/inventory/inventory-adjustments",
  INVENTORY_INVENTORY_ADJUSTMENTS_INVENTORY_ADJUSTMENT = "/inventory/inventory-adjustments/inventory-adjustment/:id",
  INVENTORY_INVENTORY_ADJUSTMENTS_NEW_INVENTORY_ADJUSTMENT = "/inventory/inventory-adjustments/new-inventory-adjustment",
  INVENTORY_INVENTORY_TRANSFERS = "/inventory/inventory-transfers",
  INVENTORY_INVENTORY_TRANSFERS_INVENTORY_TRANSFER = "/inventory/inventory-transfers/inventory-transfer/:id",
  INVENTORY_INVENTORY_TRANSFERS_NEW_INVENTORY_TRANSFER = "/inventory/inventory-transfers/new-inventory-transfer",
  INVENTORY_INVENTORY_TRANSFERS_RECEIVE_INVENTORY_TRANSFER = "/inventory/inventory-transfers/receive-inventory-transfer",

  INVENTORY_PRINT_BARCODE = "/inventory/print-barcode",

  CUSTOMERS = "/customers",
  CUSTOMERS_CUSTOMER = "/customers/customer/:id",
  CUSTOMERS_NEW_CUSTOMER = "/customers/new-customer",

  VENDORS = "/vendors",
  VENDORS_VENDOR = "/vendors/vendor/:id",
  VENDORS_NEW_VENDOR = "/vendors/new-vendor",

  HR = "/hr",
  HR_EMPLOYEES = "/hr/employees",
  HR_EMPLOYEES_EMPLOYEE = "/hr/employees/employee/:id",
  HR_EMPLOYEES_NEW_EMPLOYEE = "/hr/employees/new-employee",
  HR_EMPLOYEE_SCHEDULE = "/hr/employee-schedules",
  HR_EMPLOYEE_SCHEDULES_NEW_EMPLOYEE_SCHEDULE = "/hr/employee-schedules/new-employee-schedule",

  ACCOUNTING = "/accounting",
  ACCOUNTING_ACCOUNTS = "/accounting/accounts",
  ACCOUNTING_ACCOUNTS_ACCOUNT = "/accounting/accounts/account/:id",
  ACCOUNTING_ACCOUNTS_NEW_ACCOUNT = "/accounting/accounts/new-account",
  ACCOUNTING_TRANSACTIONS = "/accounting/transactions",
  ACCOUNTING_TRANSACTIONS_TRANSACTION = "/accounting/transactions/transaction/:id",
  ACCOUNTING_TRANSACTIONS_NEW_TRANSACTION = "/accounting/transactions/new-transaction",
  ACCOUNTING_CUSTOMERS = "/accounting/customers",
  ACCOUNTING_CUSTOMER_INVOICES = "/accounting/customers/customer-invoices/:id",
  ACCOUNTING_VENDORS = "/accounting/vendors",
  ACCOUNTING_VENDORS_NEW_BILL = "/accounting/vendors/new-bill",
  ACCOUNTING_VENDORS_BILL_PAYMENT = "/accounting/vendors/:id/vendor-bill-payment",
  ACCOUNTING_REGISTERS = "/accounting/registers",
  ACCOUNTING_REGISTERS_REGISTER = "/accounting/registers/register/:id",
  ACCOUNTING_HR = "/accounting/hr",
  ACCOUNTING_HR_NEW_PAY = "/accounting/hr/new-pay",
  ACCOUNTING_COMPANY = "/accounting/company",
  ACCOUNTING_COMPANY_NEW_EXPENSE = "/accounting/company/new-expense",

  REPORTS = "/reports",
  REPORTS_SALES_SALES_BY_CATEGORY = "/reports/sales/sales-by-category",
  REPORTS_SALES_SALES_BY_ITEM = "/reports/sales/sales-by-item",
  REPORTS_SALES_TOP_SOLD_ITEMS = "/reports/sales/top-sold-items",
  REPORTS_SALES_LEAST_SOLD_ITEMS = "/reports/sales/least-sold-items",
  REPORTS_SALES_SALES_SUMMARY = "/reports/sales/sales-summary",
  REPORTS_SALES_SALES_NEW_REGISTER_CLOSING = "/reports/sales/new-register-closing",
  REPORTS_SALES_SALES_REGISTER_CLOSING_REPORT = "/reports/sales/register-closing-report",
  REPORTS_SALES_END_OF_DAY = "/reports/sales/end-of-day",
  REPORTS_SALES_SALES_COMPARISON = "/reports/sales/sales-comparison",
  REPORTS_SALES_SALES_HEATMAP = "/reports/sales/sales-heatmap",
  REPORTS_CUSTOMERS_TOP_CUSTOMERS = "/reports/customers/top-customers",
  REPORTS_VENDORS_TOP_VENDORS = "/reports/vendors/top-vendors",
  REPORTS_INVENTORY_QUANTITY = "/reports/inventory/quantity",
  REPORTS_INVENTORY_INVENTORY_VALUE = "/reports/inventory/inventory-value",
  REPORTS_INVENTORY_INVENTORY_VALUE_DETAILS = "/reports/inventory/inventory-value-details",
  REPORTS_ACCOUNTING_PROFIT_AND_LOSS = "/reports/accounting/profit-and-loss",
  REPORTS_VENDORS_VENDORS_BALANCES = "/reports/vendors/vendors-balances",
  REPORTS_ACCOUNTING_CUSTOMERS_BALANCES = "/reports/accounting/customers-balances",

  SETTINGS = "/settings"
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: Route.HOME,
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.LOGIN,
    name: "login",
    component: Login,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.SALES,
    name: "sales",
    component: Sales,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_NEW_SALE,
    name: "new-sale",
    component: () =>
      import(/* webpackChunkName: "sales" */ "@/views/sales/NewCheckout.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS,
    name: "orders",
    component: () =>
      import(/* webpackChunkName: "sales" */ "@/views/sales/Orders.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_ORDER,
    name: "order",
    component: () =>
      import(/* webpackChunkName: "sales" */ "@/views/sales/Order.vue"),
    meta: {
      requiresAuth: true
    }
  },
  // need to verify this
  {
    path: Route.SALES_ORDERS_ORDER_RECEIPT,
    name: "order-receipt",
    component: () =>
      import(/* webpackChunkName: "sales" */ "@/views/sales/OrderReceipt.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_RETURN_ORDER,
    name: "return-order",
    component: () =>
      import(/* webpackChunkName: "sales" */ "@/views/sales/ReturnOrder.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_RETURN_ORDER_RECEIPT,
    name: "return-order-receipt",
    component: () =>
      import(
        /* webpackChunkName: "sales" */ "@/views/sales/ReturnOrderReceipt.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_ORDER_PAYMENT,
    name: "order-payment",
    component: () =>
      import(/* webpackChunkName: "sales" */ "@/views/sales/OrderPayment.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_ORDERS_ORDER_PAYMENT_RECEIPT,
    name: "order-payment-receipt",
    component: () =>
      import(
        /* webpackChunkName: "sales" */ "@/views/sales/OrderPaymentReceipt.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_REGISTERS_OPEN_REGISTER,
    name: "open-register",
    component: () =>
      import(
        /* webpackChunkName: "sales" */ "@/views/sales/registers/OpenRegister.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SALES_REGISTERS_CLOSE_REGISTER,
    name: "close-register",
    component: () =>
      import(
        /* webpackChunkName: "sales" */ "@/views/sales/registers/CloseRegister.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.INVENTORY,
    name: "inventory",
    component: Inventory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEMS,
    name: "inventory-items",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/items/Items.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEMS_SIMPLE_ITEM,
    name: "simple-item",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/items/SimpleItem.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEMS_NEW_SIMPLE_ITEM,
    name: "new-simple-item",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/items/NewSimpleItem.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_BRANDS,
    name: "item-brands",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/brands/Brands.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_BRANDS_BRAND,
    name: "brand",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/brands/Brand.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_BRANDS_NEW_BRAND,
    name: "new-brand",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/brands/NewBrand.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_CATEGORIES,
    name: "categories",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/categories/Categories.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_CATEGORIES_CATEGORY,
    name: "category",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/categories/Category.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_CATEGORIES_NEW_CATEGORY,
    name: "new-category",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/categories/NewCategory.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_TAGS,
    name: "tags",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/tags/Tags.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_TAGS_TAG,
    name: "tag",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/tags/Tag.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_ITEM_TAGS_NEW_TAG,
    name: "new-tag",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/tags/NewTag.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_RECEIVINGS,
    name: "inventory-receivings",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/inventoryReceivings/InventoryReceivings.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_RECEIVINGS_INVENTORY_RECEIVING,
    name: "inventory-receiving",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/inventoryReceivings/InventoryReceiving.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_RECEIVINGS_NEW_INVENTORY_RECEIVING,
    name: "new-inventory-receiving",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/inventoryReceivings/NewInventoryReceiving.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_ADJUSTMENTS,
    name: "inventory-adjustments",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/inventoryAdjustments/InventoryAdjustments.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_ADJUSTMENTS_INVENTORY_ADJUSTMENT,
    name: "inventory-adjustment",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/inventoryAdjustments/InventoryAdjustment.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_ADJUSTMENTS_NEW_INVENTORY_ADJUSTMENT,
    name: "new-inventory-adjustment",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/inventoryAdjustments/NewInventoryAdjustment.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_TRANSFERS,
    name: "inventory-transfers",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/inventoryTransfers/InventoryTransfers.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_TRANSFERS_INVENTORY_TRANSFER,
    name: "inventory-transfer",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/inventoryTransfers/InventoryTransfer.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_TRANSFERS_NEW_INVENTORY_TRANSFER,
    name: "new-inventory-transfer",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/inventoryTransfers/NewInventoryTransfer.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_INVENTORY_TRANSFERS_RECEIVE_INVENTORY_TRANSFER,
    name: "receive-inventory-transfer",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/inventoryTransfers/ReceiveInventoryTransfer.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.INVENTORY_PRINT_BARCODE,
    name: "print-barcode",
    component: () =>
      import(
        /* webpackChunkName: "inventory" */ "@/views/inventory/barcode/PrintBarcode.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.CUSTOMERS,
    name: "customers",
    component: Customers
  },
  {
    path: Route.CUSTOMERS_CUSTOMER,
    name: "customer",
    component: () =>
      import(
        /* webpackChunkName: "customers" */ "@/views/customers/Customer.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.CUSTOMERS_NEW_CUSTOMER,
    name: "new-customer",
    component: () =>
      import(
        /* webpackChunkName: "customers" */ "@/views/customers/NewCustomer.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.VENDORS,
    name: "vendors",
    component: Vendors
  },

  {
    path: Route.VENDORS_VENDOR,
    name: "vendor",
    component: () =>
      import(/* webpackChunkName: "vendors" */ "@/views/vendors/Vendor.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.VENDORS_NEW_VENDOR,
    name: "new-vendor",
    component: () =>
      import(/* webpackChunkName: "vendors" */ "@/views/vendors/NewVendor.vue"),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.HR,
    name: "hr",
    component: HumanResources,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.HR_EMPLOYEES,
    name: "employees",
    component: () =>
      import(/* webpackChunkName: "hr" */ "@/views/hr/employees/Employees.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.HR_EMPLOYEES_EMPLOYEE,
    name: "employee",
    component: () =>
      import(/* webpackChunkName: "hr" */ "@/views/hr/employees/Employee.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.HR_EMPLOYEES_NEW_EMPLOYEE,
    name: "new-employee",
    component: () =>
      import(
        /* webpackChunkName: "hr" */ "@/views/hr/employees/NewEmployee.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.HR_EMPLOYEE_SCHEDULE,
    name: "employee-schedules",
    component: () =>
      import(
        /* webpackChunkName: "hr" */ "@/views/hr/employeeSchedule/ViewEmployeeSchedule.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.HR_EMPLOYEE_SCHEDULES_NEW_EMPLOYEE_SCHEDULE,
    name: "new-employee-schedule",
    component: () =>
      import(
        /* webpackChunkName: "hr" */ "@/views/hr/employeeSchedule/NewEmployeeSchedule.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.ACCOUNTING,
    name: "accounting",
    component: Accounting,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_ACCOUNTS,
    name: "accounts",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/accounts/Accounts.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_ACCOUNTS_ACCOUNT,
    name: "account",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/accounts/Account.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_ACCOUNTS_NEW_ACCOUNT,
    name: "new-account",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/accounts/NewAccount.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_TRANSACTIONS,
    name: "transactions",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/transactions/Transactions.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_TRANSACTIONS_TRANSACTION,
    name: "transaction",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/transactions/Transaction.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_TRANSACTIONS_NEW_TRANSACTION,
    name: "new-transaction",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/transactions/NewTransaction.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_CUSTOMERS,
    name: "accounting-customers",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/customers/Customers.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_CUSTOMER_INVOICES,
    name: "customer-invoices",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/customers/CustomerInvoicesByCustomer.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_VENDORS,
    name: "accounting-vendors",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/vendors/Vendors.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_REGISTERS,
    name: "accounting-registers",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/registers/Registers.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_HR,
    name: "accounting-hr",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/hr/Hr.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_COMPANY,
    name: "company",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/company/Company.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_VENDORS_NEW_BILL,
    name: "new-bill",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/vendors/NewBill.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_VENDORS_BILL_PAYMENT,
    name: "bill-payment",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/vendors/VendorBillPayment.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_REGISTERS_REGISTER,
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/registers/Register.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_HR_NEW_PAY,
    name: "hr-newpay",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/hr/NewPay.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.ACCOUNTING_COMPANY_NEW_EXPENSE,
    name: "new-expense",
    component: () =>
      import(
        /* webpackChunkName: "accounting" */ "@/views/accounting/company/NewExpense.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: Route.REPORTS,
    name: "reports",
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/reports/Reports.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_BY_CATEGORY,
    name: "sales-by-category",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/sales/SalesByCategory.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_TOP_SOLD_ITEMS,
    name: "top-sold-items",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/sales/TopSoldItems.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_BY_ITEM,
    name: "sales-by-item",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/sales/SalesByItem.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_CUSTOMERS_TOP_CUSTOMERS,
    name: "top-customers",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/customers/TopCustomers.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_LEAST_SOLD_ITEMS,
    name: "least-sold-items",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/sales/LeastSoldItems.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_VENDORS_TOP_VENDORS,
    name: "top-vendors",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/vendors/TopVendors.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_INVENTORY_INVENTORY_VALUE,
    name: "inventory-value",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/inventory/InventoryValue.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_INVENTORY_INVENTORY_VALUE_DETAILS,
    name: "inventory-value-details",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/inventory/InventoryValueDetails.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_SUMMARY,
    name: "sales-summary",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/sales/SalesSummary.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_NEW_REGISTER_CLOSING,
    name: "new-register-closing",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/sales/RegisterClosing/NewRegisterClosing.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_END_OF_DAY,
    name: "end-of-day",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/sales/EndOfDay.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_COMPARISON,
    name: "sales-comparison",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/sales/SalesComparison.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_REGISTER_CLOSING_REPORT,
    name: "register-closing-report",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/sales/RegisterClosing/RegisterClosingReport.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_VENDORS_VENDORS_BALANCES,
    name: "vendors-balances",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/vendors/VendorsBalances.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_ACCOUNTING_CUSTOMERS_BALANCES,
    name: "customers-balances",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/accounting/CustomersBalances.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_SALES_SALES_HEATMAP,
    name: "sales-heatmap",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/sales/SalesHeatmap.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_INVENTORY_QUANTITY,
    name: "quantity-report",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/inventory/QuantityReport.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.REPORTS_ACCOUNTING_PROFIT_AND_LOSS,
    name: "profit-and-loss-report",
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/reports/accounting/ProfitAndLoss.vue"
      ),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: Route.SETTINGS,
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true
    }
  }
];
