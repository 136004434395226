
import { defineComponent } from "vue";

import HorizontalButton from "@/components/HorizontalButton.vue";
import ActionButton from "@/components/ActionButton.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

// models
import { i18nOrderMessages, Order, OrderItem } from "@/models/sales/Order";
import Item from "@/models/inventory/Item";
import Customer from "@/models/customer/Customer";
import { Register, RegisterLog } from "@/models/company/Register";

// tools
import { useI18n } from "vue-i18n";
import { localizeFieldName } from "@/plugins/i18n";

// helpers
import { formatDinero } from "@/utils/money";
import PaymentInput from "@/views/sales/components/PaymentInput.vue";
import MoneyInput from "@/components/form/MoneyInput.vue";

import { ElPopover } from "element-plus";

export default defineComponent({
  name: "new-sale",
  setup() {
    const { t } = useI18n({
      useScope: "global",
      messages: {
        en: {
          ...i18nOrderMessages.en,

          searchItems: "Enter item barcode or SKU or name",
          buttons: {
            addCustomer: "Add Customer",
            payment: "Payment",
            direct: "Direct",
            layaway: "Layaway Payment",
            finance: "Partial",
            quote: "Save as Quote",
            cancel: "Cancel Order",
            confirm: "Confirm",
            completePay: "Complete Payment"
          },

          selectSerials: "Select serials",
          selectCustomer: "Select a customer",
          selectPaymentType: "Select order type",

          outOfStockErr:
            "Item {name} is out of stock. Please update your stock before selling this item",

          successMsg: "Sale complete",

          errPaymentMustMatch:
            "Payment must be made in full. If you'd like to pay partially, choose layaway or finance"
        },
        ar: {
          ...i18nOrderMessages.ar,

          searchItems: "ادخل الباركود او ال SKU او اسم المنتج",
          buttons: {
            addCustomer: "اضافة زبون",
            payment: "دفع",
            direct: "مباشر",
            layaway: "الدفع بالعربون",
            finance: "بيع بالدين",
            quote: "الحفظ كعرض",
            cancel: "الغاء البيع",
            confirm: "التأكيد",
            completePay: "اتمام الدفع"
          },

          selectSerials: "اختر الارقام",
          selectCustomer: "اختر زبون",
          selectPaymentType: "اختر نوع البيع",

          outOfStockErr:
            "نفذ مخزون المنتج {name}. يرجى اعادة تحديث المخزن قبل بيع المنتج.",

          successMsg: "تم اكمال البيع",

          errPaymentMustMatch:
            "يجب ان يتم دفع المبلغ بشكل كامل. لدفع مبلغ جزئي، قم بتحويل نوع الدفع الى دين او عربون"
        }
      }
    });

    return { t };
  },

  components: {
    HorizontalButton,
    ActionButton,
    MoneyInput,
    PaymentInput,
    AlertBadge,
    LoadingSpinner,
    ElPopover
  },

  async mounted() {
    // load register status
    try {
      this.loading = true;
      const registerLog = await this.$http.get<RegisterLog>(
        `${Register.ENDPOINT}/status/${this.$ctx.currentRegister.id}`
      );

      this.$ctx.updateRegisterStatus(RegisterLog.from(registerLog).action);

      if (!registerLog.action || registerLog.action === "close") {
        await this.$router.push(this.$Route.SALES_REGISTERS_OPEN_REGISTER);
      }
    } catch (error) {
      this.error.title = error.title;
      this.error.body = error.body;
    } finally {
      this.loading = false;
    }
  },

  data() {
    const customers = new Array<Customer>();
    const customerName = "";
    const selectedIndex = 0;
    const direction = "rtl";

    return {
      order: new Order(),
      pressedEnter: false,
      itemSearchTerm: "",
      error: { title: "", body: "" },

      currentOrderItem: new OrderItem(new Item()),
      insertSerialDialogVisible: false,

      customerDialogOpen: false,
      customers,
      customerName,
      selectedIndex,

      checkoutDrawerOpen: false,
      direction,

      loading: false
    };
  },

  methods: {
    onCustomerNameChange(value: string) {
      this.selectedIndex = this.customers.findIndex(
        customer => customer.id === value
      );
    },

    resetState() {
      this.order = new Order();
      this.pressedEnter = false;
      this.itemSearchTerm = "";
      this.error = { title: "", body: "" };

      this.currentOrderItem = new OrderItem(new Item());
      this.insertSerialDialogVisible = false;

      this.customerDialogOpen = false;
      this.customers = new Array<Customer>();

      this.checkoutDrawerOpen = false;
    },

    formatDinero,

    openSerialDialog(oi: OrderItem) {
      this.currentOrderItem = oi;
      this.insertSerialDialogVisible = true;
    },

    closeSerialDialog() {
      this.currentOrderItem = new OrderItem(new Item());
      this.insertSerialDialogVisible = false;
    },

    async searchItems(query: string, cb: Function) {
      if (this.pressedEnter) {
        this.itemSearchTerm = "";
      }

      // if an item already exists in the table, don't do remote search
      const cachedItem =
        this.order.findByBarcode(query) || this.order.findBySKU(query);
      if (cachedItem) {
        this.addItem(cachedItem);
        cb([]);
        return;
      }

      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        let items = await this.$http.get<Item[]>(url);
        items = items.map(item => Item.from(item));

        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && items.length === 1) {
          this.addItem(items[0]);
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }

      this.pressedEnter = false;
    },

    openCustomerDialog() {
      this.$ctx.getDir() === "rtl"
        ? (this.direction = "rtl")
        : (this.direction = "ltr");

      this.customerDialogOpen = true;
    },

    closeCustomerDialog() {
      this.customerName = this.customers[this.selectedIndex].contactDisplayName;
      this.customerDialogOpen = false;
    },

    async searchCustomers(query: string) {
      try {
        const url = `${Customer.ENDPOINT}?q=${query}`;
        const customers = await this.$http.get<Customer[]>(url);
        this.customers = customers.map(customer => Customer.from(customer));
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        this.customers = [];
      }
    },

    addItem(selected: Item) {
      this.itemSearchTerm = "";
      // don't add items that are out of stock!
      if (
        selected.currentLocationStock.quantity <= 0 &&
        !(selected.unlimitedStock || selected.sellOutOfStock)
      ) {
        this.$alertModal.showDanger({
          title: this.t("outOfStockErr", { name: selected.name })
        });
        return;
      }

      const oi = this.order.addItem(selected);
      if (selected.isSerialized) {
        this.openSerialDialog(oi);
      }
    },

    openCheckoutDialog() {
      // this.order.prepareForCheckout();
      if (!this.validateOrder()) {
        this.checkoutDrawerOpen = false;
      }
    },

    // having validate here simplifies things
    // since i18n is already available
    validateOrder() {
      const errors: string[] = [];

      this.order.orderItems.forEach(oi => {
        if (oi.item?.isSerialized && oi.serialIDs.length !== oi.quantity) {
          const errMsg = `${oi.item.name}: ${this.t(
            "validation.matchingLength",
            {
              thisField: localizeFieldName(
                "lenSerialNumbers",
                i18nOrderMessages
              ),
              thatField: localizeFieldName("quantity", i18nOrderMessages)
            }
          )}`;
          errors.push(errMsg);
        }
      });

      if (errors.length) {
        this.error.title = this.t("validation.inputErrors");
        this.error.body = errors.join("\n");
        return false;
      }

      return true;
    },

    validatePayment() {
      // const totalDue = this.order.orderPayments[0].totalDueAmount;
      // const totalPaid = this.order.orderPayments[0].totalPaidAmount;

      // if (this.order.paymentType === "direct" && totalDue !== totalPaid) {
      //   this.error.title = this.t("errPaymentMustMatch");
      //   this.checkoutDrawerOpen = false;
      //   return false;
      // }

      return true;
    },

    async submitOrder() {
      if (!this.validateOrder() || !this.validatePayment()) return;

      // print receipt if all good
      this.error.title = "";
      this.error.body = "";

      this.order.preparePayload(
        this.$ctx.currentUser,
        this.$ctx.currentRegister,
        this.$ctx.currentLocation
      );

      try {
        const o = await this.$http.post<Order>(Order.ENDPOINT, this.order);
        this.$alertModal.showSuccess({ title: this.t("successMsg") });
        await this.$router.push(
          this.$Route.SALES_ORDERS_ORDER_RECEIPT.replace(":id", o.id || "")
        );
      } catch (error) {
        this.error.title = error.title;
        this.error.body = error.body;
        this.checkoutDrawerOpen = false;
      }
    }
  }
});
