
import { defineComponent } from "vue";

export default defineComponent({
  name: "actionButton",
  props: {
    id: String,
    title: String,
    faIcon: String,
    faPrefix: {
      required: false,
      type: String,
      default: "fas"
    },
    isDanger: Boolean,
    isSuccess: Boolean,
    isWarning: Boolean
  }
});
