
// note that the parent element wrappign tooltip must have position: relative
// for this to work!
import { defineComponent } from "vue";

export default defineComponent({
  name: "ToolTip",
  props: {
    tip: {
      type: String,
      require: true
    }
  }
});
