/*
   a naive implementation of a fixed size stack
*/
export class FixedSizeStack<T> {
  stack: Array<T>;
  maxSize: number;

  constructor(maxSize = 10) {
    this.stack = new Array<T>();
    this.maxSize = maxSize;
  }

  push(item: T) {
    if (this.stack.length === this.maxSize) {
      // make room
      this.stack.shift();
    }
    this.stack.push(item);
  }

  pop() {
    return this.stack.pop();
  }

  size() {
    return this.stack.length;
  }

  empty() {
    return this.size() === 0;
  }

  peek() {
    // should only be called after calling is empty or size
    return this.stack[this.stack.length - 1];
  }
}
