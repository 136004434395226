
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import { ColDef, GridOptions } from "ag-grid-community";
import HorizontalButton from "@/components/HorizontalButton.vue";

import Category, { categoryI18nMessages } from "@/models/inventory/Category";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "categories",

  setup() {
    const { t } = useI18n({
      messages: categoryI18nMessages
    });

    return { t };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: Category[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.t("name"),
        field: "name",
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.t("parentCategory"),
        field: "parentCategory.name"
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();

    // go to view category
    this.gridOptions.onCellDoubleClicked = event => {
      this.$router.push(
        this.$Route.INVENTORY_ITEM_CATEGORIES_CATEGORY.replace(
          ":id",
          event.data.id
        )
      );
    };
  },

  watch: {
    searchTerm() {
      this.filterResult();
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();

      try {
        const data = await this.$http.get<Category[]>(Category.ENDPOINT);
        this.rowData = data.map(category => Category.from(category));
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    },

    async delete(categories: Category[]) {
      if (
        confirm(this.$t("views.common.listView.selectedRows.deleteConfirm"))
      ) {
        try {
          await this.$http.delete(Category.ENDPOINT, { data: categories });
          await this.updateRowData();
          this.$alertModal.showSuccess({
            title: this.$t("views.common.listView.selectedRows.deleteSuccess")
          });
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          });
        }
      }
    },

    async applyToSelected() {
      if (this.selectedValue === "delete" || this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();

        if (selected?.length) {
          if (this.selectedValue === "delete") {
            // send request to archive
            await this.delete(selected);
          } else {
            this.gridOptions.api?.exportDataAsCsv({ onlySelected: true });
            this.$alertModal.showSuccess({
              title: this.$t("views.common.listView.selectedRows.exportSuccess")
            });
          }

          // deselect
          selected.length = 0;
        }
      }

      this.selectedValue = "";
    },

    filterResult() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    }
  }
});
