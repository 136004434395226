<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title && !hasLoaded"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />

  <div v-else class="w-3/6 md:w-1/3 bg-white p-5 mx-2 mt-2">
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <div v-if="orderPayment">
      <PaymentInput
        :totalDue="orderPayment.totalDueAmount"
        v-model:changeDue="orderPayment.changeDueAmount"
        v-model:paidBeforeChange="orderPayment.paidBeforeChangeAmount"
        v-model:totalPaid="orderPayment.totalPaidAmount"
        :currency="orderPayment.currency"
        :precision="orderPayment.precision"
      />
    </div>

    <div class="flex justify-between items-center mb-2 mt-2">
      <horizontal-button
        :title="t('placePay')"
        isSuccess
        @click="submitPayment"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "@vue/runtime-core";

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import PaymentInput from "./components/PaymentInput.vue";

import { useI18n } from "vue-i18n";
import {
  CustomerInvoice,
  CustomerInvoicePayment
} from "@/models/sales/CustomerInvoice";
import { useHTTP } from "@/plugins/http";
import { useAlertModal } from "@/plugins/alert-modal/alert-modal";
import { useRouter } from "vue-router";
import { Route } from "@/router/routes";

export default defineComponent({
  name: "order-payment",

  components: {
    LoadingSpinner,
    AlertBadge,
    HorizontalButton,
    PaymentInput
  },

  setup() {
    const http = useHTTP();
    const alertModal = useAlertModal();
    const router = useRouter();
    const params = router.currentRoute.value.params;
    const loading = ref(false);
    const hasLoaded = ref(false);
    let invoice: CustomerInvoice;
    const orderPayment = ref(new CustomerInvoicePayment());
    orderPayment.value.changeDueAmount = 0;
    orderPayment.value.paidBeforeChangeAmount = 0;
    const error = ref({
      title: "",
      body: ""
    });

    const { t } = useI18n({
      messages: {
        en: {
          successMsg: "Payment made successfully",
          errInvalidAmount: "Invalid amount. Must be bigger > 0",
          errPaymentIsComplete: "Payments are already complete, nothing to pay",
          placePay: "Place payment"
        },
        ar: {
          successMsg: "تم الدفع بنجاح",
          errInvalidAmount: "يجب ان يكون المبلغ المدفوع اكبر من 0",
          errPaymentIsComplete: "تم دفع المبلغ كاملا",
          placePay: "اتمام الدفع"
        }
      }
    });

    onBeforeMount(async () => {
      try {
        loading.value = true;
        const invoiceRes = await http.get<CustomerInvoice>(
          `${CustomerInvoice.ENDPOINT}/${params.id}`
        );

        invoice = CustomerInvoice.from(invoiceRes);

        orderPayment.value.currency = invoice.currency;
        orderPayment.value.precision = invoice.precision;
        orderPayment.value.customerInvoiceID = invoice.id;

        const paymentRemaining = invoice.totalBalanceAmount;
        orderPayment.value.totalDueAmount = paymentRemaining;
        if (paymentRemaining === 0) {
          alertModal.showDanger({
            title: t("errPaymentIsComplete")
          });

          await router.push(
            Route.SALES_ORDERS_ORDER.replace(":id", params.id as string)
          );
        }
      } catch (err) {
        error.value.title = err.title;
        error.value.body = err.body;
        loading.value = false;
        hasLoaded.value = true;
      } finally {
        loading.value = false;
        hasLoaded.value = true;
      }
    });

    async function submitPayment() {
      if ((orderPayment.value.totalPaidAmount || 1) <= 0) {
        error.value.title = t("validation.inputErrors");
        error.value.body = t("errInvalidAmount");
        return;
      }
      try {
        await http.post<CustomerInvoicePayment>(
          `${CustomerInvoicePayment.ENDPOINT}/payments`,
          orderPayment.value
        );
      } catch (err) {
        error.value.title = err.title;
        error.value.body = err.body;
      } finally {
        alertModal.showSuccess({
          title: t("successMsg")
        });

        await router.push(
          Route.SALES_ORDERS_ORDER_PAYMENT_RECEIPT.replace(
            ":id",
            invoice.referenceableID || ""
          )
        );
      }
    }
    return { t, orderPayment, submitPayment, error, loading, hasLoaded };
  }
});
</script>

<style></style>
