
import { defineComponent } from "vue";
import InventoryTransfer, {
  inventoryTransferI18nMessages
} from "@/models/inventory/InventoryTransfer";
import Location from "@/models/company/Location";
import { ElForm } from "element-plus";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import InputGroup from "@/components/form/InputGroup.vue";
import Item from "@/models/inventory/Item";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "new-inventory-receiving",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryTransferI18nMessages.en,

          sections: {
            info: "Meta data",
            inventory: "Inventory Info"
          },

          placeholders: {
            reason: "Example: store beta is running low on item alpha",
            itemName: "Enter barcode, sku, or item name"
          },

          userTransfering: "Employee responsible for this inventory transfer",
          insertSerials: "You must insert {num} serial numbers",

          currentQuantity: "Quantity left",
          sentQuantity: "Sent quantity",

          successMsg: "Inventory was transfered successfully",

          transferOutType: "Choose transfer type",
          direct: "Direct Transfer",
          indirect: "Indirect Transfer",

          tooltips: {
            transferOutType: "With direct transfer, the "
          }
        },
        ar: {
          ...inventoryTransferI18nMessages.ar,

          sections: {
            info: "البيانات الوصفية",
            inventory: "البضاعة المنقولة"
          },

          placeholders: {
            reason: "مثلا: نفذت البضاعة من محل اخر",
            itemName: "ادخل اسم المنتج او الباركود او SKU"
          },

          userTransfering: "الموظف المسؤول عن نقل البضاعة",
          insertSerials: "قم بادخال {num} serial numbers",

          currentQuantity: "الكمية المتبقية",
          sentQuantity: "الكمية المرسلة",

          successMsg: "تم نقل البضاعة بنجاح",

          transferOutType: "اختر نوع النقل",
          direct: "مباشر",
          indirect: "غير مباشر",

          tooltips: {
            transferOutType:
              "باستخدام النقل المباشر، تتحدث الكمية في الموقع المستلم بشكل مباشر. اما بالنقل الغير مباشر، فيجب استلام البضاعة من الموقع المستلم بشكل يدوي"
          }
        }
      }
    });

    return { t };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection,
    InputGroup
  },

  async beforeMount() {
    try {
      const locResp = await this.$http.get<Location[]>(Location.ENDPOINT);
      this.locations = locResp
        .map(loc => Location.from(loc))
        // exclude this location
        .filter(loc => loc.id !== this.$ctx.currentLocation.id);
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },

  data() {
    return {
      inventoryTransfer: new InventoryTransfer("out"),
      itermSearchTerm: "",
      error: { title: "", body: "" },
      serials: [],
      pressedEnter: false,
      user: this.$ctx.currentUser,
      locations: new Array<Location>(),
      rules: {
        reason: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        transferOutType: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        toLocationID: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    async searchItems(query: string, cb: Function) {
      // if an item already exists in the table, don't do remote search
      const cachedItem =
        this.inventoryTransfer.findByBarcode(query) ||
        this.inventoryTransfer.findBySKU(query);
      if (cachedItem) {
        this.addItem(cachedItem);
        cb([]);
        return;
      }

      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        const items = await this.$http.get<Item[]>(url);

        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && items.length === 1) {
          this.addItem(items[0]);
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }

      this.pressedEnter = false;
    },

    addItem(selected: Item) {
      this.inventoryTransfer.addItem(selected);
      this.itermSearchTerm = "";
    },

    removeItem(index: number) {
      this.inventoryTransfer.removeItem(index);
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
      this.inventoryTransfer = new InventoryTransfer("");
    },

    async submit(ir: InventoryTransfer) {
      this.error.title = "";
      this.error.body = "";

      try {
        await this.$http.post(InventoryTransfer.ENDPOINT, ir);
        await this.$router.push(this.$Route.INVENTORY_INVENTORY_TRANSFERS);
      } catch (error) {
        return error;
      }
    },

    checkForm() {
      const errors = [];
      for (const iai of this.inventoryTransfer.inventoryTransferItems) {
        errors.push(iai.validateSerials());
        errors.push(iai.validateQuantity());
      }

      this.error.body = errors.filter(err => err).join("\n");

      if (this.error.body) {
        this.error.title = this.$t("validation.inputErrors");
        document.getElementById("main")?.scrollTo({ top: 0 });
        return false;
      }

      return true;
    },

    async validateSubmit(formName: string) {
      this.inventoryTransfer.prepareSendPayloadData();
      const form = this.$refs[formName] as typeof ElForm;

      form?.validate(async (valid: boolean) => {
        if (!valid || !this.checkForm()) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submit(
          this.inventoryTransfer as InventoryTransfer
        );

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          this.clearForm(formName);
          this.$alertModal.showSuccess({ title: this.t("successMsg") });
        }
        return true;
      });
    }
  }
});
