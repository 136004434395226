<template>
  <div>
    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <div class="flex flex-col items-center justify-center w-full">
      <div class="flex gap-4">
        <div>
          <label class="input-label">{{ t("depositTooltip") }}</label>
          <easy-total class="m-auto" v-model="depositTotal" />
        </div>

        <div>
          <label class="input-label">{{ t("floatingTooltip") }}</label>
          <easy-total class="m-auto" v-model="floatingTotal" />
        </div>
      </div>

      <div class="md:w-4/12 m-2">
        <horizontal-button
          :title="t('submitCount')"
          isSuccess
          @click.prevent="validateSubmit()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EasyTotal from "@/components/money/EasyTotal.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";

import Dinero from "dinero.js";
import { useI18n } from "vue-i18n";
import { CloseRegisterPayload, Register } from "@/models/company/Register";

export default defineComponent({
  name: "close-register",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          submitCount: "Close Register",
          depositTooltip: "Please enter the closing deposit amount",
          floatingTooltip: "Please enter the closing floating amount"
        },
        ar: {
          submitCount: "اغلق الخزانة",
          depositTooltip: "يرجى ادخال مبلغ التوديع النهائي",
          floatingTooltip: "يرجى ادخال مبلغ الخردة النهائي (لن يودع)"
        }
      }
    });

    return { t };
  },

  components: {
    EasyTotal,
    AlertBadge,
    HorizontalButton
  },

  data() {
    return {
      error: {
        title: "",
        body: ""
      },
      depositTotal: Dinero(),
      floatingTotal: Dinero()
    };
  },

  methods: {
    async validateSubmit() {
      try {
        const payload: CloseRegisterPayload = {
          registerID: this.$ctx.currentRegister.id,
          currency: this.depositTotal.getCurrency(),
          precision: this.depositTotal.getPrecision(),
          actualClosingDepositAmount: this.depositTotal.getAmount(),
          actualClosingFloatingAmount: this.floatingTotal.getAmount(),
          action: "close",
          timestamp: new Date(),
          userID: this.$ctx.currentUser.id
        };

        await this.$http.post(`company/register-actions`, payload);

        await this.$router.push(this.$Route.SALES);
      } catch (error) {
        return error;
      }
    }
  }
});
</script>

<style></style>
