
import { defineComponent } from "vue";
import Dinero, { Dinero as DineroType } from "dinero.js";
import { formatDinero, sumDineros } from "@/utils/money";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "iqd-easy-total",
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          group: "Group",
          quantity: "Quantity",
          total: "Total"
        },
        ar: {
          group: "الفئة",
          quantity: "التعداد",
          total: "المجموع"
        }
      }
    });

    return { t };
  },

  props: {
    modelValue: {
      required: false,
      type: Object as () => DineroType
    }
  },

  data() {
    return {
      m250: 0,
      m500: 0,
      m1000: 0,
      m5000: 0,
      m10000: 0,
      m25000: 0,
      m50000: 0
    };
  },

  emits: ["update:modelValue"],

  methods: {
    formatDinero
  },

  computed: {
    inputVal: {
      get(): DineroType | undefined {
        return this.modelValue;
      },

      set(val: DineroType) {
        this.$emit("update:modelValue", val);
      }
    },

    total(): string {
      const currency = "IQD";
      const precision = 0;

      const mult = [
        Dinero({ amount: 250, currency, precision }).multiply(this.m250),
        Dinero({ amount: 500, currency, precision }).multiply(this.m500),
        Dinero({ amount: 1000, currency, precision }).multiply(this.m1000),
        Dinero({ amount: 5000, currency, precision }).multiply(this.m5000),
        Dinero({ amount: 10000, currency, precision }).multiply(this.m10000),
        Dinero({ amount: 25000, currency, precision }).multiply(this.m25000),
        Dinero({ amount: 50000, currency, precision }).multiply(this.m50000)
      ];

      const total = sumDineros(mult, currency, precision);

      this.$emit("update:modelValue", total);

      return formatDinero(total);
    }
  }
});
