
import { defineComponent } from "vue";

// components
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import AlertBadge from "@/components/AlertBadge.vue";

import { ElForm } from "element-plus";
import { Route } from "@/plugins/navigation";

// models
import { Account, accountI18nMessages } from "@/models/accounting/Account";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "account-form",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...accountI18nMessages.en,
          section: "Account Info",
          enNamePlaceholder: "For example, overhead expenses",
          arNamePlaceholder: "For example, overhead expenses",
          enDescriptionPlaceholder:
            "For example, this account is used to track overhead expenses",
          arDescriptionPlaceholder:
            "For example, this account is used to track overhead expenses",
          skipParent: "Skip",
          accountCategory: "Account Category"
        },
        ar: {
          ...accountI18nMessages.ar,
          section: "معلومات الحساب",
          enNamePlaceholder: "مثلا: النفقات العامة",
          arNamePlaceholder: "مثلا: النفقات العامة",
          enDescriptionPlaceholder:
            "For example, this account is used to track overhead expenses",
          arDescriptionPlaceholder:
            "For example, this account is used to track overhead expenses",
          skipParent: "تخطي",
          accountCategory: "فئة الحساب"
        }
      },
      unescape: "global"
    });

    return { t };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection
  },
  props: {
    initAccount: {
      type: Account,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  beforeMount() {
    if (!this.account.parentAccount) {
      // create an empty parent
      this.account.parentAccount = new Account();
    }
  },

  data() {
    const accounts = new Array<Account>();
    const accountCategories = [
      "Assets",
      "Liabilities",
      "Equity",
      "Revenue",
      "Expenses"
    ];

    return {
      account: this.initAccount,
      accounts,
      error: { title: "", body: "" },
      accountCategories,
      rules: {
        enName: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          },
          {
            max: 255,
            message: this.$t("validation.maxLength", { len: 255 }),
            trigger: "blur"
          }
        ],
        arName: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          },
          {
            max: 255,
            message: this.$t("validation.maxLength", { len: 255 }),
            trigger: "blur"
          }
        ],
        isSystemAccount: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        code: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        accountCategory: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    async searchAccounts(query: string) {
      try {
        const url = `${Account.ENDPOINT}?q=${query}`;
        this.accounts = await this.$http.get<Account[]>(url);
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        this.accounts = [];
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.account);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
          this.$goTo(Route.ACCOUNTING_ACCOUNTS);
        }
        return true;
      });
    }
  }
});
