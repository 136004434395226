
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  name: "before-create",
  props: {
    do: {
      type: Function,
      required: true
    }
  },
  beforeCreate() {
    this.do();
  }
});
