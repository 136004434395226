import UUIDBase from "../UUIDBase";
import User from "../user/User";
import Item from "@/models/inventory/Item";
import ItemSerial from "./ItemSerial";
import Location from "../company/Location";
import { useI18nInstance, localizeFieldName } from "@/plugins/i18n";

const { t } = useI18nInstance().global;

export const inventoryAdjustmentI18nMessages = {
  en: {
    id: "ID",
    user: "By employee",
    reason: "Reason for adjustment",
    item: "Item Name",
    oldQuantity: "Old Quantity",
    newQuantity: "New Quantity",
    location: "Location",
    itemName: "itemName",
    serialNumbers: "Serial Numbers"
  },

  ar: {
    id: "ID",
    user: "الموظف المسؤول",
    reason: "سبب التعديل",
    item: "اسم المنتج",
    oldQuantity: "الكمية السابقة",
    newQuantity: "الكمية الحديثة",
    location: "الموقع",
    itemName: "اسم المنتج",
    serialNumbers: "Serial Numbers"
  }
};

export class InventoryAdjustmentItemSerial extends UUIDBase {
  inventoryAdjustmentItemID = "";
  itemSerialID = "";
  itemSerial: ItemSerial;

  constructor(serialNumber: string, id = "") {
    super();
    this.itemSerial = new ItemSerial(serialNumber);
    if (id) {
      this.itemSerial.id = id;
      this.itemSerialID = id;
    }
  }
}

export class InventoryAdjustmentItem extends UUIDBase {
  inventoryAdjustmentID?: string;

  itemID = "";
  item: Item;

  // default quantity
  oldQuantity = 0;
  newQuantity: number;
  inventoryAdjustmentItemSerials?: InventoryAdjustmentItemSerial[];

  // used as a v-model to simplify things a bit
  // at submit, we process this and turn it into inventoryAdjustmentItemSerials
  toRemoveSerialIDs?: Array<string>;

  constructor(item: Item) {
    super();
    this.item = item;
    this.itemID = item.id || "";

    // better be safe than sorry
    // this should always be the quantity of the current store
    if (this.item.itemStocks.length)
      this.oldQuantity = this.item.itemStocks[0].quantity;

    // set it to the old quantity as default value
    this.newQuantity = this.oldQuantity;

    if (item.isSerialized) {
      this.inventoryAdjustmentItemSerials = new Array<
        InventoryAdjustmentItemSerial
      >();

      this.toRemoveSerialIDs = new Array<string>();
    }
  }

  includesSerialNumber(serialNumber: string): boolean {
    return !!this.inventoryAdjustmentItemSerials?.some(
      serial => serial.itemSerial.number === serialNumber
    );
  }

  insertSerialNumber(serialNumber: string) {
    if (!this.includesSerialNumber(serialNumber))
      this.inventoryAdjustmentItemSerials?.push(
        new InventoryAdjustmentItemSerial(serialNumber, "")
      );
  }

  removeSerialNumber(index: number) {
    this.inventoryAdjustmentItemSerials?.splice(index, 1);
  }

  resetInventoryAdjustmentItemSerials() {
    if (this.inventoryAdjustmentItemSerials)
      this.inventoryAdjustmentItemSerials.length = 0;
  }

  includesItemSerialID(id: string): boolean {
    return !!this.inventoryAdjustmentItemSerials?.some(
      serial => serial.itemSerial.id === id
    );
  }

  insertItemSerialID(id: string) {
    if (!this.includesItemSerialID(id)) {
      this.inventoryAdjustmentItemSerials?.push(
        new InventoryAdjustmentItemSerial("", id)
      );
    }
  }

  populateInventoryAdjustmentItemSerials(itemSerialIDs?: string[]) {
    if (itemSerialIDs) {
      this.resetInventoryAdjustmentItemSerials();
      itemSerialIDs.forEach(id => this.insertItemSerialID(id));
    }
  }

  validateSerials() {
    const difference = Math.abs(this.newQuantity - this.oldQuantity);
    if (
      this.item.isSerialized &&
      this.inventoryAdjustmentItemSerials?.length !== difference
    ) {
      return t("validation.matchingLength", {
        thisField: localizeFieldName(
          "serialNumbers",
          inventoryAdjustmentI18nMessages
        ),
        thatField: difference
      });
    }
  }

  validateQuantity() {
    if (this.newQuantity <= 0) {
      return t("validation.positive", {
        field: localizeFieldName("newQuantity", inventoryAdjustmentI18nMessages)
      });
    }
  }
}

export default class InventoryAdjustment extends UUIDBase {
  user = new User();
  location = new Location();
  reason = "";
  inventoryAdjustmentItems = new Array<InventoryAdjustmentItem>();

  static from(json: Record<string, unknown> | InventoryAdjustment) {
    const i = Object.assign(new InventoryAdjustment(), json);
    i.user = User.from(i.user);
    i.location = Location.from(i.location);
    return i;
  }

  static ENDPOINT = "/inventory/inventoryadjustments";

  includes(item: Item) {
    for (const ia of this.inventoryAdjustmentItems) {
      if (ia.item.id === item.id) {
        return true;
      }
    }

    return false;
  }

  findByBarcode(barcode: string): Item | undefined {
    for (const ia of this.inventoryAdjustmentItems) {
      if (ia.item.barcode === barcode) {
        return ia.item;
      }
    }
  }

  findBySKU(sku: string): Item | undefined {
    for (const ia of this.inventoryAdjustmentItems) {
      if (ia.item.sku === sku) {
        return ia.item;
      }
    }
  }

  addItem(item: Item) {
    if (!this.includes(item)) {
      this.inventoryAdjustmentItems.push(new InventoryAdjustmentItem(item));
    }
  }

  removeItem(index: number) {
    this.inventoryAdjustmentItems.splice(index, 1);
  }

  empty() {
    return this.inventoryAdjustmentItems.length === 0;
  }

  preparePayloadData() {
    this.inventoryAdjustmentItems.forEach(iai => {
      if (iai.item.isSerialized && iai.newQuantity < iai.oldQuantity) {
        iai.populateInventoryAdjustmentItemSerials(iai.toRemoveSerialIDs);
      }
    });
  }
}
