import mitt from "mitt";
import { App } from "vue";

const globalEmitter = mitt();

export function useEmitter() {
  return globalEmitter;
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$globalEmitter = globalEmitter;
  }
};
