import { App } from "vue";
import { useI18nInstance } from "@/plugins/i18n";

// define necessary types
interface Conditions {
  /*
    possible op values:
    lt
    lte
    eq
    nq
    gt
    gte
  */
  [op: string]: { rhs: string | number; desc: string };
}

// a permission is a mapping from actions to subjects
// each action could have more than one subject
// each subject could have more than one condition

interface Permission {
  action: string;
  object: string;
}
interface Permissions {
  [action: string]: { [object: string]: boolean };
}

const permissionsTest: Permissions[] = [
  {
    create: {
      "/inventory/create": true
    }
  }
];

// permissions_test["create"]["/inventory/create"];
/*
    abilities will be populated upon login
    an example abilities object:
    {
        create: {
            discount: {
                "lte": {rhs: 100000, desc: "discount value must be less than rhs"},
                "gt": {rhs: 1000, desc: "employee cannot apply a discount less than rhs"}
            },
            product: {
                "nq": {rhs: "watches", desc: "cannot create products in watches category"}
            }
        },
        read: {
          "article": {}
        }
    }

*/
const permissions: Permissions = {};

const state = { currentErr: "" };
const t = useI18nInstance().global.t;

// can is the function that checks if a user is authorized to perform an
// action in a specific subject with a given optional value.
// it reads like: can I create a discount ofValue 10%?
function can(
  action: string,
  object: string,
  ofValue?: string | number
): boolean {
  // reset error
  state.currentErr = "";

  // early termination
  if (!permissions[action] || !permissions[action][object]) {
    state.currentErr = t("errors.client.accessControl.unauthorized");
    return false;
  }

  // we'll revisit it later
  // if (ofValue) {
  //   const conditions = permissions[action][object];
  //   for (const [op, { rhs }] of Object.entries(conditions)) {
  //     switch (op) {
  //       case "lt": {
  //         if (ofValue < rhs) break;
  //         state.currentErr = t("errors.client.accessControl.lt", {
  //           lhs: ofValue,
  //           rhs
  //         });
  //         return false;
  //       }
  //       case "lte": {
  //         if (ofValue <= rhs) break;
  //         state.currentErr = t("errors.client.accessControl.lte", {
  //           lhs: ofValue,
  //           rhs
  //         });
  //         return false;
  //       }
  //       case "eq": {
  //         if (ofValue === rhs) break;
  //         state.currentErr = t("errors.client.accessControl.eq", {
  //           lhs: ofValue,
  //           rhs
  //         });
  //         return false;
  //       }
  //       case "nq": {
  //         if (ofValue !== rhs) break;
  //         state.currentErr = t("errors.client.accessControl.nq", {
  //           lhs: ofValue,
  //           rhs
  //         });
  //         return false;
  //       }
  //       case "gt": {
  //         if (ofValue > rhs) break;
  //         state.currentErr = t("errors.client.accessControl.gt", {
  //           lhs: ofValue,
  //           rhs
  //         });
  //         return false;
  //       }
  //       case "gte": {
  //         if (ofValue >= rhs) break;
  //         state.currentErr = t("errors.client.accessControl.gte", {
  //           lhs: ofValue,
  //           rhs
  //         });
  //         return false;
  //       }
  //       default: {
  //         const systemErr = errors.system.SYSTEM_UNEXPECTED_ERR.bindDebugInfo({
  //           scope: "accessControl.can",
  //           desc: `unexpected operator: ${op}`
  //         });
  //         logError(systemErr.dumpDebugInfo());
  //         state.currentErr = systemErr.toString();
  //         return false;
  //       }
  //     }
  //   }
  // }

  // all checks have passed at this point
  return true;
}

// whyCannot gets called if can returns false.
// it returns the reason of why the user wasn't authorized
// to perform an action in a subject with an optional value.
function whyCannot(): string {
  return state.currentErr;
}

// grantPermission is used during login to grant permission
// to user to perform an action in a subject with certain conditions
export function grantPermission(action: string, object: string) {
  if (!permissions[action]) {
    permissions[action] = {};
  }
  permissions[action][object] = true;
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$can = can;
    app.config.globalProperties.$whyCannot = whyCannot;
  }
};
