
import { defineComponent } from "vue";
import { goTo } from "./routing";
import { Props } from "./types";

/**
 * @deprecated This component should not be used
 */
export default defineComponent({
  props: {
    to: {
      required: true,
      type: String
    },

    props: {
      required: false,
      type: Object as () => Props
    }
  },
  methods: {
    navigateToRoute() {
      goTo(this.to, this.props);
    }
  }
});
