import UUIDBase from "../UUIDBase";
import { Dinero as DineroType } from "dinero.js";

export const userI18nMessages = {
  en: {
    username: "Username",
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    phone: "Phone number",

    fullName: "Full Name",

    streetAddress: "Street Address",
    city: "City",
    postalCode: "Postal code",
    country: "Country",

    employeeSalaryAmount: "Salary",
    employeeTitle: "Job title",
    employeeStartDate: "Start Date",
    employeeEndDate: "End Date"
  },

  ar: {
    username: "اسم المستخدم",
    firstName: "الاسم",
    lastName: "اللقب",
    email: "البريد الالكتروني",
    phone: "رقم الهاتف",

    fullName: "الاسم الكامل",

    streetAddress: "العنوان",
    city: "المدينة",
    postalCode: "الرمز البريدي",
    country: "الدولة",

    employeeSalaryAmount: "الراتب الشهري",
    employeeTitle: "المسمى الوظيفي",
    employeeStartDate: "تاريخ بدأ الخدمة",
    employeeEndDate: "تاريخ انتهاء الخدمة"
  }
};

export class UserPermission extends UUIDBase {
  constructor(action: string, object: string) {
    super();
    this.permission.action = action;
    this.permission.object = object;
  }

  permission: {
    action: string;
    object: string;
    id? : string
  } = { action: "", object: ""};

  userID = "";
}

export default class User extends UUIDBase {
  static ENDPOINT = "/user/users";

  username = "";
  firstName = "";
  lastName = "";
  email = "";
  phone = "";

  streetAddress = "";
  city = "";
  postalCode = "";
  country = "";

  employeeSalaryAmount?: number;
  employeeSalaryCurrency?: string;
  employeeSalaryPrecision?: number;
  employeeSalaryDinero?: DineroType;

  employeeTitle?: string;

  employeeStartDate?: Date;
  employeeEndDate?: Date;

  accessToken = "";

  userPermissions = new Array<UserPermission>();

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  static from(json: Record<string, unknown> | User): User {
    return Object.assign(new User(), json);
  }
}
