<!--
The MainLayout simply divides the layout into nav and tab system
-->
<template>
  <div class="flex" id="globalContainer" :dir="$ctx.getDir()">
    <template v-if="loading">
      <div
        class="absolute flex items-center justify-center w-full h-full bg-black bg-opacity-20"
      >
        <LoadingSpinner :diameter="'50px'" />
      </div>
    </template>
    <!-- nav bar> -->
    <template v-else>
      <div class="navbar-component">
        <navbar></navbar>
      </div>
      <div class="main">
        <alert-modal />
        <div class="page-header">
          <div
            class="back-button"
            v-if="showBackButton()"
            @click="this.$router.back()"
          >
            <font-awesome-icon
              :icon="
                this.$ctx.locale === 'ar' ? 'chevron-right' : 'chevron-left'
              "
              size="xs"
            >
            </font-awesome-icon>
            <div class="mx-2" style="margin-top: 3px">{{ t("back") }}</div>
          </div>
        </div>
        <router-view />
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import Navbar from "@/layouts/nav/Navbar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useAuth } from "@/plugins/auth";

import { ArrowLeft } from "@element-plus/icons";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    Navbar,
    LoadingSpinner
  },
  setup() {
    const loading = ref(false);
    const { loggedIn } = useAuth();
    const { t } = useI18n({
      messages: {
        en: {
          back: "Back"
        },
        ar: {
          back: "العودة"
        }
      }
    });

    return {
      loading,
      loggedIn,
      ArrowLeft,
      t
    };
  },

  methods: {
    showBackButton() {
      return this.$route.path !== "/" && this.$route.path.split("/").length > 2;
    }
  }
});
</script>

<style scoped lang="postcss">
.navbar-component {
  @apply w-1/6;
}

.main {
  @apply w-5/6;
  height: calc(100vh - 0.7rem);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 0.7rem;
}

.page-header {
  width: 100%;
  height: 0.5rem;
}

.back-button {
  display: flex;
  align-items: center;
  margin: 0.5rem 0.5rem -0.25rem;
  cursor: pointer;
}

.back-button:hover {
  color: var(--primary-bg-color);
}
</style>
