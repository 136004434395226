<template>
  <div class="navbar">
    <img src="@/assets/logo.png" />
    <ul>
      <li
        v-for="(item, i) in navItems"
        :key="i"
        id="item.route"
        @click.prevent="$router.push(item.route)"
      >
        <div
          class="navitem"
          :class="{
            'selected-navitem-bg-color': isSelectedNavItem(item.route)
          }"
        >
          <div class="navitem-icon">
            <font-awesome-icon :icon="item.icon"> </font-awesome-icon>
          </div>
          <!--hide the title on mobile--->
          <div class="hidden-navitem-title">
            {{ item.title }}
          </div>
        </div>
      </li>
    </ul>

    <div class="navitem" @click.prevent="signout">
      <div class="navitem-icon">
        <font-awesome-icon icon="sign-out-alt"> </font-awesome-icon>
      </div>
      <!--hide the title on mobile--->
      <div class="hidden-navitem-title">
        {{ t("signOut") }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// libraries
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useAuth } from "@/plugins/auth";
import { useLoadingModal } from "@/plugins/loading-modal/loading-modal";
import { useAlertModal } from "@/plugins/alert-modal/alert-modal";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          signOut: "Sign Out"
        },
        ar: {
          signOut: "تسجيل الخروج"
        }
      },
      useScope: "global"
    });

    const navItems = [
      {
        route: "/",
        icon: "home",
        title: t("views.home.home")
      },
      {
        route: "/sales",
        icon: "dollar-sign",
        title: t("views.sales.sales")
      },
      {
        route: "/inventory",
        icon: "warehouse",
        title: t("views.inventory.inventory")
      },
      {
        route: "/customers",
        icon: "users",
        title: t("views.customers.customers")
      },
      {
        route: "/vendors",
        icon: "users",
        title: t("views.vendors.vendors")
      },
      {
        route: "/hr",
        icon: "address-book",
        title: t("views.hr.hr")
      },
      {
        route: "/accounting",
        icon: "calculator",
        title: t("views.accounting.accounting")
      },
      // {
      //   route: "/reports",
      //   icon: "flag",
      //   title: t("views.reports.reports")
      // },
      {
        route: "/settings",
        icon: "cogs",
        title: t("views.settings.settings")
      }
    ];

    const router = useRouter();

    const isSelectedNavItem = function(parentRoute: string) {
      const currentRoute = router.currentRoute.value.fullPath;

      return parentRoute === "/"
        ? currentRoute === parentRoute
        : currentRoute.startsWith(parentRoute);
    };

    const signout = async () => {
      const { show, hide } = useLoadingModal();
      const { showDanger } = useAlertModal();
      try {
        const { userLogout } = useAuth();
        show();
        await userLogout();
        router.replace("/login");
      } catch (error) {
        showDanger({
          title: error.title,
          body: error.body
        });
      } finally {
        hide();
      }
    };

    return {
      navItems,
      isSelectedNavItem,
      t,
      signout
    };
  }
});
</script>

<style scoped lang="postcss">
.navbar {
  background: var(--secondary-bg-color);
  @apply min-h-screen;
  @apply top-0;
  @apply sticky;
  @apply shadow-xl;
}

.navitem {
  @apply flex items-center cursor-pointer transition duration-100 ease-in-out transform;
}

.navitem:hover {
  @apply -translate-y-1;
  background: var(--primary-hover-bg-color);
  color: var(--primary-hover-text-color);
}

/* Documentaion reference https://tailwindcss.com/docs/functions-and-directives#apply */
@screen md {
  .navitem {
    @apply justify-start;
  }
}

.selected-navitem-bg-color {
  background: var(--primary-bg-color);
  color: var(--primary-text-color);
  @extend .primary;
}

.navitem-icon {
  @apply grid m-4 w-6 justify-center;
}

/*
By default, Tailwind uses a mobile-first breakpoint system. So on mobile, we would like to hide the navitem's title. Then we override that in the next block @screen md to display the title on the medium breakpoint onwards.
*/
.hidden-navitem-title {
  @apply hidden;
}

@screen md {
  .hidden-navitem-title {
    @apply contents;
  }
}
</style>
