<template>
  <div class="input-section">
    <slot> </slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "input-section"
});
</script>

<style lang="postcss">
h1 {
  @apply text-lg py-3 font-bold;
}

h2 {
  @apply py-3 font-bold;
}

h3 {
  @apply py-3 font-bold;
}

.input-section {
  @apply p-1  rounded w-full justify-center md:flex-row lg:w-7/12;
}
</style>
