<template>
  <div class="input-label">
    <label v-if="title">
      {{ title }}
    </label>
    <span :class="{ danger: !isValid }" class="tag-input input">
      <input
        :placeholder="placeholder"
        class="tag-text el-input__inner"
        @keydown.enter.prevent="addTag($event.target)"
        @change.prevent="validateInput"
      />
      <span v-for="(tag, index) in tags" :key="tag" class="tag-box">
        <span @click="removeTag(index)" class="mx-4">x</span>
        {{ valueFormatter(tag) }}
      </span>
    </span>
    <span v-if="!isValid" class="danger">{{ err }}</span>
    <span class="hide relative" v-if="toolTip">
      <tool-tip :tip="toolTip" />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ToolTip from "@/components/ToolTip.vue";

export default defineComponent({
  name: "tag-input",
  components: {
    ToolTip
  },
  created() {
    this.validateInput();
  },
  data() {
    return {
      // just an alias
      tags: this.modelValue,
      isValid: true,
      err: ""
    };
  },
  props: {
    title: {
      type: String,
      required: false
    },
    // in some cases, our array is more complex than array of strings
    // we want to support those cases
    // hence, inserFunction and removeFunction take care of the insert
    // and remove operations outside this component
    // this could include object creation, and other operations
    // I chose this approach instead of event passing since this is cleaner
    insertFunction: {
      // (val: string) => void
      type: Function,
      required: false
    },
    removeFunction: {
      // (index: number) => void
      type: Function,
      required: false
    },
    valueFormatter: {
      // (object) => string
      type: Function,
      required: false,
      // return the value itself
      default: (t: unknown) => t
    },
    placeholder: {
      type: String,
      default: "اضغط Enter بعد اضافة كل قيمة"
    },
    modelValue: {
      type: Array,
      required: true
    },
    toolTip: {
      type: String,
      required: false
    },
    validate: {
      type: Function,
      required: false
    }
  },
  watch: {
    tags: {
      handler() {
        this.validateInput();
      },
      deep: true
    }
  },
  methods: {
    addTag(target: HTMLInputElement) {
      const val = target?.value.trim();
      if (val.length > 0 && this.tags.indexOf(val) < 0) {
        if (this.insertFunction) {
          this.insertFunction(val);
        } else {
          this.tags.push(val);
        }
      }
      target.value = "";
    },
    removeTag(index: number) {
      if (this.removeFunction) {
        this.removeFunction(index);
      } else {
        this.tags.splice(index, 1);
      }
    },
    removeLastTag(target: HTMLInputElement) {
      if (target?.value.length === 0) {
        this.removeTag(this.tags.length - 1);
      }
    },
    validateInput() {
      if (this.validate) {
        const err = this.validate();
        if (err) {
          this.isValid = false;
          this.err = err;
        } else {
          this.isValid = true;
          this.err = "";
        }
      }
    }
  }
});
</script>
<style scoped>
.tag-input {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 140px;
}

.tag-box {
  display: flex;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin: 0.25rem;
  width: auto;
  background-color: var(--tertiary-bg-color);
  color: var(--tertiary-text-color);
  border-radius: 5px;
}

.tag-box > span {
  cursor: pointer;
  opacity: 0.75;
}

.tag-text {
  margin-inline-start: 0.5rem;
  border: none;
  outline: none;
  background: none;
  width: auto;
  line-height: 40px;
  padding: 0 15;
}

.hide {
  display: none;
}

.input:hover + .hide {
  display: flex;
  justify-content: center;
}

.danger {
  border-color: var(--danger-bg-color) !important;
  color: var(--danger-hover-bg-color) !important;
}
</style>
