<template>
  <el-form
    @submit.prevent="validateSubmit"
    novalidate
    :model="inventoryAdjustment"
    ref="inventoryAdjustmentForm"
    :rules="rules"
    label-position="top"
  >
    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('inventoryAdjustmentForm')"
          />
        </div>

        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('inventoryAdjustmentForm')"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <input-section class="my-2">
      <h1>{{ t("sections.info") }}</h1>
      <h2>{{ t("userAdjusting") }}: {{ user.fullName }}</h2>

      <el-form-item :label="t('reason')" class="input-label" prop="reason">
        <el-input
          id="reason"
          v-model="inventoryAdjustment.reason"
          :placeholder="t('placeholders.reason')"
          clearable
          required
        >
        </el-input>
      </el-form-item>
    </input-section>

    <input-section>
      <h1>{{ t("sections.inventory") }}</h1>
      <el-form-item :label="t('itemName')" class="input-label">
        <el-autocomplete
          id="item"
          v-model="itermSearchTerm"
          :fetch-suggestions="searchItems"
          value-key="name"
          :trigger-on-focus="false"
          class="w-full"
          @select="addItem"
          @keypress.enter.prevent="pressedEnter = true"
          highlight-first-item
        >
        </el-autocomplete>
      </el-form-item>
    </input-section>

    <div class="w-10/12 mt-8" v-if="!inventoryAdjustment.empty()">
      <el-table
        :data="inventoryAdjustment.inventoryAdjustmentItems"
        style="width: 100%"
        stripe
      >
        <el-table-column prop="item.name" :label="t('itemName')">
        </el-table-column>

        <el-table-column
          prop="oldQuantity"
          :label="t('oldQuantity')"
          width="200"
          align="center"
        >
          <template #default="props">
            <div class="el-input el-input--small">
              {{ props.row.oldQuantity }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="newQuantity"
          :label="t('newQuantity')"
          width="200"
          align="center"
        >
          <template #default="props">
            <el-input-number
              v-model="props.row.newQuantity"
              :step="1"
              size="small"
              :min="0"
              controls-position="right"
            ></el-input-number>
          </template>
        </el-table-column>

        <el-table-column
          prop="inventoryAdjustmentItemSerials"
          :label="t('serialNumbers')"
          width="300"
        >
          <template #default="props">
            <template v-if="props.row.item.isSerialized">
              <!-- add new serials -->
              <before-create
                v-if="props.row.newQuantity > props.row.oldQuantity"
                :do="() => props.row.resetInventoryAdjustmentItemSerials()"
              >
                <tag-input
                  :title="
                    t('insertSerials', {
                      num: props.row.newQuantity - props.row.oldQuantity
                    })
                  "
                  v-model="props.row.inventoryAdjustmentItemSerials"
                  :insertFunction="val => props.row.insertSerialNumber(val)"
                  :removeFunction="index => props.row.removeSerialNumber(index)"
                  :valueFormatter="
                    serialObject => serialObject.itemSerial.number
                  "
                  :validate="() => props.row.validateSerials()"
                />
              </before-create>

              <!-- remove old serials -->
              <label
                class="input-label"
                v-else-if="props.row.newQuantity < props.row.oldQuantity"
              >
                {{
                  t("removeSerials", {
                    num: props.row.oldQuantity - props.row.newQuantity
                  })
                }}

                <el-select
                  filterable
                  clearable
                  multiple
                  class="w-full"
                  v-model="props.row.toRemoveSerialIDs"
                  :multiple-limit="
                    props.row.oldQuantity - props.row.newQuantity
                  "
                >
                  <el-option
                    v-for="(item, i) in props.row.item.itemStocks[0]
                      .itemSerials"
                    :key="i"
                    :label="item.number"
                    :value="item.id"
                  >
                    <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                      {{ item.number }}
                    </span>
                  </el-option>
                </el-select>
              </label>
            </template>
          </template>
        </el-table-column>

        <el-table-column width="50" align="center">
          <template #default="scope">
            <span
              class="text-red-600 cursor-pointer"
              @click="removeItem(scope.$index)"
            >
              <font-awesome-icon icon="trash" />
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InventoryAdjustment, {
  inventoryAdjustmentI18nMessages
} from "@/models/inventory/InventoryAdjustment";
import { ElForm } from "element-plus";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import TagInput from "@/components/form/TagInput.vue";
import Item from "@/models/inventory/Item";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "new-inventory-receiving",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryAdjustmentI18nMessages.en,

          sections: {
            info: "Meta data",
            inventory: "Inventory Info"
          },

          placeholders: {
            reason: "Example: missing a couple of items",
            itemName: "Enter barcode, sku, or item name"
          },

          userAdjusting: "Employee responsible for this inventory adjustment",
          insertSerials: "You must insert {num} new serial numbers",
          removeSerials: "You must remove {num} old serial numbers",

          successMsg: "Inventory was adjusted successfully"
        },
        ar: {
          ...inventoryAdjustmentI18nMessages.ar,

          sections: {
            info: "البيانات الوصفية",
            inventory: "البضاعة المعدلة"
          },

          placeholders: {
            reason: "مثلا: بعض البضاعة مفقودة",
            itemName: "ادخل اسم المنتج او الباركود او SKU"
          },

          userAdjusting: "الموظف المسؤول عن تعديل البضاعة",
          insertSerials: "قم بادخال {num} serial numbers",
          removeSerials: "قم بمسح {num} serial numbers",

          successMsg: "تم تعديل البضاعة بنجاح"
        }
      }
    });

    return { t };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    TagInput,
    InputSection
  },
  data() {
    return {
      inventoryAdjustment: new InventoryAdjustment(),
      itermSearchTerm: "",
      error: { title: "", body: "" },
      serials: [],
      pressedEnter: false,
      user: this.$ctx.currentUser,
      rules: {
        reason: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    async searchItems(query: string, cb: Function) {
      // if an item already exists in the table, don't do remote search
      const cachedItem =
        this.inventoryAdjustment.findByBarcode(query) ||
        this.inventoryAdjustment.findBySKU(query);
      if (cachedItem) {
        this.addItem(cachedItem);
        cb([]);
        return;
      }

      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        const items = await this.$http.get<Item[]>(url);

        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && items.length === 1) {
          this.addItem(items[0]);
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }

      this.pressedEnter = false;
    },

    addItem(selected: Item) {
      this.inventoryAdjustment.addItem(selected);
      this.itermSearchTerm = "";
    },

    removeItem(index: number) {
      this.inventoryAdjustment.removeItem(index);
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
      this.inventoryAdjustment = new InventoryAdjustment();
    },

    async submit(ir: InventoryAdjustment) {
      this.error.title = "";
      this.error.body = "";

      try {
        await this.$http.post(InventoryAdjustment.ENDPOINT, ir);
        await this.$router.push(this.$Route.INVENTORY_INVENTORY_ADJUSTMENTS);
      } catch (error) {
        return error;
      }
    },

    checkForm() {
      const errors = [];
      for (const iai of this.inventoryAdjustment.inventoryAdjustmentItems) {
        errors.push(iai.validateSerials());
        errors.push(iai.validateQuantity());
      }

      this.error.body = errors.filter(err => err).join("\n");

      if (this.error.body) {
        this.error.title = this.$t("validation.inputErrors");
        document.getElementById("main")?.scrollTo({ top: 0 });
        return false;
      }

      return true;
    },

    async validateSubmit(formName: string) {
      this.inventoryAdjustment.preparePayloadData();
      const form = this.$refs[formName] as typeof ElForm;

      form?.validate(async (valid: boolean) => {
        if (!valid || !this.checkForm()) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submit(
          this.inventoryAdjustment as InventoryAdjustment
        );

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          this.clearForm(formName);
          this.$alertModal.showSuccess({ title: this.t("successMsg") });
        }
        return true;
      });
    }
  }
});
</script>

<style lang="postcss" scoped>
form > h1 {
  @apply p-2 text-lg py-3 font-bold;
}

form > h2 {
  @apply p-2 py-3 font-bold;
}

form > h3 {
  @apply p-2 py-3 font-bold;
}

form {
  @apply flex flex-col items-center;
}
</style>
