import { App } from "vue";
import { createI18n } from "vue-i18n";
import globalMessages from "./globalMessages";
import { useContext } from "@/plugins/context";
import { logWarn } from "@/utils/log";

const currentLocale = useContext().locale;

const vueI18n = createI18n({
  // initial state
  locale: currentLocale,
  messages: globalMessages,
  availableLocales: ["ar", "en"],
  fallbackLocale: {
    // if you're in English, fallback to Arabic
    en: ["ar"],
    // and vice versa
    ar: ["en"]
  },
  // to use composition API
  legacy: false,
  // to be able to use $t and $tc
  globalInjection: true
});

export function useI18nInstance() {
  return vueI18n;
}

export function localizeFieldName(
  fieldName: string,
  localFieldsDictionary?: Record<string, Record<string, string>>
) {
  let localizedFieldName;
  if (localFieldsDictionary && currentLocale in localFieldsDictionary) {
    localizedFieldName = localFieldsDictionary[currentLocale][fieldName];

    if (!localizedFieldName) {
      logWarn(
        `${fieldName} not found in ${JSON.stringify(
          localFieldsDictionary
        )}. Locale: ${currentLocale}`
      );
    }
  } else {
    localizedFieldName = vueI18n.global.t(`models.common.fields.${fieldName}`);

    if (!localizedFieldName) {
      logWarn(
        `${fieldName} not found in models.common.fields. Locale: ${currentLocale}`
      );
    }
  }

  return localizedFieldName || fieldName;
}

export default {
  install: (app: App) => {
    app.use(vueI18n);
  }
};
