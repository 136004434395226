import UUIDBase from "../UUIDBase";

export const customerI18nMessages = {
  en: {
    firstName: "Customer First Name",
    lastName: "Customer Last Name",
    customerDisplayName: "Customer Full Name",
    email: "Email",
    phone: "Phone",

    streetAddress: "Street Address",
    city: "City",
    postalCode: "Postal Code",
    country: "Country",
    customerType: "Customer Type",
    regular: "regular",
    wholesale: "regular"
  },
  ar: {
    firstName: "اسم الزبون",
    lastName: "لقب الزبون",
    customerDisplayName: "اسم الزبون",
    email: "البريد الالكتروني",
    phone: "رقم الهاتف",

    streetAddress: "عنوان الزبون",
    city: "المدينة",
    postalCode: "الرمز البريدي",
    country: "الدولة",
    customerType: "نوع العميل",
    regular: "عام",
    wholesale: "جملة"
  }
};

export default class Customer extends UUIDBase {
  static from(json: Record<string, unknown> | Customer) {
    return Object.assign(new Customer(), json);
  }
  static ENDPOINT = "/customer/customers";
  static INVOICES_ENDPOINT = "accounting/customerinvoices?customer_id=";

  firstName = "";
  lastName = "";
  email = "";
  phone = "";
  customerType = ""
  streetAddress = "";
  city = "";
  postalCode = "";
  country = "";

  get contactDisplayName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
