
import { defineComponent } from "vue";

import { i18nOrderMessages, Order, OrderChange } from "@/models/sales/Order";
import { parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero } from "@/utils/money";

import { Dinero } from "dinero.js";

import Receipt from "@/components/Receipt.vue";
import ActionButton from "@/components/ActionButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";

type ReturnOrderItem = {
  itemName: string;
  quantityReturned: number;
  itemPrice: Dinero;
  itemTotal: Dinero;
};

export default defineComponent({
  components: {
    Receipt,
    ActionButton,
    LoadingSpinner,
    AlertBadge
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          totalDue: "Total Due",
          totalPaid: "Total paid",
          changeDue: "Change due",
          totalKept: "Total Received",
          serialNumbers: "Serial Numbers",
          print: "Print Receipt",
          newSale: "New Sale"
        },
        ar: {
          ...i18nOrderMessages.ar,
          totalDue: "المبلغ المطلوب",
          totalPaid: "المبلغ المدفوع",
          changeDue: "الباقي",
          totalKept: "الصافي",
          serialNumbers: "Serial Numbers",
          print: "اطبع الفاتورة",
          newSale: "بيع جديد"
        }
      }
    });

    return { t };
  },

  data() {
    return {
      order: new Order(),
      orderChange: new OrderChange("return"),
      returnItems: new Array<ReturnOrderItem>(),
      loading: true,
      error: {
        title: "",
        body: ""
      }
    };
  },
  async beforeMount() {
    // get order
    try {
      const resp = await this.$http.get<Order>(
        `${Order.ENDPOINT}/${this.$route.params.orderID}`
      );
      this.order = Order.from(resp);

      const returnData = this.order.orderChanges.find(
        oc => oc.id === this.$route.params.id
      );

      if (returnData) {
        this.orderChange = returnData;
        // inverse lookup
        this.order.orderItems.forEach(oi => {
          const returned = returnData.orderChangeItems[oi.id || ""];
          if (returned) {
            this.returnItems.push({
              itemName: oi.item.name,
              quantityReturned: returned.quantity,
              itemPrice: oi.soldAtPriceDinero,
              itemTotal: oi.soldAtPriceDinero.multiply(returned.quantity)
            });
          }
        });
      } else {
        // show error
      }
    } catch (error) {
      this.error.title = error.title;
      this.error.body = error.body;
    } finally {
      this.loading = false;
    }
  },

  methods: {
    parseFormat,
    formatDinero,
    truncateItemName(itemName: string) {
      const maxLength = Math.min(itemName.length, 40);
      return maxLength >= 40
        ? `${itemName.substring(0, maxLength)}...`
        : itemName;
    }
  }
});
