<template>
  <div class="m-3" style="height:90vh">
    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <el-select
        class="input-label"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/inventory/items')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
        <template v-if="$can('delete', '/inventory/items')">
          <el-option
            key="delete"
            :label="$t('views.common.listView.selectedRows.delete')"
            value="delete"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full md:w-6/12"
        prefix-icon="el-icon-search"
        v-model="searchTerm"
      />

      <div class="w-full md:w-3/12">
        <template v-if="$can('create', '/inventory/items')">
          <horizontal-button
            :title="$t('views.inventory.items.newSimpleItem')"
            @click.prevent="
              $router.push($Route.INVENTORY_ITEMS_NEW_SIMPLE_ITEM)
            "
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-balham"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh;"
      :pagination="true"
      :paginationAutoPageSize="true"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import {
  ColDef,
  GridOptions,
  ValueFormatterParams,
  ValueGetterParams
} from "ag-grid-community";
import Item, { itemI18nMessages } from "@/models/inventory/Item";
// import ItemTag from "@/models/inventory/Tag";
import HorizontalButton from "@/components/HorizontalButton.vue";

import { formatDinero } from "@/utils/money";
import { useI18n } from "vue-i18n";
import { ProcessCellForExportParams } from "ag-grid-community/dist/lib/interfaces/exportParams";

export default defineComponent({
  name: "items",

  components: {
    AgGridVue,
    HorizontalButton
  },

  setup() {
    const messages = {
      en: {
        ...itemI18nMessages.en,
        itemPriceUpdated: "Item price has been updated"
      },
      ar: {
        ...itemI18nMessages.ar,
        itemPriceUpdated: "تم تحديث سعر المنتج بنجاح"
      }
    };

    const { t } = useI18n({
      messages
    });

    return {
      t
    };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: Item[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: "",
      selectedValue: ""
    };
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 100,
      cellStyle: { textAlign: "center" }
    };

    const booleanFormatter = (params: ValueFormatterParams) =>
      params.value ? "✔" : "✖";

    this.columnDefs = [
      {
        headerName: this.t("name"),
        field: "name",
        checkboxSelection: true,
        autoHeight: true,
        cellStyle: { textAlign: "start" },
        headerCheckboxSelection: true,
        minWidth: 500
      },
      {
        headerName: this.t("listPriceAmount"),
        field: "listPriceAmount",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        valueFormatter: ({ data }) => {
          const item = data as Item;
          return item.listPriceDinero ? formatDinero(item.listPriceDinero) : "";
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("salePriceAmount"),
        field: "salePriceAmount",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        valueFormatter: ({ data }) => {
          const item = data as Item;
          return item.salePriceDinero ? formatDinero(item.salePriceDinero) : "";
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("wholesalePriceAmount"),
        field: "wholesalePriceAmount",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        valueFormatter: ({ data }) => {
          const item = data as Item;
          return item.wholesalePriceDinero
            ? formatDinero(item.wholesalePriceDinero)
            : "";
        },
        cellStyle: { textAlign: "center" }
      },
      {
        headerName: this.t("currentLocationStockQuantity"),
        field: "currentLocationStockQuantity",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        cellStyle: ({ value }) => {
          const base = { textAlign: "center", backgroundColor: "transparent" };

          if (value < 0) {
            base["backgroundColor"] = "var(--danger-bg-color)";
          } else if (value < 10) {
            base["backgroundColor"] = "var(--warning-bg-color)";
          }

          return base;
        }
      },
      {
        headerName: this.t("vendor"),
        field: "vendor.name"
      },
      {
        headerName: this.t("itemBrand"),
        field: "itemBrand.name"
      },
      {
        headerName: this.t("itemCategory"),
        field: "itemCategory.name"
      },
      {
        headerName: this.t("itemTags"),
        valueGetter(params: ValueGetterParams) {
          const item = params.data as Item;
          const tags = item.itemTags;
          if (!tags) return "";
          return tags.map(tag => tag.name).join(", ");
        }
      },
      {
        headerName: this.t("isSerialized"),
        field: "isSerialized",
        valueFormatter: booleanFormatter
      },
      {
        headerName: this.t("discountEligible"),
        field: "discountEligible",
        valueFormatter: booleanFormatter
      },
      {
        headerName: this.t("sellOutOfStock"),
        field: "sellOutOfStock",
        valueFormatter: booleanFormatter
      },
      {
        headerName: this.t("disabled"),
        field: "disabled",
        valueFormatter: booleanFormatter
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();

    // go to view item
    this.gridOptions.onCellDoubleClicked = event => {
      this.$router.push(
        this.$Route.INVENTORY_ITEMS_SIMPLE_ITEM.replace(":id", event.data.id)
      );
    };
  },

  watch: {
    searchTerm() {
      this.filterItems();
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();

      try {
        const data = await this.$http.get<Item[]>(Item.ENDPOINT);
        this.rowData = data.map(item => Item.from(item));
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      for (const row of this.rowData) {
        if (row.itemStocks && row.itemStocks.length) {
          row.currentLocationStockQuantity = row.itemStocks[0].quantity;
        }
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    },

    async deleteItems(items: Item[]) {
      if (
        confirm(this.$t("views.common.listView.selectedRows.deleteConfirm"))
      ) {
        try {
          await this.$http.delete(Item.ENDPOINT, { data: items });
          await this.updateRowData();
          this.$alertModal.showSuccess({
            title: this.$t("views.common.listView.selectedRows.deleteSuccess")
          });
        } catch (error) {
          this.$alertModal.showDanger({
            title: error.title,
            body: error.body
          });
        }
      }
    },

    async applyToSelected() {
      if (this.selectedValue === "delete" || this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();
        if (selected?.length) {
          if (this.selectedValue === "delete") {
            // send request to archive
            await this.deleteItems(selected);
          } else {
            this.gridOptions.api?.exportDataAsCsv({
              onlySelected: true,
              processCellCallback: this.processCellForExport
            });
            this.$alertModal.showSuccess({
              title: this.$t("views.common.listView.selectedRows.exportSuccess")
            });
          }
        }
        this.selectedValue = "";
      }
    },

    processCellForExport(params: ProcessCellForExportParams) {
      if (params.column.getColDef().headerName === "List Price") {
        return params.node?.data.salePriceDinero
          ? formatDinero(params.node?.data.listPriceDinero)
          : "";
      } else if (params.column.getColDef().headerName === "Sale Price") {
        return params.node?.data.salePriceDinero
          ? formatDinero(params.node?.data.salePriceDinero)
          : "";
      } else {
        //if the cell doesn't belong to any of the above don't change it
        return params.value;
      }
    },

    filterItems() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    }
  }
});
</script>

<style lang="scss">
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin";

.ag-theme-balham {
  @include ag-theme-balham(
    (
      odd-row-background-color: #f5f5f5,
      row-border-color: transparent,
      row-hover-color: null,
      checkbox-checked-color: var(--primary-bg-color),
      range-selection-border-color: var(--primary-bg-color),
      font-size: 1rem
    )
  );
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-content-cell-label {
  justify-content: center;
}
</style>
