<template>
  <div class="spinner" :style="{ width: diameter, height: diameter }" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingSpinner",
  props: {
    diameter: {
      type: String,
      default: "30px"
    }
  }
});
</script>

<style lang="postcss" scoped>
.spinner {
  border: 4px solid var(--primary-bg-color);
  border-radius: 50%;
  border-top: 4px solid var(--secondary-bg-color);
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
