import { App } from "vue";
import LoadingModal from "./LoadingModal.vue";
import { useEmitter } from "@/plugins/mitt";

export function useLoadingModal() {
  const emitter = useEmitter();

  const show = () => {
    emitter.emit("show-loading-modal");
  };

  const hide = () => {
    emitter.emit("hide-loading-modal");
  };

  return {
    show,
    hide
  };
}

export default {
  install: (app: App) => {
    app.component("loading-modal", LoadingModal);
    app.config.globalProperties.$loadingModal = useLoadingModal();
  }
};
