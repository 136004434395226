
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingSpinner",
  props: {
    diameter: {
      type: String,
      default: "30px"
    }
  }
});
