
import { defineComponent } from "vue";

export default defineComponent({
  name: "alert-badge",

  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: false
    },
    isDanger: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isNormal: !(this.isWarning || this.isDanger || this.isSuccess),
      dismissed: false
    };
  },
  emits: ["dismissed"],
  methods: {
    dismiss() {
      this.dismissed = true;
      this.$emit("dismissed");
    }
  },

  watch: {
    title() {
      // make sure the badge appears if title changes
      this.dismissed = false;
    }
  }
});
