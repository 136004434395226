
import { defineComponent, onBeforeMount, ref } from "vue";

import { useHTTP } from "@/plugins/http";
import { formatDinero } from "@/utils/money";
import { parseFormat } from "@/utils/date";

import {
  Transaction,
  transactionI18nMessages
} from "@/models/accounting/Transaction";

// components
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { Account } from "@/models/accounting/Account";
import { Dinero } from "dinero.js";

interface Posting {
  account: Account;
  type: string;
  amountDinero: Dinero;
}

export default defineComponent({
  name: "transaction",
  components: {
    LoadingSpinner,
    AlertBadge
  },
  setup() {
    const transaction = ref(new Transaction());
    const http = useHTTP();
    const loading = ref(true);
    const error = ref({ title: "", body: "" });
    const route = useRoute();
    const postings = ref(new Array<Posting>());

    const { t } = useI18n({
      messages: transactionI18nMessages,
      unescape: "global"
    });

    onBeforeMount(async () => {
      const url = `${Transaction.ENDPOINT}/${route.params.id}`;

      try {
        const resp = await http.get<Transaction>(url);
        transaction.value = Transaction.from(resp);

        postings.value = [
          {
            account: transaction.value.creditedAccount,
            type: "credit",
            amountDinero: transaction.value.amountDinero
          },
          {
            account: transaction.value.debitedAccount,
            type: "debit",
            amountDinero: transaction.value.amountDinero
          }
        ];
      } catch (errorResp) {
        error.value.title = errorResp.title;
        error.value.body = errorResp.body;
      }

      loading.value = false;
    });

    return {
      transaction,
      loading,
      error,
      t,
      postings
    };
  },
  methods: {
    parseFormat,
    formatDinero,
    colorRows(params: { row: Posting }) {
      if (params.row.type === "credit") {
        return "bg-danger";
      }

      return "bg-success";
    }
  }
});
