import UUIDBase from "../UUIDBase";
import Category from "./Category";
import Brand from "./Brand";
import Tag from "./Tag";
import Vendor from "@/models/vendor/Vendor";
import Stock from "./Stock";
import { localizeFieldName, useI18nInstance } from "@/plugins/i18n";
import Dinero, { Currency, Dinero as DineroType } from "dinero.js";

const { t } = useI18nInstance().global;

export const itemI18nMessages = {
  en: {
    name: "Item Name",
    description: "Item Description",

    barcode: "Barcode",
    sku: "SKU",

    price: "Price",
    cost: "Cost",

    imageURL: "Item Image",

    isSerialized: "Is Serialized",
    discountEligible: "Discount Eligible",
    unlimitedStock: "Unlimited Stock",
    sellOutOfStock: "Sell out of Stock",
    disabled: "Disabled",

    expirationDate: "Expiration Date",

    vendor: "Vendor",
    itemBrand: "Brand",
    itemCategory: "Category",
    itemTags: "Tags",

    itemStocks: "Stocks",
    currentLocationStockQuantity: "Stock quantity (in this store)",

    listPriceAmount: "List Price",
    salePriceAmount: "Sale Price",
    wholesalePriceAmount: "Wholesale Price",

    priceAmount: "Price"
  },

  ar: {
    name: "اسم المنتج",
    description: "وصف المنتج",

    barcode: "Barcode",
    sku: "SKU",

    price: "السعر",
    cost: "التكلفة",

    imageURL: "صورة المنتج",

    isSerialized: "منتج مرقم - serialized",
    discountEligible: "قابل للتخفيض",
    unlimitedStock: "مخزون غير محدود",
    sellOutOfStock: "بيع بعد نفاذ المخزون",
    disabled: "معطل",
    expirationDate: "تاريخ النفاذ",

    vendor: "الموزع",
    itemBrand: "العلامة التجارية",
    itemCategory: "المجموعة",
    itemTags: "الوسوم",

    itemStocks: "المخزون",
    currentLocationStockQuantity: "المخزون في هذا الموقع",

    listPriceAmount: "سعر القائمة",
    salePriceAmount: "سعر البيع",
    wholesalePriceAmount: "سعر الجملة",

    priceAmount: "السعر"
  }
};

export default class Item extends UUIDBase {
  static from(json: Record<string, unknown> | Item) {
    const item = Object.assign(new Item(), json);

    if (item.listPriceAmount && item.listPriceCurrency) {
      item.listPriceDinero = Dinero({
        amount: item.listPriceAmount,
        currency: item.listPriceCurrency as Currency,
        precision: item.listPricePrecision
      });
    }

    if (item.salePriceAmount && item.salePriceCurrency) {
      item.salePriceDinero = Dinero({
        amount: item.salePriceAmount,
        currency: item.salePriceCurrency as Currency,
        precision: item.salePricePrecision
      });
    }

    if (item.wholesalePriceAmount && item.wholesalePriceCurrency) {
      item.wholesalePriceDinero = Dinero({
        amount: item.wholesalePriceAmount,
        currency: item.wholesalePriceCurrency as Currency,
        precision: item.wholesalePricePrecision
      });
    }

    if (item.costAmount && item.costCurrency) {
      item.costDinero = Dinero({
        amount: item.costAmount,
        currency: item.costCurrency as Currency,
        precision: item.costPrecision
      });
    }

    item.createdAt = new Date(json.createdAt as string);
    item.updatedAt = new Date(json.updatedAt as string);
    item.deletedAt = json.deletedAt
      ? new Date(json.deletedAt as string)
      : undefined;

    return item;
  }

  static ENDPOINT = "/inventory/items";

  name = "";
  description = "";

  itemGroupID?: string;

  options: Record<string, string> = {};
  barcode?: string;
  sku?: string;

  listPriceAmount?: number;
  listPriceCurrency?: string;
  listPricePrecision?: number;
  listPriceDinero?: DineroType;

  salePriceAmount?: number;
  salePriceCurrency?: string;
  salePricePrecision?: number;
  salePriceDinero?: DineroType;

  wholesalePriceAmount?: number;
  wholesalePriceCurrency?: string;
  wholesalePricePrecision?: number;
  wholesalePriceDinero?: DineroType;

  costAmount?: number;
  costCurrency?: string;
  costPrecision?: number;
  costDinero?: DineroType;

  imageURL?: string;
  imageType?: string;

  discountEligible = true;
  isSerialized = false;
  unlimitedStock = false;
  sellOutOfStock = true;
  disabled = false;

  expirationDate = new Date();

  // belongs to
  vendor: Vendor = new Vendor();
  itemBrand: Brand = new Brand();
  itemCategory: Category = new Category();
  itemTags = new Array<Tag>();

  currentLocationStock = new Stock();
  itemStocks = new Array<Stock>();
  currentLocationStockQuantity = 0;

  // validation
  validateName() {
    if (this.name.length === 0) {
      return t("validation.required", {
        field: localizeFieldName("name", itemI18nMessages)
      });
    } else if (this.name.length > 255) {
      return t("validation.maxLength", {
        field: localizeFieldName("name", itemI18nMessages)
      });
    }
  }

  validatePrice() {
    if (this?.listPriceAmount && this.listPriceAmount < 0) {
      return t("validation.nonNegative", {
        field: localizeFieldName("price", itemI18nMessages)
      });
    }
  }

  validateImage() {
    if (
      this.imageType &&
      this.imageType !== "jpeg" &&
      this.imageType !== "jpg" &&
      this.imageType !== "png"
    ) {
      return t("validation.unsupportedImage", {
        field: localizeFieldName("imageURL", itemI18nMessages)
      });
    }
  }
}
