<template>
  <el-tabs tab-position="left" style="height: 100%" class="demo-tabs">
    <el-tab-pane :label="t('views.settings.tabs.printers')">
      <el-select
        filterable
        class="w-1/4"
        v-model="selectedPrinter"
        value-key="id"
      >
        <el-option
          v-for="option in printers"
          :key="option"
          :label="option"
          :value="option"
        >
          <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
            {{ option }}
          </span>
        </el-option>
      </el-select>
    </el-tab-pane>

    <el-tab-pane :label="t('views.settings.tabs.currencies')">
      <div class="input-label flex gap-2 w-1/2 items-center">
        <label for="input" class="text-base w-1/2">{{ t("USDToIQD") }}</label>
        <el-input type="number" v-model.number="USDtoIQDRate" />
      </div>

      <div class="input-label flex gap-2 w-1/2 items-center">
        <label for="input" class="text-base w-1/2">{{ t("IQDToUSD") }}</label>
        <el-input type="number" v-model.number="IQDtoUSDRate" />
      </div>
    </el-tab-pane>

    <el-tab-pane :label="t('views.settings.tabs.language')">
      <el-select v-model="currentLocale">
        <el-option value="en" label="English"><span>English</span></el-option>
        <el-option value="ar" label="العربية"><span>العربية</span></el-option>
      </el-select>
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import Location from "@/models/company/Location";
import { isElectron } from "@/utils/env";

export default defineComponent({
  setup() {
    const selectedPrinter = ref("");
    const { locale, t } = useI18n({
      messages: {
        en: {
          switchLang: "Switch language",
          switchLocation: "Switch Location",
          switchPrinter: "Switch Printer",
          confirmMsg:
            "Are you sure? This will reload the app and close all open tabs",
          USDToIQD: "Set USD To IQD Rate",
          IQDToUSD: "Set IQD to USD Rate"
        },

        ar: {
          switchLang: "اختر اللغة",
          switchLocation: "غير الموقع",
          switchPrinter: "اختر طابعة",
          confirmMsg:
            "يرجى التأكيد. ستقوم هذه العملية بغلق جميع النوافذ واعادة تحميل النظام",
          USDToIQD: "سعر الصرف من الدولار الى الدينار",
          IQDToUSD: "سعر الصرف من الدينار الى الدولار"
        }
      }
    });

    const currentLocale = ref(locale.value);
    const locations = [
      {
        id: "7929d71b-833f-447b-8042-d3cf137612e9",
        name: "Al-Mansour Store",
        type: "store"
      },
      {
        id: "87f44a21-0b6d-4150-b82f-af458d2c2db1",
        name: "Al-Zayona Store",
        type: "store"
      },
      {
        id: "fe462e64-d8ba-43ef-9507-99ec3c2da5e4",
        name: "Karada Warehouse",
        type: "warehouse"
      }
    ].map(loc => Location.from(loc));

    return {
      t,
      currentLocale,
      locations,
      selectedPrinter
    };
  },

  async beforeMount() {
    if (isElectron()) {
      try {
        const { ipcRenderer } = await import("electron");
        ipcRenderer.on("printers-list", (_e, args) => {
          this.printers = args;
        });
        ipcRenderer.send("get-printers");
      } catch (error) {
        // do nothing
      }
    }
  },

  data() {
    const tabsPosition = this.$ctx.locale === "en" ? "left" : "right";
    return {
      tabsPosition,
      currentLocation: this.$ctx.currentLocation,
      currentPrinter: this.$ctx.currentPrinter,
      printers: [],
      USDtoIQDRate: this.$ctx.exchangeRates.IQD,
      IQDtoUSDRate: this.$ctx.exchangeRates.USD
    };
  },

  watch: {
    currentLocale() {
      if (confirm(this.t("confirmMsg"))) {
        this.$ctx.setLocale(this.currentLocale);
      }
    },

    currentLocation() {
      this.$ctx.setLocation(this.currentLocation as Location);
    },

    currentPrinter() {
      this.$ctx.setPrinter(this.currentPrinter);
    },

    USDtoIQDRate() {
      this.$ctx.setExchangeRates({
        IQD: this.USDtoIQDRate,
        USD: this.IQDtoUSDRate
      });
    },

    IQDtoUSDRate() {
      this.$ctx.setExchangeRates({
        IQD: this.USDtoIQDRate,
        USD: this.IQDtoUSDRate
      });
    }
  }
});
</script>

<style>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  font-size: 32px;
  font-weight: 600;
}

.el-tabs--right .el-tabs__content,
.el-tabs--left .el-tabs__content {
  height: 100%;
}

.is-active {
  background-color: var(--primary-bg-color);
  color: white !important;
}
.el-tabs__header {
  width: 15%;
}

.el-tabs--left .el-tabs__item.is-left {
  text-align: left !important;
}
</style>
