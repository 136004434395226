
import { defineComponent } from "vue";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";

// models
import Tag, { tagI18nMessages } from "@/models/inventory/Tag";
import { useI18n } from "vue-i18n";
import { ElForm } from "element-plus";

export default defineComponent({
  name: "tag-form",
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...tagI18nMessages.en,
          section: "Tag Info",
          namePlaceholder: "For example, plus sizes"
        },
        ar: {
          ...tagI18nMessages.ar,
          section: "معلومات الوسم",
          namePlaceholder: "مثلا: احجام كبيرة"
        }
      },
      unescape: "global"
    });

    return { t };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection
  },
  props: {
    initTag: {
      type: Tag,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tag: this.initTag,
      error: { title: "", body: "" },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          },
          {
            max: 255,
            message: this.$t("validation.maxLength", { len: 255 }),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.tag);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    }
  }
});
