import { App } from "vue";
import AlertModal from "./AlertModal.vue";
import { useEmitter } from "@/plugins/mitt";

export function showSuccess(params: { title: string; body?: string }) {
  const emitter = useEmitter();
  emitter.emit("show-alert-modal", {
    ...params,
    level: "success"
  });
}

export function showDanger(params: { title: string; body?: string }) {
  const emitter = useEmitter();
  emitter.emit("show-alert-modal", {
    ...params,
    level: "danger"
  });
}

export function showNormal(params: { title: string; body?: string }) {
  const emitter = useEmitter();
  emitter.emit("show-alert-modal", {
    ...params,
    level: "normal"
  });
}

export function showWarning(params: { title: string; body?: string }) {
  const emitter = useEmitter();
  emitter.emit("show-alert-modal", {
    ...params,
    level: "warning"
  });
}

export function useAlertModal() {
  return {
    showSuccess,
    showDanger,
    showNormal,
    showWarning
  };
}

export default {
  install: (app: App) => {
    app.component("alert-modal", AlertModal);
    app.config.globalProperties.$alertModal = useAlertModal();
  }
};
