import UUIDBase from "@/models/UUIDBase";
import { useI18nInstance, localizeFieldName } from "@/plugins/i18n";

const { t } = useI18nInstance().global;

export const vendorI18nMessages = {
  en: {
    name: "Vendor name",
    contactFirstName: "Contact First Name",
    contactLastName: "Contact Last Name",
    contactDisplayName: "Contact Full Name",
    email: "Email",
    phone: "Phone",

    streetAddress: "Street Address",
    city: "City",
    postalCode: "Postal Code",
    country: "Country"
  },
  ar: {
    name: "اسم الموزع",
    contactFirstName: "اسم جهة الاتصال",
    contactLastName: "لقب جهة الاتصال",
    contactDisplayName: "جهة الاتصال",
    email: "البريد الالكتروني",
    phone: "رقم الهاتف",

    streetAddress: "عنوان الموزع",
    city: "المدينة",
    postalCode: "الرمز البريدي",
    country: "الدولة"
  }
};

export default class Vendor extends UUIDBase {
  static from(json: Record<string, unknown> | Vendor) {
    return Object.assign(new Vendor(), json);
  }
  static ENDPOINT = "/vendor/vendors";

  name = "";

  contactFirstName = "";
  contactLastName = "";
  email = "";
  phone = "";

  streetAddress = "";
  city = "";
  postalCode = "";
  country = "";

  get contactDisplayName() {
    return `${this.contactFirstName} ${this.contactLastName}`;
  }

  validateName() {
    if (this.name.length === 0) {
      return t("validation.required", {
        field: localizeFieldName("name", vendorI18nMessages)
      });
    } else if (this.name.length > 255) {
      return t("validation.maxLength", {
        field: localizeFieldName("name", vendorI18nMessages)
      });
    }
  }
}
