<template>
  <table class="p-4 table">
    <tr>
      <th>{{ t("group") }}</th>
      <th style="width:2px;" class="text-center"></th>
      <th>{{ t("quantity") }}</th>
    </tr>

    <tr>
      <td>$1</td>
      <th style="width:2px;" class="text-center">x</th>
      <td>
        <el-input-number
          v-model="m1"
          :step="1"
          size="mini"
          :min="0"
          controls-position="right"
        ></el-input-number>
      </td>
    </tr>

    <tr>
      <td>$5</td>
      <th style="width:2px;" class="text-center">x</th>
      <td>
        <el-input-number
          v-model="m5"
          :step="1"
          size="mini"
          :min="0"
          controls-position="right"
        ></el-input-number>
      </td>
    </tr>

    <tr>
      <td>$10</td>
      <th style="width:2px;" class="text-center">x</th>
      <td>
        <el-input-number
          v-model="m10"
          :step="1"
          size="mini"
          :min="0"
          controls-position="right"
        ></el-input-number>
      </td>
    </tr>

    <tr>
      <td>$20</td>
      <th style="width:2px;" class="text-center">x</th>
      <td>
        <el-input-number
          v-model="m20"
          :step="1"
          size="mini"
          :min="0"
          controls-position="right"
        ></el-input-number>
      </td>
    </tr>

    <tr>
      <td>$50</td>
      <th style="width:2px;" class="text-center">x</th>
      <td>
        <el-input-number
          v-model="m50"
          :step="1"
          size="mini"
          :min="0"
          controls-position="right"
        ></el-input-number>
      </td>
    </tr>

    <tr>
      <td>$100</td>
      <th style="width:2px;" class="text-center">x</th>
      <td>
        <el-input-number
          v-model="m100"
          :step="1"
          size="mini"
          :min="0"
          controls-position="right"
        ></el-input-number>
      </td>
    </tr>

    <tr>
      <td>{{ t("total") }}</td>
      <td></td>
      <td>
        {{ total }}
      </td>
    </tr>
  </table>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Dinero, { Dinero as DineroType } from "dinero.js";
import { formatDinero, sumDineros } from "@/utils/money";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "usd-easy-total",
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          group: "Group",
          quantity: "Quantity",
          total: "Total"
        },
        ar: {
          group: "الفئة",
          quantity: "التعداد",
          total: "المجموع"
        }
      }
    });

    return { t };
  },

  props: {
    modelValue: {
      required: false,
      type: Object as () => DineroType
    }
  },

  data() {
    return {
      m1: 0,
      m5: 0,
      m10: 0,
      m20: 0,
      m50: 0,
      m100: 0
    };
  },

  emits: ["update:modelValue"],

  methods: {
    formatDinero
  },

  computed: {
    inputVal: {
      get(): DineroType | undefined {
        return this.modelValue;
      },

      set(val: DineroType) {
        this.$emit("update:modelValue", val);
      }
    },

    total(): string {
      const currency = "USD";
      const precision = 2;
      // USD is represented in cents
      const mult = [
        Dinero({ amount: 100, currency, precision }).multiply(this.m1),
        Dinero({ amount: 500, currency, precision }).multiply(this.m5),
        Dinero({ amount: 1000, currency, precision }).multiply(this.m10),
        Dinero({ amount: 2000, currency, precision }).multiply(this.m20),
        Dinero({ amount: 5000, currency, precision }).multiply(this.m50),
        Dinero({ amount: 10000, currency, precision }).multiply(this.m100)
      ];

      const total = sumDineros(mult, currency, precision);

      this.$emit("update:modelValue", total);

      return formatDinero(total);
    }
  }
});
</script>

<style scoped>
td,
th {
  border: 1px solid #dddddd;
  padding: 8px;
}

.table {
  background: var(--secondary-bg-color);
}
</style>
