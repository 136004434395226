
import { defineComponent } from "vue";

export default defineComponent({
  setup(props) {
    const chartOptions = {
      chart: {
        type: "bar",
        height: props.height || "400"
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "100%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: props.showLables
      },
      legend: {
        show: props.showLegend
      },
      stroke: {
        show: true,
        width: 5,
        colors: ["transparent"]
      },
      xaxis: {
        categories: props.xAxis
      },
      fill: {
        opacity: 1,
        colors: props.colors
      }
    };
    return { chartOptions };
  },

  props: {
    chartData: {
      required: true,
      type: Object as () => ApexAxisChartSeries
    },
    chartTitle: String,
    xAxis: {
      type: Object as () => string[]
    },
    height: Number,
    colors: {
      type: Object as () => string[]
    },
    showLables: {
      type: Boolean,
      default: true
    },
    showLegend: {
      type: Boolean,
      default: true
    }
  }
});
