<template>
  <div>
    <!-- important information like time and account infor -->
    <div class="header">
      <div class="icon" @click="$router.push($Route.ACCOUNTING_REGISTERS)">
        <font-awesome-icon icon="cash-register" />
      </div>

      <div dir="ltr">
        {{
          now.toLocaleDateString("en-GB") +
            " " +
            now.toLocaleTimeString("en-US")
        }}
      </div>

      <div class="icon">
        <el-dropdown trigger="click">
          <div class="icon el-dropdown-link">
            <font-awesome-icon icon="user-circle" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                @click.prevent="$router.push($Route.HR_EMPLOYEE_SCHEDULE)"
                >{{ $t("views.hr.viewSchedule") }}</el-dropdown-item
              >
              <el-dropdown-item @click.prevent="$router.push($Route.HR)">{{
                $t("views.hr.clockInOut")
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- <font-awesome-icon icon="user-circle" /> -->
      </div>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-2 content-center">
      <!-- shortcuts -->
      <div class="m-3 col-span-1">
        <div class="title">
          {{ t("shortcuts") }}
        </div>
        <draggable
          :list="list"
          item-key="route"
          class="flex flex-col flex-wrap content-center md:flex-row "
        >
          <template #item="{ element }">
            <action-button
              :title="$t(element.title)"
              @click="$router.push(element.route)"
              :faIcon="element.icon"
              :faPrefix="element.iconPrefix"
              :isSuccess="element.isSuccess"
            ></action-button>
          </template>
        </draggable>
      </div>

      <div class="m-3 ">
        <div class="title">
          {{ t("reports") }}
        </div>
        <div class="justify-center">
          <div class="bg-white w-full">
            <column-chart
              :xAxis="salesSummary.getChartCategories"
              :chartData="
                salesSummary.getSummaryBySalesValueChartData(
                  $t(
                    'views.reports.salesSummary.salesSummaryValues.totalSales'
                  ),
                  $t(
                    'views.reports.salesSummary.salesSummaryValues.totalReturns'
                  ),
                  $t('views.reports.salesSummary.salesSummaryValues.netSales')
                )
              "
              :chartTitle="
                $t('views.reports.salesSummary.salesSummaryValues.title')
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ActionButton from "@/components/ActionButton.vue";
import ColumnChart from "@/components/charts/ColumnChart.vue";
import draggable from "vuedraggable";
import { SalesSummary } from "./reports/sales/SalesSummary.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          shortcuts: "Shortcuts",
          reports: "Important Reports"
        },
        ar: {
          shortcuts: "مختصراتي",
          reports: "التقارير المهمة"
        }
      }
    });

    return { t };
  },

  components: {
    ActionButton,
    draggable,
    ColumnChart
  },
  data() {
    return {
      now: new Date(),
      text: "",
      salesSummary: new SalesSummary(),
      list: [
        {
          title: "views.sales.newSale",
          route: this.$Route.SALES_NEW_SALE,
          id: 0,
          icon: "new-sale",
          iconPrefix: "fac",
          isSuccess: true
        },
        {
          title: "views.inventory.items.items",
          route: this.$Route.INVENTORY_ITEMS,
          id: 1,
          icon: "eye"
        },
        {
          title: "views.inventory.items.newSimpleItem",
          route: this.$Route.INVENTORY_ITEMS_NEW_SIMPLE_ITEM,
          id: 2,
          icon: "plus"
        },
        {
          title: "views.inventory.inventoryTransfers.receiveInventoryTransfer",
          route: this.$Route
            .INVENTORY_INVENTORY_TRANSFERS_RECEIVE_INVENTORY_TRANSFER,
          icon: "arrows-alt-h",
          id: 3
        },
        {
          title: "views.inventory.inventoryTransfers.newInventoryTransfer",
          route: this.$Route
            .INVENTORY_INVENTORY_TRANSFERS_NEW_INVENTORY_TRANSFER,
          icon: "arrows-alt-h",
          id: 6
        },
        {
          title: "views.hr.hr",
          route: this.$Route.HR,
          id: 4,
          icon: "address-book"
        },
        {
          title: "views.reports.reports",
          route: this.$Route.REPORTS,
          id: 5,
          icon: "flag"
        }
      ]
    };
  },

  async beforeMount() {
    // will be used when the backend is ready
    // const salesSummaryResp = await this.$http.get<SalesSummary>(
    //   getPath(SalesSummary.ENDPOINT, this.location, this.period)
    // );
    // this.salesSummary = salesSummaryResp;

    //here just generating mock data
    const mockResp = {
      summaryByNumberOfSales: [
        {
          storeName: "Al-Mansour",
          storeID: "123",
          totalSales: 1001,
          totalReturns: 50
        },
        {
          storeName: "Al-Karada",
          storeID: "567",
          totalSales: 5000,
          totalReturns: 100
        }
      ],
      summaryBySalesValue: [
        {
          storeName: "Al-Mansour",
          storeID: "123",
          currency: "IQD",
          precision: 0,
          totalSalesAmount: 5000,
          totalReturnsAmount: 2000,
          netSalesAmount: 3000
        },
        {
          storeName: "Al-Karada",
          storeID: "567",
          currency: "IQD",
          precision: 0,
          totalSalesAmount: 10000,
          totalReturnsAmount: 5000,
          netSalesAmount: 5000
        }
      ]
    };
    this.salesSummary = SalesSummary.from(mockResp);
  },
  mounted() {
    setInterval(() => (this.now = new Date()), 1000);
  }
});
</script>

<style lang="postcss" scoped>
.header {
  @apply flex p-4 m-3 rounded shadow-sm items-center justify-between;
  background: var(--secondary-bg-color);
  color: var(--secondary-text-color);
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.icon {
  @apply cursor-pointer text-2xl rounded-full h-12 w-12 flex items-center justify-center;
  background: var(--body-bg-color);
  color: var(--secondary-text-color);
}

.icon:hover {
  background: var(--primary-hover-bg-color);
  color: var(--primary-hover-text-color);
}

.title {
  @apply col-span-1 p-2 rounded shadow-sm text-center text-lg w-full;
  background: var(--secondary-bg-color);
  color: var(--secondary-text-color);
}

@screen lg {
  .title {
    @apply col-span-3;
  }
}
</style>
