<template>
  <div
    class="flex flex-col items-center p-3 card"
    v-if="order.id && !order.empty()"
  >
    <div class="w-full">
      <div class="flex justify-between w-full p-3">
        <p>{{ t("paymentType") }}</p>
        <p class="flex justify-end">
          {{ t(`type${order.paymentType}`) }}
        </p>
      </div>

      <div class="flex justify-between w-full p-3 bg-gray-100">
        <p>{{ t("user") }}</p>
        <p class="flex justify-end">
          {{ order?.user?.fullName }}
        </p>
      </div>

      <div class="flex justify-between w-full p-3 ">
        <p>{{ t("location") }}</p>
        <p class="flex justify-end">
          {{
            $ctx.locale === "en"
              ? order?.location?.enName
              : order?.location?.arName
          }}
        </p>
      </div>

      <div class="flex justify-between w-full p-3 bg-gray-100">
        <p>{{ t("register") }}</p>
        <p class="flex justify-end">
          {{
            $ctx.locale === "en"
              ? order?.register?.enName
              : order?.register?.arName
          }}
        </p>
      </div>

      <div class="flex justify-between w-full p-3">
        <p>{{ t("customer") }}</p>
        <p class="flex justify-end">
          {{ order?.invoice?.customer?.contactDisplayName }}
        </p>
      </div>

      <div class="w-full p-2">
        <hr class="solid" />
      </div>

      <div class="flex justify-between w-full p-3">
        <p>{{ t("totalBeforeDiscounts") }}</p>
        <p class="flex justify-end">
          {{ formatDinero(order.invoice?.totalBeforeDiscountsDinero) }}
        </p>
      </div>

      <div class="flex justify-between w-full p-3">
        <p>{{ t("total") }}</p>
        <p class="flex justify-end">
          {{ formatDinero(order.invoice?.totalDueDinero) }}
        </p>
      </div>
    </div>

    <template v-if="!order.paymentComplete">
      <div class="flex justify-between w-full p-3 bg-green-100">
        <p>{{ t("paymentsMade") }}</p>
        <p class="flex justify-end">
          {{ formatDinero(order.invoice?.totalPaidDinero) }}
        </p>
      </div>
      <div class="flex justify-between w-full p-3 bg-red-100">
        <p>{{ t("moneyOwed") }}</p>
        <p class="flex justify-end">
          {{ formatDinero(order.invoice?.totalBalanceDinero) }}
        </p>
      </div>

      <div class="w-full p-2">
        <hr class="solid" />
      </div>

      <div class="flex flex-col items-center w-11/12 gap-1 text-black">
        <horizontal-button
          faIcon="money-bill-wave"
          :title="t('makePayment')"
          @click="handleMakePayment"
        />
      </div>
    </template>

    <template v-if="showPrintReceipt">
      <div class="w-full p-2">
        <hr class="solid" />
      </div>
      <div class="flex flex-col items-center w-11/12 gap-1 text-black">
        <horizontal-button
          faIcon="print"
          :title="t('print')"
          @click="
            $router.push(
              $Route.SALES_ORDERS_ORDER_RECEIPT.replace(':id', order.id)
            )
          "
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { formatDinero, formatMoney } from "@/utils/money";
import { useI18n } from "vue-i18n";
import { i18nOrderMessages, Order } from "@/models/sales/Order";

import HorizontalButton from "@/components/HorizontalButton.vue";

export default defineComponent({
  name: "order-info",
  setup() {
    const { t } = useI18n({
      useScope: "global",
      messages: {
        en: {
          ...i18nOrderMessages.en,
          print: "Print Receipt",
          makePayment: "Make a payment",
          paymentsMade: "Total paid amount",
          moneyOwed: "Money Owed"
        },
        ar: {
          ...i18nOrderMessages.ar,
          print: "اطبع الفاتورة",
          makePayment: "دفع تجاه البيع",
          paymentsMade: "المبلغ المدفوع",
          moneyOwed: "المبلغ المطلوب"
        }
      }
    });

    return { t };
  },
  props: {
    order: {
      type: Order,
      required: true
    },
    showPrintReceipt: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    HorizontalButton
  },

  methods: {
    formatDinero,
    formatMoney,
    handleMakePayment() {
      this.$router.push(
        this.$Route.SALES_ORDERS_ORDER_PAYMENT.replace(
          ":id",
          this.order.invoiceID as string
        )
      );
    }
  }
});
</script>

<style></style>
