import View from "@/plugins/navigation/View";
// import { NavIndex, Route } from "./enums";
import { NavIndex, Props, Route, Routes } from "./types";
import { pushView } from "./tabs";
import { useI18nInstance } from "../i18n";

/**
 * @deprecated This method should not be used
 */
const t = useI18nInstance().global.t;

/**
 * @deprecated This object should not be used
 */
export const routes: Routes = {
  [Route.HOME]: {
    view: () => new View("home", NavIndex.HOME, t("views.home"), "home")
  },

  [Route.LOGIN]: {
    view: () => new View("login", -1, t("views.login"), "login")
  },

  // sales
  [Route.SALES]: {
    view: () =>
      new View("sales", NavIndex.SALES, t("views.sales.sales"), "sales")
  },
  [Route.SALES_NEW_SALE]: {
    view: () =>
      new View(
        "new-sale",
        NavIndex.SALES,
        t("views.sales.newSale"),
        "new-sale",
        "sales"
      ),
    parent: Route.SALES
  },
  [Route.SALES_ORDERS]: {
    view: () =>
      new View(
        "orders",
        NavIndex.SALES,
        t("views.sales.orders.orders"),
        "orders",
        "sales"
      ),
    parent: Route.SALES
  },
  [Route.SALES_ORDERS_ORDER]: {
    view: (props?: Props) =>
      new View(
        "order",
        NavIndex.SALES,
        t("views.sales.orders.order"),
        "order",
        "orders",
        props
      ),
    parent: Route.SALES_ORDERS
  },
  [Route.SALES_ORDERS_ORDER_RECEIPT]: {
    view: (props?: Props) =>
      new View(
        "order-receipt",
        NavIndex.SALES,
        t("views.sales.receipt"),
        "order-receipt",
        "order",
        props
      ),
    parent: Route.SALES_ORDERS_ORDER
  },
  [Route.SALES_ORDERS_RETURN_ORDER]: {
    view: (props?: Props) =>
      new View(
        "return-order",
        NavIndex.SALES,
        t("views.sales.orders.returnOrder"),
        "return-order",
        "orders",
        props
      ),
    parent: Route.SALES_ORDERS
  },
  [Route.SALES_ORDERS_RETURN_ORDER_RECEIPT]: {
    view: (props?: Props) =>
      new View(
        "return-order-receipt",
        NavIndex.SALES,
        t("views.sales.receipt"),
        "return-order-receipt",
        "order",
        props
      ),
    parent: Route.SALES_ORDERS_RETURN_ORDER
  },

  [Route.SALES_ORDERS_ORDER_PAYMENT]: {
    view: (props?: Props) =>
      new View(
        "order-payment",
        NavIndex.SALES,
        t("views.sales.orders.orderPayment"),
        "order-payment",
        "orders",
        props
      ),
    parent: Route.SALES_ORDERS
  },
  [Route.SALES_ORDERS_ORDER_PAYMENT_RECEIPT]: {
    view: (props?: Props) =>
      new View(
        "order-payment-receipt",
        NavIndex.SALES,
        t("views.sales.receipt"),
        "order-payment-receipt",
        "order",
        props
      ),
    parent: Route.SALES_ORDERS_ORDER_PAYMENT
  },

  [Route.SALES_REGISTERS_OPEN_REGISTER]: {
    view: () =>
      new View(
        "open-register",
        NavIndex.SALES,
        t("views.sales.registers.openRegister"),
        "open-register",
        "sales"
      ),
    parent: Route.SALES
  },
  [Route.SALES_REGISTERS_CLOSE_REGISTER]: {
    view: () =>
      new View(
        "close-register",
        NavIndex.SALES,
        t("views.sales.registers.closeRegister"),
        "close-register",
        "sales"
      ),
    parent: Route.SALES
  },

  [Route.INVENTORY]: {
    view: () =>
      new View(
        "inventory",
        NavIndex.INVENTORY,
        t("views.inventory.inventory"),
        "inventory"
      )
  },

  [Route.INVENTORY_ITEMS]: {
    view: () =>
      new View(
        "items",
        NavIndex.INVENTORY,
        t("views.inventory.items.items"),
        "items",
        "inventory"
      ),
    parent: Route.INVENTORY
  },
  [Route.INVENTORY_ITEMS_SIMPLE_ITEM]: {
    view: (props?: Props) =>
      new View(
        "simple-item",
        NavIndex.INVENTORY,
        t("views.inventory.items.simpleItem"),
        "simple-item",
        "items",
        props
      ),
    parent: Route.INVENTORY_ITEMS
  },
  [Route.INVENTORY_ITEMS_NEW_SIMPLE_ITEM]: {
    view: () =>
      new View(
        "new-simple-item",
        NavIndex.INVENTORY,
        t("views.inventory.items.newSimpleItem"),
        "new-simple-item",
        "items"
      ),
    parent: Route.INVENTORY_ITEMS
  },

  [Route.INVENTORY_BRANDS]: {
    view: () =>
      new View(
        "brands",
        NavIndex.INVENTORY,
        t("views.inventory.brands.brands"),
        "brands",
        "inventory"
      ),
    parent: Route.INVENTORY
  },
  [Route.INVENTORY_BRANDS_BRAND]: {
    view: (props?: Props) =>
      new View(
        "brand",
        NavIndex.INVENTORY,
        t("views.inventory.brands.brand"),
        "brand",
        "brands",
        props
      ),
    parent: Route.INVENTORY_BRANDS
  },
  [Route.INVENTORY_BRANDS_NEW_BRAND]: {
    view: () =>
      new View(
        "brand",
        NavIndex.INVENTORY,
        t("views.inventory.brands.newBrand"),
        "new-brand",
        "brands"
      ),
    parent: Route.INVENTORY_BRANDS
  },

  [Route.INVENTORY_CATEGORIES]: {
    view: () =>
      new View(
        "categories",
        NavIndex.INVENTORY,
        t("views.inventory.categories.categories"),
        "categories"
      ),
    parent: Route.INVENTORY
  },
  [Route.INVENTORY_CATEGORIES_CATEGORY]: {
    view: (props?: Props) =>
      new View(
        "category",
        NavIndex.INVENTORY,
        t("views.inventory.categories.category"),
        "category",
        "categories",
        props
      ),
    parent: Route.INVENTORY_CATEGORIES
  },
  [Route.INVENTORY_CATEGORIES_NEW_CATEGORY]: {
    view: () =>
      new View(
        "new-category",
        NavIndex.INVENTORY,
        t("views.inventory.categories.newCategory"),
        "new-category",
        "categories"
      ),
    parent: Route.INVENTORY_CATEGORIES
  },

  [Route.INVENTORY_TAGS]: {
    view: () =>
      new View(
        "tags",
        NavIndex.INVENTORY,
        t("views.inventory.tags.tags"),
        "tags"
      ),
    parent: Route.INVENTORY
  },
  [Route.INVENTORY_TAGS_TAG]: {
    view: (props?: Props) =>
      new View(
        "tag",
        NavIndex.INVENTORY,
        t("views.inventory.tags.tag"),
        "tag",
        "tags",
        props
      ),
    parent: Route.INVENTORY_TAGS
  },
  [Route.INVENTORY_TAGS_NEW_TAG]: {
    view: () =>
      new View(
        "new-tag",
        NavIndex.INVENTORY,
        t("views.inventory.tags.newTag"),
        "new-tag",
        "tags"
      ),
    parent: Route.INVENTORY_TAGS
  },

  [Route.INVENTORY_INVENTORY_RECEIVINGS]: {
    view: () =>
      new View(
        "inventory-receivings",
        NavIndex.INVENTORY,
        t("views.inventory.inventoryReceivings.inventoryReceivings"),
        "inventory-receivings"
      ),
    parent: Route.INVENTORY
  },
  [Route.INVENTORY_INVENTORY_RECEIVINGS_INVENTORY_RECEIVING]: {
    view: (props?: Props) =>
      new View(
        "inventory-receiving",
        NavIndex.INVENTORY,
        t("views.inventory.inventoryReceivings.inventoryReceiving"),
        "inventory-receiving",
        "inventory-receivings",
        props
      ),
    parent: Route.INVENTORY_INVENTORY_RECEIVINGS
  },
  [Route.INVENTORY_INVENTORY_RECEIVINGS_NEW_INVENTORY_RECEIVING]: {
    view: () =>
      new View(
        "new-inventory-receiving",
        NavIndex.INVENTORY,
        t("views.inventory.inventoryReceivings.newInventoryReceiving"),
        "new-inventory-receiving",
        "inventory-receivings"
      ),
    parent: Route.INVENTORY_INVENTORY_RECEIVINGS
  },

  [Route.INVENTORY_INVENTORY_ADJUSTMENTS]: {
    view: () =>
      new View(
        "inventory-adjustments",
        NavIndex.INVENTORY,
        t("views.inventory.inventoryAdjustments.inventoryAdjustments"),
        "inventory-adjustments"
      ),
    parent: Route.INVENTORY
  },
  [Route.INVENTORY_INVENTORY_ADJUSTMENTS_INVENTORY_ADJUSTMENT]: {
    view: (props?: Props) =>
      new View(
        "inventory-adjustment",
        NavIndex.INVENTORY,
        t("views.inventory.inventoryAdjustments.inventoryAdjustment"),
        "inventory-adjustment",
        "inventory-adjustments",
        props
      ),
    parent: Route.INVENTORY_INVENTORY_RECEIVINGS
  },
  [Route.INVENTORY_INVENTORY_ADJUSTMENTS_NEW_INVENTORY_ADJUSTMENT]: {
    view: (props?: Props) =>
      new View(
        "new-inventory-adjustment",
        NavIndex.INVENTORY,
        t("views.inventory.inventoryAdjustments.newInventoryAdjustment"),
        "new-inventory-adjustment",
        "inventory-adjustments",
        props
      ),
    parent: Route.INVENTORY_INVENTORY_RECEIVINGS
  },

  [Route.INVENTORY_INVENTORY_TRANSFERS]: {
    view: () =>
      new View(
        "inventory-transfers",
        NavIndex.INVENTORY,
        t("views.inventory.inventoryTransfers.inventoryTransfers"),
        "inventory-transfers"
      ),
    parent: Route.INVENTORY
  },
  [Route.INVENTORY_INVENTORY_TRANSFERS_INVENTORY_TRANSFER]: {
    view: (props?: Props) =>
      new View(
        "inventory-adjustment",
        NavIndex.INVENTORY,
        t("views.inventory.inventoryTransfers.inventoryTransfer"),
        "inventory-transfer",
        "inventory-transfers",
        props
      ),
    parent: Route.INVENTORY_INVENTORY_TRANSFERS
  },
  [Route.INVENTORY_INVENTORY_TRANSFERS_NEW_INVENTORY_TRANSFER]: {
    view: (props?: Props) =>
      new View(
        "new-inventory-transfer",
        NavIndex.INVENTORY,
        t("views.inventory.inventoryTransfers.newInventoryTransfer"),
        "new-inventory-transfer",
        "inventory-transfers",
        props
      ),
    parent: Route.INVENTORY_INVENTORY_TRANSFERS
  },
  [Route.INVENTORY_INVENTORY_TRANSFERS_RECEIVE_INVENTORY_TRANSFER]: {
    view: (props?: Props) =>
      new View(
        "receive-inventory-transfer",
        NavIndex.INVENTORY,
        t("views.inventory.inventoryTransfers.receiveInventoryTransfer"),
        "receive-inventory-transfer",
        "inventory-transfers",
        props
      ),
    parent: Route.INVENTORY_INVENTORY_TRANSFERS
  },

  [Route.INVENTORY_PRINT_BARCODE]: {
    view: () =>
      new View(
        "print-barcode",
        NavIndex.INVENTORY,
        t("views.inventory.printBarcode.printBarcode"),
        "print-barcode"
      ),
    parent: Route.INVENTORY
  },

  [Route.CUSTOMERS]: {
    view: () =>
      new View(
        "customers",
        NavIndex.CUSTOMERS,
        t("views.customers.customers"),
        "customers"
      )
  },
  [Route.CUSTOMERS_CUSTOMER]: {
    view: (props?: Props) =>
      new View(
        "customer",
        NavIndex.CUSTOMERS,
        t("views.customers.customer"),
        "customer",
        "customers",
        props
      ),
    parent: Route.CUSTOMERS
  },
  [Route.CUSTOMERS_NEW_CUSTOMER]: {
    view: () =>
      new View(
        "new-customer",
        NavIndex.CUSTOMERS,
        t("views.customers.newCustomer"),
        "new-customer",
        "customers"
      ),
    parent: Route.CUSTOMERS
  },

  [Route.VENDORS]: {
    view: () =>
      new View(
        "vendors",
        NavIndex.VENDORS,
        t("views.vendors.vendors"),
        "vendors"
      )
  },
  [Route.VENDORS_VENDOR]: {
    view: (props?: Props) =>
      new View(
        "vendor",
        NavIndex.VENDORS,
        t("views.vendors.vendor"),
        "vendor",
        "vendors",
        props
      ),
    parent: Route.VENDORS
  },
  [Route.VENDORS_NEW_VENDOR]: {
    view: () =>
      new View(
        "new-vendor",
        NavIndex.VENDORS,
        t("views.vendors.newVendor"),
        "new-vendor",
        "vendors"
      ),
    parent: Route.VENDORS
  },

  [Route.HR]: {
    view: () => new View("hr", NavIndex.HR, t("views.hr.hr"), "human-resources")
  },

  [Route.HR_EMPLOYEES]: {
    view: () =>
      new View(
        "employees",
        NavIndex.HR,
        t("views.hr.employees.employees"),
        "employees"
      ),
    parent: Route.HR
  },

  [Route.HR_EMPLOYEES_EMPLOYEE]: {
    view: (props?: Props) =>
      new View(
        "employee",
        NavIndex.HR,
        t("views.hr.employees.employee"),
        "employee",
        "employees",
        props
      ),
    parent: Route.HR_EMPLOYEES
  },

  [Route.HR_EMPLOYEES_NEW_EMPLOYEE]: {
    view: (props?: Props) =>
      new View(
        "new-employee",
        NavIndex.HR,
        t("views.hr.employees.newEmployee"),
        "new-employee",
        "employees",
        props
      ),
    parent: Route.HR_EMPLOYEES
  },

  [Route.HR_EMPLOYEE_SCHEDULE]: {
    view: () =>
      new View(
        "employee-schedule",
        NavIndex.HR,
        t("views.hr.employeeSchedule.employeeSchedule"),
        "employee-schedule",
        "hr"
      ),
    parent: Route.HR
  },

  [Route.HR_EMPLOYEE_SCHEDULE_NEW_EMPLOYEE_SCHEDULE]: {
    view: () =>
      new View(
        "new-employee-schedule",
        NavIndex.HR,
        t("views.hr.employeeSchedule.newEmployeeSchedule"),
        "new-employee-schedule",
        "employee-schedule"
      ),
    parent: Route.HR_EMPLOYEE_SCHEDULE
  },

  [Route.ACCOUNTING]: {
    view: () =>
      new View(
        "accounting",
        NavIndex.ACCOUNTING,
        t("views.accounting.accounting"),
        "accounting"
      )
  },

  [Route.ACCOUNTING_ACCOUNTS]: {
    view: () =>
      new View(
        "accounts",
        NavIndex.ACCOUNTING,
        t("views.accounting.accounts.accounts"),
        "accounts",
        "accounting"
      ),
    parent: Route.ACCOUNTING
  },

  [Route.ACCOUNTING_ACCOUNTS_ACCOUNT]: {
    view: (props?: Props) =>
      new View(
        "account",
        NavIndex.ACCOUNTING,
        t("views.accounting.accounts.account"),
        "account",
        "accounts",
        props
      ),
    parent: Route.ACCOUNTING_ACCOUNTS
  },

  [Route.ACCOUNTING_ACCOUNTS_NEW_ACCOUNT]: {
    view: (props?: Props) =>
      new View(
        "new-account",
        NavIndex.ACCOUNTING,
        t("views.accounting.accounts.newAccount"),
        "new-account",
        "accounts",
        props
      ),
    parent: Route.ACCOUNTING_ACCOUNTS
  },

  [Route.ACCOUNTING_TRANSACTIONS]: {
    view: () =>
      new View(
        "transactions",
        NavIndex.ACCOUNTING,
        t("views.accounting.transactions.transactions"),
        "transactions",
        "accounting"
      ),
    parent: Route.ACCOUNTING
  },

  [Route.ACCOUNTING_TRANSACTIONS_TRANSACTION]: {
    view: (props?: Props) =>
      new View(
        "transaction",
        NavIndex.ACCOUNTING,
        t("views.accounting.transactions.transaction"),
        "transaction",
        "transactions",
        props
      ),
    parent: Route.ACCOUNTING_TRANSACTIONS
  },

  [Route.ACCOUNTING_TRANSACTIONS_NEW_TRANSACTION]: {
    view: () =>
      new View(
        "new-transaction",
        NavIndex.ACCOUNTING,
        t("views.accounting.transactions.newTransaction"),
        "new-transaction",
        "transactions"
      ),
    parent: Route.ACCOUNTING_TRANSACTIONS
  },

  [Route.REPORTS]: {
    view: () =>
      new View(
        "reports",
        NavIndex.REPORTS,
        t("views.reports.reports"),
        "reports"
      )
  },

  [Route.SETTINGS]: {
    view: () =>
      new View(
        "settings",
        NavIndex.SETTINGS,
        t("views.settings.settings"),
        "settings"
      )
  }
};

/**
 * @deprecated This method should not be used
 */
export function goTo(route: string, props?: Props) {
  if (!(route in routes)) {
    // do not change the route
    console.error("route not found", route);
    // TODO: log
    return;
  }

  const view = routes[route].view(props);
  pushView(view);
}
