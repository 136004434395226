<template>
  <account-form
    :initAccount="initAccount"
    :successMsg="t('created')"
    :submitAction="submit"
    enableClearForm
    clearFormOnSubmit
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AccountForm from "./AccountForm.vue";

import { Account } from "@/models/accounting/Account";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "new-account",
  components: {
    AccountForm
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          created: "Account was created successfully"
        },
        ar: {
          created: "تم انشاء الحساب بنجاح"
        }
      }
    });

    return { t };
  },

  data() {
    return {
      initAccount: new Account()
    };
  },

  methods: {
    async submit(category: Account) {
      try {
        await this.$http.post(Account.ENDPOINT, category);
        await this.$router.push(this.$Route.ACCOUNTING_ACCOUNTS);
      } catch (error) {
        return error;
      }
    }
  }
});
</script>

<style lang="postcss"></style>
