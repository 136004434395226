<template>
  <teleport to="body">
    <div
      v-if="show"
      class="bg-black bg-opacity-20 absolute top-0 w-full flex h-full justify-center items-center"
    >
      <loading-spinner diameter="64px" />
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: "loading-modal",
  components: {
    LoadingSpinner
  },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.$globalEmitter.on("show-loading-modal", () => {
      this.show = true;
    });

    this.$globalEmitter.on("hide-loading-modal", () => {
      this.show = false;
    });
  }
});
</script>

<style></style>
