<template>
  <div>
    <div class="w-full mx-8 my-2">
      {{ t("items") }}
    </div>
    <div
      class="flex flex-col flex-wrap content-center m-3 md:flex-row lg:justify-start"
    >
      <template v-if="$can('create', '/inventory/items')">
        <action-button
          :title="$t('views.inventory.items.newSimpleItem')"
          faIcon="plus"
          @click.prevent="$router.push($Route.INVENTORY_ITEMS_NEW_SIMPLE_ITEM)"
        >
        </action-button>
      </template>

      <!-- <template v-if="true">
        <action-button title="ادخال منتجات" faIcon="file-import">
        </action-button>
      </template> -->

      <template v-if="$can('read', '/inventory/items')">
        <action-button
          :title="$t('views.inventory.items.items')"
          faIcon="eye"
          @click.prevent="$router.push($Route.INVENTORY_ITEMS)"
        >
        </action-button>
      </template>
    </div>

    <div class="w-full mx-8 my-2">
      {{ t("inventory") }}
    </div>
    <div
      class="flex flex-col flex-wrap content-center m-3 md:flex-row lg:justify-start"
    >
      <template v-if="$can('read', '/inventory/inventoryreceivings')">
        <action-button
          :title="$t('views.inventory.inventoryReceivings.inventoryReceivings')"
          faIcon="arrow-down"
          @click.prevent="$router.push($Route.INVENTORY_INVENTORY_RECEIVINGS)"
        >
        </action-button>
      </template>

      <template v-if="$can('read', '/inventory/inventoryadjustments')">
        <action-button
          :title="
            $t('views.inventory.inventoryAdjustments.inventoryAdjustments')
          "
          faIcon="edit"
          @click.prevent="$router.push($Route.INVENTORY_INVENTORY_ADJUSTMENTS)"
          isDanger
        >
        </action-button>
      </template>

      <template v-if="$can('read', '/inventory/inventorytransfers')">
        <action-button
          :title="$t('views.inventory.inventoryTransfers.inventoryTransfers')"
          @click.prevent="$router.push($Route.INVENTORY_INVENTORY_TRANSFERS)"
          faIcon="arrows-alt-h"
          isDanger
        >
        </action-button>
      </template>

      <!-- <template v-if="true">
        <action-button
          :title="
            $t(
              'views.inventory.inventoryReturnToVendors.inventoryReturnToVendors'
            )
          "
          faIcon="arrow-left"
          isDanger
        >
        </action-button>
      </template> -->
    </div>

    <div class="w-full mx-8 my-2">
      {{ t("shortcuts") }}
    </div>
    <div
      class="flex flex-col flex-wrap content-center m-3 md:flex-row lg:justify-start"
    >
      <template v-if="true">
        <action-button
          :title="$t('views.inventory.printBarcode.printBarcode')"
          faIcon="barcode"
          @click.prevent="$router.push($Route.INVENTORY_PRINT_BARCODE)"
        >
        </action-button>
      </template>

      <template v-if="$can('read', '/vendor/vendors')">
        <action-button
          :title="$t('views.vendors.vendors')"
          faIcon="store"
          @click.prevent="$router.push($Route.VENDORS)"
        >
        </action-button>
      </template>

      <template v-if="$can('read', '/inventory/itemcategories')">
        <action-button
          :title="$t('views.inventory.categories.categories')"
          faIcon="sitemap"
          @click.prevent="$router.push($Route.INVENTORY_ITEM_CATEGORIES)"
        >
        </action-button>
      </template>

      <template v-if="$can('read', '/inventory/itemtags')">
        <action-button
          :title="$t('views.inventory.tags.tags')"
          faIcon="tags"
          @click.prevent="$router.push($Route.INVENTORY_ITEM_TAGS)"
        >
        </action-button>
      </template>

      <template v-if="$can('read', '/inventory/itembrands')">
        <action-button
          :title="$t('views.inventory.brands.brands')"
          faIcon="certificate"
          @click.prevent="$router.push($Route.INVENTORY_ITEM_BRANDS)"
        >
        </action-button>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ActionButton from "@/components/ActionButton.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "inventory",
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          items: "Items",
          inventory: "Inventory",
          shortcuts: "Useful Shortcuts"
        },
        ar: {
          items: "المنتجات",
          inventory: "البضاعة",
          shortcuts: "المختصرات المفيدة"
        }
      }
    });

    return { t };
  },

  components: {
    ActionButton
  }
});
</script>

<style></style>
