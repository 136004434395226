
import { defineComponent, ref } from "vue";
import Navbar from "@/layouts/nav/Navbar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useAuth } from "@/plugins/auth";

import { ArrowLeft } from "@element-plus/icons";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    Navbar,
    LoadingSpinner
  },
  setup() {
    const loading = ref(false);
    const { loggedIn } = useAuth();
    const { t } = useI18n({
      messages: {
        en: {
          back: "Back"
        },
        ar: {
          back: "العودة"
        }
      }
    });

    return {
      loading,
      loggedIn,
      ArrowLeft,
      t
    };
  },

  methods: {
    showBackButton() {
      return this.$route.path !== "/" && this.$route.path.split("/").length > 2;
    }
  }
});
