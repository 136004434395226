
import { defineComponent, onBeforeMount, ref } from "vue";

import { useHTTP } from "@/plugins/http";

// components
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import InventoryAdjustment, {
  inventoryAdjustmentI18nMessages
} from "@/models/inventory/InventoryAdjustment";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "inventory-receiving",
  components: {
    LoadingSpinner,
    AlertBadge
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryAdjustmentI18nMessages.en,
          generalInfoSection: "General Info",
          label: "Key",
          value: "Value"
        },
        ar: {
          ...inventoryAdjustmentI18nMessages.ar,
          generalInfoSection: "المعلومات العامة",
          label: "الحقل",
          value: "القيمة"
        }
      }
    });

    const ia = ref(new InventoryAdjustment());
    const http = useHTTP();
    const loading = ref(true);
    const error = ref({ title: "", body: "" });
    const route = useRoute();

    onBeforeMount(async () => {
      const url = `${InventoryAdjustment.ENDPOINT}/${route.params.id}`;

      try {
        const resp = await http.get<InventoryAdjustment>(url);
        ia.value = InventoryAdjustment.from(resp);
      } catch (errorResp) {
        error.value.title = errorResp.title;
        error.value.body = errorResp.body;
      }

      loading.value = false;
    });

    return {
      ia,
      loading,
      error,
      t
    };
  }
});
