
import { defineComponent } from "vue";

import ActionButton from "@/components/ActionButton.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "inventory",
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          accounting: "Accounting Records",
          shortcuts: "Useful Shortcuts",
          registers: "Registers",
          company: "Company",
          assets: "Assets",
          main: "Main",
          all: "All",
          other: "Other"
        },
        ar: {
          accounting: "سجلات المحاسبة",
          shortcuts: "المختصرات المفيدة",
          registers: "صناديق النقد",
          company: "الشركة",
          assets: "الموجودات",
          main: "الحسابات الرئيسية",
          all: "الكل",
          other: "اخرى"
        }
      },
      useScope: "global"
    });

    return { t };
  },

  components: {
    ActionButton
  }
});
