<template>
  <div id="currency-converter" dir="rtl">
    <div class="flex gap-2 items-center m-2 justify-between">
      <p>{{ t("amount") }}</p>

      <span style="width:190px">
        <money-input
          :currency="fromCurrency"
          v-model="amount"
          @formatted-money-updated="formatAmount"
          autofocus
      /></span>
    </div>

    <div class="flex gap-2 items-center m-2 justify-between">
      <p>{{ fromCurrency }}</p>
      <font-awesome-icon
        v-if="$ctx.getDir() === 'rtl'"
        icon="long-arrow-alt-left"
      />
      <font-awesome-icon v-else icon="long-arrow-alt-right" />
      <p>{{ toCurrency }}</p>
    </div>

    <div class="w-full p-2">
      <hr class="solid" />
    </div>

    <div class="flex  gap-2 items-center m-2 justify-between">
      <el-button @click="convert"> {{ t("convert") }} </el-button>
      <p style="justify-self-end" class="text-center">
        {{ convertedAmountFormatted }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Dinero, { Currency } from "dinero.js";
import { useI18n } from "vue-i18n";
import { formatDinero } from "@/utils/money";

import MoneyInput from "@/components/form/MoneyInput.vue";

export default defineComponent({
  name: "currency-converter",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          amount: "Amount",
          from: "From",
          to: "To",
          convert: "Convert"
        },
        ar: {
          amount: "القيمة",
          from: "من",
          to: "إلى",
          convert: "حول"
        }
      },
      unescape: "global"
    });

    return { t };
  },

  components: {
    MoneyInput
  },

  props: {
    fromCurrency: {
      type: String,
      required: true
    },

    toCurrency: {
      type: String,
      required: true
    },

    rate: {
      type: Number,
      required: true
    }
  },

  emits: ["converted-amount"],

  data() {
    return {
      amount: 0,
      previousAmount: 0,
      amountFormated: "",
      convertedAmount: 0,
      convertedAmountFormatted: "",
      rates: {
        rates: {
          [this.toCurrency]: this.rate
        }
      }
    };
  },

  methods: {
    formatAmount(formatted: string) {
      this.amountFormated = formatted;
    },

    async convert() {
      // this operation has already been done
      if (this.amount === this.previousAmount) return;
      this.previousAmount = this.amount;

      // since we're using precision 0 for IQD, currency converstion is losing precision
      // for example, 1,000 IQD would be $1, which sucks
      // by doing this trick, we get a more accurate number
      const amount =
        this.fromCurrency === "IQD" ? this.amount * 1000 : this.amount;
      const precision =
        this.fromCurrency === "IQD" ? 3 : this.fromCurrency === "USD" ? 2 : 0;

      const toConvert = Dinero({
        amount,
        currency: this.fromCurrency as Currency,
        precision
      });

      let converted = await toConvert.convert(this.toCurrency, {
        endpoint: new Promise(resolve => resolve(this.rates))
      });

      if (this.toCurrency === "IQD") {
        converted = converted.convertPrecision(0);
      }

      this.convertedAmount = converted.getAmount();
      this.convertedAmountFormatted = formatDinero(converted);
      this.$emit("converted-amount", this.convertedAmount);
    }
  }
});
</script>

<style scoped>
#currency-converter {
  width: 300px;
  padding: 15px;
}
</style>
