<template>
  <div>
    <iqd-easy-total v-if="$ctx.currency === 'IQD'" v-model="inputVal" />
    <usd-easy-total v-else v-model="inputVal" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Dinero as DineroType } from "dinero.js";
import IQDEasyTotal from "./IQDEasyTotal.vue";
import USDEasyTotal from "./USDEasyTotal.vue";

export default defineComponent({
  components: {
    "iqd-easy-total": IQDEasyTotal,
    "usd-easy-total": USDEasyTotal
  },

  props: {
    modelValue: {
      required: false,
      type: Object as () => DineroType
    }
  },

  emits: ["update:modelValue"],

  computed: {
    inputVal: {
      get(): DineroType | undefined {
        return this.modelValue;
      },

      set(val: DineroType) {
        this.$emit("update:modelValue", val);
      }
    }
  }
});
</script>

<style scoped>
td,
th {
  border: 1px solid #dddddd;
  padding: 8px;
}

.table {
  background: var(--secondary-bg-color);
}
</style>
