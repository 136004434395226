
import { defineComponent } from "vue";

import { i18nOrderMessages, Order } from "@/models/sales/Order";
import { parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero, formatMoney } from "@/utils/money";

import PaymentReceipt from "./PaymentReceipt.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";

export default defineComponent({
  components: {
    PaymentReceipt,
    LoadingSpinner,
    AlertBadge
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          totalDue: "Total Due",
          totalPaid: "Total paid",
          changeDue: "Change due",
          totalKept: "Total Received",
          serialNumbers: "Serial Numbers",
          print: "Print Receipt",
          newSale: "New Sale"
        },
        ar: {
          ...i18nOrderMessages.ar,
          totalDue: "المبلغ المطلوب",
          totalPaid: "المبلغ المدفوع",
          changeDue: "الباقي",
          totalKept: "الصافي",
          serialNumbers: "Serial Numbers",
          print: "اطبع الفاتورة",
          newSale: "بيع جديد"
        }
      }
    });

    return { t };
  },

  data() {
    const order = new Order();
    return {
      order,
      loading: true,
      error: {
        title: "",
        body: ""
      }
    };
  },
  async beforeMount() {
    // get order
    try {
      const orderRes = await this.$http.get<Order>(
        `${Order.ENDPOINT}/${this.$route.params.id}`
      );
      this.order = Order.from(orderRes);
    } catch (error) {
      this.error.title = error.title;
      this.error.body = error.body;
    } finally {
      this.loading = false;
    }
  },

  methods: {
    parseFormat,
    formatDinero,
    formatMoney
  }
});
