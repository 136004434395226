import {
  IconDefinition,
  IconName,
  IconPrefix
} from "@fortawesome/fontawesome-common-types";

export const faNewSale: IconDefinition = {
  prefix: "fac" as IconPrefix,
  iconName: "new-sale" as IconName,
  icon: [
    850,
    850,
    [],
    "e001",
    "M972.8 140.8h-921.6c-28.263 0.035-51.165 22.937-51.2 51.197v522.243c0.035 28.263 22.937 51.165 51.197 51.2h921.603c28.263-0.035 51.165-22.937 51.2-51.197v-522.243c-0.035-28.263-22.937-51.165-51.197-51.2h-0.003zM952.32 345.6c-79.14-0.087-143.273-64.22-143.36-143.352v-0.008h143.36zM71.68 345.6v-143.36h143.36c-0.093 79.138-64.222 143.267-143.351 143.36h-0.009zM512 622.080c-96.142 0-174.080-77.938-174.080-174.080s77.938-174.080 174.080-174.080c96.142 0 174.080 77.938 174.080 174.080v0c-0.111 96.097-77.983 173.969-174.069 174.080h-0.011zM808.96 693.76c0.087-79.14 64.22-143.273 143.352-143.36h0.008v143.36zM71.68 693.76v-143.36c79.14 0.087 143.273 64.22 143.36 143.352v0.008zM638.792 475.873v-59.648h-249.672v59.648zM545.321 321.249h-62.73v249.631h62.72z"
  ]
};
