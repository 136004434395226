
import { defineComponent, reactive } from "vue";

import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import AlertBadge from "@/components/AlertBadge.vue";

import { useI18n } from "vue-i18n";
import { useAuth } from "@/plugins/auth";
import { ElForm } from "element-plus";

import { Register } from "@/models/company/Register";
import { Machine } from "@/models/company/Machine";
import { grantPermission } from "@/plugins/access-control";

export default defineComponent({
  name: "login",
  components: {
    HorizontalButton,
    InputSection,
    AlertBadge
  },

  beforeCreate() {
    const { resetAuthState } = useAuth();
    resetAuthState();
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          username: "Username",
          password: "Password",
          submit: "Login"
        },
        ar: {
          username: "اسم المستخدم",
          password: "كلمة المرور",
          submit: "تسجيل الدخول"
        }
      },
      useScope: "global"
    });

    const creds = reactive({
      username: "",
      password: ""
    });
    const error = reactive({ title: "", body: "" });

    const rules = {
      username: [{ required: true, message: t("validation.required") }],
      password: [{ required: true, message: t("validation.required") }]
    };

    return { t, creds, error, rules };
  },
  methods: {
    async login(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      const { userLogin } = useAuth();

      form?.validate(async (valid: boolean) => {
        if (!valid) {
          return false;
        }

        try {
          this.$loadingModal.show();
          const user = await userLogin(this.creds);
          user.userPermissions.forEach(up => {
            grantPermission(up.permission.action, up.permission.object);
          });

          if (!this.$ctx.currentMachine.id) {
            const machineGUID =
              localStorage.getItem("machineGUID") ||
              "e3fb76a9-9664-4d1d-ae05-e98119c72032";

            // load machine and register
            const machine = await this.$http.get<Machine>(
              `${Machine.ENDPOINT}?machine_guid=${machineGUID}`
            );
            this.$ctx.setMachine(Machine.from(machine));

            const register = await this.$http.get<Register>(
              `${Register.ENDPOINT}?machine_id=${this.$ctx.currentMachine.id}`
            );
            this.$ctx.setRegister(Register.from(register));
          }
        } catch (err) {
          this.error.title = err.title;
          this.error.body = err.body;
        } finally {
          this.$loadingModal.hide();
          await this.$router.replace("/");
        }
      });
    }
  }
});
