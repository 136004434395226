
import { defineComponent } from "vue";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";

// models
import Brand, { brandI18nMessages } from "@/models/inventory/Brand";
import { useI18n } from "vue-i18n";
import { ElForm } from "element-plus";

export default defineComponent({
  name: "brand-form",
  components: {
    AlertBadge,
    HorizontalButton,
    InputSection
  },
  props: {
    initBrand: {
      type: Brand,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...brandI18nMessages.en,
          brandInfo: "Brand Info",
          placeholder: "Example: Adidas"
        },
        ar: {
          ...brandI18nMessages.ar,
          brandInfo: "بيانات العلامة التجارية",
          placeholder: "مثلا: اديداس"
        }
      },
      useScope: "global"
    });

    return { t };
  },

  data() {
    return {
      brand: this.initBrand,
      error: { title: "", body: "" },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          },
          {
            max: 255,
            message: this.$t("validation.maxLength", { len: 255 }),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.brand);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    }
  }
});
