
import { defineComponent, PropType, ref } from "vue";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import InputGroup from "@/components/form/InputGroup.vue";
import { ElForm } from "element-plus";

// models
import User, { userI18nMessages, UserPermission } from "@/models/user/User";
import { useI18n } from "vue-i18n";
import MoneyInput from "@/components/form/MoneyInput.vue";

// data
import {
  cashierPermissions,
  defaultAllFalse,
  managerPermissions,
  ownerPermissions,
  PermGroup
} from "./predefinedPermissionGroups";
import PermissionGroup from "@/models/hr/Permission";

export default defineComponent({
  name: "employee-form",

  setup() {
    const { t } = useI18n({
      useScope: "global",
      messages: {
        en: {
          ...userI18nMessages.en,

          sections: {
            generalInfo: "General Information",
            employmentInfo: "Employment Information",
            loginInfo: "Login Information",
            permissions: "Permissions"
          },

          placeholders: {
            firstName: "Example: Ahmed",
            lastName: "Example: Al-Joubouri",
            phone: "Example: 077011111111",
            email: "Example: ahmed@gmail.com",
            streetAddress: "Example: Next to Almansour Mall",
            city: "Example: Baghdad",
            postalCode: "Example: 10015",
            country: "Example: Iraq",
            title: "Example: Sales Rep",
            salary: "Example: IQD 1,000,000",
            username: "Example: ahmedjoubouri"
          },

          permGroup: "Permission Group",

          actions: {
            create: "Create",
            read: "Read",
            update: "Update",
            delete: "Delete"
          },
          permissions: {
            accounting: "Accounting",
            "/accounting/accounts": "Accounts",
            "/accounting/transactions": "Transactions",
            companyManagement: "Company Management",
            "/company/registers/close": "Close Register",
            "/company/registers/open": "Open Register",
            customers: "Customers",
            "/customer/customers": "Customers",
            hr: "Human Resources",
            "/hr/employeeclocks": "Clock in/Clock out",
            "/hr/employees": "Employees",
            "/hr/employeeschedule": "Employee Schedule",
            inventory: "Inventory",
            "/inventory/inventoryadjustments": "Inventory Adjustment",
            "/inventory/inventoryreceivings": "Inventory Receiving",
            "/inventory/inventorytransfers": "Inventory Transfer",
            "/inventory/itembrands": "Brands",
            "/inventory/itemcategories": "Categories",
            "/inventory/items": "Items",
            "/inventory/itemtags": "Tags",
            sales: "Sales",
            "/sales/orderpayments": "Order Payments",
            "/sales/orders": "Orders",
            "/sales/returnorders": "Return Orders",
            users: "Employees",
            "/user/users": "Employees",
            vendors: "Vendors",
            "/accounting/registerstatus": "Register Status",
            "/vendor/vendors": "Vendors",
            "/accounting/company":"Company",
            "/accounting/company/expensePayee":"Expense Payee",
            "/accounting/company/expenseType":"Expense Type",
            "/accounting/customerinvoices":"Customer Invoices",
            "/accounting/customerinvoices/payments":"Customer Invoices Payments",
            "/accounting/hr":"HR",
            "/accounting/postings":"Postings",
            "/accounting/registertransactions":"Register Transactions",
            "/accounting/vendorbills":"Vendor Bills",
            "/accounting/vendorbills/payments":"Vendor Bills Payments",
            "company":"Company",
            "/company/locations":"Locations",
            "/company/machines":"Machines",
            "/company/register-actions":"Register Actions",
            "/company/registers":"Registers",
            "/company/registers/compute-closing-amount":"Compute Register Closing Amount",
            "/company/registers/status":"Registers Status",
            "customer":"Customer",
            "helpers":"Helpers",
            "/helpers/count":"Count Rows",
            "/inventory/inventorytransactions":"Inventory Transactions",
            "reports":"Reports",
            "/reports/accounting/profit-and-loss":"Profit And Loss",
            "/reports/customer/customer-balance":"Customer Balance",
            "/reports/customer/top-customers":"Top Customers",
            "/reports/inventory-quantity":"Inventory Quantity",
            "/reports/inventory-value":"Inventory Value",
            "/reports/sales/end-of-day":"End Of Day",
            "/reports/sales/heatmap":"Heatmap",
            "/reports/sales/least-sold-items":"Least Sold Items",
            "/reports/sales/sales-by-category":"Sales By Category",
            "/reports/sales/sales-by-item":"Sales By Item",
            "/reports/sales/sales-comparison":"Sales Comparison",
            "/reports/sales/sales-summary":"Sales Summary",
            "/reports/sales/top-sold-items":"Top Sold Items",
            "/reports/vendor/top-vendors":"Top Vendors",
            "/reports/vendor/vendor-balance":"Vendor Balance",
            "settings":"Settings",
            "/settings":"Global Settings",
            "/settings/permissions":"Permissions",
            "user": "User",
            "/user/permissionusers": "User Permissions",
            "vendor": "Vendor",
          }
        },
        ar: {
          ...userI18nMessages.ar,

          sections: {
            generalInfo: "المعلومات العامة",
            employmentInfo: "المعلومات الوظيفية",
            loginInfo: "تسجيل الدخول",
            permissions: "تحديد الصلاحيات"
          },

          placeholders: {
            name: "مثلا: شركة الحمزة للمنتجات الغذائية",
            firstName: "مثلا: احمد",
            lastName: "مثلا: الجبوري",
            phone: "مثلا: 077011111111",
            email: "مثلا: ahmed@gmail.com",
            streetAddress: "مثلا: مجاور لمول المنصور",
            city: "مثلا: بغداد",
            postalCode: "مثلا: 10015",
            country: "مثلا: العراق",
            title: "مثلا: موظف مبيعات",
            salary: "مثلا: 1,000,000",
            username: "مثلا: ahmedjoubouri"
          },

          permGroup: "الصلاحية",

          actions: {
            create: "انشاء",
            read: "قراءة",
            update: "تحديث",
            delete: "مسح"
          },
          permissions: {
            accounting: "المحاسبة",
            "/accounting/accounts": "الحسابات",
            "/accounting/transactions": "التحركات المالية",
            companyManagement: "ادارة الشركة",
            "/company/registers/close": "غلق الخزانة",
            "/company/registers/open": "فتح الخزانة",
            customers: "الزبائن",
            "/customer/customers": "الزبائن",
            hr: "الموارد البشرية",
            "/hr/employeeclocks": "تسجيل الدخول والخروج",
            "/hr/employees": "ادارة الموظفين",
            "/hr/employeeschedule": "جدول الموظفين",
            inventory: "المخزن",
            "/inventory/inventoryadjustments": "تعديل البضاعة",
            "/inventory/inventoryreceivings": "استلام البضاعة",
            "/inventory/inventorytransfers": "تحويل البضاعة",
            "/inventory/itembrands": "العلامات التجارية",
            "/inventory/itemcategories": "المجاميع",
            "/inventory/items": "المنتجات",
            "/inventory/itemtags": "الوسوم",
            sales: "المبيعات",
            "/sales/orderpayments": "دفعات المبيعات",
            "/sales/orders": "المبيعات",
            "/sales/returnorders": "ارجاع المبيعات",
            users: "الموظفين",
            "/user/users": "الموظفين",
            vendors: "الموزعين",
            "/accounting/registerstatus": "حالة الخزنة",
            "/vendor/vendors": "الموزعين",
            "/accounting/company":"الشركات",
            "/accounting/company/expensePayee":"المصاريف المدفوعة",
            "/accounting/company/expenseType":"انواع المصاريف",
            "/accounting/customerinvoices":"فواتير الزبائن",
            "/accounting/customerinvoices/payments":"مدفوعات فواتير الزبائن",
            "/accounting/hr":"الموارد البشرية",
            "/accounting/postings":"Postings",
            "/accounting/registertransactions":"الحراكات المالية لصناديق النقد",
            "/accounting/vendorbills":"مصاريف الموزعين",
            "/accounting/vendorbills/payments":"مدفوعات مصاريف الموزعين",
            "company":"الشركة",
            "/company/locations":"المتاجر",
            "/company/machines":"الاجهزة",
            "/company/register-actions":"عمليات صناديق النقد",
            "/company/registers":"صناديق النقد",
            "/company/registers/compute-closing-amount":"حساب مبلغ اغلاق الخزنة",
            "/company/registers/status":"حالة صناديق النقد",
            "customer":"الزبون",
            "helpers":"المساعدة",
            "/helpers/count":"حساب عدد الصفوف",
            "/inventory/inventorytransactions":"عمليات المخزون",
            "reports":"التقارير",
            "/reports/accounting/profit-and-loss":"الربح والخسارة",
            "/reports/customer/customer-balance":"رصيد الزبون",
            "/reports/customer/top-customers":"افضل الزبائن",
            "/reports/inventory-quantity":"كمية المخزون",
            "/reports/inventory-value":"قيمة المخزون",
            "/reports/sales/end-of-day":"نهاية اليوم",
            "/reports/sales/heatmap":"خريطة الحرارة",
            "/reports/sales/least-sold-items":"المنتجات الاقل مبيعا",
            "/reports/sales/sales-by-category":"المبيعات حسب الفئة",
            "/reports/sales/sales-by-item":"المبيعات حسب المنتج",
            "/reports/sales/sales-comparison":"مقارنة المبيعات",
            "/reports/sales/sales-summary":"ملخص المبيعات",
            "/reports/sales/top-sold-items":"اكثر المنتجات بيعا",
            "/reports/vendor/top-vendors":"افضل المخازن",
            "/reports/vendor/vendor-balance":"رصيد المخزن",
            "settings":"الاعدادات",
            "/settings":"الاعدادات العامة",
            "/settings/permissions":"الصلاحيات",
            "user": "المستخدم",
            "/user/permissionusers": "صلاحيات المستخدم",
            "vendor": "المخزن",
          }
        }
      }
    });

    const rules = {
      firstName: [
        { required: true, message: t("validation.required") },
        { max: 127, message: t("validation.maxLength", { len: 127 }) }
      ],
      lastName: [
        { required: true, message: t("validation.required") },
        { max: 127, message: t("validation.maxLength", { len: 127 }) }
      ],
      phone: [{ max: 15, message: t("validation.maxLength", { len: 15 }) }],
      email: [
        { required: true, message: t("validation.required") },
        { max: 255, message: t("validation.maxLength", { len: 255 }) }
      ],
      streetAddress: [
        { max: 255, message: t("validation.maxLength", { len: 255 }) }
      ],
      city: [{ max: 70, message: t("validation.maxLength", { len: 70 }) }],
      postalCode: [
        { max: 10, message: t("validation.maxLength", { len: 10 }) }
      ],
      country: [{ max: 70, message: t("validation.maxLength", { len: 70 }) }],
      employeeStartDate: [
        { required: true, message: t("validation.required") }
      ],
      username: [
        { required: true, message: t("validation.required") },
        { max: 255, message: t("validation.maxLength", { len: 255 }) }
      ]
    };

    const predefinedPermissionGroups = [
      {
        id: "allFalse",
        value: defaultAllFalse
      },
      {
        id: "owner",
        value: ownerPermissions
      },
      {
        id: "manager",
        value: managerPermissions
      },
      {
        id: "cashier",
        value: cashierPermissions
      }
    ];

    const permissionDropdownValue = ref("");

    return {
      t,
      rules,
      predefinedPermissionGroups,
      permissionDropdownValue
    };
  },

  components: {
    MoneyInput,
    AlertBadge,
    HorizontalButton,
    InputSection,
    InputGroup
  },
  props: {
    initEmployee: {
      type: User,
      required: true
    },
    permissionGroups: {
      type: Array as PropType<Array<PermissionGroup>>,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      employee: this.initEmployee,
      prmGroups: this.permissionGroups,
      error: { title: "", body: "" }
    };
  },

  mounted() {
    console.log('salom')
    console.log(this.prmGroups)

    // update permissions
    this.employee.userPermissions.forEach(p => {
      this.prmGroups.forEach((_, i) => {
        this.prmGroups[i].permission_routes?.forEach((_, j) => {
          this.prmGroups[i].permission_routes![j].permissions.forEach(
            (pr, k) => {
              if (
                p.permission.id ==
                this.prmGroups[i].permission_routes![j].permissions[k].id
              ) {
                this.prmGroups[i].permission_routes![j].permissions[
                  k
                ].checked = true;
              }
            }
          );
        });
      });
    });
  },

  methods: {
    makeTitle({ row }: { row: { groupName: string } }) {
      return row?.groupName ? [1, 5] : [1, 1];
    },

    changePermissionGroup(value: any) {
      this.prmGroups.forEach((_, i) => {
        this.prmGroups[i].permission_routes?.forEach((_, j) => {
          this.prmGroups[i].permission_routes![j].permissions.forEach(
            (pr, k) => {
              this.prmGroups[i].permission_routes![j].permissions[k].checked = false;
            }
          );
        });
      });

      this.predefinedPermissionGroups.forEach((e) => {
        if (e.id == this.permissionDropdownValue) {
          e.value.forEach(pg => {
            this.prmGroups.forEach((_, i) => {
            this.prmGroups[i].permission_routes?.forEach((_, j) => {
              this.prmGroups[i].permission_routes![j].permissions.forEach((pr, k) => {
                  if (pg.object == this.prmGroups[i].permission_routes![j].permissions[k].object) {
                    if (pg.grantedActions![0]) {
                      if (this.prmGroups[i].permission_routes![j].permissions[k].action == "create") {
                        this.prmGroups[i].permission_routes![j].permissions[k].checked = true;
                      }
                    }
                    if (pg.grantedActions![1]) {
                      if (this.prmGroups[i].permission_routes![j].permissions[k].action == "read") {
                        this.prmGroups[i].permission_routes![j].permissions[k].checked = true;
                      }
                    }
                    if (pg.grantedActions![2]) {
                      if (this.prmGroups[i].permission_routes![j].permissions[k].action == "update") {
                        this.prmGroups[i].permission_routes![j].permissions[k].checked = true;
                      }
                    }
                    if (pg.grantedActions![3]) {
                      if (this.prmGroups[i].permission_routes![j].permissions[k].action == "delete") {
                        this.prmGroups[i].permission_routes![j].permissions[k].checked = true;
                      }
                    }
                    
                  }
              });
            });
          });
            
        });
        }
      })
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }
        // populate user permissions
        this.employee.userPermissions = [];
        this.prmGroups.forEach((_, i) => {
        this.prmGroups[i].permission_routes?.forEach((_, j) => {
          this.prmGroups[i].permission_routes![j].permissions.forEach((pr, k) => {
              if (pr.checked) {
                this.employee.userPermissions.push(new UserPermission(pr.action, pr.object || "")
              );
              }
            }
          );
        });
      });


        const resp = await this.submitAction(this.employee);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    }
  }
});
