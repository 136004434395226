
// credit: https://codepen.io/afarrar/pen/JRaEjP
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "clock",
  setup() {
    const time = ref("");
    setInterval(() => {
      const date = new Date();
      let h = date.getHours(); // 0 - 23
      const m = date.getMinutes(); // 0 - 59
      const s = date.getSeconds(); // 0 - 59
      let session = "AM";

      if (h == 0) {
        h = 12;
      }

      if (h > 12) {
        h = h - 12;
        session = "PM";
      }

      const hs = h < 10 ? "0" + h : h;
      const ms = m < 10 ? "0" + m : m;
      const ss = s < 10 ? "0" + s : s;

      time.value = hs + ":" + ms + ":" + ss + " " + session;
    }, 1000);

    return { time };
  }
});
