import UUIDBase from "../UUIDBase";

export class Machine extends UUIDBase {
  static ENDPOINT = "/company/machines";

  name = "";
  machineGUID = "";

  static from(json: Record<string, unknown> | Machine): Machine {
    return Object.assign(new Machine(), json);
  }
}
