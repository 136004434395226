import UUIDBase from "@/models/UUIDBase";
import User from "../user/User";
import Vendor from "@/models/vendor/Vendor";
import Item from "@/models/inventory/Item";
import ItemSerial from "./ItemSerial";
import Location from "../company/Location";
import { useI18nInstance, localizeFieldName } from "@/plugins/i18n";
import { Dinero as DineroType } from "dinero.js";
import VendorBill from "../vendor/VendorBill";
import { Register } from "../company/Register";

const { t } = useI18nInstance().global;

export const inventoryReceivingI18nMessages = {
  en: {
    id: "ID",
    user: "By employee",
    vendor: "Related to vendor",
    notes: "Notes",
    item: "Item Name",
    quantity: "Quantity",
    location: "Location",
    itemName: "itemName",
    serialNumbers: "Serial Numbers",
    itemCost: "Cost",
    totalCost: "Total Cost",
    billDate: "Bill Date",
    dueDate: "Due Date",
    paidAmount: "Paid Amount",
    otherCosts: "Other Costs",
    addSerials: "Add Serials",
    total: "Total",
    inventoryTotal: "Inventory Total"
  },

  ar: {
    id: "ID",
    user: "الموظف المسؤول",
    vendor: "متعلقة بالموزع",
    notes: "الملاحظات",
    item: "اسم المنتج",
    quantity: "الكمية",
    location: "الموقع",
    itemName: "اسم المنتج",
    serialNumbers: "الارقام التسلسلية",
    itemCost: "التكلفة",
    totalCost: "التكلفة الكلية",
    billDate: "تاريخ الفاتورة",
    dueDate: "تاريخ انتهاء الفاتورة",
    paidAmount: "المبلغ المدفوع",
    otherCosts: "تكاليف اخرى",
    addSerials: "Add Serials",
    total: "التكلفة الكلية",
    inventoryTotal: "تكلفة المخزن"
  }
};

export class InventoryReceivingItemSerial extends UUIDBase {
  inventoryReceivingItemID = "";
  itemSerialID = "";
  itemSerial: ItemSerial;

  constructor(serialNumber: string) {
    super();
    this.itemSerial = new ItemSerial(serialNumber);
  }
}

export class InventoryReceivingItem extends UUIDBase {
  inventoryReceivingID?: string;

  itemID = "";
  item: Item;
  // default quantity
  quantity = 1 as number;
  inventoryReceivingItemSerials?: InventoryReceivingItemSerial[];
  costAmount?: number;

  constructor(item: Item) {
    super();
    this.item = item;
    this.itemID = item.id || "";
    this.costAmount = item.costAmount;
    if (item.isSerialized) {
      this.inventoryReceivingItemSerials = new Array<
        InventoryReceivingItemSerial
      >();
    }
  }

  insertSerialNumber(serialNumber: string) {
    this.inventoryReceivingItemSerials?.push(
      new InventoryReceivingItemSerial(serialNumber)
    );
  }

  removeSerialNumber(index: number) {
    this.inventoryReceivingItemSerials?.splice(index, 1);
  }

  validateSerials() {
    if (
      this.item.isSerialized &&
      this.inventoryReceivingItemSerials?.length != this.quantity
    ) {
      return t("validation.matchingLength", {
        thisField: localizeFieldName(
          "serialNumbers",
          inventoryReceivingI18nMessages
        ),
        thatField: localizeFieldName("quantity", inventoryReceivingI18nMessages)
      });
    }
  }

  validateQuantity() {
    if (this.quantity <= 0) {
      return t("validation.positive", {
        field: localizeFieldName("quantity", inventoryReceivingI18nMessages)
      });
    }
  }
}

export default class InventoryReceiving extends UUIDBase {
  vendorID?: string;
  vendor = new Vendor();
  userID?: string;
  user = new User();
  locationID?: string;
  location = new Location();
  notes = "";
  inventoryReceivingItems = new Array<InventoryReceivingItem>();
  vendorBillID?: string;
  vendorBill = new VendorBill();

  totalCostAmount?: number;
  totalCostDinero?: DineroType;

  static from(json: Record<string, unknown> | InventoryReceiving) {
    const i = Object.assign(new InventoryReceiving(), json);
    i.user = User.from(i.user);
    i.vendor = Vendor.from(i.vendor);
    i.location = Location.from(i.location);
    return i;
  }

  static ENDPOINT = "/inventory/inventoryreceivings";

  includes(item: Item) {
    for (const ir of this.inventoryReceivingItems) {
      if (ir.item.id === item.id) {
        return true;
      }
    }

    return false;
  }

  findByBarcode(barcode: string): Item | undefined {
    for (const ir of this.inventoryReceivingItems) {
      if (ir.item.barcode === barcode) {
        return ir.item;
      }
    }
  }

  findBySKU(sku: string): Item | undefined {
    for (const ir of this.inventoryReceivingItems) {
      if (ir.item.sku === sku) {
        return ir.item;
      }
    }
  }

  incrementQuantity(item: Item) {
    for (const ir of this.inventoryReceivingItems) {
      if (ir.item.id === item.id) {
        ir.quantity++;
        return;
      }
    }
  }

  addItem(item: Item) {
    if (!this.includes(item)) {
      this.inventoryReceivingItems.push(new InventoryReceivingItem(item));
    } else {
      this.incrementQuantity(item);
    }
  }

  removeItem(index: number) {
    this.inventoryReceivingItems.splice(index, 1);
  }

  empty() {
    return this.inventoryReceivingItems.length === 0;
  }

  calculateTotalCostAmount() {
    let total = 0;
    console.log(this.inventoryReceivingItems);
    if (this.inventoryReceivingItems.length > 0) {
      this.inventoryReceivingItems.forEach(inventoryReceivingItem => {
        total += inventoryReceivingItem.costAmount! * inventoryReceivingItem.quantity;
      });
      this.totalCostAmount = total;
    }
  }

  preparePayload(register: Register, user: User, location: Location) {
    this.locationID = location.id;
    this.userID = user.id;
  }
}
