
import { defineComponent } from "vue";

import { i18nOrderMessages, Order } from "@/models/sales/Order";
import { parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero, formatMoney } from "@/utils/money";

import Receipt from "@/components/Receipt.vue";

export default defineComponent({
  components: {
    Receipt
  },

  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          totalDue: "Total Due",
          totalPaid: "Total Paid",
          changeDue: "Change Due",
          totalKept: "Total Received",
          serialNumbers: "Serial Numbers",
          print: "Print Receipt",
          newSale: "New Sale",
          totalPaidBeforeChange: "Total Paid Before Change",
          orderPayments: "Order Payments"
        },
        ar: {
          ...i18nOrderMessages.ar,
          totalDue: "المبلغ المطلوب",
          totalPaid: "المبلغ المدفوع",
          changeDue: "الباقي",
          totalKept: "الصافي",
          serialNumbers: "الارقام التسلسلية",
          print: "اطبع الفاتورة",
          newSale: "بيع جديد",
          totalPaidBeforeChange: "المبلغ المدفوع قبل الباقي",
          orderPayments: "المدفوعات"
        }
      }
    });

    function truncateItemName(itemName: string) {
      const maxLength = Math.min(itemName.length, 40);
      return maxLength >= 40
        ? `${itemName.substring(0, maxLength)}...`
        : itemName;
    }

    function getLastCustomerInvoicePayment() {
      const arrayLength =
        props.order.invoice.customerInvoicePayments.length - 1;
      return props.order.invoice?.customerInvoicePayments[arrayLength];
    }
    return {
      t,
      formatDinero,
      formatMoney,
      parseFormat,
      truncateItemName,
      getLastCustomerInvoicePayment
    };
  },

  props: {
    order: {
      required: true,
      type: Order
    },
    isOrderPayment: Boolean
  }
});
