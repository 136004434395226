
import { defineComponent } from "@vue/runtime-core";
import EasyTotal from "@/components/money/EasyTotal.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";

import Dinero from "dinero.js";
import { useI18n } from "vue-i18n";
import { OpenRegisterPayload, Register } from "@/models/company/Register";

export default defineComponent({
  name: "open-register",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          submitCount: "Open Register",
          tooltip: "Please enter opening amount (floating money)"
        },
        ar: {
          submitCount: "افتح الخزانة",
          tooltip: "يرجى ادخال المبلغ الاولي في الخزانة"
        }
      }
    });

    return { t };
  },

  components: {
    EasyTotal,
    AlertBadge,
    HorizontalButton
  },

  data() {
    return {
      error: {
        title: "",
        body: ""
      },
      total: Dinero()
    };
  },

  methods: {
    async validateSubmit() {
      try {
        const payload: OpenRegisterPayload = {
          actualOpenAmount: this.total.getAmount(),
          currency: this.total.getCurrency(),
          precision: this.total.getPrecision(),
          registerID: this.$ctx.currentRegister.id,
          timestamp: new Date(),
          userID: this.$ctx.currentUser.id,
          action: "open"
        };

        await this.$http.post(`/company/register-actions`, payload);

        await this.$router.push(this.$Route.SALES);
      } catch (error) {
        return error;
      }
    }
  }
});
