import UUIDBase from "../UUIDBase";

export const accountI18nMessages = {
  en: {
    id: "ID",
    enName: "Account English Name",
    arName: "Account Arabic Name",
    enDescription: "English Description",
    arDescription: "Arabic Description",
    code: "Code",
    isSystemAccount: "Is System Account",
    accountCategory: "AccountCategory",
    parentAccount: "Belongs to Account"
  },
  ar: {
    id: "ID",
    enName: "عنوان الحساب الانكليزي",
    arName: "عنوان الحساب العربي",
    enDescription: "الوصف الانكلبزي",
    arDescription: "الوصع العربي",
    code: "الرمز",
    isSystemAccount: "حساب أساسي",
    accountCategory: "AccountCategory",
    parentAccount: "تابع الى"
  }
};

enum AccountCategory {
  Assets = "Assets",
  Liabilities = "Liabilities",
  Equity = "Equity",
  Revenue = "Revenue",
  Expenses = "Expenses"
}

enum code {
  CashAccountCode = 101,
  InventoryAccountCode = 102,
  AccountsReceivableAccountCode = 103,
  FurnitureAccountCode = 104,
  EquipmentAccountCode = 105,
  LoanAccountCode = 201,
  AccountsPayableAccountCode = 202,
  CustomerDepositAccountCode = 203,
  CapitalAccountCode = 301,
  SalesAccountCode = 401,
  ReturnsAndAllowancesAccountCode = 402,
  CoGSAccountCode = 501,
  PayrollAccountCode = 502,
  BillsAccountCode = 503,
  TransportationAccountCode = 504
}
export class Account extends UUIDBase {
  static ENDPOINT = "/accounting/accounts";

  parentAccount?: Account;
  parentAccountID = "";

  enName = "";
  arName = "";

  code?: code;
  arDescription?: string;
  enDescription?: string;
  accountCategory?: AccountCategory;
  isSystemAccount = false;
  referenceableID?: string;
  referenceableType?: string;

  static from(json: Record<string, unknown> | Account) {
    const a = Object.assign(new Account(), json);
    if (json.parentAccount) {
      a.parentAccount = Account.from(
        json.parentAccount as Record<string, unknown>
      );
    }

    return a;
  }
}
