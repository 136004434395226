
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import { ColDef, GridOptions } from "ag-grid-community";
import HorizontalButton from "@/components/HorizontalButton.vue";

import InventoryReceiving, {
  inventoryReceivingI18nMessages
} from "@/models/inventory/InventoryReceiving";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "inventory-receivings",

  setup() {
    const { t } = useI18n({
      messages: inventoryReceivingI18nMessages
    });

    return { t };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: InventoryReceiving[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.t("id"),
        field: "externalID",
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.$t("models.common.createdAt"),
        field: "createdAt",
        cellStyle: { direction: "ltr" },
        valueFormatter: params => {
          const createdAt = new Date(params.value);
          return (
            createdAt.toLocaleDateString("en-GB") +
            " " +
            createdAt.toLocaleTimeString("en-US")
          );
        }
      },
      {
        headerName: this.t("user"),
        field: "user.fullName"
      },
      {
        headerName: this.t("vendor"),
        field: "vendor.name"
      },
      {
        headerName: this.t("location"),
        field: "location.enName"
      },
      {
        headerName: this.t("notes"),
        field: "notes"
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();

    // go to view item
    this.gridOptions.onCellDoubleClicked = event => {
      this.$router.push(
        this.$Route.INVENTORY_INVENTORY_RECEIVINGS_INVENTORY_RECEIVING.replace(
          ":id",
          event.data.id
        )
      );
    };
  },

  watch: {
    searchTerm() {
      this.filterResult();
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();

      try {
        const data = await this.$http.get<InventoryReceiving[]>(
          InventoryReceiving.ENDPOINT
        );
        this.rowData = data.map(inventoryReceing =>
          InventoryReceiving.from(inventoryReceing)
        );
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    },

    async applyToSelected() {
      if (this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();

        if (selected?.length) {
          this.gridOptions.api?.exportDataAsCsv({ onlySelected: true });
          this.$alertModal.showSuccess({
            title: this.$t("views.common.listView.selectedRows.exportSuccess")
          });

          // deselect
          selected.length = 0;
        }
      }

      this.selectedValue = "";
    },

    filterResult() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    }
  }
});
