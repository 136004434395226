import { createApp } from "vue";
import {
  ElAutocomplete,
  ElButton,
  ElCheckbox,
  ElCol,
  ElCollapse,
  ElCollapseItem,
  ElDatePicker,
  ElDialog,
  ElDivider,
  ElDrawer,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElForm,
  ElFormItem,
  ElIcon,
  ElInput,
  ElInputNumber,
  ElLink,
  ElOption,
  ElPopover,
  ElRadio,
  ElRadioButton,
  ElRadioGroup,
  ElRow,
  ElSelect,
  ElSwitch,
  ElTable,
  ElTableColumn,
  ElTabPane,
  ElTabs,
  ElTag,
  ElTimeline,
  ElTimelineItem,
  ElTooltip
} from "element-plus";

// plugins
import router from "./router";
// import { store } from "./store";
import accessControl from "@/plugins/access-control";
import http from "@/plugins/http";
import VueApexCharts from "vue3-apexcharts";

// assets
import "./assets/css/index.css";
import "./assets/css/components.css";

// hooks
import BeforeCreate from "@/components/hooks/BeforeCreate.vue";

// main entry
import App from "./App.vue";
import Login from "@/views/Login.vue";

// TODO: move this part somewhere else
// import Views to register them as global components
import Home from "@/views/Home.vue";

// sales
import Sales from "@/views/sales/Sales.vue";
import NewSale from "@/views/sales/NewSale.vue";
import Orders from "@/views/sales/Orders.vue";
import Order from "@/views/sales/Order.vue";
import OrderReceipt from "@/views/sales/OrderReceipt.vue";
import ReturnOrder from "@/views/sales/ReturnOrder.vue";
import ReturnOrderReceipt from "@/views/sales/ReturnOrderReceipt.vue";
import OrderPayment from "@/views/sales/OrderPayment.vue";
import OrderPaymentReceipt from "@/views/sales/OrderPaymentReceipt.vue";

// registers
import OpenRegister from "@/views/sales/registers/OpenRegister.vue";
import CloseRegister from "@/views/sales/registers/CloseRegister.vue";

// inventory
import Inventory from "@/views/inventory/Inventory.vue";

// inv -- item
import Items from "@/views/inventory/items/Items.vue";
import SimpleItem from "@/views/inventory/items/SimpleItem.vue";
import NewSimpleItem from "@/views/inventory/items/NewSimpleItem.vue";

// inv -- brands
import Brands from "@/views/inventory/brands/Brands.vue";
import Brand from "@/views/inventory/brands/Brand.vue";
import NewBrand from "@/views/inventory/brands/NewBrand.vue";

// inv -- categories
import Categories from "@/views/inventory/categories/Categories.vue";
import Category from "@/views/inventory/categories/Category.vue";
import NewCategory from "@/views/inventory/categories/NewCategory.vue";

// inv -- tags
import Tags from "@/views/inventory/tags/Tags.vue";
import Tag from "@/views/inventory/tags/Tag.vue";
import NewTag from "@/views/inventory/tags/NewTag.vue";

// inv -- receiving
import InventoryReceivings from "@/views/inventory/inventoryReceivings/InventoryReceivings.vue";
import InventoryReceiving from "@/views/inventory/inventoryReceivings/InventoryReceiving.vue";
import NewInventoryReceiving from "@/views/inventory/inventoryReceivings/NewInventoryReceiving.vue";

// inv -- adjustment
import InventoryAdjustments from "@/views/inventory/inventoryAdjustments/InventoryAdjustments.vue";
import InventoryAdjustment from "@/views/inventory/inventoryAdjustments/InventoryAdjustment.vue";
import NewInventoryAdjustment from "@/views/inventory/inventoryAdjustments/NewInventoryAdjustment.vue";

// inv - transfer
import InventoryTransfers from "@/views/inventory/inventoryTransfers/InventoryTransfers.vue";
import InventoryTransfer from "@/views/inventory/inventoryTransfers/InventoryTransfer.vue";
import NewInventoryTransfer from "@/views/inventory/inventoryTransfers/NewInventoryTransfer.vue";
import ReceiveInventoryTransfer from "@/views/inventory/inventoryTransfers/ReceiveInventoryTransfer.vue";

//inv -- barcode
import PrintBarcode from "@/views/inventory/barcode/PrintBarcode.vue";

// customers
import Customers from "@/views/customers/Customers.vue";
import Customer from "@/views/customers/Customer.vue";
import NewCustomer from "@/views/customers/NewCustomer.vue";

// vendors
import Vendors from "@/views/vendors/Vendors.vue";
import Vendor from "@/views/vendors/Vendor.vue";
import NewVendor from "@/views/vendors/NewVendor.vue";

// human resources
import HumanResources from "@/views/hr/HumanResources.vue";
import ClockInOut from "@/views/hr/ClockInOut.vue";
import NewEmployee from "@/views/hr/employees/NewEmployee.vue";

// accounting
import Accounting from "@/views/accounting/Accounting.vue";

// accounts
import Accounts from "@/views/accounting/accounts/Accounts.vue";
import NewAccount from "@/views/accounting/accounts/NewAccount.vue";
import Account from "@/views/accounting/accounts/Account.vue";

// transactions
import Transactions from "@/views/accounting/transactions/Transactions.vue";
import NewTransaction from "@/views/accounting/transactions/NewTransaction.vue";
import Transaction from "@/views/accounting/transactions/Transaction.vue";

// settings
import Settings from "@/views/settings/Settings.vue";

// font awesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dom, library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressBook,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faBarcode,
  faBook,
  faCalculator,
  faCalendarDay,
  faCashRegister,
  faCertificate,
  faChevronLeft,
  faChevronRight,
  faCogs,
  faDollarSign,
  faEdit,
  faExchangeAlt,
  faEye,
  faFileExport,
  faFileImport,
  faFlag,
  faForward,
  faHome,
  faLandmark,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faMoneyBillWave,
  faPen,
  faPercentage,
  faPlus,
  faPlusCircle,
  faPrint,
  faReceipt,
  faSave,
  faSearch,
  faSignOutAlt,
  faSitemap,
  faStore,
  faTags,
  faTimes,
  faTimesCircle,
  faTrash,
  faTruck,
  faUmbrella,
  faUser,
  faUserCircle,
  faUserClock,
  faUserPlus,
  faUsers,
  faWarehouse
} from "@fortawesome/free-solid-svg-icons";
// custom icons
import { faNewSale } from "@/assets/icons";
import Mitt from "./plugins/mitt";
import AlertModal from "./plugins/alert-modal/alert-modal";
import i18n from "@/plugins/i18n";
import context from "@/plugins/context";
import loadingModal from "./plugins/loading-modal/loading-modal";
import drawer from "@/plugins/drawer/drawer";
import Employee from "@/views/hr/employees/Employee.vue";
import Employees from "@/views/hr/employees/Employees.vue";
import NewEmployeeSchedule from "@/views/hr/employeeSchedule/NewEmployeeSchedule.vue";
import { rootPath } from "@/utils/electron-root-path";
import path from "path";

library.add(
  faHome,
  faPlus,
  faWarehouse,
  faUsers,
  faAddressBook,
  faFlag,
  faCogs,
  faTimesCircle,
  faPlusCircle,
  faUserCircle,
  faCashRegister,
  faCalendarDay,
  faSearch,
  faUserClock,
  faDollarSign,
  faForward,
  faUmbrella,
  faPrint,
  faUser,
  faUserPlus,
  faTrash,
  faReceipt,
  faPercentage,
  faFileImport,
  faArrowDown,
  faEdit,
  faArrowsAltH,
  faArrowLeft,
  faArrowRight,
  faBarcode,
  faStore,
  faSitemap,
  faTags,
  faCertificate,
  faEye,
  faFileExport,
  faSave,
  faSignOutAlt,
  faCalculator,
  faBook,
  faExchangeAlt,
  faPen,
  faTimes,
  faNewSale,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faMoneyBillWave,
  faTruck,
  faLandmark,
  faChevronLeft,
  faChevronRight
);
dom.watch();

const elComponents = [
  ElInput,
  ElInputNumber,
  ElSelect,
  ElAutocomplete,
  ElForm,
  ElFormItem,
  ElCheckbox,
  ElOption,
  ElTable,
  ElTableColumn,
  ElTag,
  ElRadio,
  ElRadioGroup,
  ElRadioButton,
  ElSwitch,
  ElTooltip,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElButton,
  ElIcon,
  ElLink,
  ElDivider,
  ElPopover,
  ElDialog,
  ElDrawer,
  ElDatePicker,
  ElTimeline,
  ElTimelineItem,
  ElRow,
  ElCol,
  ElCollapse,
  ElCollapseItem,
  ElTabs,
  ElTabPane
];

// load .env file
export let envSettings: any = {};
if (process.env.IS_ELECTRON) {
  const result = require("dotenv").config({
    path: path.join(rootPath, ".env")
  });
  if (result.error) {
    console.error(result.error);
  }
  envSettings = result.parsed;
}

const currentLocale = localStorage.getItem("locale");
(async function(): Promise<void> {
  if (currentLocale && currentLocale !== "ar") {
    await import("@/assets/element-ui/ltr");
  } else {
    await import("@/assets/element-ui/rtl");
  }
})();

// create app
const app = createApp(App)
  // register plugins
  .use(router)
  .use(accessControl)
  .use(http)
  .use(Mitt)
  .use(AlertModal)
  .use(drawer)
  .use(loadingModal)
  .use(i18n)
  .use(context)
  .use(VueApexCharts);

elComponents.forEach(component => {
  app.component(component.name, component);
});

// register components
app
  .component("font-awesome-icon", FontAwesomeIcon)
  // register hooks
  .component("before-create", BeforeCreate)

  // register views
  .component("home", Home)
  .component("login", Login)
  // sales
  .component("sales", Sales)
  .component("new-sale", NewSale)
  .component("orders", Orders)
  .component("order", Order)
  .component("order-receipt", OrderReceipt)
  .component("return-order", ReturnOrder)
  .component("return-order-receipt", ReturnOrderReceipt)
  .component("order-payment", OrderPayment)
  .component("order-payment-receipt", OrderPaymentReceipt)

  // registers
  .component("open-register", OpenRegister)
  .component("close-register", CloseRegister)
  // inventory
  .component("inventory", Inventory)
  // inv - item
  .component("items", Items)
  .component("simple-item", SimpleItem)
  .component("new-simple-item", NewSimpleItem)
  // inv - brands
  .component("brands", Brands)
  .component("brand", Brand)
  .component("new-brand", NewBrand)
  // inv - categories
  .component("categories", Categories)
  .component("category", Category)
  .component("new-category", NewCategory)
  // inv - tags
  .component("tags", Tags)
  .component("tag", Tag)
  .component("new-tag", NewTag)
  // inv - receiving
  .component("inventory-receivings", InventoryReceivings)
  .component("inventory-receiving", InventoryReceiving)
  .component("new-inventory-receiving", NewInventoryReceiving)

  // inv adjustment
  .component("inventory-adjustments", InventoryAdjustments)
  .component("inventory-adjustment", InventoryAdjustment)
  .component("new-inventory-adjustment", NewInventoryAdjustment)

  // inv transfer
  .component("inventory-transfers", InventoryTransfers)
  .component("inventory-transfer", InventoryTransfer)
  .component("new-inventory-transfer", NewInventoryTransfer)
  .component("receive-inventory-transfer", ReceiveInventoryTransfer)

  //inv - barcode
  .component("print-barcode", PrintBarcode)

  // customers
  .component("customers", Customers)
  .component("customer", Customer)
  .component("new-customer", NewCustomer)

  // vendors
  .component("vendors", Vendors)
  .component("vendor", Vendor)
  .component("new-vendor", NewVendor)

  // human resources
  .component("human-resources", HumanResources)
  .component("clock-in-out", ClockInOut)

  .component("employees", Employees)
  .component("employee", Employee)
  .component("new-employee", NewEmployee)
  .component("new-employee-schedule", NewEmployeeSchedule)

  // accounting
  .component("accounting", Accounting)
  .component("accounts", Accounts)
  .component("new-account", NewAccount)
  .component("account", Account)
  .component("transactions", Transactions)
  .component("new-transaction", NewTransaction)
  .component("transaction", Transaction)

  // settings
  .component("settings", Settings)

  //apexChart
  .component("apexchart", VueApexCharts)
  .mount("#app");

// set the locale of element UI then mount
