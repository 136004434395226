
import { defineComponent } from "vue";
import ActionButton from "@/components/ActionButton.vue";
import CustomIcon from "@/components/CustomIcon.vue";
import INewSale from "@/components/icons/sales/INewSale.vue";
import IReturnSale from "@/components/icons/sales/IReturnSale.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";

import { Register, RegisterLog } from "@/models/company/Register";

export default defineComponent({
  components: {
    ActionButton,
    CustomIcon,
    INewSale,
    IReturnSale,
    LoadingSpinner,
    AlertBadge
  },

  data() {
    return {
      error: {
        title: "",
        body: ""
      },
      loading: false
    };
  },

  computed: {
    registerIsOpen() {
      return this.$ctx.currentRegister.status === "open";
    }
  },

  async beforeMount() {
    const loadRegisterStatus = async () => {
      try {
        this.loading = true;
        const registerLog = await this.$http.get<RegisterLog>(
          `${Register.ENDPOINT}/status/${this.$ctx.currentRegister.id}`
        );

        this.$ctx.currentRegister.status = RegisterLog.from(registerLog).action;
      } catch (error) {
        this.error.title = error.title;
        this.error.body = error.body;
      } finally {
        this.loading = false;
      }
    };
    // check if the register is open or not
    loadRegisterStatus();
  },
  methods: {
    canWithReason(): boolean {
      return this.$can("read", "magazine");
    }
  }
});
