
import { defineComponent, onBeforeMount, ref } from "vue";

import { useHTTP } from "@/plugins/http";

// components
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import InventoryTransfer, {
  inventoryTransferI18nMessages
} from "@/models/inventory/InventoryTransfer";
import { useI18n } from "vue-i18n";

import { parseFormat } from "@/utils/date";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "inventory-receiving",
  components: {
    LoadingSpinner,
    AlertBadge
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryTransferI18nMessages.en,
          generalInfoSection: "General Info",
          label: "Key",
          value: "Value"
        },
        ar: {
          ...inventoryTransferI18nMessages.ar,
          generalInfoSection: "المعلومات العامة",
          label: "الحقل",
          value: "القيمة"
        }
      }
    });

    const it = ref(new InventoryTransfer(""));
    const http = useHTTP();
    const loading = ref(true);
    const error = ref({ title: "", body: "" });
    const route = useRoute();

    onBeforeMount(async () => {
      const url = `${InventoryTransfer.ENDPOINT}/${route.params.id}`;

      try {
        const resp = await http.get<InventoryTransfer>(url);
        it.value = InventoryTransfer.from(resp);
      } catch (errorResp) {
        error.value.title = errorResp.title;
        error.value.body = errorResp.body;
      }

      loading.value = false;
    });

    return {
      it,
      loading,
      error,
      t
    };
  },

  methods: {
    parseFormat
  }
});
