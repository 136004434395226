<template>
  <el-form
    @submit.prevent="validateSubmit('inventoryReceivingForm')"
    novalidate
    label-position="top"
    :model="inventoryReceiving"
    :rules="rules"
    ref="inventoryReceivingForm"
  >
    <input-section class="self-end">
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('inventoryReceivingForm')"
          />
        </div>

        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <section class="my-2 flex flex-col justify-between lg:flex-row w-full">
      <div
        class="w-3/5"
        :class="$ctx.getDir() === 'rtl' ? 'border-left' : 'border-right'"
      >
        <input-section>
          <h1>{{ t("sections.inventory") }}</h1>
          <el-form-item :label="t('item')" class="input-label">
            <el-autocomplete
              id="item"
              v-model="itemSearchTerm"
              :fetch-suggestions="searchItems"
              value-key="name"
              :trigger-on-focus="false"
              class="w-full"
              @select="addItem"
              @keypress.enter.prevent="pressedEnter = true"
              highlight-first-item
              :placeholder="t('placeholders.itemName')"
            >
            </el-autocomplete>
          </el-form-item>
        </input-section>
        <div class="mt-16" :class="$ctx.getDir() === 'rtl' ? 'ml-8' : 'mr-8'">
          <el-table
            :data="inventoryReceiving.inventoryReceivingItems"
            style="width: 100%"
          >
            <el-table-column :label="t('item')" prop="item.name" />
            <el-table-column :label="t('itemCost')">
              <template #default="scope">
                <money-input
                  id="cost"
                  :currency="$ctx.currency"
                  v-model="scope.row.item.costAmount"
                  @dinero-created="
                    dinero =>
                      (inventoryReceiving.inventoryReceivingItems[
                        scope.$index
                      ].item.salePriceDinero = dinero)
                  "
                />
              </template>
            </el-table-column>
            <el-table-column :label="t('quantity')">
              <template #default="scope">
                <el-input
                  type="number"
                  v-model="scope.row.quantity"
                  :min="1"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="t('totalCost')">
              <template #default="scope">
                <div>{{ calculateItemTotal(scope) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              :label="t('serialNumbers')"
              prop="inventoryReceivingItemSerials"
            >
              <template #default="scope">
                <tag-input
                  v-if="
                    inventoryReceiving.inventoryReceivingItems[scope.$index]
                      .item.isSerialized
                  "
                  v-model="scope.row.inventoryReceivingItemSerials"
                  :insertFunction="val => scope.row.insertSerialNumber(val)"
                  :removeFunction="index => scope.row.removeSerialNumber(index)"
                  :valueFormatter="
                    serialObject => serialObject.itemSerial.number
                  "
                  :validate="() => scope.row.validateSerials()"
                />
              </template>
            </el-table-column>
            <el-table-column align="center" width="55px">
              <template #default="scope">
                <horizontal-button
                  title=""
                  isDanger
                  faIcon="trash"
                  @click="removeItem(scope.$index)"
                  class="delete-btn"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="w-2/5 mx-8">
        <div class="my-2 w-full">
          <h1>{{ t("sections.billingInfo") }}</h1>

          <el-form-item
            :label="t('vendor')"
            class="input-label"
            prop="vendor.name"
          >
            <el-autocomplete
              id="vendor"
              v-model="inventoryReceiving.vendor.name"
              :fetch-suggestions="searchVendors"
              :trigger-on-focus="false"
              label="name"
              value-key="name"
              class="w-full"
              @select="selectVendor"
            >
            </el-autocomplete>
          </el-form-item>
          <el-form-item
            :label="t('billDate')"
            class="input-label"
            prop="vendorBill.billDate"
          >
            <el-date-picker
              id="bill-date"
              v-model="inventoryReceiving.vendorBill.billDate"
              type="date"
              :placeholder="t('pickDate')"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item
            :label="t('dueDate')"
            class="input-label"
            prop="vendorBill.billDueDate"
          >
            <el-date-picker
              id="due-date"
              v-model="inventoryReceiving.vendorBill.billDueDate"
              type="date"
              :placeholder="t('pickDate')"
              style="width: 100%"
            />
          </el-form-item>
          <el-form-item
            :label="t('otherCosts')"
            class="input-label"
            prop="vendorBill.otherCostsAmount"
          >
            <money-input
              id="other-costs"
              :currency="$ctx.currency"
              v-model="inventoryReceiving.vendorBill.otherCostsAmount"
              @dinero-created="
                dinero =>
                  (inventoryReceiving.vendorBill.otherCostsDinero = dinero)
              "
              :key="inventoryReceiving.totalCostAmount"
            />
          </el-form-item>
          <el-form-item
            :label="t('total')"
            class="input-label"
            prop="totalAmount"
          >
            <money-input
              id="total-amount"
              :currency="$ctx.currency"
              v-model="inventoryReceiving.vendorBill.totalDueAmount"
              @dinero-created="
                dinero =>
                  (inventoryReceiving.vendorBill.totalDueDinero = dinero)
              "
              :disabled="true"
              :key="inventoryReceiving.vendorBill.totalDueAmount"
              :onchange="calculateInventoryTotal()"
            />
          </el-form-item>
          <div class="input-group">
            <el-form-item
              :label="t('paidAmount')"
              class="input-label"
              prop="vendorBill.totalPaidAmount"
            >
              <money-input
                id="paid-amount"
                :currency="$ctx.currency"
                v-model="inventoryReceiving.vendorBill.totalPaidAmount"
                @dinero-created="
                  dinero =>
                    (inventoryReceiving.vendorBill.totalPaidDinero = dinero)
                "
                :key="inventoryReceiving.vendorBill.totalPaidAmount"
              />
            </el-form-item>
            <el-form-item class="input-label">
              <horizontal-button
                :title="t('fullPay')"
                :isSuccess="true"
                @click.prevent="fullPay"
                class="fully-paid-button"
              />
            </el-form-item>
          </div>
          <el-form-item :label="t('notes')" class="input-label" prop="notes">
            <el-input
              type="textarea"
              id="notes"
              v-model="inventoryReceiving.notes"
            ></el-input>
          </el-form-item>
        </div>
      </div>
    </section>
  </el-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InventoryReceiving, {
  inventoryReceivingI18nMessages,
  InventoryReceivingItem
} from "@/models/inventory/InventoryReceiving";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import Item from "@/models/inventory/Item";
import Vendor from "@/models/vendor/Vendor";
import { useI18n } from "vue-i18n";
import Dinero, { Currency } from "dinero.js";
import { formatDinero, formatMoney } from "@/utils/money";
import MoneyInput from "@/components/form/MoneyInput.vue";
import TagInput from "@/components/form/TagInput.vue";
import { ElForm } from "element-plus";

export default defineComponent({
  name: "new-inventory-receiving",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryReceivingI18nMessages.en,
          sections: {
            info: "Meta data",
            inventory: "Inventory Info",
            billingInfo: "Billing Info"
          },

          placeholders: {
            notes: "Example: items were delayed for one week",
            itemName: "Enter barcode, sku, or item name"
          },

          userReceiving: "Employee responsible for this inventory receiving",
          fullPay: "Fully Paid",
          pickDate: "Pick a Date"
        },
        ar: {
          ...inventoryReceivingI18nMessages.ar,
          sections: {
            info: "البيانات الوصفية",
            inventory: "البضاعة المستلمة",
            billingInfo: "معلومات الدفع"
          },

          placeholders: {
            notes: "مثلا: تاخرت البضاعة",
            itemName: "ادخل اسم المنتج او الباركود او SKU"
          },

          userReceiving: "الموظف المسؤول عن استلام البضاعة",
          fullPay: "دفع كل المبلغ",
          pickDate: "اختر التاريخ"
        }
      }
    });

    const rules = {
      "vendor.name": [{ required: true, message: t("validation.required") }],
      "vendorBill.totalPaidAmount": [
        { required: true, message: t("validation.required") }
      ],
      "vendorBill.billDate": [
        { required: true, message: t("validation.required") }
      ],
      "vendorBill.billDueDate": [
        { required: true, message: t("validation.required") }
      ]
    };

    return { t, rules };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection,
    MoneyInput,
    TagInput
  },
  data() {
    return {
      inventoryReceiving: new InventoryReceiving(),
      itemSearchTerm: "",
      error: { title: "", body: "" },
      serials: [],
      pressedEnter: false,
      user: this.$ctx.currentUser
    };
  },
  methods: {
    formatDinero,
    formatMoney,
    async searchVendors(query: string, cb: Function) {
      try {
        const url = `${Vendor.ENDPOINT}?q=${query}`;
        const vendors = await this.$http.get<Vendor[]>(url);
        cb(vendors);
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }
    },

    async searchItems(query: string, cb: Function) {
      // if an item already exists in the table, don't do remote search
      const cachedItem =
        this.inventoryReceiving.findByBarcode(query) ||
        this.inventoryReceiving.findBySKU(query);
      if (cachedItem) {
        this.addItem(cachedItem);
        cb([]);
        return;
      }

      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        const items = await this.$http.get<Item[]>(url);

        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && items.length === 1) {
          this.addItem(items[0]);
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }

      this.pressedEnter = false;
    },

    selectVendor(selected: Vendor) {
      this.inventoryReceiving.vendor = selected;
    },

    addItem(selected: Item) {
      this.inventoryReceiving.addItem(selected);
      this.calculateInventoryTotal();
      this.itemSearchTerm = "";
    },

    removeItem(index: number) {
      this.inventoryReceiving.removeItem(index);
      this.calculateInventoryTotal();
    },

    clearForm(formName: string) {
      this.inventoryReceiving = new InventoryReceiving();
      const form = this.$refs[formName] as typeof ElForm;
      this.$globalEmitter.emit("money-input-reset");
      if (form) form.resetFields();
    },

    calculateItemTotal(scope: any) {
      const price = Dinero({
        amount: scope.row.quantity * scope.row.item.costAmount,
        currency: scope.row.item.costCurrency,
        precision: scope.row.item.costPrecision
      });
      return formatDinero(price);
    },

    calculateInventoryTotal() {
      let total = 0;
      this.inventoryReceiving.inventoryReceivingItems.forEach(item => {
        total +=
          item.quantity * (item.item.costAmount ? item.item.costAmount : 1);
      });

      this.inventoryReceiving.totalCostAmount = total;
      this.inventoryReceiving.totalCostDinero = Dinero({
        amount: total,
        currency: this.inventoryReceiving.vendorBill.currency as Currency,
        precision: 0
      });
      this.calculateTotal();
      return total;
    },

    calculateTotal() {
      this.inventoryReceiving.vendorBill.totalDueAmount =
        (this.inventoryReceiving.totalCostAmount
          ? this.inventoryReceiving.totalCostAmount
          : 0) +
        (this.inventoryReceiving.vendorBill.otherCostsAmount
          ? this.inventoryReceiving.vendorBill.otherCostsAmount
          : 0);

      this.inventoryReceiving.vendorBill.totalDueDinero = Dinero({
        amount: this.inventoryReceiving.vendorBill.totalDueAmount,
        currency: this.inventoryReceiving.vendorBill.currency as Currency,
        precision: 0
      });
    },

    fullPay() {
      this.inventoryReceiving.vendorBill.totalPaidAmount = this.inventoryReceiving.vendorBill.totalDueAmount;
    },

    async submit(ir: InventoryReceiving) {
      this.error.title = "";
      this.error.body = "";
      ir.locationID = this.$ctx.currentLocation.id;
      ir.userID = this.$ctx.currentUser.id;
      ir.vendorBill.currency = this.$ctx.currency;
      ir.vendorBill.precision = this.$ctx.currencyPrecision;
      ir.vendorID = ir.vendor.id;
      ir.vendorBill.vendorID = ir.vendor.id;
      ir.inventoryReceivingItems = ir.inventoryReceivingItems.map(
        inventoryReceivingItem => {
          if (typeof inventoryReceivingItem.quantity === "string")
            return {
              ...inventoryReceivingItem,
              quantity: parseInt(inventoryReceivingItem.quantity)
            } as InventoryReceivingItem;
          return inventoryReceivingItem;
        }
      );
      try {
        await this.$http.post(InventoryReceiving.ENDPOINT, ir);
        await this.$router.push(this.$Route.INVENTORY_INVENTORY_RECEIVINGS);
      } catch (error) {
        return error;
      }
    },

    checkForm() {
      const errors = [];
      this.error.body = "";
      for (const iri of this.inventoryReceiving.inventoryReceivingItems) {
        errors.push(iri.validateSerials());
        errors.push(iri.validateQuantity());
      }

      this.error.body = errors.filter(err => err).join("\n");

      if (this.error.body !== "") {
        this.error.title = this.$t("validation.inputErrors");
        document.getElementById("main")?.scrollTo({ top: 0 });
        return false;
      }

      return true;
    },

    async validateSubmit(formName: string) {
      if (this.checkForm()) {
        const form = this.$refs[formName] as typeof ElForm;
        form?.validate(async (valid: boolean) => {
          if (!valid) {
            document.getElementById("main")?.scrollTo({ top: 0 });
            return false;
          }
          const resp = await this.submit(
            this.inventoryReceiving as InventoryReceiving
          );

          if (resp) {
            this.error.title = resp?.title;
            this.error.body = resp?.body;
            document.getElementById("main")?.scrollTo({ top: 0 });
          } else {
            this.clearForm("inventoryReceivingForm");
            this.$alertModal.showSuccess({ title: "تم استلام البضاعة بنجاح" });
          }
        });
      }
    }
  }
});
</script>

<style lang="postcss" scoped>
form > h1 {
  @apply p-2 text-lg py-3 font-bold;
}

form > h2 {
  @apply p-2 py-3 font-bold;
}

form > h3 {
  @apply p-2 py-3 font-bold;
}

form {
  @apply flex flex-col items-center mx-8;
}

/* table */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 2px;
}
tr:nth-child(odd) {
  background-color: #ebebeb;
}

.modified {
  padding: 0 !important;
  margin-top: -0.5rem !important;
}

.input-label {
  margin: 0 !important;
}

.border-right {
  border-right: gray 2px solid;
}
.border-left {
  border-left: gray 2px solid;
}

.input-group {
  @apply flex justify-between w-full items-end;
}
.fully-paid-button {
  height: 40px;
}
.delete-btn {
  width: 100%;
}
</style>
