
import { defineComponent } from "vue";

import { useI18n } from "vue-i18n";
import { useDrawer } from "@/plugins/drawer/drawer";
import ActionButton from "@/components/ActionButton.vue";

export default defineComponent({
  name: "human-resources",
  setup() {
    const { showDrawer } = useDrawer();

    const { t } = useI18n({
      messages: {
        en: {
          employeeTimesheet: "Employee Timesheet",
          employeeTimeLog: "ClockIn/Out",
          employeeManagement: "Employee Management"
        },
        ar: {
          employeeTimesheet: "الجداول",
          employeeTimeLog: "سجل الوقت",
          employeeManagement: "ادارة الموظفين"
        }
      }
    });

    return { t, showDrawer };
  },
  components: { ActionButton }
});
