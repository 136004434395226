
import { defineComponent, ref, watchEffect } from "vue";

import AlertBadge from "@/components/AlertBadge.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import OrderInfo from "./components/OrderInfo.vue";
import { ElCollapse, ElCollapseItem } from "element-plus";
import ActionButton from "@/components/ActionButton.vue";

// models
import {
  i18nOrderMessages,
  Order,
  OrderChange,
  OrderChangeItemSerial,
  OrderItem,
  OrderItemSerial
} from "@/models/sales/Order";
import Item from "@/models/inventory/Item";
import { Register, RegisterLog } from "@/models/company/Register";

// tools
import { useI18n } from "vue-i18n";
import { localizeFieldName } from "@/plugins/i18n";

// helpers
import { formatDinero, sumDineros } from "@/utils/money";
import Dinero, { Dinero as DineroType } from "dinero.js";

function initialState() {
  return {
    itemSearchTerm: "",
    error: { title: "", body: "" },
    loading: false,

    originalOrder: new Order(),
    returnPayload: new OrderChange("return"),
    pressedEnter: false,

    selectedToReturnOrderItemIDs: new Set<string>(),

    currentOrderItem: new OrderItem(new Item()),
    currentOrderItemIndex: 0,
    insertSerialDialogVisible: false
  };
}

export default defineComponent({
  name: "return-order",
  setup() {
    const { t } = useI18n({
      useScope: "global",
      messages: {
        en: {
          ...i18nOrderMessages.en,

          searchItems: "Enter receipt number",
          buttons: {
            completeReturn: "Return",
            confirm: "Confirm"
          },

          selectSerials: "Select serials",
          selectCustomer: "Select a customer",
          selectPaymentType: "Select order type",

          purchasedQuantity: "Total purchased Quantity",
          olderReturns: "Older Returns",
          toReturnQuantity: "Return Quantity",

          orderInfo: "Order Info",
          returnInfo: "Return Info",

          outOfStockErr:
            "Item {name} is out of stock. Please update your stock before selling this item",

          successMsg: "Order return complete",

          errPaymentMustMatch:
            "Payment must be made in full. If you'd like to pay partially, choose layaway or finance",

          orderAlreadyReturned: "All items in this order have been returned"
        },
        ar: {
          ...i18nOrderMessages.ar,

          searchItems: "ادخل رقم الوصل",
          buttons: {
            completeReturn: "اتمام الارجاع",
            confirm: "التأكيد"
          },

          selectSerials: "اختر الارقام",
          selectCustomer: "اختر زبون",
          selectPaymentType: "اختر نوع البيع",

          purchasedQuantity: "الكمية المباعة",
          olderReturns: "المرجع مسبقاً",
          toReturnQuantity: "كمية الترجيع الحالي",

          orderInfo: "معلومات الطلب",
          returnInfo: "معلومات الترجيع",

          outOfStockErr:
            "نفذ مخزون المنتج {name}. يرجى اعادة تحديث المخزن قبل بيع المنتج.",

          successMsg: "تم ارجاع المنتجات بنجاح",

          errPaymentMustMatch:
            "يجب ان يتم دفع المبلغ بشكل كامل. لدفع مبلغ جزئي، قم بتحويل نوع الدفع الى دين او عربون",

          orderAlreadyReturned: "تم ارجاع جميع المنتجات في هذا الطلب مسبقا"
        }
      }
    });

    const returnOrderTable = ref({
      // will be replaced at runtime by the real deal
      toggleAllSelection() {
        // do nothing
      }
    });
    watchEffect(
      () => {
        returnOrderTable.value.toggleAllSelection();
      },
      {
        flush: "post"
      }
    );

    const errorWhileMounting = ref(false);

    return { t, returnOrderTable, errorWhileMounting };
  },

  components: {
    AlertBadge,
    LoadingSpinner,
    OrderInfo,
    ElCollapse,
    ElCollapseItem,
    ActionButton
  },

  async mounted() {
    // load register status
    try {
      this.loading = true;
      const registerLog = await this.$http.get<RegisterLog>(
        `${Register.ENDPOINT}/status/${this.$ctx.currentRegister.id}`
      );

      this.$ctx.updateRegisterStatus(RegisterLog.from(registerLog).action);

      if (registerLog.action === "close") {
        this.$router.push(this.$Route.SALES_REGISTERS_OPEN_REGISTER);
      }
    } catch (error) {
      this.error.title = error.title;
      this.error.body = error.body;
      this.errorWhileMounting = true;
    } finally {
      this.loading = false;
    }
  },

  data() {
    return initialState();
  },

  methods: {
    formatDinero,

    openSerialDialog(oi: OrderItem, oiIndex: number) {
      this.currentOrderItem = oi;
      this.currentOrderItemIndex = oiIndex;
      this.insertSerialDialogVisible = true;
    },

    closeSerialDialog() {
      this.currentOrderItem = new OrderItem(new Item());
      this.insertSerialDialogVisible = false;
    },

    excludeReturnedSerials(orderItemSerials: OrderItemSerial[]) {
      return orderItemSerials.filter(ois => !ois.returned);
    },

    excludeFullyReturnedItems(order: Order) {
      return order.orderItems.filter(oi => oi.quantity > oi.returnedQuantity);
    },

    async searchReceipts(query: string, cb: Function) {
      try {
        // limit results to 20
        const url = `${Order.ENDPOINT}?page=1&page_size=20&filter=${query}`;
        let orders = await this.$http.get<Order[]>(url);
        orders = orders.map(order => Order.from(order));

        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && orders.length === 1) {
          this.fetchOrder(orders[0]);
          cb([]);
        } else {
          // show them all options
          cb(orders);
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }

      this.pressedEnter = false;
    },

    async fetchOrder(order: Order) {
      try {
        const o = await this.$http.get<Order>(`${Order.ENDPOINT}/${order.id}`);
        order = Order.from(o);
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title, body: error.body });
      }

      // reset state
      Object.assign(this.$data, initialState());

      this.originalOrder = Order.from(order);
      const returnableItems = this.excludeFullyReturnedItems(
        this.originalOrder as Order
      );
      if (returnableItems.length < 1) {
        this.$alertModal.showDanger({
          title: this.t("orderAlreadyReturned")
        });
      }
      this.originalOrder.orderItems = returnableItems;

      this.returnPayload.orderID = this.originalOrder.id || "";
      this.originalOrder.orderItems.forEach(oi => {
        const serials: Record<string, OrderChangeItemSerial> = {};

        this.returnPayload.orderChangeItems[oi.id || ""] = {
          quantity: oi.quantity - oi.returnedQuantity,
          orderChangeItemSerials: serials,
          serialIDs: new Array<string>()
        };
      });

      // select all
      this.returnOrderTable.toggleAllSelection();
    },

    selectItems(selected: OrderItem[]) {
      this.selectedToReturnOrderItemIDs.clear();
      selected.forEach(oi =>
        this.selectedToReturnOrderItemIDs.add(oi.id || "")
      );
    },

    isSelected(orderItemID: string): boolean {
      return this.selectedToReturnOrderItemIDs.has(orderItemID);
    },

    computeOrderItemReturnTotal(orderItem: OrderItem) {
      const returnedQuantity = this.returnPayload.orderChangeItems[
        orderItem.id || ""
      ].quantity;
      return orderItem.soldAtPriceDinero.multiply(returnedQuantity);
    },

    // for frontend purposes only. This total is being re-compouted on the backend.
    computeAndFormatInitalTotalAmountToReturn() {
      if (!this.originalOrder.empty()) {
        const dineros: DineroType[] = [];

        this.originalOrder.orderItems.forEach(oi => {
          if (oi.id && this.isSelected(oi.id)) {
            dineros.push(this.computeOrderItemReturnTotal(oi as OrderItem));
          }
        });

        return formatDinero(
          sumDineros(
            dineros,
            this.originalOrder.currency,
            this.originalOrder.precision
          )
        );
      }
    },

    computeAndFormatFinalTotalAmountToReturn() {
      if (!this.originalOrder.empty()) {
        const dineros: DineroType[] = [];

        this.originalOrder.orderItems.forEach(oi => {
          if (oi.id && this.isSelected(oi.id)) {
            dineros.push(this.computeOrderItemReturnTotal(oi as OrderItem));
          }
        });

        const totalPaymentsMade = this.originalOrder.computeTotalPaid();
        const actualAmountToReturn = Dinero.minimum([
          sumDineros(
            dineros,
            this.originalOrder.currency,
            this.originalOrder.precision
          ),
          totalPaymentsMade
        ]);

        return formatDinero(actualAmountToReturn);
      }
    },

    preparePayload(): OrderChange {
      // copy the return payload
      const returnOrderPayload: OrderChange = OrderChange.from(
        this.returnPayload
      );

      // only keep order items that have been selected
      for (const orderItemID of Object.keys(
        returnOrderPayload.orderChangeItems
      )) {
        if (!this.isSelected(orderItemID)) {
          delete returnOrderPayload.orderChangeItems[orderItemID];
        }
      }

      // move order item serial ids to the correct location
      for (const orderItem of Object.values(
        returnOrderPayload.orderChangeItems
      )) {
        orderItem.serialIDs.forEach(
          (id: string) => (orderItem.orderChangeItemSerials[id] = {})
        );
      }

      // attach neccessary info
      returnOrderPayload.userID = this.$ctx.currentUser.id || "";
      returnOrderPayload.locationID = this.$ctx.currentLocation.id || "";
      returnOrderPayload.registerID = this.$ctx.currentRegister.id || "";

      // return
      return returnOrderPayload;
    },

    // having validate here simplifes things
    validate(payload: OrderChange) {
      const errors: string[] = [];

      this.originalOrder.orderItems.forEach(oi => {
        if (oi.id && oi.id in payload.orderChangeItems) {
          const orderChangeItem = payload.orderChangeItems[oi.id];
          if (
            oi.orderItemSerials.length &&
            orderChangeItem.quantity !==
              Object.keys(orderChangeItem.orderChangeItemSerials).length
          ) {
            const errMsg = `${oi.item.name}: ${this.t(
              "validation.matchingLength",
              {
                thisField: localizeFieldName(
                  "lenSerialNumbers",
                  i18nOrderMessages
                ),
                thatField: localizeFieldName("quantity", i18nOrderMessages)
              }
            )}`;
            errors.push(errMsg);
          }
        }
      });

      if (errors.length) {
        this.error.title = this.t("validation.inputErrors");
        this.error.body = errors.join("\n");
        return false;
      }

      return true;
    },

    async submit() {
      const payload = this.preparePayload();
      if (this.validate(payload)) {
        try {
          const o = await this.$http.put<OrderChange>(Order.ENDPOINT, payload);
          this.$alertModal.showSuccess({ title: this.t("successMsg") });
          const orderChange = OrderChange.from(o);
          await this.$router.push(
            this.$Route.SALES_ORDERS_RETURN_ORDER_RECEIPT.replace(
              ":orderID",
              this.originalOrder.id || ""
            ).replace(":id", orderChange.id || "")
          );
        } catch (error) {
          this.error.title = error.title;
          this.error.body = error.body;
        }
      }
    }
  }
});
