
import { defineComponent } from "vue";
import { isElectron } from "@/utils/env";

import { i18nOrderMessages, Order } from "@/models/sales/Order";
import { parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero } from "@/utils/money";

import ActionButton from "@/components/ActionButton.vue";
import JsBarcode from "jsbarcode";

export default defineComponent({
  components: {
    ActionButton
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          date: "Date",
          print: "Print Receipt"
        },
        ar: {
          ...i18nOrderMessages.ar,
          date: "التاريخ",
          print: "اطبع الفاتورة"
        }
      }
    });

    return { t };
  },
  props: {
    operationTitle: {
      type: String,
      required: true
    },

    externalID: {
      type: String,
      required: true
    },

    userFullName: {
      type: String,
      required: false
    },

    customerFullName: {
      type: String,
      default: ""
    },

    locationName: {
      type: String,
      required: true
    },

    date: {
      type: Date,
      required: true
    },

    printOnMount: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      order: new Order()
    };
  },

  mounted() {
    JsBarcode("#barcode", this.externalID, {
      height: 50
    });

    if (this.printOnMount) {
      if (isElectron()) {
        // wait until all child components have mounted
        this.$nextTick(async () => {
          this.$nextTick(async () => {
            // set window name as order id
            const winID = `${this.$route.params.id}`;
            window.document.title = winID;

            try {
              const { ipcRenderer } = await import("electron");
              ipcRenderer.send("print", {
                printerName: this.$ctx.currentPrinter,
                windowID: winID
              });
              // window.close();
            } catch (error) {
              this.$alertModal.showDanger({ title: error });
            }
          });
        });
      } else {
        window.print();
      }
    }
  },

  methods: {
    parseFormat,
    formatDinero,
    print() {
      window.print();
    }
  }
});
