<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />

  <div v-else class="flex flex-col items-center">
    <div class="w-full md:w-8/12 mt-8">
      <el-table
        stripe
        :data="[
          { label: t('id'), value: ir.externalID },
          { label: t('user'), value: ir.user.fullName },
          { label: t('vendor'), value: ir.vendor.name },
          { label: t('notes'), value: ir.notes }
        ]"
      >
        <el-table-column prop="label" :label="t('label')"></el-table-column>
        <el-table-column prop="value" :label="t('value')"></el-table-column>
      </el-table>
    </div>

    <div class="w-full md:w-8/12 mt-8">
      <el-table stripe :data="ir.inventoryReceivingItems">
        <el-table-column prop="item.name" :label="t('itemName')">
        </el-table-column>
        <el-table-column
          prop="quantity"
          :label="t('quantity')"
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="inventoryReceivingItemSerials"
          :label="t('serialNumbers')"
          width="300"
          align="center"
        >
          <template #default="scope">
            <li
              v-for="(serial, i) in scope.row.inventoryReceivingItemSerials"
              :key="i"
            >
              <el-tag :key="i" type="info">{{
                serial.itemSerial.number
              }}</el-tag>
            </li>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";

import { useHTTP } from "@/plugins/http";

// components
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import InventoryReceiving, {
  inventoryReceivingI18nMessages
} from "@/models/inventory/InventoryReceiving";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "inventory-receiving",
  components: {
    LoadingSpinner,
    AlertBadge
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryReceivingI18nMessages.en,
          generalInfoSection: "General Info",
          label: "Key",
          value: "Value"
        },
        ar: {
          ...inventoryReceivingI18nMessages.ar,
          generalInfoSection: "المعلومات العامة",
          label: "الحقل",
          value: "القيمة"
        }
      }
    });

    const ir = ref(new InventoryReceiving());
    const http = useHTTP();
    const loading = ref(true);
    const error = ref({ title: "", body: "" });
    const route = useRoute();

    onBeforeMount(async () => {
      const url = `${InventoryReceiving.ENDPOINT}/${route.params.id}`;

      try {
        const resp = await http.get<InventoryReceiving>(url);
        ir.value = InventoryReceiving.from(resp);
      } catch (errorResp) {
        error.value.title = errorResp.title;
        error.value.body = errorResp.body;
      }

      loading.value = false;
    });

    return {
      ir,
      loading,
      error,
      t
    };
  }
});
</script>

<style scoped lang="postcss">
/* table */
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}
td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 4px;
}
tr:nth-child(odd) {
  background-color: #ebebeb;
}
</style>
