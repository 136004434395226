<template>
  <div>
    <div class="flex">
      <action-button :title="t('print')" faIcon="print" @click="print" />

      <slot name="extraActions"> </slot>
    </div>

    <div class="flex flex-col items-center mx-4" id="receipt">
      <!-- header info -->
      <div class="flex flex-col items-center mt-6">
        <svg id="barcode"></svg>
        <h3>{{ operationTitle }}</h3>
        <h3>{{ this.$ctx.companyName }}</h3>
        <h4>{{ t("location") }}: {{ locationName }}</h4>
        <h4 v-if="userFullName">{{ t("user") }}: {{ userFullName }}</h4>
        <h4 v-if="customerFullName">
          {{ t("customer") }}: {{ customerFullName }}
        </h4>
        <p dir="ltr">{{ parseFormat(date) }}</p>
      </div>

      <slot name="receiptContent"> </slot>

      <div class="flex flex-col items-center justify-center w-1/2">
        <p><img src="@/assets/logo.png" height="200" width="200" /></p>
        <p>goclassyllc.com</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isElectron } from "@/utils/env";

import { i18nOrderMessages, Order } from "@/models/sales/Order";
import { parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero } from "@/utils/money";

import ActionButton from "@/components/ActionButton.vue";
import JsBarcode from "jsbarcode";

export default defineComponent({
  components: {
    ActionButton
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          date: "Date",
          print: "Print Receipt"
        },
        ar: {
          ...i18nOrderMessages.ar,
          date: "التاريخ",
          print: "اطبع الفاتورة"
        }
      }
    });

    return { t };
  },
  props: {
    operationTitle: {
      type: String,
      required: true
    },

    externalID: {
      type: String,
      required: true
    },

    userFullName: {
      type: String,
      required: false
    },

    customerFullName: {
      type: String,
      default: ""
    },

    locationName: {
      type: String,
      required: true
    },

    date: {
      type: Date,
      required: true
    },

    printOnMount: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      order: new Order()
    };
  },

  mounted() {
    JsBarcode("#barcode", this.externalID, {
      height: 50
    });

    if (this.printOnMount) {
      if (isElectron()) {
        // wait until all child components have mounted
        this.$nextTick(async () => {
          this.$nextTick(async () => {
            // set window name as order id
            const winID = `${this.$route.params.id}`;
            window.document.title = winID;

            try {
              const { ipcRenderer } = await import("electron");
              ipcRenderer.send("print", {
                printerName: this.$ctx.currentPrinter,
                windowID: winID
              });
              // window.close();
            } catch (error) {
              this.$alertModal.showDanger({ title: error });
            }
          });
        });
      } else {
        window.print();
      }
    }
  },

  methods: {
    parseFormat,
    formatDinero,
    print() {
      window.print();
    }
  }
});
</script>

<style>
.receipt-table {
  border-collapse: collapse;
  width: 95%;
  margin-top: 15px;
  font-size: 11px !important;
}

.reciept-row {
  padding: 8px;
  text-align: center;
}

.reciept-cell {
  padding: 8px;
}

.receipt-row-border {
  border: 0px none !important;
  border-bottom: 1px solid #dddddd;
}

#receipt {
  width: 100mm;
  height: 500px;
  overflow: scroll;
  background-color: #fff;
}

@media print {
  @page {
    size: auto;
    margin: 0 auto;
  }

  body * {
    visibility: hidden;
  }

  #receipt,
  #receipt * {
    visibility: visible;
  }

  #receipt {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow: visible;
    margin-top: 1cm;
    margin-bottom: 1cm;
    margin: 0 auto;
  }

  p,
  div {
    page-break-inside: avoid;
  }
}
</style>
