<template>
  <el-form
    @submit.prevent="validateSubmit"
    novalidate
    :model="inventoryTransfer"
    ref="inventoryTransferForm"
    :rules="rules"
    label-position="top"
  >
    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('inventoryTransferForm')"
          />
        </div>

        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('inventoryTransferForm')"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <input-section class="my-2 p-2">
      <h1>{{ t("sections.info") }}</h1>
      <h2>{{ t("userTransfering") }}: {{ user.fullName }}</h2>
      <input-group class="items-center">
        <h2>
          {{ t("fromLocation") }}:
          {{
            $ctx.locale === "en"
              ? $ctx.currentLocation.enName
              : $ctx.currentLocation.arName
          }}
        </h2>
        <el-form-item
          :label="t('toLocation')"
          prop="toLocationID"
          class="input-label"
        >
          <el-select
            v-model="inventoryTransfer.toLocationID"
            filterable
            clearable
            class="w-full"
          >
            <el-option
              v-for="item in locations"
              :key="item.id"
              :label="$ctx.locale === 'en' ? item.enName : item.arName"
              :value="item.id"
            >
              <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">{{
                $ctx.locale === "en" ? item.enName : item.arName
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </input-group>

      <input-group>
        <el-form-item
          :label="t('transferOutType')"
          class="input-label"
          prop="transferOutType"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="t('tooltips.transferOutType')"
            placement="bottom"
          >
            <el-select
              v-model="inventoryTransfer.transferOutType"
              class="w-full"
            >
              <!-- indirect transfer is currently not supported -->
              <el-option
                v-for="item in ['direct']"
                :key="item"
                :label="t(item)"
                :value="item"
              >
                <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">{{
                  t(item)
                }}</span>
              </el-option>
            </el-select>
          </el-tooltip>
        </el-form-item>

        <el-form-item :label="t('reason')" class="input-label" prop="reason">
          <el-input
            id="reason"
            v-model="inventoryTransfer.reason"
            :placeholder="t('placeholders.reason')"
            clearable
            required
          >
          </el-input>
        </el-form-item>
      </input-group>
    </input-section>

    <input-section>
      <h1>{{ t("sections.inventory") }}</h1>
      <el-form-item :label="t('itemName')" class="input-label">
        <el-autocomplete
          id="item"
          v-model="itermSearchTerm"
          :fetch-suggestions="searchItems"
          value-key="name"
          :trigger-on-focus="false"
          class="w-full"
          @select="addItem"
          @keypress.enter.prevent="pressedEnter = true"
          highlight-first-item
        >
        </el-autocomplete>
      </el-form-item>
    </input-section>

    <div class="w-10/12 mt-8" v-if="!inventoryTransfer.empty()">
      <el-table
        :data="inventoryTransfer.inventoryTransferItems"
        style="width: 100%"
        stripe
      >
        <el-table-column prop="item.name" :label="t('itemName')">
        </el-table-column>

        <el-table-column
          :label="t('currentQuantity')"
          width="200"
          align="center"
        >
          <template #default="props">
            <div class="el-input el-input--small">
              {{ props.row.item.itemStocks[0].quantity - props.row.quantity }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="quantity"
          :label="t('sentQuantity')"
          width="200"
          align="center"
        >
          <template #default="props">
            <el-input-number
              v-model="props.row.quantity"
              :step="1"
              size="small"
              :min="0"
              :max="props.row.item.itemStocks[0].quantity"
              controls-position="right"
            ></el-input-number>
          </template>
        </el-table-column>

        <el-table-column
          prop="inventoryTransferItemSerials"
          :label="t('serialNumbers')"
          width="300"
        >
          <template #default="props">
            <template v-if="props.row.item.isSerialized">
              <!-- select serials to transfer -->
              <label class="input-label">
                {{
                  t("insertSerials", {
                    num: props.row.quantity
                  })
                }}
                <el-select
                  filterable
                  clearable
                  multiple
                  class="w-full"
                  v-model="props.row.toSendSerialIDs"
                  :multiple-limit="props.row.quantity"
                >
                  <el-option
                    v-for="(item, i) in props.row.item.itemStocks[0]
                      .itemSerials"
                    :key="i"
                    :label="item.number"
                    :value="item.id"
                  >
                    <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">
                      {{ item.number }}
                    </span>
                  </el-option>
                </el-select>
              </label>
            </template>
          </template>
        </el-table-column>

        <el-table-column width="50" align="center">
          <template #default="scope">
            <span
              class="text-red-600 cursor-pointer"
              @click="removeItem(scope.$index)"
            >
              <font-awesome-icon icon="trash" />
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InventoryTransfer, {
  inventoryTransferI18nMessages
} from "@/models/inventory/InventoryTransfer";
import Location from "@/models/company/Location";
import { ElForm } from "element-plus";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import InputGroup from "@/components/form/InputGroup.vue";
import Item from "@/models/inventory/Item";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "new-inventory-receiving",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryTransferI18nMessages.en,

          sections: {
            info: "Meta data",
            inventory: "Inventory Info"
          },

          placeholders: {
            reason: "Example: store beta is running low on item alpha",
            itemName: "Enter barcode, sku, or item name"
          },

          userTransfering: "Employee responsible for this inventory transfer",
          insertSerials: "You must insert {num} serial numbers",

          currentQuantity: "Quantity left",
          sentQuantity: "Sent quantity",

          successMsg: "Inventory was transfered successfully",

          transferOutType: "Choose transfer type",
          direct: "Direct Transfer",
          indirect: "Indirect Transfer",

          tooltips: {
            transferOutType: "With direct transfer, the "
          }
        },
        ar: {
          ...inventoryTransferI18nMessages.ar,

          sections: {
            info: "البيانات الوصفية",
            inventory: "البضاعة المنقولة"
          },

          placeholders: {
            reason: "مثلا: نفذت البضاعة من محل اخر",
            itemName: "ادخل اسم المنتج او الباركود او SKU"
          },

          userTransfering: "الموظف المسؤول عن نقل البضاعة",
          insertSerials: "قم بادخال {num} serial numbers",

          currentQuantity: "الكمية المتبقية",
          sentQuantity: "الكمية المرسلة",

          successMsg: "تم نقل البضاعة بنجاح",

          transferOutType: "اختر نوع النقل",
          direct: "مباشر",
          indirect: "غير مباشر",

          tooltips: {
            transferOutType:
              "باستخدام النقل المباشر، تتحدث الكمية في الموقع المستلم بشكل مباشر. اما بالنقل الغير مباشر، فيجب استلام البضاعة من الموقع المستلم بشكل يدوي"
          }
        }
      }
    });

    return { t };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection,
    InputGroup
  },

  async beforeMount() {
    try {
      const locResp = await this.$http.get<Location[]>(Location.ENDPOINT);
      this.locations = locResp
        .map(loc => Location.from(loc))
        // exclude this location
        .filter(loc => loc.id !== this.$ctx.currentLocation.id);
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },

  data() {
    return {
      inventoryTransfer: new InventoryTransfer("out"),
      itermSearchTerm: "",
      error: { title: "", body: "" },
      serials: [],
      pressedEnter: false,
      user: this.$ctx.currentUser,
      locations: new Array<Location>(),
      rules: {
        reason: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        transferOutType: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        toLocationID: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    async searchItems(query: string, cb: Function) {
      // if an item already exists in the table, don't do remote search
      const cachedItem =
        this.inventoryTransfer.findByBarcode(query) ||
        this.inventoryTransfer.findBySKU(query);
      if (cachedItem) {
        this.addItem(cachedItem);
        cb([]);
        return;
      }

      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        const items = await this.$http.get<Item[]>(url);

        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && items.length === 1) {
          this.addItem(items[0]);
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }

      this.pressedEnter = false;
    },

    addItem(selected: Item) {
      this.inventoryTransfer.addItem(selected);
      this.itermSearchTerm = "";
    },

    removeItem(index: number) {
      this.inventoryTransfer.removeItem(index);
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
      this.inventoryTransfer = new InventoryTransfer("");
    },

    async submit(ir: InventoryTransfer) {
      this.error.title = "";
      this.error.body = "";

      try {
        await this.$http.post(InventoryTransfer.ENDPOINT, ir);
        await this.$router.push(this.$Route.INVENTORY_INVENTORY_TRANSFERS);
      } catch (error) {
        return error;
      }
    },

    checkForm() {
      const errors = [];
      for (const iai of this.inventoryTransfer.inventoryTransferItems) {
        errors.push(iai.validateSerials());
        errors.push(iai.validateQuantity());
      }

      this.error.body = errors.filter(err => err).join("\n");

      if (this.error.body) {
        this.error.title = this.$t("validation.inputErrors");
        document.getElementById("main")?.scrollTo({ top: 0 });
        return false;
      }

      return true;
    },

    async validateSubmit(formName: string) {
      this.inventoryTransfer.prepareSendPayloadData();
      const form = this.$refs[formName] as typeof ElForm;

      form?.validate(async (valid: boolean) => {
        if (!valid || !this.checkForm()) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submit(
          this.inventoryTransfer as InventoryTransfer
        );

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          this.clearForm(formName);
          this.$alertModal.showSuccess({ title: this.t("successMsg") });
        }
        return true;
      });
    }
  }
});
</script>

<style lang="postcss" scoped>
form > h1 {
  @apply text-lg py-3 font-bold;
}

form > h2 {
  @apply py-3;
}

form > h3 {
  @apply py-3 font-bold;
}

form {
  @apply flex flex-col items-center;
}
</style>
