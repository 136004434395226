<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />

  <brand-form
    v-else
    :initBrand="initBrand"
    :successMsg="$t('actions.saveChangesSuccess')"
    :submitAction="update"
  />
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";

import { useHTTP } from "@/plugins/http";

import Brand from "@/models/inventory/Brand";

// components
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import BrandForm from "@/views/inventory/brands/BrandForm.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "brand",
  components: {
    LoadingSpinner,
    BrandForm,
    AlertBadge
  },

  setup() {
    const initBrand = ref(new Brand());
    const http = useHTTP();
    const loading = ref(true);
    const error = ref({ title: "", body: "" });

    const route = useRoute();

    onBeforeMount(async () => {
      const url = `${Brand.ENDPOINT}/${route.params.id}`;

      try {
        const resp = await http.get<Brand>(url);
        initBrand.value = Brand.from(resp);
      } catch (errorResp) {
        error.value.title = errorResp.title;
        error.value.body = errorResp.body;
      }

      loading.value = false;
    });

    const update = async (brand: Brand) => {
      try {
        const url = `${Brand.ENDPOINT}/${brand.id}`;
        const resp = await http.put<Brand>(url, brand);
        initBrand.value = Brand.from(resp);
      } catch (error) {
        return error;
      }
    };

    return {
      initBrand,
      loading,
      update,
      error
    };
  }
});
</script>
