
import { defineComponent } from "vue";
import InventoryReceiving, {
  inventoryReceivingI18nMessages,
  InventoryReceivingItem
} from "@/models/inventory/InventoryReceiving";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import Item from "@/models/inventory/Item";
import Vendor from "@/models/vendor/Vendor";
import { useI18n } from "vue-i18n";
import Dinero, { Currency } from "dinero.js";
import { formatDinero, formatMoney } from "@/utils/money";
import MoneyInput from "@/components/form/MoneyInput.vue";
import TagInput from "@/components/form/TagInput.vue";
import { ElForm } from "element-plus";

export default defineComponent({
  name: "new-inventory-receiving",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryReceivingI18nMessages.en,
          sections: {
            info: "Meta data",
            inventory: "Inventory Info",
            billingInfo: "Billing Info"
          },

          placeholders: {
            notes: "Example: items were delayed for one week",
            itemName: "Enter barcode, sku, or item name"
          },

          userReceiving: "Employee responsible for this inventory receiving",
          fullPay: "Fully Paid",
          pickDate: "Pick a Date"
        },
        ar: {
          ...inventoryReceivingI18nMessages.ar,
          sections: {
            info: "البيانات الوصفية",
            inventory: "البضاعة المستلمة",
            billingInfo: "معلومات الدفع"
          },

          placeholders: {
            notes: "مثلا: تاخرت البضاعة",
            itemName: "ادخل اسم المنتج او الباركود او SKU"
          },

          userReceiving: "الموظف المسؤول عن استلام البضاعة",
          fullPay: "دفع كل المبلغ",
          pickDate: "اختر التاريخ"
        }
      }
    });

    const rules = {
      "vendor.name": [{ required: true, message: t("validation.required") }],
      "vendorBill.totalPaidAmount": [
        { required: true, message: t("validation.required") }
      ],
      "vendorBill.billDate": [
        { required: true, message: t("validation.required") }
      ],
      "vendorBill.billDueDate": [
        { required: true, message: t("validation.required") }
      ]
    };

    return { t, rules };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection,
    MoneyInput,
    TagInput
  },
  data() {
    return {
      inventoryReceiving: new InventoryReceiving(),
      itemSearchTerm: "",
      error: { title: "", body: "" },
      serials: [],
      pressedEnter: false,
      user: this.$ctx.currentUser
    };
  },
  methods: {
    formatDinero,
    formatMoney,
    async searchVendors(query: string, cb: Function) {
      try {
        const url = `${Vendor.ENDPOINT}?q=${query}`;
        const vendors = await this.$http.get<Vendor[]>(url);
        cb(vendors);
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }
    },

    async searchItems(query: string, cb: Function) {
      // if an item already exists in the table, don't do remote search
      const cachedItem =
        this.inventoryReceiving.findByBarcode(query) ||
        this.inventoryReceiving.findBySKU(query);
      if (cachedItem) {
        this.addItem(cachedItem);
        cb([]);
        return;
      }

      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        const items = await this.$http.get<Item[]>(url);

        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && items.length === 1) {
          this.addItem(items[0]);
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }

      this.pressedEnter = false;
    },

    selectVendor(selected: Vendor) {
      this.inventoryReceiving.vendor = selected;
    },

    addItem(selected: Item) {
      this.inventoryReceiving.addItem(selected);
      this.calculateInventoryTotal();
      this.itemSearchTerm = "";
    },

    removeItem(index: number) {
      this.inventoryReceiving.removeItem(index);
      this.calculateInventoryTotal();
    },

    clearForm(formName: string) {
      this.inventoryReceiving = new InventoryReceiving();
      const form = this.$refs[formName] as typeof ElForm;
      this.$globalEmitter.emit("money-input-reset");
      if (form) form.resetFields();
    },

    calculateItemTotal(scope: any) {
      const price = Dinero({
        amount: scope.row.quantity * scope.row.item.costAmount,
        currency: scope.row.item.costCurrency,
        precision: scope.row.item.costPrecision
      });
      return formatDinero(price);
    },

    calculateInventoryTotal() {
      let total = 0;
      this.inventoryReceiving.inventoryReceivingItems.forEach(item => {
        total +=
          item.quantity * (item.item.costAmount ? item.item.costAmount : 1);
      });

      this.inventoryReceiving.totalCostAmount = total;
      this.inventoryReceiving.totalCostDinero = Dinero({
        amount: total,
        currency: this.inventoryReceiving.vendorBill.currency as Currency,
        precision: 0
      });
      this.calculateTotal();
      return total;
    },

    calculateTotal() {
      this.inventoryReceiving.vendorBill.totalDueAmount =
        (this.inventoryReceiving.totalCostAmount
          ? this.inventoryReceiving.totalCostAmount
          : 0) +
        (this.inventoryReceiving.vendorBill.otherCostsAmount
          ? this.inventoryReceiving.vendorBill.otherCostsAmount
          : 0);

      this.inventoryReceiving.vendorBill.totalDueDinero = Dinero({
        amount: this.inventoryReceiving.vendorBill.totalDueAmount,
        currency: this.inventoryReceiving.vendorBill.currency as Currency,
        precision: 0
      });
    },

    fullPay() {
      this.inventoryReceiving.vendorBill.totalPaidAmount = this.inventoryReceiving.vendorBill.totalDueAmount;
    },

    async submit(ir: InventoryReceiving) {
      this.error.title = "";
      this.error.body = "";
      ir.locationID = this.$ctx.currentLocation.id;
      ir.userID = this.$ctx.currentUser.id;
      ir.vendorBill.currency = this.$ctx.currency;
      ir.vendorBill.precision = this.$ctx.currencyPrecision;
      ir.vendorID = ir.vendor.id;
      ir.vendorBill.vendorID = ir.vendor.id;
      ir.inventoryReceivingItems = ir.inventoryReceivingItems.map(
        inventoryReceivingItem => {
          if (typeof inventoryReceivingItem.quantity === "string")
            return {
              ...inventoryReceivingItem,
              quantity: parseInt(inventoryReceivingItem.quantity)
            } as InventoryReceivingItem;
          return inventoryReceivingItem;
        }
      );
      try {
        await this.$http.post(InventoryReceiving.ENDPOINT, ir);
        await this.$router.push(this.$Route.INVENTORY_INVENTORY_RECEIVINGS);
      } catch (error) {
        return error;
      }
    },

    checkForm() {
      const errors = [];
      this.error.body = "";
      for (const iri of this.inventoryReceiving.inventoryReceivingItems) {
        errors.push(iri.validateSerials());
        errors.push(iri.validateQuantity());
      }

      this.error.body = errors.filter(err => err).join("\n");

      if (this.error.body !== "") {
        this.error.title = this.$t("validation.inputErrors");
        document.getElementById("main")?.scrollTo({ top: 0 });
        return false;
      }

      return true;
    },

    async validateSubmit(formName: string) {
      if (this.checkForm()) {
        const form = this.$refs[formName] as typeof ElForm;
        form?.validate(async (valid: boolean) => {
          if (!valid) {
            document.getElementById("main")?.scrollTo({ top: 0 });
            return false;
          }
          const resp = await this.submit(
            this.inventoryReceiving as InventoryReceiving
          );

          if (resp) {
            this.error.title = resp?.title;
            this.error.body = resp?.body;
            document.getElementById("main")?.scrollTo({ top: 0 });
          } else {
            this.clearForm("inventoryReceivingForm");
            this.$alertModal.showSuccess({ title: "تم استلام البضاعة بنجاح" });
          }
        });
      }
    }
  }
});
