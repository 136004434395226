
import { defineComponent } from "vue";

export default defineComponent({
  name: "horizontal-button",
  data() {
    return {
      isNormal: !(this.isDanger || this.isSuccess || this.isWarning)
    };
  },
  props: {
    title: {
      required: true,
      type: String
    },
    faIcon: {
      required: false,
      type: String
    },
    isDanger: {
      type: Boolean,
      default: false
    },
    isSuccess: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    },
    styles: {
      required: false,
      type: String
    },
    rounded: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    getClasses() {
      const roundedClass = this.rounded ? "rounded-lg " : "";
      if (this.styles !== undefined) {
        return roundedClass + this.styles;
      }

      if (this.isDanger) {
        return roundedClass + " button-danger";
      } else if (this.isSuccess) {
        return roundedClass + " button-success";
      } else if (this.isWarning) {
        return roundedClass + " button-warning";
      } else {
        return roundedClass + " button-normal";
      }
    }
  }
});
