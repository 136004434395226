import { App } from "vue";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { logError } from "@/utils/log";
import errors from "@/errors";
import { useContext } from "@/plugins/context";
import { envSettings } from "@/main";
import { useRouter } from "vue-router";

enum RequestType {
  get,
  delete,
  post,
  put
}

/**
 *
 * @param type request type
 * @param url request url
 * @param data optional, request data
 * @param config optional, request config
 *
 * the purpose of this function is to not repeat ourselves and separate concerns
 * when making a request, there are three possible types of errors:
 * - the server responded: in this case, we will use whatever error message the server returns,
 *   if it's in 400 range, then the user has messed up. if it's in the 500 range, we know it's on the server
 *   and the server is concerned of logging the error. In in any case,
 *   we just return the error messages returned by the server.
 * - the server didn't respond, this is the case if the server is down.
 *   we log the error and tell the user something is wrong on our side. In the future, such error
 *   should provoke workers to try to restart the server
 * - the request didn't go through. Maybe it's the wrong URL? Who knows! We log the error and tell the
 *   user that this is a problem on our side.
 *
 * just imagine how awful it would be to write the same code in every Vue component
 * that makes an http request!
 * with this plugin, the Vue component expects only two possible outcomes:
 * - onSuccess (inside try): the data its expecting
 * - onFailure (inside Catch): an array of string representing error messages to show to the user
 */
export async function doRequest<T = unknown>(
  client: AxiosInstance,
  type: RequestType = RequestType.get,
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
): Promise<T> {
  const router = useRouter();
  let resp;
  try {
    switch (type) {
      case RequestType.delete: {
        resp = await client.delete<T>(url, config);
        break;
      }
      case RequestType.post: {
        resp = await client.post<T>(url, data, config);
        break;
      }
      case RequestType.put: {
        resp = await client.put<T>(url, data, config);
        break;
      }
      default: {
        resp = await client.get<T>(url, config);
        break;
      }
    }

    return resp.data as T;
  } catch (error) {
    let err;
    if (error.response) {
      // the request was made and the server responded!

      // if the server returns a response
      if (error.response.data) {
        if (error.response.status === 401 && !url.includes("login")) {
          // user is logged out
          router.replace("/login");
        }
        // we are expecting a json response structured as {"title", "", "body", ""}
        const errResp = error.response.data;
        if (errResp.title) {
          throw {
            title: errResp.title || "",
            body: errResp.body || "",
            code: error.response.status
          };
        }
      }

      // unexpected response from server
      err = errors.system.http.UNEXPECTED_RESPONSE.bindDebugInfo({
        scope: "doRequest",
        method: type,
        url,
        data,
        config,
        responseError: error.response
      });
    } else if (error.request) {
      // The request was made but no response was received
      err = errors.system.http.SERVER_NOT_RESPONDING.bindDebugInfo({
        scope: "doRequest",
        method: type,
        url,
        data,
        config,
        requestError: error.request
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      err = errors.system.http.FAILED_TO_CONNECT_TO_SERVER.bindDebugInfo({
        scope: "doRequest",
        method: type,
        url,
        data,
        config,
        error
      });
    }

    logError(err.dumpDebugInfo());
    throw { title: err.toString() };
  }
}

export function useHTTP() {
  const { locale } = useContext();
  let BASE_URL: string;
  if (process?.env?.NODE_ENV === "development") {
    BASE_URL = "http://127.0.0.1:3001/api/v1";
  } else {
    if (process.env.IS_ELECTRON) {
      BASE_URL = `http://127.0.0.1:${envSettings["APP_PORT"]}/api/v1`;
    } else {
      BASE_URL =
        "http://138.68.107.40/backend/api/v1";
    }
  }

  const client = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
      "Content-Language": locale
    }
  });

  client.interceptors.request.use(req => {
    req.headers = {
      ...req.headers,
      "Session-Id": localStorage.getItem("Session-Id"),
      Authorization: "Bearer " + localStorage.getItem("token")
    };
    return req;
  });

  return {
    get: async function<T = unknown>(
      url: string,
      config?: AxiosRequestConfig
    ): Promise<T> {
      return doRequest<T>(client, RequestType.get, url, undefined, config);
    },

    delete: async function<T = unknown>(
      url: string,
      config?: AxiosRequestConfig
    ): Promise<T> {
      return doRequest<T>(client, RequestType.delete, url, undefined, config);
    },

    post: async function<T = unknown>(
      url: string,
      data?: unknown,
      config?: AxiosRequestConfig
    ): Promise<T> {
      return doRequest<T>(client, RequestType.post, url, data, config);
    },

    put: async function<T = unknown>(
      url: string,
      data?: unknown,
      config?: AxiosRequestConfig
    ): Promise<T> {
      return doRequest<T>(client, RequestType.put, url, data, config);
    }
  };
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$http = useHTTP();
  }
};
