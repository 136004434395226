
// prefix-icon="el-icon-money"
import { defineComponent, Ref, watch } from "vue";
import { useCurrencyInput } from "vue-currency-input";
import Dinero from "dinero.js";
import { formatDinero } from "@/utils/money";

/*
 * why is max length = 15?
 * javascript uses double precision IEEE floating points
 * length = 15 is the maximum safe length of a number
 * that is still a lot of money btw!
 */

export default defineComponent({
  name: "money-input",

  setup(props) {
    const { formattedValue, inputRef, setValue } = useCurrencyInput({
      currency: props.currency,
      precision:
        props.currency === "IQD" ? 0 : props.currency === "USD" ? 2 : undefined,
      locale: "en-US",
      exportValueAsInteger: true
    });
    if (props.modelValue !== undefined) {
      watch(
        () => props.modelValue,
        value => {
          if (value || value === 0) {
            setValue(value);
          }
        }
      );
    }
    return { formattedValue, inputRef, setValue };
  },

  props: {
    currency: {
      type: String,
      required: true
    },

    modelValue: {
      type: Number,
      required: false,
      default: undefined
    },

    placeholder: {
      type: String,
      required: false,
      default: ""
    },

    autofocus: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: [
    "dinero-created",
    "update:modelValue",
    "formatted-money-updated",
    "input"
  ],

  data() {
    return {
      moneyAsDinero: Dinero()
    };
  },

  created() {
    this.$globalEmitter.on("money-input-reset", () => {
      this.reset();
    });
  },

  methods: {
    update(event: Event) {
      if (!(event instanceof CustomEvent)) return;

      const currency = this.currency;
      const val = event.detail.number as number;

      if (!val && val !== 0) return;

      let asDineroVal = Dinero();

      /*
       * why precision: 0 for IQD?
       * Dinero uses  ISO 4217
       * which still has 1 IQD = 1000 fils
       * unfortunately, this isn't the case in Iraq
       * fils are neglected in almost all day to day operations
       * it would be super confusing for people to read 250.000
       * they might understand it as 250K Iraqi Dinars
       * so for the sake of UX, this decision has been made!
       */
      asDineroVal =
        currency === "IQD"
          ? Dinero({
              amount: val,
              currency: "IQD",
              precision: 0
            })
          : Dinero({
              amount: val,
              currency: "USD"
            });

      this.$emit("dinero-created", asDineroVal);
      this.$emit("update:modelValue", val);
      this.$emit("formatted-money-updated", formatDinero(asDineroVal));
      this.$emit("input", val);
    },

    reset() {
      if (this.inputRef) this.inputRef.value = "";
      this.$emit("update:modelValue", null);
      this.$emit("input", null);
    }
  }
});
