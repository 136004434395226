
import { defineComponent } from "vue";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import InputGroup from "@/components/form/InputGroup.vue";
import { ElForm } from "element-plus";

// models
import Vendor, { vendorI18nMessages } from "@/models/vendor/Vendor";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "vendor-form",

  setup() {
    const { t } = useI18n({
      useScope: "global",
      messages: {
        en: {
          ...vendorI18nMessages.en,

          sections: {
            vendor: "Vendor Info",
            contact: "Contcat Info",
            address: "Address"
          },

          placeholders: {
            name: "Example: Hamaza Ltd",
            contactFirstName: "Example: Ahmed",
            contactLastName: "Example: Al-Joubouri",
            phone: "Example: 077011111111",
            email: "Example: info[at]hamza.com",
            streetAddress: "Example: Next to Almansour Mall",
            city: "Example: Baghdad",
            postalCode: "Example: 10015",
            country: "Example: Iraq"
          }
        },
        ar: {
          ...vendorI18nMessages.ar,

          sections: {
            vendor: "عن الموزع",
            contact: "معلومات الاتصال",
            address: "العنوان"
          },

          placeholders: {
            name: "مثلا: شركة الحمزة للمنتجات الغذائية",
            contactFirstName: "مثلا: احمد",
            contactLastName: "مثلا: الجبوري",
            phone: "مثلا: 077011111111",
            email: `مثلا: info[at]hamaza.com`,
            streetAddress: "مثلا: مجاور لمول المنصور",
            city: "مثلا: بغداد",
            postalCode: "مثلا: 10015",
            country: "مثلا: العراق"
          }
        }
      }
    });

    const rules = {
      name: [
        { required: true, message: t("validation.required") },
        { max: 255, message: t("validation.maxLength", { len: 255 }) }
      ],
      contactFirstName: [
        { max: 127, message: t("validation.maxLength", { len: 127 }) }
      ],
      contactLastName: [
        { max: 127, message: t("validation.maxLength", { len: 127 }) }
      ],
      phone: [{ max: 15, message: t("validation.maxLength", { len: 15 }) }],
      email: [{ max: 255, message: t("validation.maxLength", { len: 255 }) }],
      streetAddress: [
        { max: 255, message: t("validation.maxLength", { len: 255 }) }
      ],
      city: [{ max: 70, message: t("validation.maxLength", { len: 70 }) }],
      postalCode: [
        { max: 10, message: t("validation.maxLength", { len: 10 }) }
      ],
      country: [{ max: 70, message: t("validation.maxLength", { len: 70 }) }]
    };

    return { t, rules };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection,
    InputGroup
  },
  props: {
    initVendor: {
      type: Vendor,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      vendor: this.initVendor,
      error: { title: "", body: "" }
    };
  },

  methods: {
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.vendor);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    }
  }
});
