
import { defineComponent } from "vue";

// components
import MoneyInput from "@/components/form/MoneyInput.vue";
import CurrencyConverter from "@/components/form/CurrencyConverter.vue";

// utils
import { useI18n } from "vue-i18n";
import dinero, { Currency, Dinero } from "dinero.js";
import { formatDinero, formatMoney } from "@/utils/money";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          total: "Total Due",
          cash: "Amount Paid",
          changeDue: "Change Due",
          totalPaid: "Total received",
          useConverted: "Use as amount paid",
          clickToAdd: "Click + to add ",
          toTheTotal: "to the total received",
          cashReceived: "Cash Received"
        },
        ar: {
          total: "المبلغ المطلوب",
          cash: "المبلغ المدفوع",
          changeDue: "المبلغ الباقي",
          totalPaid: "المبلغ المستحصل",
          useConverted: "استخدم كمبلغ مدفوع",
          clickToAdd: "اضغط على + لاضافة ",
          toTheTotal: "للمبلغ المستحصل",
          cashReceived: "المبلغ المستحصل"
        }
      }
    });

    const calculatorButtons = [
      { label: "7", value: 7 },
      { label: "8", value: 8 },
      { label: "9", value: 9 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "0", value: 0 }
    ];

    return { t, calculatorButtons };
  },

  data() {
    const calculatorTotalValue = "";
    return {
      convertedAmount: 0,
      currencyCalcVisible: false,
      calculatorTotalValue
    };
  },

  props: {
    totalDue: {
      type: Number,
      required: true
    },

    changeDue: {
      type: Number,
      required: true
    },

    paidBeforeChange: {
      type: Number,
      required: true
    },

    totalPaid: {
      type: Number,
      required: true
    },

    currency: {
      type: String,
      required: true
    },

    precision: {
      type: Number,
      required: true
    }
  },

  computed: {
    paidBeforeChangeVal: {
      get(): number {
        return this.paidBeforeChange;
      },
      set(val: number) {
        this.$emit("update:paidBeforeChange", val);
      }
    },

    changeDueDinero(): Dinero {
      return dinero({
        amount: this.changeDue,
        currency: this.currency as Currency,
        precision: this.precision
      });
    },

    totalDueDinero(): Dinero {
      return dinero({
        amount: this.totalDue,
        currency: this.currency as Currency,
        precision: this.precision
      });
    },

    totalPaidDinero(): Dinero {
      return dinero({
        amount: this.totalPaid,
        currency: this.currency as Currency,
        precision: this.precision
      });
    },

    calculatorTotalValueDinero(): Dinero {
      return dinero({
        amount: +this.calculatorTotalValue,
        currency: this.currency as Currency,
        precision: this.precision
      });
    }
  },

  components: {
    MoneyInput,
    CurrencyConverter
  },

  methods: {
    formatDinero,
    formatMoney,
    useConvertedAmount() {
      this.currencyCalcVisible = false;
      this.updatePaidBeforeChange(this.convertedAmount);
    },

    // Caution: HORRIBLE CODE BELOW!
    updatePaidBeforeChange(newVal: number) {
      this.$emit("update:paidBeforeChange", newVal);
      this.updateChnageDue(newVal);
      this.updateTotalPaid(newVal);
    },

    appendNumber(val: number) {
      // add the val (which is a number) to a string so it gets appended to the right side
      this.calculatorTotalValue += val;
    },

    addAppendedNumberToTotal() {
      this.updatePaidBeforeChange(
        this.paidBeforeChange + Number(this.calculatorTotalValue)
      );
      // reset the calculator
      this.calculatorTotalValue = "";
    },

    removeLastDigitFromTotal() {
      // remove the temporary calculator value
      if (this.calculatorTotalValue) {
        this.calculatorTotalValue = this.calculatorTotalValue.substr(
          0,
          this.calculatorTotalValue.length - 1
        );
      } else {
        const result = Math.floor(this.paidBeforeChange / 10);
        this.updatePaidBeforeChange(result);
      }
    },

    addToPaidAmount(val: number) {
      this.updatePaidBeforeChange(this.paidBeforeChange + val);
    },

    updateChnageDue(paidBeforeChange: number) {
      const change = this.computeChangeDue(paidBeforeChange);
      if (change >= 0) this.$emit("update:changeDue", change);
    },

    computeChangeDue(paidAmount: number) {
      return Math.max(paidAmount - this.totalDue, 0);
    },

    updateTotalPaid(paidBeforeChange: number) {
      const total = Math.min(paidBeforeChange, this.totalDue);
      this.$emit("update:totalPaid", total);
    }
  }
});
