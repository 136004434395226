
import { defineComponent } from "vue";

import AlertBadge from "@/components/AlertBadge.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import OrderInfo from "./components/OrderInfo.vue";
import { ElCollapse, ElCollapseItem } from "element-plus";

// models
import { i18nOrderMessages, Order } from "@/models/sales/Order";

// tools
import { useI18n } from "vue-i18n";

// helpers
import { formatDinero } from "@/utils/money";
import { parseFormat } from "@/utils/date";

export default defineComponent({
  name: "order",
  setup() {
    const { t } = useI18n({
      useScope: "global",
      messages: {
        en: {
          ...i18nOrderMessages.en,
          print: "Print Receipt",
          makePayment: "Make a payment",
          paymentsMade: "Total paid amount",
          moneyOwed: "Money Owed",
          returns: "Order returns"
        },
        ar: {
          ...i18nOrderMessages.ar,
          print: "اطبع الفاتورة",
          makePayment: "دفع تجاه البيع",
          paymentsMade: "المبلغ المدفوع",
          moneyOwed: "المبلغ المطلوب",
          returns: "الترجيعات"
        }
      }
    });

    return { t };
  },

  data() {
    return {
      order: new Order(),
      error: {
        title: "",
        body: ""
      },
      loading: false
    };
  },

  async beforeMount() {
    // get order
    try {
      this.loading = true;
      const resp = await this.$http.get<Order>(
        `${Order.ENDPOINT}/${this.$route.params.id}`
      );
      this.order = Order.from(resp);
    } catch (error) {
      this.error.title = error.title;
      this.error.body = error.body;
    } finally {
      this.loading = false;
    }
  },

  components: {
    AlertBadge,
    LoadingSpinner,
    OrderInfo,
    ElCollapse,
    ElCollapseItem
  },

  methods: {
    formatDinero,
    parseFormat
  }
});
