<template>
  <section class="flex flex-col justify-between lg:flex-row">
    <!-- a wrapper div so we can have the buttons on the left
    and the table on the right -->
    <div>
      <!-- employee timesheet -->
      <div class="mx-8 my-2">
        {{ t("employeeTimesheet") }}
      </div>
      <div class="flex m-3">
        <ActionButton
          :title="$t('views.hr.viewSchedule')"
          :faIcon="'calendar-day'"
          @click="$router.push($Route.HR_EMPLOYEE_SCHEDULE)"
        />
        <ActionButton
          :title="$t('views.hr.assignShift')"
          :faIcon="'user-clock'"
          @click="
            $router.push($Route.HR_EMPLOYEE_SCHEDULES_NEW_EMPLOYEE_SCHEDULE)
          "
        />
      </div>
      <!-- employee timesheet -->
      <div class="mx-8 my-2">
        {{ t("employeeTimeLog") }}
      </div>
      <div class="flex m-3">
        <ActionButton
          :title="$t('views.hr.clockInOut')"
          :faIcon="'calendar-day'"
          @click="showDrawer('clock-in-out')"
        />
      </div>
      <!-- employee management -->
      <div class="mx-8 my-2">
        {{ t("employeeManagement") }}
      </div>
      <div class="flex m-3">
        <ActionButton
          :title="$t('views.hr.employees.employees')"
          :faIcon="'users'"
          @click="$router.push($Route.HR_EMPLOYEES)"
        />
        <ActionButton
          :title="$t('views.hr.employees.newEmployee')"
          :faIcon="'user-plus'"
          @click="$router.push($Route.HR_EMPLOYEES_NEW_EMPLOYEE)"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { useI18n } from "vue-i18n";
import { useDrawer } from "@/plugins/drawer/drawer";
import ActionButton from "@/components/ActionButton.vue";

export default defineComponent({
  name: "human-resources",
  setup() {
    const { showDrawer } = useDrawer();

    const { t } = useI18n({
      messages: {
        en: {
          employeeTimesheet: "Employee Timesheet",
          employeeTimeLog: "ClockIn/Out",
          employeeManagement: "Employee Management"
        },
        ar: {
          employeeTimesheet: "الجداول",
          employeeTimeLog: "سجل الوقت",
          employeeManagement: "ادارة الموظفين"
        }
      }
    });

    return { t, showDrawer };
  },
  components: { ActionButton }
});
</script>

<style scoped>
.el-table--fit {
  width: max-content;
}
</style>
