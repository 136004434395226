
import { defineComponent } from "vue";
import JsBarcode from "jsbarcode";
import printJS from "print-js";

import Item from "@/models/inventory/Item";

import HorizontalButton from "@/components/HorizontalButton.vue";

import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "print-barcode",

  setup() {
    const messages = {
      en: {
        placeholders: {
          item: "Example: backpack"
        },
        itemLookup: "Lookup item to quickly fill the barcode field below",
        enterBarcode: "Enter barcode (may be filled by the field above)",
        chooseBarcode: "Choose barcode type",
        printItemName: "Print item name",
        printItemSKU: "Print item sku",
        width: "Width",
        height: "Height",
        metric: "mm"
      },
      ar: {
        placeholders: {
          item: "مثال: حقيبة ظهر"
        },
        itemLookup: "أبحث عن منتج لملء حقل الباركود أدناه",
        enterBarcode: "أدخل الباركود (يمكن ملؤه بالحقل أعلاه)",
        chooseBarcode: "أختر نوع الباركود",
        printItemName: "أطبع اسم المنتج",
        printItemSKU: "أطبع ال sku",
        width: "العرض",
        height: "الارتفاع",
        metric: "mm"
      }
    };

    const { t } = useI18n({ messages, useScope: "global" });

    return { t };
  },

  components: {
    HorizontalButton
  },
  data() {
    return {
      item: new Item(),
      barcode: "",
      barcodeType: "",
      barcodeTypes: [
        { id: "code128", name: "code128" },
        { id: "ean13", name: "EAN13" },
        { id: "upc", name: "UPC" }
      ],
      itemName: "",
      width: 2,
      height: 100,
      printItemName: false,
      printItemSku: false,
      itermSearchTerm: "",
      pressedEnter: false
    };
  },
  watch: {
    barcode() {
      this.renderBarcode();
    },
    barcodeType() {
      this.renderBarcode();
    },
    height() {
      this.renderBarcode();
    },
    width() {
      this.renderBarcode();
    }
  },
  methods: {
    renderBarcode() {
      try {
        JsBarcode("#barcode", this.barcode, {
          format: this.barcodeType,
          height: this.height,
          width: this.width,
          valid: valid => {
            if (!valid) {
              this.$alertModal.showDanger({
                title: `not a valid input for ${this.barcodeType}`,
                body: ""
              });
            }
          }
        });
      } catch (error) {
        //
      }
    },

    chooseItem(selected: Item) {
      this.item = selected;
      this.barcode = this.item.barcode || "";
    },

    async searchItems(query: string, cb: Function) {
      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        const items = await this.$http.get<Item[]>(url);
        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && items.length === 1) {
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }

      this.pressedEnter = false;
    },

    async print() {
      printJS({ printable: "barcode", type: "html", targetStyles: ["*"] });
    }
  }
});
