<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />

  <div v-else class="flex flex-col md:flex-row">
    <div class="w-full mx-2 md:w-9/12">
      <div class="mt-2">
        <el-table
          :data="order.orderItems"
          border
          style="width: 100%"
          v-if="!order.empty()"
          :max-height="800"
        >
          <el-table-column prop="item.name" :label="t('itemName')">
          </el-table-column>
          <el-table-column prop="item.salePriceAmount" :label="t('price')">
            <template #default="scope">
              <span>
                {{ formatDinero(scope.row.itemSalePriceDinero) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="item.soldAtPriceAmount"
            :label="t('soldAtPrice')"
            align="center"
          >
            <template #default="scope">
              <div class="flex justify-between">
                <span>
                  {{ formatDinero(scope.row.soldAtPriceDinero) }}
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="quantity"
            :label="t('quantity')"
            width="75"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="returnedQuantity"
            :label="t('returnedQuantity')"
            width="75"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="itemTotal"
            :label="t('itemTotal')"
            align="center"
          >
            <template #default="scope">
              {{ formatDinero(scope.row.itemTotal) }}
            </template>
          </el-table-column>

          <el-table-column prop="orderItemSerials" :label="t('serialNumbers')">
            <template #default="scope">
              <li
                v-for="(serial, i) in scope.row.orderItemSerials"
                :key="i"
                :class="{ 'line-through': serial.returned }"
              >
                {{ serial.itemSerial.number }}
              </li>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="w-full md:w-3/12 mt-2" style="font-size: 14px !important">
      <order-info :order="order" :showPrintReceipt="true" />
      <el-collapse class="card p-3">
        <el-collapse-item :title="t('returns')" name="orderInfo">
          <table class="table-auto w-full">
            <tr>
              <th>{{ $t("models.common.createdAt") }}</th>
              <th>{{ $t("models.common.id") }}</th>
            </tr>
            <tr v-for="(change, i) in order.orderChanges" :key="i">
              <td>
                {{ parseFormat(change.createdAt) }}
              </td>

              <td>
                <el-link
                  @click.prevent="
                    $router.push(
                      $Route.SALES_ORDERS_RETURN_ORDER_RECEIPT.replace(
                        ':orderID',
                        order.id
                      ).replace(':id', change.id)
                    )
                  "
                  icon="el-icon-printer"
                >
                  {{ change.externalID }}
                </el-link>
              </td>
            </tr>
          </table>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AlertBadge from "@/components/AlertBadge.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import OrderInfo from "./components/OrderInfo.vue";
import { ElCollapse, ElCollapseItem } from "element-plus";

// models
import { i18nOrderMessages, Order } from "@/models/sales/Order";

// tools
import { useI18n } from "vue-i18n";

// helpers
import { formatDinero } from "@/utils/money";
import { parseFormat } from "@/utils/date";

export default defineComponent({
  name: "order",
  setup() {
    const { t } = useI18n({
      useScope: "global",
      messages: {
        en: {
          ...i18nOrderMessages.en,
          print: "Print Receipt",
          makePayment: "Make a payment",
          paymentsMade: "Total paid amount",
          moneyOwed: "Money Owed",
          returns: "Order returns"
        },
        ar: {
          ...i18nOrderMessages.ar,
          print: "اطبع الفاتورة",
          makePayment: "دفع تجاه البيع",
          paymentsMade: "المبلغ المدفوع",
          moneyOwed: "المبلغ المطلوب",
          returns: "الترجيعات"
        }
      }
    });

    return { t };
  },

  data() {
    return {
      order: new Order(),
      error: {
        title: "",
        body: ""
      },
      loading: false
    };
  },

  async beforeMount() {
    // get order
    try {
      this.loading = true;
      const resp = await this.$http.get<Order>(
        `${Order.ENDPOINT}/${this.$route.params.id}`
      );
      this.order = Order.from(resp);
    } catch (error) {
      this.error.title = error.title;
      this.error.body = error.body;
    } finally {
      this.loading = false;
    }
  },

  components: {
    AlertBadge,
    LoadingSpinner,
    OrderInfo,
    ElCollapse,
    ElCollapseItem
  },

  methods: {
    formatDinero,
    parseFormat
  }
});
</script>

<style scoped>
.card {
  background: var(--secondary-bg-color);
}

td,
th {
  border: 1px solid #dddddd;
  padding: 0.5rem;
}
</style>
