
// libraries
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useAuth } from "@/plugins/auth";
import { useLoadingModal } from "@/plugins/loading-modal/loading-modal";
import { useAlertModal } from "@/plugins/alert-modal/alert-modal";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          signOut: "Sign Out"
        },
        ar: {
          signOut: "تسجيل الخروج"
        }
      },
      useScope: "global"
    });

    const navItems = [
      {
        route: "/",
        icon: "home",
        title: t("views.home.home")
      },
      {
        route: "/sales",
        icon: "dollar-sign",
        title: t("views.sales.sales")
      },
      {
        route: "/inventory",
        icon: "warehouse",
        title: t("views.inventory.inventory")
      },
      {
        route: "/customers",
        icon: "users",
        title: t("views.customers.customers")
      },
      {
        route: "/vendors",
        icon: "users",
        title: t("views.vendors.vendors")
      },
      {
        route: "/hr",
        icon: "address-book",
        title: t("views.hr.hr")
      },
      {
        route: "/accounting",
        icon: "calculator",
        title: t("views.accounting.accounting")
      },
      // {
      //   route: "/reports",
      //   icon: "flag",
      //   title: t("views.reports.reports")
      // },
      {
        route: "/settings",
        icon: "cogs",
        title: t("views.settings.settings")
      }
    ];

    const router = useRouter();

    const isSelectedNavItem = function(parentRoute: string) {
      const currentRoute = router.currentRoute.value.fullPath;

      return parentRoute === "/"
        ? currentRoute === parentRoute
        : currentRoute.startsWith(parentRoute);
    };

    const signout = async () => {
      const { show, hide } = useLoadingModal();
      const { showDanger } = useAlertModal();
      try {
        const { userLogout } = useAuth();
        show();
        await userLogout();
        router.replace("/login");
      } catch (error) {
        showDanger({
          title: error.title,
          body: error.body
        });
      } finally {
        hide();
      }
    };

    return {
      navItems,
      isSelectedNavItem,
      t,
      signout
    };
  }
});
