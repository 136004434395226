
import { defineComponent } from "vue";
import ActionButton from "@/components/ActionButton.vue";
import ColumnChart from "@/components/charts/ColumnChart.vue";
import draggable from "vuedraggable";
import { SalesSummary } from "./reports/sales/SalesSummary.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          shortcuts: "Shortcuts",
          reports: "Important Reports"
        },
        ar: {
          shortcuts: "مختصراتي",
          reports: "التقارير المهمة"
        }
      }
    });

    return { t };
  },

  components: {
    ActionButton,
    draggable,
    ColumnChart
  },
  data() {
    return {
      now: new Date(),
      text: "",
      salesSummary: new SalesSummary(),
      list: [
        {
          title: "views.sales.newSale",
          route: this.$Route.SALES_NEW_SALE,
          id: 0,
          icon: "new-sale",
          iconPrefix: "fac",
          isSuccess: true
        },
        {
          title: "views.inventory.items.items",
          route: this.$Route.INVENTORY_ITEMS,
          id: 1,
          icon: "eye"
        },
        {
          title: "views.inventory.items.newSimpleItem",
          route: this.$Route.INVENTORY_ITEMS_NEW_SIMPLE_ITEM,
          id: 2,
          icon: "plus"
        },
        {
          title: "views.inventory.inventoryTransfers.receiveInventoryTransfer",
          route: this.$Route
            .INVENTORY_INVENTORY_TRANSFERS_RECEIVE_INVENTORY_TRANSFER,
          icon: "arrows-alt-h",
          id: 3
        },
        {
          title: "views.inventory.inventoryTransfers.newInventoryTransfer",
          route: this.$Route
            .INVENTORY_INVENTORY_TRANSFERS_NEW_INVENTORY_TRANSFER,
          icon: "arrows-alt-h",
          id: 6
        },
        {
          title: "views.hr.hr",
          route: this.$Route.HR,
          id: 4,
          icon: "address-book"
        },
        {
          title: "views.reports.reports",
          route: this.$Route.REPORTS,
          id: 5,
          icon: "flag"
        }
      ]
    };
  },

  async beforeMount() {
    // will be used when the backend is ready
    // const salesSummaryResp = await this.$http.get<SalesSummary>(
    //   getPath(SalesSummary.ENDPOINT, this.location, this.period)
    // );
    // this.salesSummary = salesSummaryResp;

    //here just generating mock data
    const mockResp = {
      summaryByNumberOfSales: [
        {
          storeName: "Al-Mansour",
          storeID: "123",
          totalSales: 1001,
          totalReturns: 50
        },
        {
          storeName: "Al-Karada",
          storeID: "567",
          totalSales: 5000,
          totalReturns: 100
        }
      ],
      summaryBySalesValue: [
        {
          storeName: "Al-Mansour",
          storeID: "123",
          currency: "IQD",
          precision: 0,
          totalSalesAmount: 5000,
          totalReturnsAmount: 2000,
          netSalesAmount: 3000
        },
        {
          storeName: "Al-Karada",
          storeID: "567",
          currency: "IQD",
          precision: 0,
          totalSalesAmount: 10000,
          totalReturnsAmount: 5000,
          netSalesAmount: 5000
        }
      ]
    };
    this.salesSummary = SalesSummary.from(mockResp);
  },
  mounted() {
    setInterval(() => (this.now = new Date()), 1000);
  }
});
