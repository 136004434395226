import { App } from "vue";
import Go from "./Go.vue";
import { goTo } from "./routing";

export * from "./navitems";
export * from "./tabs";
export * from "./types";
export { goTo } from "./routing";

/**
 * @deprecated This method should not be used
 */
export default {
  install: (app: App) => {
    app.config.globalProperties.$goTo = goTo;
    app.component("go", Go);
  }
};
