
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default defineComponent({
  name: "loading-modal",
  components: {
    LoadingSpinner
  },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.$globalEmitter.on("show-loading-modal", () => {
      this.show = true;
    });

    this.$globalEmitter.on("hide-loading-modal", () => {
      this.show = false;
    });
  }
});
