import Timestamps from "./Timestamps";

export default class UUIDBase extends Timestamps {
  id: string | undefined = undefined;
  externalID: string | undefined = undefined;

  constructor() {
    super();
    this.parseTime(this.createdAt, this.updatedAt, this.deletedAt);
  }
}
