<template>
  <el-config-provider :locale="locale">
    <div id="app-container">
      <loading-modal />
      <drawer />
      <template v-if="this.$route.fullPath === '/login'">
        <router-view />
      </template>

      <template v-else>
        <MainLayout />
      </template>
    </div>
  </el-config-provider>
</template>
<script lang="ts">
import { defineComponent, onBeforeMount, onMounted } from "vue";
import { logInfo } from "@/utils/log";
import { useAuth } from "@/plugins/auth";
import { useRouter } from "vue-router";

import arLocale from "element-plus/lib/locale/lang/ar";
import enLocale from "element-plus/lib/locale/lang/en";
import { ElConfigProvider } from "element-plus";
import MainLayout from "@/layouts/MainLayout.vue";
import { useContext } from "@/plugins/context";
import { grantPermission } from "@/plugins/access-control";

export default defineComponent({
  components: {
    MainLayout,
    ElConfigProvider
  },

  setup() {
    // load theme info
    logInfo("app started!");
    const { userLogout, resetAuthState, loggedIn } = useAuth();
    const router = useRouter();
    const ctx = useContext();

    const checkLoggedIn = () => {
      if (!loggedIn()) {
        resetAuthState();
        router.replace("/login");
      } else {
        // load permissions
        ctx.currentUser.userPermissions.forEach(up => {
          grantPermission(up.permission.action, up.permission.object);
        });
      }
    };

    const autoLogout = () => {
      let handler = 0;

      const resetTimer = () => {
        const hour = 60 * 60 * 1000;
        clearTimeout(handler);

        handler = window.setTimeout(async () => {
          userLogout().then(() => {
            router.replace("/login");
          });
        }, hour * 2);
      };

      window.onload = resetTimer;
      window.onmousemove = resetTimer;
      window.ontouchstart = resetTimer;
      window.onclick = resetTimer;
      window.onkeypress = resetTimer;
    };

    // const logoutOnExit = () => {
    //   window.onbeforeunload = async () => {
    //     await userLogout();
    //   };
    // };

    onBeforeMount(async () => {
      if (localStorage.getItem("dark")) {
        document.body.classList.add("dark");
      }
    });

    onMounted(async () => {
      checkLoggedIn();
      autoLogout();
      //logoutOnExit();
    });

    const currentLocale = localStorage.getItem("locale");
    const locale =
      currentLocale && currentLocale === "ar" ? arLocale : enLocale;

    return { locale };
  }
});
</script>
<style></style>
