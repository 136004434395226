
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    name: {
      type: String,
      default: "icon"
    },
    color: {
      type: String,
      default: "currentColor"
    }
  }
});
