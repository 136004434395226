/*
- a View has a title
- and a component that it displays. Here it's a string because
all the main views are registered globally
so in order to display them in a dynamic component, we just need the name
*/

/**
 * @deprecated This code should not be used
 */
export default class View {
  name: string;
  navIndex: number;
  displayTitle: string;
  component: string;
  parentName?: string;
  props?: { [prop: string]: unknown };
  guarded: boolean;

  constructor(
    name: string,
    navIndex: number,
    displayTitle: string,
    component: string,
    parentName?: string,
    props?: { [prop: string]: unknown },
    guarded = false
  ) {
    this.name = name;
    this.navIndex = navIndex;
    this.displayTitle = displayTitle;
    this.component = component;
    this.parentName = parentName;
    this.props = props;
    this.guarded = guarded;
  }

  setProps(props: { [prop: string]: unknown }) {
    this.props = props;
  }

  getProps() {
    return this.props;
  }

  equals(that: View) {
    return this.component === that.component;
  }

  isGuarded() {
    return this.guarded;
  }
}
