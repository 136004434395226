
import { defineComponent } from "vue";
import { formatDinero, formatMoney } from "@/utils/money";
import { useI18n } from "vue-i18n";
import { i18nOrderMessages, Order } from "@/models/sales/Order";

import HorizontalButton from "@/components/HorizontalButton.vue";

export default defineComponent({
  name: "order-info",
  setup() {
    const { t } = useI18n({
      useScope: "global",
      messages: {
        en: {
          ...i18nOrderMessages.en,
          print: "Print Receipt",
          makePayment: "Make a payment",
          paymentsMade: "Total paid amount",
          moneyOwed: "Money Owed"
        },
        ar: {
          ...i18nOrderMessages.ar,
          print: "اطبع الفاتورة",
          makePayment: "دفع تجاه البيع",
          paymentsMade: "المبلغ المدفوع",
          moneyOwed: "المبلغ المطلوب"
        }
      }
    });

    return { t };
  },
  props: {
    order: {
      type: Order,
      required: true
    },
    showPrintReceipt: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    HorizontalButton
  },

  methods: {
    formatDinero,
    formatMoney,
    handleMakePayment() {
      this.$router.push(
        this.$Route.SALES_ORDERS_ORDER_PAYMENT.replace(
          ":id",
          this.order.invoiceID as string
        )
      );
    }
  }
});
