
import { defineComponent } from "vue";

// components
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import AlertBadge from "@/components/AlertBadge.vue";

import { ElForm } from "element-plus";
import MoneyInput from "@/components/form/MoneyInput.vue";

// models
import {
  Transaction,
  transactionI18nMessages
} from "@/models/accounting/Transaction";
import { Account } from "@/models/accounting/Account";
import { useI18n } from "vue-i18n";
import InputGroup from "@/components/form/InputGroup.vue";
import User from "@/models/user/User";

export default defineComponent({
  name: "transaction-form",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...transactionI18nMessages.en,
          section: "Transaction Info",
          descriptionPlaceholder: "Example: paid utility bill",
          accountsMustNotMatch:
            "Credited Account must not equal Debited Account"
        },
        ar: {
          ...transactionI18nMessages.ar,
          section: "معلومات الحركة المالية",
          descriptionPlaceholder: "مثلا: قمنا بدفع فاتورة الكهرباء",
          accountsMustNotMatch: "لايمكن لحساب الدائن ان يطابق حساب المدين"
        }
      },
      unescape: "global"
    });

    return { t };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection,
    MoneyInput,
    InputGroup
  },

  data() {
    const accounts = new Array<Account>();

    return {
      transaction: new Transaction(),
      error: { title: "", body: "" },
      formattedAmount: "",
      accounts,
      user: this.$ctx.currentUser,
      rules: {
        journalType: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        amountAmount: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          },
          {
            type: "number",
            min: 0
          }
        ],
        debitedAccountID: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        creditedAccountID: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    async searchAccounts(query: string) {
      try {
        const url = `${Account.ENDPOINT}?q=${query}`;
        const accounts = await this.$http.get<Account[]>(url);
        this.accounts = accounts;
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        this.accounts = [];
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
    },

    async submit(t: Transaction) {
      this.error.title = "";
      this.error.body = "";

      t.preparePayload(
        this.$ctx.currentLocation,
        this.$ctx.currentRegister,
        this.user as User
      );

      try {
        await this.$http.post(Transaction.ENDPOINT, t);
      } catch (error) {
        return error;
      }
    },

    validPostings() {
      if (
        this.transaction.creditedAccountID === this.transaction.debitedAccountID
      ) {
        this.error.title = this.$t("validation.inputErrors");
        this.error.body = this.t("accountsMustNotMatch");
        document.getElementById("main")?.scrollTo({ top: 0 });
        return false;
      }

      return true;
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid || !this.validPostings()) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submit(this.transaction as Transaction);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          this.clearForm(formName);

          this.$alertModal.showSuccess({
            title: this.t("successMsg"),
            body: ""
          });
        }
        return true;
      });
    }
  }
});
