
import { defineComponent } from "vue";
import InventoryAdjustment, {
  inventoryAdjustmentI18nMessages
} from "@/models/inventory/InventoryAdjustment";
import { ElForm } from "element-plus";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import TagInput from "@/components/form/TagInput.vue";
import Item from "@/models/inventory/Item";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "new-inventory-receiving",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryAdjustmentI18nMessages.en,

          sections: {
            info: "Meta data",
            inventory: "Inventory Info"
          },

          placeholders: {
            reason: "Example: missing a couple of items",
            itemName: "Enter barcode, sku, or item name"
          },

          userAdjusting: "Employee responsible for this inventory adjustment",
          insertSerials: "You must insert {num} new serial numbers",
          removeSerials: "You must remove {num} old serial numbers",

          successMsg: "Inventory was adjusted successfully"
        },
        ar: {
          ...inventoryAdjustmentI18nMessages.ar,

          sections: {
            info: "البيانات الوصفية",
            inventory: "البضاعة المعدلة"
          },

          placeholders: {
            reason: "مثلا: بعض البضاعة مفقودة",
            itemName: "ادخل اسم المنتج او الباركود او SKU"
          },

          userAdjusting: "الموظف المسؤول عن تعديل البضاعة",
          insertSerials: "قم بادخال {num} serial numbers",
          removeSerials: "قم بمسح {num} serial numbers",

          successMsg: "تم تعديل البضاعة بنجاح"
        }
      }
    });

    return { t };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    TagInput,
    InputSection
  },
  data() {
    return {
      inventoryAdjustment: new InventoryAdjustment(),
      itermSearchTerm: "",
      error: { title: "", body: "" },
      serials: [],
      pressedEnter: false,
      user: this.$ctx.currentUser,
      rules: {
        reason: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    async searchItems(query: string, cb: Function) {
      // if an item already exists in the table, don't do remote search
      const cachedItem =
        this.inventoryAdjustment.findByBarcode(query) ||
        this.inventoryAdjustment.findBySKU(query);
      if (cachedItem) {
        this.addItem(cachedItem);
        cb([]);
        return;
      }

      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        const items = await this.$http.get<Item[]>(url);

        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && items.length === 1) {
          this.addItem(items[0]);
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }

      this.pressedEnter = false;
    },

    addItem(selected: Item) {
      this.inventoryAdjustment.addItem(selected);
      this.itermSearchTerm = "";
    },

    removeItem(index: number) {
      this.inventoryAdjustment.removeItem(index);
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
      this.inventoryAdjustment = new InventoryAdjustment();
    },

    async submit(ir: InventoryAdjustment) {
      this.error.title = "";
      this.error.body = "";

      try {
        await this.$http.post(InventoryAdjustment.ENDPOINT, ir);
        await this.$router.push(this.$Route.INVENTORY_INVENTORY_ADJUSTMENTS);
      } catch (error) {
        return error;
      }
    },

    checkForm() {
      const errors = [];
      for (const iai of this.inventoryAdjustment.inventoryAdjustmentItems) {
        errors.push(iai.validateSerials());
        errors.push(iai.validateQuantity());
      }

      this.error.body = errors.filter(err => err).join("\n");

      if (this.error.body) {
        this.error.title = this.$t("validation.inputErrors");
        document.getElementById("main")?.scrollTo({ top: 0 });
        return false;
      }

      return true;
    },

    async validateSubmit(formName: string) {
      this.inventoryAdjustment.preparePayloadData();
      const form = this.$refs[formName] as typeof ElForm;

      form?.validate(async (valid: boolean) => {
        if (!valid || !this.checkForm()) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submit(
          this.inventoryAdjustment as InventoryAdjustment
        );

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          this.clearForm(formName);
          this.$alertModal.showSuccess({ title: this.t("successMsg") });
        }
        return true;
      });
    }
  }
});
