import { logError } from "@/utils/log";
import Dinero, { Currency, Dinero as DineroType } from "dinero.js";

// parseMoney takes a number as string, strips unsupported characters, and parses it into int
export function parseMoney(val: string): number {
  if (!val) {
    return 0;
  }
  // get rid of all non-digit
  const r = val.replace(/[^(\d)]+/g, "");
  // IQD is an int, USD and others are floating numbers
  // parseInt is used to get rid of user error and rounding issues
  const v = parseInt(r);
  return isNaN(v) ? 0 : v;
}

export function formatMoney(num: number, currency: string) {
  if (isNaN(num) || !isFinite(num)) {
    // DO NOT return "IQD NaN"! It's better to return 0!
    logError(`formatMoney: unexpected num value ${num}`);
    num = 0;
  }
  // format number
  const formatted = new Intl.NumberFormat("us-EN", {
    style: "currency",
    currency: currency
  }).format(num);

  // get rid of the decimal points since they're useless in IQD
  if (currency === "IQD") {
    return (
      formatted
        // replace non-breaking space with a regular space
        .replace(String.fromCharCode(160), String.fromCharCode(32))
        .split(".")[0]
    );
  }

  return formatted.trim();
}

export function formatDinero(dinero?: DineroType) {
  if (!dinero) {
    return "";
  }

  if (dinero.getCurrency() === "IQD") {
    return dinero.toFormat("$0,0");
  }

  return dinero.toFormat("$0,0.00");
}

export function sumDineros(
  dineros: DineroType[],
  currency: string,
  precision: number
): DineroType {
  let total = Dinero({
    amount: 0,
    currency: currency as Currency,
    precision
  });
  if (!dineros.length) return total;

  for (const d of dineros) {
    total = total.add(d);
  }

  return total;
}

export function maxDinero(d: DineroType, max: number) {
  if (d.getAmount() >= max) return d;

  return Dinero({
    amount: max,
    currency: d.getCurrency(),
    precision: d.getPrecision()
  });
}
