
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import Location from "@/models/company/Location";
import { isElectron } from "@/utils/env";

export default defineComponent({
  setup() {
    const selectedPrinter = ref("");
    const { locale, t } = useI18n({
      messages: {
        en: {
          switchLang: "Switch language",
          switchLocation: "Switch Location",
          switchPrinter: "Switch Printer",
          confirmMsg:
            "Are you sure? This will reload the app and close all open tabs",
          USDToIQD: "Set USD To IQD Rate",
          IQDToUSD: "Set IQD to USD Rate"
        },

        ar: {
          switchLang: "اختر اللغة",
          switchLocation: "غير الموقع",
          switchPrinter: "اختر طابعة",
          confirmMsg:
            "يرجى التأكيد. ستقوم هذه العملية بغلق جميع النوافذ واعادة تحميل النظام",
          USDToIQD: "سعر الصرف من الدولار الى الدينار",
          IQDToUSD: "سعر الصرف من الدينار الى الدولار"
        }
      }
    });

    const currentLocale = ref(locale.value);
    const locations = [
      {
        id: "7929d71b-833f-447b-8042-d3cf137612e9",
        name: "Al-Mansour Store",
        type: "store"
      },
      {
        id: "87f44a21-0b6d-4150-b82f-af458d2c2db1",
        name: "Al-Zayona Store",
        type: "store"
      },
      {
        id: "fe462e64-d8ba-43ef-9507-99ec3c2da5e4",
        name: "Karada Warehouse",
        type: "warehouse"
      }
    ].map(loc => Location.from(loc));

    return {
      t,
      currentLocale,
      locations,
      selectedPrinter
    };
  },

  async beforeMount() {
    if (isElectron()) {
      try {
        const { ipcRenderer } = await import("electron");
        ipcRenderer.on("printers-list", (_e, args) => {
          this.printers = args;
        });
        ipcRenderer.send("get-printers");
      } catch (error) {
        // do nothing
      }
    }
  },

  data() {
    const tabsPosition = this.$ctx.locale === "en" ? "left" : "right";
    return {
      tabsPosition,
      currentLocation: this.$ctx.currentLocation,
      currentPrinter: this.$ctx.currentPrinter,
      printers: [],
      USDtoIQDRate: this.$ctx.exchangeRates.IQD,
      IQDtoUSDRate: this.$ctx.exchangeRates.USD
    };
  },

  watch: {
    currentLocale() {
      if (confirm(this.t("confirmMsg"))) {
        this.$ctx.setLocale(this.currentLocale);
      }
    },

    currentLocation() {
      this.$ctx.setLocation(this.currentLocation as Location);
    },

    currentPrinter() {
      this.$ctx.setPrinter(this.currentPrinter);
    },

    USDtoIQDRate() {
      this.$ctx.setExchangeRates({
        IQD: this.USDtoIQDRate,
        USD: this.IQDtoUSDRate
      });
    },

    IQDtoUSDRate() {
      this.$ctx.setExchangeRates({
        IQD: this.USDtoIQDRate,
        USD: this.IQDtoUSDRate
      });
    }
  }
});
