<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />

  <receipt
    v-else
    :operationTitle="$t('views.sales.orders.returnOrder')"
    :externalID="orderChange.externalID"
    :date="orderChange.createdAt"
    :userFullName="orderChange?.user?.fullName"
    :customerFullName="order?.customer?.contactDisplayName"
    :locationName="
      $ctx.locale === 'en'
        ? orderChange?.location?.enName
        : orderChange?.location?.arName
    "
  >
    <template v-slot:extraActions>
      <action-button
        :title="t('newSale')"
        faIcon="new-sale"
        faPrefix="fac"
        isSuccess
        @click="$router.push($Route.SALES_NEW_SALE)"
      >
      </action-button>
    </template>

    <template v-slot:receiptContent>
      <table id="receipt-table" class="table-auto">
        <thead>
          <tr class="text-sm receipt-row">
            <th class="reciept-cell">{{ t("itemName") }}</th>
            <th class="reciept-cell">{{ t("quantity") }}</th>
            <th class="reciept-cell">{{ t("price") }}</th>
            <th class="reciept-cell">{{ t("itemTotal") }}</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(ri, i) in returnItems" :key="i">
            <tr class="text-sm reciept-row">
              <td class="reciept-cell">
                {{ truncateItemName(ri.itemName) }}
              </td>

              <td class="reciept-cell">{{ ri.quantityReturned }}</td>
              <td class="reciept-cell">
                {{ formatDinero(ri.itemPrice) }}
              </td>
              <td class="reciept-cell">{{ formatDinero(ri.itemTotal) }}</td>
            </tr>
          </template>
        </tbody>
      </table>

      <template v-if="!order.empty()">
        <div class="flex justify-between w-5/6 mt-2">
          <p>{{ t("initialToReturnAmount") }}</p>
          <p>
            {{ formatDinero(orderChange.initialChangeDinero) }}
          </p>
        </div>

        <div class="flex justify-between w-5/6 ">
          <p>{{ t("previouslyPaidAmount") }}</p>
          <p>
            {{ formatDinero(orderChange.previouslyPaidDinero) }}
          </p>
        </div>

        <div class="w-full p-2">
          <hr class="solid" />
        </div>

        <div class="flex justify-between w-5/6 ">
          <p>{{ t("finalToReturnAmount") }}</p>
          <p>
            {{ formatDinero(orderChange.totalChangeDinero) }}
          </p>
        </div>
      </template>
    </template>
  </receipt>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { i18nOrderMessages, Order, OrderChange } from "@/models/sales/Order";
import { parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero } from "@/utils/money";

import { Dinero } from "dinero.js";

import Receipt from "@/components/Receipt.vue";
import ActionButton from "@/components/ActionButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";

type ReturnOrderItem = {
  itemName: string;
  quantityReturned: number;
  itemPrice: Dinero;
  itemTotal: Dinero;
};

export default defineComponent({
  components: {
    Receipt,
    ActionButton,
    LoadingSpinner,
    AlertBadge
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          totalDue: "Total Due",
          totalPaid: "Total paid",
          changeDue: "Change due",
          totalKept: "Total Received",
          serialNumbers: "Serial Numbers",
          print: "Print Receipt",
          newSale: "New Sale"
        },
        ar: {
          ...i18nOrderMessages.ar,
          totalDue: "المبلغ المطلوب",
          totalPaid: "المبلغ المدفوع",
          changeDue: "الباقي",
          totalKept: "الصافي",
          serialNumbers: "Serial Numbers",
          print: "اطبع الفاتورة",
          newSale: "بيع جديد"
        }
      }
    });

    return { t };
  },

  data() {
    return {
      order: new Order(),
      orderChange: new OrderChange("return"),
      returnItems: new Array<ReturnOrderItem>(),
      loading: true,
      error: {
        title: "",
        body: ""
      }
    };
  },
  async beforeMount() {
    // get order
    try {
      const resp = await this.$http.get<Order>(
        `${Order.ENDPOINT}/${this.$route.params.orderID}`
      );
      this.order = Order.from(resp);

      const returnData = this.order.orderChanges.find(
        oc => oc.id === this.$route.params.id
      );

      if (returnData) {
        this.orderChange = returnData;
        // inverse lookup
        this.order.orderItems.forEach(oi => {
          const returned = returnData.orderChangeItems[oi.id || ""];
          if (returned) {
            this.returnItems.push({
              itemName: oi.item.name,
              quantityReturned: returned.quantity,
              itemPrice: oi.soldAtPriceDinero,
              itemTotal: oi.soldAtPriceDinero.multiply(returned.quantity)
            });
          }
        });
      } else {
        // show error
      }
    } catch (error) {
      this.error.title = error.title;
      this.error.body = error.body;
    } finally {
      this.loading = false;
    }
  },

  methods: {
    parseFormat,
    formatDinero,
    truncateItemName(itemName: string) {
      const maxLength = Math.min(itemName.length, 40);
      return maxLength >= 40
        ? `${itemName.substring(0, maxLength)}...`
        : itemName;
    }
  }
});
</script>
