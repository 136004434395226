<template>
  <div v-if="loading" class="flex h-full justify-center items-center">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />

  <div v-else class="flex flex-col items-center">
    <div class="w-full md:w-8/12 mt-8">
      <el-table
        stripe
        :data="[
          { label: $t('models.common.id'), value: transaction.externalID },
          {
            label: $t('models.common.createdAt'),
            value: parseFormat(transaction.createdAt)
          },
          { label: t('amount'), value: formatDinero(transaction.amountDinero) },
          { label: t('journalType'), value: t(transaction.journalType) },
          { label: t('user'), value: transaction.user.fullName },
          {
            label: t('location'),
            value:
              $ctx.locale === 'en'
                ? transaction.location.enName
                : transaction.location.arName
          },
          { label: t('description'), value: transaction.description }
        ]"
      >
        <el-table-column
          prop="label"
          :label="$t('models.common.label')"
        ></el-table-column>
        <el-table-column
          prop="value"
          :label="$t('models.common.value')"
        ></el-table-column>
      </el-table>

      <el-divider content-position="center">{{
        t("associatedAccounts")
      }}</el-divider>

      <el-table
        :data="postings"
        style="width: 100%"
        :row-class-name="colorRows"
        max-height="700"
      >
        <el-table-column prop="account" :label="t('account')">
          <template #default="scope">
            <el-link
              @click.prevent="
                $router.push(
                  $Route.ACCOUNTING_ACCOUNTS_ACCOUNT.replace(
                    ':id',
                    scope.row.account.id
                  )
                )
              "
            >
              {{
                this.$ctx.locale === "ar"
                  ? scope.row.account.arName
                  : scope.row.account.enName
              }}
            </el-link>
          </template>
        </el-table-column>

        <el-table-column prop="type" :label="t('postingType')" align="center">
          <template #default="scope">
            <i v-if="scope.row.type === 'credit'" class="el-icon-remove"></i>
            <i v-else class="el-icon-circle-plus"></i>
            <span class="mx-2">{{ t(scope.row.type) }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="amountDinero"
          :label="t('amount')"
          align="center"
        >
          <template #default="scope" dir="ltr">
            {{ formatDinero(scope.row.amountDinero) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref } from "vue";

import { useHTTP } from "@/plugins/http";
import { formatDinero } from "@/utils/money";
import { parseFormat } from "@/utils/date";

import {
  Transaction,
  transactionI18nMessages
} from "@/models/accounting/Transaction";

// components
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { Account } from "@/models/accounting/Account";
import { Dinero } from "dinero.js";

interface Posting {
  account: Account;
  type: string;
  amountDinero: Dinero;
}

export default defineComponent({
  name: "transaction",
  components: {
    LoadingSpinner,
    AlertBadge
  },
  setup() {
    const transaction = ref(new Transaction());
    const http = useHTTP();
    const loading = ref(true);
    const error = ref({ title: "", body: "" });
    const route = useRoute();
    const postings = ref(new Array<Posting>());

    const { t } = useI18n({
      messages: transactionI18nMessages,
      unescape: "global"
    });

    onBeforeMount(async () => {
      const url = `${Transaction.ENDPOINT}/${route.params.id}`;

      try {
        const resp = await http.get<Transaction>(url);
        transaction.value = Transaction.from(resp);

        postings.value = [
          {
            account: transaction.value.creditedAccount,
            type: "credit",
            amountDinero: transaction.value.amountDinero
          },
          {
            account: transaction.value.debitedAccount,
            type: "debit",
            amountDinero: transaction.value.amountDinero
          }
        ];
      } catch (errorResp) {
        error.value.title = errorResp.title;
        error.value.body = errorResp.body;
      }

      loading.value = false;
    });

    return {
      transaction,
      loading,
      error,
      t,
      postings
    };
  },
  methods: {
    parseFormat,
    formatDinero,
    colorRows(params: { row: Posting }) {
      if (params.row.type === "credit") {
        return "bg-danger";
      }

      return "bg-success";
    }
  }
});
</script>
