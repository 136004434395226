
import { defineComponent, onBeforeMount, ref } from "vue";

import { useHTTP } from "@/plugins/http";

import Category from "@/models/inventory/Category";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import CategoryForm from "@/views/inventory/categories/CategoryForm.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "category",
  components: {
    AlertBadge,
    LoadingSpinner,
    CategoryForm
  },
  setup() {
    const initCategory = ref(new Category());
    const http = useHTTP();
    const loading = ref(true);
    const error = ref({ title: "", body: "" });
    const route = useRoute();

    onBeforeMount(async () => {
      const url = `${Category.ENDPOINT}/${route.params.id}`;

      try {
        const resp = await http.get<Category>(url);
        initCategory.value = Category.from(resp);
      } catch (errorResp) {
        error.value.title = errorResp.title;
        error.value.body = errorResp.body;
      }

      loading.value = false;
    });

    const update = async (category: Category) => {
      try {
        const url = `${Category.ENDPOINT}/${category.id}`;
        const resp = await http.put<Category>(url, category);
        initCategory.value = Category.from(resp);
      } catch (error) {
        return error;
      }
    };

    return {
      initCategory,
      error,
      loading,
      update
    };
  }
});
