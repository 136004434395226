<template>
  <el-form
    @submit.prevent="validateSubmit('vendorForm')"
    ref="vendorForm"
    :model="vendor"
    :rules="rules"
    label-position="top"
    novalidate
  >
    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('vendorForm')"
          />
        </div>

        <div class="md:w-4/12 m-2" v-if="enableClearForm">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('vendorForm')"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <input-section>
      <h1>{{ t("sections.vendor") }}</h1>

      <el-form-item :label="t('name')" prop="name" class="input-label">
        <el-input
          id="name"
          v-model="vendor.name"
          :placeholder="t('placeholders.name')"
          maxlength="255"
          show-word-limit
          clearable
          required
        >
        </el-input>
      </el-form-item>
    </input-section>

    <input-section>
      <h1>{{ t("sections.contact") }}</h1>

      <input-group>
        <el-form-item
          :label="t('contactFirstName')"
          prop="contactFirstName"
          class="input-label"
        >
          <el-input
            id="contactFirstName"
            v-model="vendor.contactFirstName"
            :placeholder="t('placeholders.contactFirstName')"
            maxlength="127"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>

        <el-form-item
          :label="t('contactLastName')"
          prop="contactLastName"
          class="input-label"
        >
          <el-input
            id="contactLastName"
            v-model="vendor.contactLastName"
            :placeholder="t('placeholders.contactLastName')"
            maxlength="127"
            show-word-limit
            clearable
            required
          >
          </el-input>
        </el-form-item>
      </input-group>

      <input-group>
        <el-form-item :label="t('phone')" prop="phone" class="input-label">
          <el-input
            id="phone"
            v-model="vendor.phone"
            :placeholder="t('placeholders.phone')"
            maxlength="15"
            dir="ltr"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('email')" prop="email" class="input-label">
          <el-input
            id="email"
            v-model="vendor.email"
            type="email"
            :placeholder="t('placeholders.email')"
            dir="ltr"
            maxlength="255"
          >
          </el-input>
        </el-form-item>
      </input-group>
    </input-section>

    <input-section>
      <h1>{{ t("sections.address") }}</h1>
      <el-form-item
        :label="t('streetAddress')"
        prop="streetAddress"
        class="input-label"
      >
        <el-input
          id="streetAddress"
          v-model="vendor.streetAddress"
          :placeholder="t('placeholders.streetAddress')"
          show-word-limit
          maxlength="255"
          clearable
        >
        </el-input>
      </el-form-item>

      <input-group>
        <el-form-item :label="t('city')" prop="city" class="input-label">
          <el-input
            id="city"
            :placeholder="t('placeholders.city')"
            maxlength="70"
            show-word-limit
            clearable
            v-model="vendor.city"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          :label="t('postalCode')"
          prop="postalCode"
          class="input-label"
        >
          <el-input
            id="postalCode"
            :placeholder="t('placeholders.postalCode')"
            show-word-limit
            maxlength="10"
            clearable
            v-model="vendor.postalCode"
          >
          </el-input>
        </el-form-item>

        <el-form-item :label="t('country')" prop="country" class="input-label">
          <el-input
            id="country"
            :placeholder="t('placeholders.country')"
            maxlength="70"
            show-word-limit
            clearable
            v-model="vendor.country"
          >
          </el-input>
        </el-form-item>
      </input-group>
    </input-section>
  </el-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import InputGroup from "@/components/form/InputGroup.vue";
import { ElForm } from "element-plus";

// models
import Vendor, { vendorI18nMessages } from "@/models/vendor/Vendor";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "vendor-form",

  setup() {
    const { t } = useI18n({
      useScope: "global",
      messages: {
        en: {
          ...vendorI18nMessages.en,

          sections: {
            vendor: "Vendor Info",
            contact: "Contcat Info",
            address: "Address"
          },

          placeholders: {
            name: "Example: Hamaza Ltd",
            contactFirstName: "Example: Ahmed",
            contactLastName: "Example: Al-Joubouri",
            phone: "Example: 077011111111",
            email: "Example: info[at]hamza.com",
            streetAddress: "Example: Next to Almansour Mall",
            city: "Example: Baghdad",
            postalCode: "Example: 10015",
            country: "Example: Iraq"
          }
        },
        ar: {
          ...vendorI18nMessages.ar,

          sections: {
            vendor: "عن الموزع",
            contact: "معلومات الاتصال",
            address: "العنوان"
          },

          placeholders: {
            name: "مثلا: شركة الحمزة للمنتجات الغذائية",
            contactFirstName: "مثلا: احمد",
            contactLastName: "مثلا: الجبوري",
            phone: "مثلا: 077011111111",
            email: `مثلا: info[at]hamaza.com`,
            streetAddress: "مثلا: مجاور لمول المنصور",
            city: "مثلا: بغداد",
            postalCode: "مثلا: 10015",
            country: "مثلا: العراق"
          }
        }
      }
    });

    const rules = {
      name: [
        { required: true, message: t("validation.required") },
        { max: 255, message: t("validation.maxLength", { len: 255 }) }
      ],
      contactFirstName: [
        { max: 127, message: t("validation.maxLength", { len: 127 }) }
      ],
      contactLastName: [
        { max: 127, message: t("validation.maxLength", { len: 127 }) }
      ],
      phone: [{ max: 15, message: t("validation.maxLength", { len: 15 }) }],
      email: [{ max: 255, message: t("validation.maxLength", { len: 255 }) }],
      streetAddress: [
        { max: 255, message: t("validation.maxLength", { len: 255 }) }
      ],
      city: [{ max: 70, message: t("validation.maxLength", { len: 70 }) }],
      postalCode: [
        { max: 10, message: t("validation.maxLength", { len: 10 }) }
      ],
      country: [{ max: 70, message: t("validation.maxLength", { len: 70 }) }]
    };

    return { t, rules };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection,
    InputGroup
  },
  props: {
    initVendor: {
      type: Vendor,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      vendor: this.initVendor,
      error: { title: "", body: "" }
    };
  },

  methods: {
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.vendor);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    }
  }
});
</script>

<style lang="postcss" scoped>
form > h1 {
  @apply text-lg py-3 font-bold;
}

form > h2 {
  @apply py-3 font-bold;
}

formt > h3 {
  @apply py-3 font-bold;
}

form {
  @apply flex flex-col items-center;
}
</style>
