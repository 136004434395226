import User from "../user/User";
import UUIDBase from "../UUIDBase";
import Location from "./Location";
import { Machine } from "./Machine";
import Dinero, { Currency, Dinero as DineroType } from "dinero.js";

export interface OpenRegisterPayload {
  registerID?: string;
  userID?: string;
  timestamp?: Date;
  action?: RegisterAction["action"];
  actualOpenAmount?: number;
  currency?: string;
  precision?: number;
}

export interface CloseRegisterPayload {
  registerID?: string;
  userID?: string;
  timestamp?: Date;
  action?: RegisterAction["action"];
  actualClosingDepositAmount?: number;
  actualClosingFloatingAmount?: number;
  currency?: string;
  precision?: number;
}

export class RegisterLog extends UUIDBase {
  user = new User();

  timestamp?: Date;
  action = "";
  payload?: OpenRegisterPayload | CloseRegisterPayload;

  notes = "";

  static from(json: Record<string, unknown> | RegisterLog) {
    const o = Object.assign(new RegisterLog(), json);
    o.user = User.from(o.user);
    return o;
  }
}

export class RegisterTransaction extends UUIDBase {
  user = new User();

  totalCashAmountAmount?: number;
  totalCashAmountCurrency?: string;
  totalCashAmountPrecision?: number;
  totalCashAmountDinero?: DineroType;

  addMoneyInAmount?: number;
  addMoneyInCurrency?: string;
  addMoneyInPrecision?: number;
  addMoneyInDinero?: DineroType;

  takeMoneyOutAmount?: number;
  takeMoneyOutCurrency?: string;
  takeMoneyOutPrecision?: number;
  takeMoneyOutDinero?: DineroType;

  dropMoneyAmount?: number;
  dropMoneyCurrency?: string;
  dropMoneyPrecision?: number;
  dropMoneyDinero?: DineroType;

  static from(json: Record<string, unknown> | RegisterLog) {
    const o = Object.assign(new RegisterLog(), json);
    o.user = User.from(o.user);
    return o;
  }
}

export class RegisterAction extends UUIDBase {
  // Register operation was done by user
  userID?: string;
  User?: User;

  timestamp?: Date;

  // Action is one of open | close | add | drop | withdraw
  action?: "open" | "close";

  currency = "";
  precision = 0;

  expectedOpenAmount?: number;
  actualOpenAmount?: number;

  expectedDepositAmount?: number;
  expectedClosingFloatingAmount?: number;
  ActualClosingDepositAmount?: number;
  ActualClosingFloatingAmount?: number;
  CloseRegisterTransactionID?: string;
}
export class RegisterStatus extends UUIDBase {
  static from(json: Record<string, unknown> | RegisterStatus): RegisterStatus {
    const o = Object.assign(new RegisterStatus(), json);
    o.openingFloatingDinero = Dinero({
      amount: o.openingFloatingAmount,
      precision: o.precision,
      currency: o.currency
    });

    o.closingFloatingDinero = Dinero({
      amount: o.closingFloatingAmount,
      precision: o.precision,
      currency: o.currency
    });

    o.totalCashOutDinero = Dinero({
      amount: o.totalCashOutAmount,
      precision: o.precision,
      currency: o.currency
    });

    o.totalCashInDinero = Dinero({
      amount: o.totalCashInAmount,
      precision: o.precision,
      currency: o.currency
    });

    o.balanceDinero = Dinero({
      amount: o.balanceAmount,
      precision: o.precision,
      currency: o.currency
    });

    o.moneyDroppedDinero = Dinero({
      amount: o.moneyDroppedAmount,
      precision: o.precision,
      currency: o.currency
    });

    return o;
  }

  no = 1;
  status?: "open" | "close";
  currency = "" as Currency;
  precision = 0;
  openingFloatingAmount?: number;
  openingFloatingDinero?: DineroType;

  closingFloatingAmount?: number;
  closingFloatingDinero?: DineroType;

  totalCashInAmount?: number;
  totalCashInDinero?: DineroType;

  totalCashOutAmount?: number;
  totalCashOutDinero?: DineroType;

  balanceAmount?: number;
  balanceDinero?: DineroType;

  moneyDroppedAmount?: number;
  moneyDroppedDinero?: DineroType;
}
export class Register extends UUIDBase {
  static ENDPOINT = "/company/registers";

  arName = "";
  enName = "";

  location = new Location();
  machine = new Machine();

  defaultOpenAmountAmount = 0;
  defaultOpenAmountCurrency = "";
  defaultOpenAmountPrecision = "";

  registerLogs?: Array<RegisterLog>;

  // one of open or close
  status?: string;

  static from(json: Record<string, unknown> | Register): Register {
    const o = Object.assign(new Register(), json);
    o.location = Location.from(o.location);
    o.machine = Machine.from(o.machine);
    return o;
  }
}
