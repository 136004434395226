<template>
  <div v-if="loading" class="flex items-center justify-center h-full">
    <loading-spinner diameter="64px" />
  </div>

  <alert-badge
    v-else-if="error.title"
    isDanger
    :title="error.title"
    :body="error.body"
    @dismissed="
      () => {
        error.title = '';
        error.body = '';
      }
    "
  />

  <payment-receipt v-else :order="order" :isOrderPayment="true" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { i18nOrderMessages, Order } from "@/models/sales/Order";
import { parseFormat } from "@/utils/date";
import { useI18n } from "vue-i18n";
import { formatDinero, formatMoney } from "@/utils/money";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import PaymentReceipt from "@/views/sales/PaymentReceipt.vue";

export default defineComponent({
  components: {
    PaymentReceipt,
    LoadingSpinner,
    AlertBadge
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...i18nOrderMessages.en,
          totalDue: "Total Due",
          totalPaid: "Total Paid",
          changeDue: "Change Due",
          totalKept: "Total Received",
          serialNumbers: "Serial Numbers",
          print: "Print Receipt",
          newSale: "New Sale",
          totalPaidBeforeChange: "Total Paid Before Change",
          orderPayments: "Order Payments"
        },
        ar: {
          ...i18nOrderMessages.ar,
          totalDue: "المبلغ المطلوب",
          totalPaid: "المبلغ المدفوع",
          changeDue: "الباقي",
          totalKept: "الصافي",
          serialNumbers: "الارقام التسلسلية",
          print: "اطبع الفاتورة",
          newSale: "بيع جديد",
          totalPaidBeforeChange: "المبلغ المدفوع قبل الباقي",
          orderPayments: "المدفوعات"
        }
      }
    });

    return { t };
  },
  data() {
    return {
      order: new Order(),
      loading: true,
      error: {
        title: "",
        body: ""
      }
    };
  },
  async beforeMount() {
    // get order
    try {
      const resp = await this.$http.get<Order>(
        `${Order.ENDPOINT}/${this.$route.params.id}`
      );
      this.order = Order.from(resp);
    } catch (error) {
      this.error.title = error.title;
      this.error.body = error.body;
    } finally {
      this.loading = false;
    }
  },

  props: {
    isOrderPayment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    parseFormat,
    formatDinero,
    formatMoney,
    truncateItemName(itemName: string) {
      const maxLength = Math.min(itemName.length, 40);
      return maxLength >= 40
        ? `${itemName.substring(0, maxLength)}...`
        : itemName;
    },
    print() {
      window.print();
    }
  }
});
</script>
