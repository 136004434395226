<template>
  <el-form
    @submit.prevent="validateSubmit"
    novalidate
    :model="inventoryTransfer"
    ref="inventoryTransferForm"
    :rules="rules"
  >
    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('inventoryTransferForm')"
          />
        </div>

        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('inventoryTransferForm')"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <input-section class="my-2 p-2">
      <h1>{{ t("sections.info") }}</h1>
      <h2>{{ t("userTransfering") }}: {{ user.fullName }}</h2>

      <el-form-item
        :label="t('transferOutID')"
        class="input-label"
        prop="transferOutID"
      >
        <el-input
          id="transferOutID"
          :placeholder="t('placeholders.transferOutID')"
          clearable
          @input="verifyTransferOut"
          required
        >
        </el-input>
      </el-form-item>
    </input-section>

    <template v-if="transferOutVerfied">
      <input-section>
        <h1>{{ t("sections.inventory") }}</h1>
        <el-form-item :label="t('itemName')" class="input-label">
          <el-autocomplete
            id="item"
            v-model="itermSearchTerm"
            :fetch-suggestions="searchItems"
            value-key="name"
            :trigger-on-focus="false"
            class="w-full"
            @select="addItem"
            @keypress.enter.prevent="pressedEnter = true"
            highlight-first-item
          >
          </el-autocomplete>
        </el-form-item>
      </input-section>
      <div class="w-10/12 mt-8" v-if="!inventoryTransfer.empty()">
        <el-table
          :data="inventoryTransfer.inventoryTransferItems"
          style="width: 100%"
          stripe
        >
          <el-table-column prop="item.name" :label="t('itemName')">
          </el-table-column>
          <el-table-column
            :label="t('currentQuantity')"
            width="200"
            align="center"
          >
            <template #default="props">
              <div class="el-input el-input--small">
                {{ props.row.item.itemStocks[0].quantity + props.row.quantity }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="receivedQuantity"
            :label="t('receivedQuantity')"
            width="200"
            align="center"
          >
            <template #default="props">
              <el-input-number
                v-model="props.row.quantity"
                :step="1"
                size="small"
                :min="0"
                controls-position="right"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            prop="inventoryTransferItemSerials"
            :label="t('serialNumbers')"
            width="300"
          >
            <template #default="props">
              <template v-if="props.row.item.isSerialized">
                <!-- select serials to transfer -->
                <label class="input-label">
                  <tag-input
                    :title="
                      t('insertSerials', {
                        num: props.row.receivedQuantity
                      })
                    "
                    v-model="props.row.inventoryTransferItemSerials"
                    :insertFunction="val => props.row.insertSerialNumber(val)"
                    :removeFunction="
                      index => props.row.removeSerialNumber(index)
                    "
                    :valueFormatter="
                      serialObject => serialObject.itemSerial.number
                    "
                    :validate="() => props.row.validateSerials()"
                  />
                </label>
              </template>
            </template>
          </el-table-column>
          <el-table-column width="50" align="center">
            <template #default="scope">
              <span
                class="text-red-600 cursor-pointer"
                @click="removeItem(scope.$index)"
              >
                <font-awesome-icon icon="trash" />
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
  </el-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InventoryTransfer, {
  inventoryTransferI18nMessages
} from "@/models/inventory/InventoryTransfer";
import { ElForm } from "element-plus";

// components
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import TagInput from "@/components/form/TagInput.vue";
import Item from "@/models/inventory/Item";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "new-inventory-receiving",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryTransferI18nMessages.en,

          sections: {
            info: "Meta data",
            inventory: "Inventory Info"
          },

          placeholders: {
            reason: "Example: store beta is running low on item alpha",
            itemName: "Enter barcode, sku, or item name",
            transferOutID: "Enter transfer id"
          },

          userTransfering:
            "Employee responsible for receiving this inventory transfer",
          insertSerials: "You must insert {num} serial numbers",

          currentQuantity: "New quantity",
          receivedQuantity: "Recieved quantity",

          successMsg: "Inventory was transfered successfully",
          errTransferOutToLocation:
            "This transfer out cannot be received at the current location since it belongs to another location",
          errTransferOutIsDirect:
            "This transfer out was moved directly. There's no need to recieve it"
        },
        ar: {
          ...inventoryTransferI18nMessages.ar,

          sections: {
            info: "البيانات الوصفية",
            inventory: "البضاعة المنقولة"
          },

          placeholders: {
            reason: "مثلا: نفذت البضاعة من محل اخر",
            itemName: "ادخل اسم المنتج او الباركود او SKU",
            transferOutID: "رقم الارسال"
          },

          userTransfering: "الموظف المسؤول عن استلام نقل البضاعة",
          insertSerials: "قم بادخال {num} serial numbers",

          currentQuantity: "الكمية الحديثة",
          receivedQuantity: "الكمية المستلمة",

          successMsg: "تم نقل البضاعة بنجاح",

          errTransferOutToLocation: "نقل البضاعة لايعود الى هذا الموقع",
          errTransferOutIsDirect:
            "تم نقل البضاعة بشكل مباشر. لايوجد حاجة لاستلامها"
        }
      }
    });

    return { t };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection,
    TagInput
  },

  data() {
    return {
      inventoryTransfer: new InventoryTransfer("receive"),
      itermSearchTerm: "",
      error: { title: "", body: "" },
      serials: [],
      pressedEnter: false,
      user: this.$ctx.currentUser,
      transferOutVerfied: false,
      rules: {
        inventoryTransferSendID: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    async searchItems(query: string, cb: Function) {
      // if an item already exists in the table, don't do remote search
      const cachedItem =
        this.inventoryTransfer.findByBarcode(query) ||
        this.inventoryTransfer.findBySKU(query);
      if (cachedItem) {
        this.addItem(cachedItem);
        cb([]);
        return;
      }

      try {
        const url = `${Item.ENDPOINT}?q=${query}`;
        const items = await this.$http.get<Item[]>(url);

        // when entering a barcode, the scanner hits enter by default
        // so if we get back one result, we wanna append that right away
        // it makes the process much faster than make the user select by themselves
        if (this.pressedEnter && items.length === 1) {
          this.addItem(items[0]);
          cb([]);
        } else {
          // show them all options
          cb(items);
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        cb([]);
      }

      this.pressedEnter = false;
    },

    async verifyTransferOut(transferOutID: string) {
      this.transferOutVerfied = false;
      try {
        const url = `${InventoryTransfer.ENDPOINT}?external_id=${transferOutID}`;
        const transferOut = await this.$http.get<InventoryTransfer>(url);

        let err = "";
        if (transferOut.toLocationID !== this.$ctx.currentLocation.id) {
          err = this.t("errTransferOutToLocation");
        } else if (transferOut.transferOutType == "direct") {
          err = this.t("errTransferOutIsDirect");
        }

        if (err) {
          this.$alertModal.showDanger({ title: err });
        } else {
          this.transferOutVerfied = true;
          this.inventoryTransfer.fromLocationID = transferOut.fromLocationID;
          this.inventoryTransfer.toLocationID = transferOut.toLocationID;
          this.inventoryTransfer.transferOutID = transferOut.id;
        }
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
      }
    },

    addItem(selected: Item) {
      this.inventoryTransfer.addItem(selected);
      this.itermSearchTerm = "";
    },

    removeItem(index: number) {
      this.inventoryTransfer.removeItem(index);
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
      this.inventoryTransfer = new InventoryTransfer("receive");
    },

    async submit(it: InventoryTransfer) {
      this.error.title = "";
      this.error.body = "";

      try {
        await this.$http.post(InventoryTransfer.ENDPOINT, it);
      } catch (error) {
        return error;
      }
    },

    checkForm() {
      const errors = [];
      for (const iti of this.inventoryTransfer.inventoryTransferItems) {
        errors.push(iti.validateSerials());
        errors.push(iti.validateQuantity());
      }

      this.error.body = errors.filter(err => err).join("\n");

      if (this.error.body) {
        this.error.title = this.$t("validation.inputErrors");
        document.getElementById("main")?.scrollTo({ top: 0 });
        return false;
      }

      return true;
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;

      form?.validate(async (valid: boolean) => {
        if (!valid || !this.checkForm()) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submit(
          this.inventoryTransfer as InventoryTransfer
        );

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          this.clearForm(formName);
          this.$alertModal.showSuccess({ title: this.t("successMsg") });
        }
        return true;
      });
    }
  }
});
</script>

<style lang="postcss" scoped>
form > h1 {
  @apply text-lg py-3 font-bold;
}

form > h2 {
  @apply py-3 font-bold;
}

form > h3 {
  @apply py-3 font-bold;
}

form {
  @apply flex flex-col items-center;
}
</style>
