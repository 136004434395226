<template>
  <div class="m-3" style="height:90vh" :dir="$ctx.getDir()">
    <div class="flex flex-col items-center content-center gap-2 md:flex-row">
      <template v-if="$can('read', '/sales/orders')">
        <el-select
          class="input-label"
          v-model="selectedValue"
          @change="applyToSelected"
        >
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </el-select>
      </template>

      <el-input
        :placeholder="t('searchReceipt')"
        class="w-full md:w-6/12"
        prefix-icon="el-icon-search"
        v-model="searchTerm"
        ref="searchBar"
      />
    </div>

    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
      rowModelType="infinite"
      :maxConcurrentDatasourceRequests="1"
      :inifiniteInitialRowCount="1000"
      :components="components"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import {
  ColDef,
  GridOptions,
  IDatasource,
  IGetRowsParams,
  ValueFormatterParams
} from "ag-grid-community";

import { i18nOrderMessages, Order } from "@/models/sales/Order";
import { useI18n } from "vue-i18n";
import { logError } from "@/utils/log";
import { parseFormat } from "@/utils/date";
import { formatMoney } from "@/utils/money";

export default defineComponent({
  name: "orders",

  setup() {
    const { t } = useI18n({
      messages: {
        en: { ...i18nOrderMessages.en, searchReceipt: "Enter receipt number" },
        ar: { ...i18nOrderMessages.ar, searchReceipt: "ادخل رقم الفاتورة" }
      }
    });

    return {
      t
    };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: Order[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};
    const datasource: IDatasource = {} as IDatasource;
    const components = {
      loadingRenderer: (params: ValueFormatterParams) => {
        return params.value !== undefined
          ? params.value
          : '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    };

    return {
      dateRange: "",
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,
      components,
      datasource,
      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue
  },

  beforeMount() {
    this.defaultColDef = {
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.t("receiptNumber"),
        field: "invoice.externalID",
        checkboxSelection: true
      },
      {
        headerName: this.$t("models.common.createdAt"),
        field: "createdAt",
        cellStyle: { direction: "ltr" },
        valueFormatter: params => {
          const createdAt = new Date(params.value);
          return parseFormat(createdAt);
        }
      },
      {
        headerName: this.t("paymentComplete"),
        field: "invoice.paymentComplete",
        valueFormatter: params => {
          const paymentComplete = new Date(params.value);
          return parseFormat(paymentComplete);
        }
      },
      {
        headerName: this.t("total"),
        field: "invoice.totalDueAmount",
        valueFormatter: ({ data }) => {
          const order = data as Order;
          if (!order) return "";
          return order.invoice?.totalDueAmount
            ? formatMoney(order.invoice?.totalDueAmount, order.invoice.currency)
            : "";
        }
      },
      {
        headerName: this.t("paymentType"),
        field: "paymentType",
        valueFormatter: ({ data }) => {
          const order = data as Order;
          if (!order) return "";
          return order.invoice?.paymentType || "";
        }
      },
      {
        headerName: this.t("user"),
        field: "user.fullName"
      },
      {
        headerName: this.t("location"),
        field: this.$ctx.locale === "en" ? "location.enName" : "location.arName"
      },
      {
        headerName: this.t("customer"),
        field: "invoice.customer.contactDisplayName"
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();

    // go to view category
    this.gridOptions.onCellDoubleClicked = event => {
      this.$router.push(`/sales/orders/order/${event.data.id}`);
    };
  },

  watch: {
    searchTerm() {
      this.filterItems();
    }
  },

  methods: {
    async updateRowData(receiptNumber: string) {
      let rowCount = { count: 0 };
      try {
        // get row counts
        rowCount = await this.$http.get<typeof rowCount>(
          "/helpers/count?model=orders"
        );
      } catch (error) {
        logError(`failed to retrieve orders count: ${error}`);
      }

      const get = this.$http.get;
      const alertModal = this.$alertModal;
      const pageSize = this.gridOptions.paginationPageSize || 20;

      this.datasource = {
        async getRows(params: IGetRowsParams) {
          const page = Math.floor(params.startRow / pageSize) + 1;

          try {
            const data = await get<Order[]>(
              `${Order.ENDPOINT}?page=${page}&page_size=${pageSize}&filter=${receiptNumber}`
            );
            const orders = data.map(order => Order.from(order));

            // if the receipt number was entered
            if (receiptNumber) params.successCallback(orders, orders.length);
            else params.successCallback(orders, rowCount.count);
          } catch (error) {
            if (error?.code === 404) {
              params.successCallback([], 0);
            } else {
              alertModal.showDanger({ title: error.title, body: error.body });
              params.failCallback();
            }
          }
        }
      } as IDatasource;

      this.gridOptions.api?.setDatasource(this.datasource);
    },

    async onGridReady() {
      this.gridOptions.cacheBlockSize = this.gridOptions.paginationPageSize;
      await this.updateRowData("");
    },

    async applyToSelected() {
      if (this.selectedValue === "delete" || this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();

        if (selected?.length) {
          if (this.selectedValue === "delete") {
            // send request to archive
          } else {
            this.gridOptions.api?.exportDataAsCsv({ onlySelected: true });
            this.$alertModal.showSuccess({
              title: this.$t("views.common.listView.selectedRows.exportSuccess")
            });
          }

          // deselect
          selected.length = 0;
        }
      }

      this.selectedValue = "";
    },

    filterItems() {
      this.updateRowData(this.searchTerm);
    }
  }
});
</script>

<style lang="scss">
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #f5f5f5,
      row-border-color: transparent,
      row-hover-color: null,
      checkbox-checked-color: var(--primary-bg-color),
      range-selection-border-color: var(--primary-bg-color)
    )
  );
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-content-cell-label {
  justify-content: center;
}
</style>
