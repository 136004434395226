import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from "vue-router";
import { useAuth } from "@/plugins/auth";
import { Route, routes } from "./routes";
import { App } from "vue";

export const router = createRouter({
  history: process.env.IS_ELECTRON
    ? createWebHashHistory()
    : createWebHistory(),
  routes: routes
});

router.beforeEach((to, _from, next) => {
  const { loggedIn } = useAuth();
  // Not logged into a guarded route?
  if (to.meta.requiresAuth && !loggedIn()) next({ name: "login" });
  // Carry On...
  else next();
});

export default {
  install: (app: App) => {
    app.use(router);
    app.config.globalProperties.$Route = Route;
  }
};
