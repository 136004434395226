import UUIDBase from "@/models/UUIDBase";
import User from "@/models/user/User";
import Location from "@/models/company/Location";

export default class EmployeeSchedule extends UUIDBase {
  static from(json: Record<string, unknown> | EmployeeSchedule) {
    const o = Object.assign(new EmployeeSchedule(), json);
    if (o.user) o.user = User.from(o.user);
    return o;
  }

  static ENDPOINT = "/hr/employeeschedule";

  userID = "";
  user?: User;

  locationID = "";
  location?: Location;

  workDate = new Date();

  startWorkHour = 0;
  startWorkMinute = 0;

  endWorkHour = 0;
  endWorkMinute = 0;

  isHoliday = false;
  isWeekend = false;
}
