import User from "@/models/user/User";
import Location from "@/models/company/Location";
import { App, reactive } from "vue";
import { Register } from "@/models/company/Register";
import { Machine } from "@/models/company/Machine";

interface ExchangeRates {
  USD: number;
  IQD: number;
}

export interface Context {
  currency: string;
  currencyPrecision: number;
  locale: string;
  currentUser: User;
  currentLocation: Location;
  currentRegister: Register;
  currentMachine: Machine;
  currentPrinter: string;
  companyName: string;
  exchangeRates: ExchangeRates;
  getDir: () => string;
  getAccessToken: () => string;
  setLocale: (newLocale: string) => void;
  setUser: (user: User) => void;
  setLocation: (newLocation: Location) => void;
  setRegister: (register: Register) => void;
  updateRegisterStatus: (newStatus: string) => void;
  setMachine: (machine: Machine) => void;
  setPrinter: (printer: string) => void;
  setExchangeRates: (exchangeRates: ExchangeRates) => void;
}

const hardcodedLocation = new Location();
hardcodedLocation.id = "87f44a21-0b6d-4150-b82f-af458d2c2db1";
hardcodedLocation.enName = "Al-Mansour Store";
hardcodedLocation.arName = "متجر المنصور";

const ctx: Context = {
  // default values
  currency: "IQD",
  currencyPrecision: 0,
  locale: localStorage.getItem("locale") || "ar",
  currentUser: User.from(JSON.parse(localStorage.getItem("user") || "{}")),
  currentLocation: hardcodedLocation,
  currentRegister: Register.from(
    JSON.parse(localStorage.getItem("register") || "{}")
  ),
  currentMachine: Machine.from(
    JSON.parse(localStorage.getItem("machine") || "{}")
  ),
  currentPrinter: localStorage.getItem("printer") || "",
  companyName: "Aleef Pet",
  exchangeRates: {
    USD: 0.0006852,
    IQD: 1459.43
  },
  getDir: function() {
    return this.locale === "ar" ? "rtl" : "ltr";
  },
  getAccessToken: function() {
    return this.currentUser.accessToken;
  },
  setLocale: function(newLocale: string) {
    localStorage.setItem("locale", newLocale);
    location.reload();
  },
  setUser: function(user: User) {
    this.currentUser = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
  setLocation: function(newLocation: Location) {
    this.currentLocation = newLocation;
    localStorage.setItem("location", JSON.stringify(location));
  },
  setRegister: function(register: Register) {
    this.currentRegister = register;
    localStorage.setItem("register", JSON.stringify(register));
  },
  updateRegisterStatus: function(newStatus: string) {
    this.currentRegister.status = newStatus;
  },
  setMachine: function(machine: Machine) {
    this.currentMachine = machine;
    localStorage.setItem("machine", JSON.stringify(machine));
  },
  setPrinter: function(printer: string) {
    this.currentPrinter = printer;
    localStorage.setItem("printer", printer);
  },
  setExchangeRates: function(exchangeRates: ExchangeRates) {
    this.exchangeRates = exchangeRates;
  }
};

export function useContext() {
  return reactive(ctx);
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$ctx = ctx;
  }
};
