<template>
  <category-form
    :initCategory="initCategory"
    :successMsg="t('created')"
    :submitAction="submit"
    enableClearForm
    clearFormOnSubmit
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CategoryForm from "@/views/inventory/categories/CategoryForm.vue";

import Category from "@/models/inventory/Category";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "new-category",
  components: {
    CategoryForm
  },

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          created: "Category was created successfully"
        },
        ar: {
          created: "تم انشاء المجموعة بنجاح"
        }
      }
    });

    return { t };
  },

  data() {
    return {
      initCategory: new Category()
    };
  },

  methods: {
    async submit(category: Category) {
      try {
        await this.$http.post(Category.ENDPOINT, category);
        await this.$router.push(this.$Route.INVENTORY_ITEM_CATEGORIES);
      } catch (error) {
        return error;
      }
    }
  }
});
</script>

<style lang="postcss"></style>
