
import { defineComponent } from "vue";

export default defineComponent({
  name: "alert-modal",
  mounted() {
    this.$globalEmitter.on("show-alert-modal", event => {
      this.title = event?.title;
      this.body = event?.body || "";
      const level = event?.level;
      switch (level) {
        case "danger":
          this.isDanger = true;
          break;
        case "success":
          this.isSuccess = true;
          break;
        case "warning":
          this.isWarning = true;
          break;
        default:
          this.isNormal = true;
      }

      this.dismissed = false;

      setTimeout(() => {
        this.dismiss();
      }, 5000);
    });
  },

  data() {
    return {
      title: "",
      body: "",
      isDanger: false,
      isSuccess: false,
      isWarning: false,
      isNormal: false,
      dismissed: false,
      overlay: false
    };
  },
  emits: ["dismissed"],
  methods: {
    dismiss() {
      document.querySelector("#alert-modal")?.classList.remove("slide-bottom");
      document.querySelector("#alert-modal")?.classList.add("slide-top");

      setTimeout(() => {
        this.$emit("dismissed");
        this.dismissed = true;
        this.title = "";
        this.body = "";
        this.overlay = false;
        this.isNormal = false;
        this.isDanger = false;
        this.isWarning = false;
        this.isSuccess = false;

        document.querySelector("#alert-modal")?.classList.remove("slide-top");
        document.querySelector("#alert-modal")?.classList.add("slide-bottom");
      }, 1000);
    }
  }
});
