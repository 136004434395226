
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import { ColDef, GridOptions } from "ag-grid-community";
import { useI18n } from "vue-i18n";
import InventoryTransfer, {
  inventoryTransferI18nMessages
} from "@/models/inventory/InventoryTransfer";
import HorizontalButton from "@/components/HorizontalButton.vue";

export default defineComponent({
  name: "inventory-transfers",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...inventoryTransferI18nMessages.en,
          newTransfer: "New Transfer"
        },
        ar: { ...inventoryTransferI18nMessages.ar, newTransfer: "نقل جديد" }
      }
    });

    return { t };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: InventoryTransfer[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.t("id"),
        field: "externalID",
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.$t("models.common.createdAt"),
        field: "createdAt",
        cellStyle: { direction: "ltr" },
        valueFormatter: params => {
          const createdAt = new Date(params.value);
          return (
            createdAt.toLocaleDateString("en-GB") +
            " " +
            createdAt.toLocaleTimeString("en-US")
          );
        }
      },
      {
        headerName: this.t("status"),
        field: "status"
      },
      {
        headerName: this.t("user"),
        field: "user.fullName"
      },
      {
        headerName: this.t("fromLocation"),
        field:
          this.$ctx.locale == "ar"
            ? "fromLocation.arName"
            : "fromLocation.enName"
      },
      {
        headerName: this.t("toLocation"),
        field:
          this.$ctx.locale == "ar" ? "toLocation.arName" : "toLocation.enName"
      },
      {
        headerName: this.t("receivedAt"),
        field: "receivedAt",
        cellStyle: { direction: "ltr" },
        valueFormatter: params => {
          const date = params.value ? new Date(params.value) : "";
          return date
            ? date.toLocaleDateString("en-GB") +
                " " +
                date.toLocaleTimeString("en-US")
            : "";
        }
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();

    // go to view item
    this.gridOptions.onCellDoubleClicked = event => {
      this.$router.push(
        this.$Route.INVENTORY_INVENTORY_TRANSFERS_INVENTORY_TRANSFER.replace(
          ":id",
          event.data.id
        )
      );
    };
  },

  watch: {
    searchTerm() {
      this.filterResult();
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();

      try {
        const data = await this.$http.get<InventoryTransfer[]>(
          InventoryTransfer.ENDPOINT
        );
        this.rowData = data.map(inventoryTransfer =>
          InventoryTransfer.from(inventoryTransfer)
        );
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    },

    async applyToSelected() {
      if (this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();

        if (selected?.length) {
          this.gridOptions.api?.exportDataAsCsv({ onlySelected: true });
          this.$alertModal.showSuccess({
            title: this.$t("views.common.listView.selectedRows.exportSuccess")
          });

          // deselect
          selected.length = 0;
        }
      }

      this.selectedValue = "";
    },

    filterResult() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    }
  }
});
