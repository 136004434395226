<template>
  <div class="m-3" style="height:90vh">
    <div class="flex flex-col items-center content-center md:flex-row">
      <el-select
        class="input-label"
        v-model="selectedValue"
        @change="applyToSelected"
      >
        <template v-if="$can('read', '/inventory/inventoryadjustments')">
          <el-option
            key="export"
            :label="$t('views.common.listView.selectedRows.export')"
            value="export"
          >
          </el-option>
        </template>
      </el-select>

      <el-input
        :placeholder="$t('views.common.listView.searchRows')"
        class="w-full md:w-6/12"
        prefix-icon="el-icon-search"
        v-model="searchTerm"
      />

      <div class="w-full m-2 md:w-3/12">
        <template v-if="$can('create', '/inventory/inventoryadjustments')">
          <horizontal-button
            :title="
              $t('views.inventory.inventoryAdjustments.newInventoryAdjustment')
            "
            @click="
              $router.push(
                $Route.INVENTORY_INVENTORY_ADJUSTMENTS_NEW_INVENTORY_ADJUSTMENT
              )
            "
          />
        </template>
      </div>
    </div>

    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      rowSelection="multiple"
      :enable-rtl="$ctx.getDir() === 'rtl'"
      style="height: 85vh"
      :pagination="true"
      :paginationAutoPageSize="true"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :onGridReady="onGridReady"
    >
    </ag-grid-vue>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import { AgGridVue } from "ag-grid-vue3";
import { ColDef, GridOptions } from "ag-grid-community";
import HorizontalButton from "@/components/HorizontalButton.vue";
import { useI18n } from "vue-i18n";
import InventoryAdjustment, {
  inventoryAdjustmentI18nMessages
} from "@/models/inventory/InventoryAdjustment";

export default defineComponent({
  name: "inventory-adjustments",

  setup() {
    const { t } = useI18n({
      messages: inventoryAdjustmentI18nMessages
    });

    return { t };
  },

  data() {
    const columnDefs: ColDef[] = [];
    const rowData: InventoryAdjustment[] = [];
    const gridOptions: GridOptions = {};
    const defaultColDef: ColDef = {};

    return {
      columnDefs,
      rowData,
      gridOptions,
      defaultColDef,

      searchTerm: "",
      selectedValue: ""
    };
  },

  components: {
    AgGridVue,
    HorizontalButton
  },

  beforeMount() {
    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      minWidth: 150
    };

    this.columnDefs = [
      {
        headerName: this.t("id"),
        field: "externalID",
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: this.$t("models.common.createdAt"),
        field: "createdAt",
        cellStyle: { direction: "ltr" },
        valueFormatter: params => {
          const createdAt = new Date(params.value);
          return (
            createdAt.toLocaleDateString("en-GB") +
            " " +
            createdAt.toLocaleTimeString("en-US")
          );
        }
      },
      {
        headerName: this.t("user"),
        field: "user.fullName"
      },
      {
        headerName: this.t("location"),
        field: this.$ctx.locale == "ar" ? "location.arName" : "location.enName"
      },
      {
        headerName: this.t("reason"),
        field: "reason"
      }
    ];
  },

  mounted() {
    this.gridOptions.api?.sizeColumnsToFit();

    // go to view item
    this.gridOptions.onCellDoubleClicked = event => {
      this.$router.push(
        this.$Route.INVENTORY_INVENTORY_ADJUSTMENTS_INVENTORY_ADJUSTMENT.replace(
          ":id",
          event.data.id
        )
      );
    };
  },

  watch: {
    searchTerm() {
      this.filterResult();
    }
  },

  methods: {
    async updateRowData() {
      this.gridOptions.api?.showLoadingOverlay();

      try {
        const data = await this.$http.get<InventoryAdjustment[]>(
          InventoryAdjustment.ENDPOINT
        );
        this.rowData = data.map(inventoryAdjustment =>
          InventoryAdjustment.from(inventoryAdjustment)
        );
      } catch (error) {
        this.$alertModal.showDanger({
          title: error.title,
          body: error.body
        });
      }

      this.gridOptions.api?.hideOverlay();
    },

    async onGridReady() {
      await this.updateRowData();
    },

    async applyToSelected() {
      if (this.selectedValue === "export") {
        const selected = this.gridOptions.api?.getSelectedRows();

        if (selected?.length) {
          this.gridOptions.api?.exportDataAsCsv({ onlySelected: true });
          this.$alertModal.showSuccess({
            title: this.$t("views.common.listView.selectedRows.exportSuccess")
          });

          // deselect
          selected.length = 0;
        }
      }

      this.selectedValue = "";
    },

    filterResult() {
      this.gridOptions.api?.setQuickFilter(this.searchTerm);
    }
  }
});
</script>

<style lang="scss">
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #f5f5f5,
      row-border-color: transparent,
      row-hover-color: null,
      checkbox-checked-color: var(--primary-bg-color),
      range-selection-border-color: var(--primary-bg-color)
    )
  );
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-content-cell-label {
  justify-content: center;
}
</style>
