<template>
  <el-form
    @submit.prevent="validateSubmit('catForm')"
    :model="category"
    :rules="rules"
    label-position="top"
    ref="catForm"
    novalidate
  >
    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('catForm')"
          />
        </div>

        <div class="md:w-4/12 m-2" v-if="enableClearForm">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('catForm')"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <input-section>
      <h1>{{ t("section") }}</h1>

      <el-form-item :label="t('name')" prop="name" class="input-label">
        <el-input
          id="name"
          v-model="category.name"
          :placeholder="t('namePlaceholder')"
          maxlength="255"
          show-word-limit
          clearable
          required
        >
        </el-input>
      </el-form-item>

      <el-form-item
        :label="t('parentCategory')"
        prop="parentCategory.id"
        class="input-label"
      >
        <el-select
          v-model="category.parentCategory.id"
          filterable
          clearable
          class="w-full"
        >
          <el-option
            v-for="item in categories"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span :class="{ 'float-right': $ctx.getDir() == 'rtl' }">{{
              item.name
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </input-section>
  </el-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
// import Alert from "@/components/Alert.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import AlertBadge from "@/components/AlertBadge.vue";

import { ElForm } from "element-plus";

// models
import Category, { categoryI18nMessages } from "@/models/inventory/Category";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "category-form",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...categoryI18nMessages.en,
          section: "Category Info",
          namePlaceholder: "For example, women clothing",
          skipParent: "Skip"
        },
        ar: {
          ...categoryI18nMessages.ar,
          section: "معلومات المجموعة",
          namePlaceholder: "مثلا: ملابس نسائية",
          skipParent: "تخطي"
        }
      },
      unescape: "global"
    });

    return { t };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    // CustomTextInput,
    InputSection
  },
  props: {
    initCategory: {
      type: Category,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  created() {
    if (!this.category.parentCategory) {
      // create an empty parent
      this.category.parentCategory = new Category();
    }
  },

  async beforeMount() {
    try {
      const catResp = await this.$http.get<Category[]>(Category.ENDPOINT);
      this.categories = catResp.map(cat => Category.from(cat));
    } catch (error) {
      this.error.title = error?.title;
      this.error.body = error?.body;
    }
  },

  data() {
    return {
      category: this.initCategory,
      categories: new Array<Category>(),
      error: { title: "", body: "" },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          },
          {
            max: 255,
            message: this.$t("validation.maxLength", { len: 255 }),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.category);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
        }
        return true;
      });
    }
  }
});
</script>

<style lang="postcss" scoped>
h1,
h2,
h3 {
  @apply text-lg  font-bold;
}

form {
  @apply flex flex-col items-center;
}
</style>
