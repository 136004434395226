
import { defineComponent } from "vue";
import EasyTotal from "@/components/money/EasyTotal.vue";
import AlertBadge from "@/components/AlertBadge.vue";
import HorizontalButton from "@/components/HorizontalButton.vue";

import Dinero from "dinero.js";
import { useI18n } from "vue-i18n";
import { CloseRegisterPayload, Register } from "@/models/company/Register";

export default defineComponent({
  name: "close-register",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          submitCount: "Close Register",
          depositTooltip: "Please enter the closing deposit amount",
          floatingTooltip: "Please enter the closing floating amount"
        },
        ar: {
          submitCount: "اغلق الخزانة",
          depositTooltip: "يرجى ادخال مبلغ التوديع النهائي",
          floatingTooltip: "يرجى ادخال مبلغ الخردة النهائي (لن يودع)"
        }
      }
    });

    return { t };
  },

  components: {
    EasyTotal,
    AlertBadge,
    HorizontalButton
  },

  data() {
    return {
      error: {
        title: "",
        body: ""
      },
      depositTotal: Dinero(),
      floatingTotal: Dinero()
    };
  },

  methods: {
    async validateSubmit() {
      try {
        const payload: CloseRegisterPayload = {
          registerID: this.$ctx.currentRegister.id,
          currency: this.depositTotal.getCurrency(),
          precision: this.depositTotal.getPrecision(),
          actualClosingDepositAmount: this.depositTotal.getAmount(),
          actualClosingFloatingAmount: this.floatingTotal.getAmount(),
          action: "close",
          timestamp: new Date(),
          userID: this.$ctx.currentUser.id
        };

        await this.$http.post(`company/register-actions`, payload);

        await this.$router.push(this.$Route.SALES);
      } catch (error) {
        return error;
      }
    }
  }
});
