import { useHTTP } from "@/plugins/http";
import User from "@/models/user/User";
import { useContext } from "@/plugins/context";

// interface AuthState {
//   user?: User;
//   expires?: Date;
// }

const userEndpoints = {
  login: `${User.ENDPOINT}/login`,
  logout: `${User.ENDPOINT}/logout`,
  isLoggedIn: `${User.ENDPOINT}/is-logged-in`
};

const USER_LOCAL_STORAGE_KEY = "user";
const ACCESST_TOKEN_LOCAL_STORAGE_KEY = "token";

export const useAuth = () => {
  const { post, get } = useHTTP();
  const ctx = useContext();

  const userLogin = async (credentials: {
    username: string;
    password: string;
  }): Promise<User> => {
    const resp = await post<User>(userEndpoints.login, credentials);
    const user = User.from(resp);
    ctx.setUser(User.from(user));
    localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
    localStorage.setItem(ACCESST_TOKEN_LOCAL_STORAGE_KEY, user.accessToken);

    return user;
  };

  const resetAuthState = () => {
    ctx.setUser(new User());
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    localStorage.removeItem(ACCESST_TOKEN_LOCAL_STORAGE_KEY);
  };

  const userLogout = async () => {
    resetAuthState();
    await post(userEndpoints.logout);
  };

  const loggedIn = async (): Promise<boolean> => {
    if (!ctx.currentUser.accessToken) return false;
    return (
      get(userEndpoints.isLoggedIn)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(_ => true)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(_ => false)
    );
  };

  return {
    userLogin,
    userLogout,
    loggedIn,
    resetAuthState
  };
};
