import { useEmitter } from "@/plugins/mitt";
import { App } from "vue";
import Drawer from "@/plugins/drawer/Drawer.vue";

export function showDrawer(component: string) {
  const emitter = useEmitter();
  emitter.emit("show-drawer", { component });
}

export function useDrawer() {
  return {
    showDrawer
  };
}

export default {
  install: (app: App) => {
    app.component("drawer", Drawer);
    app.config.globalProperties.$drawer = useDrawer();
  }
};
