<template>
  <el-form
    @submit.prevent="validateSubmit('accountForm')"
    :model="account"
    :rules="rules"
    label-position="top"
    ref="accountForm"
    novalidate
  >
    <input-section>
      <div class="flex flex-col md:flex-row justify-end">
        <div class="md:w-4/12 m-2">
          <horizontal-button
            :title="$t('actions.saveChanges')"
            isSuccess
            faIcon="save"
            @click.prevent="validateSubmit('accountForm')"
          />
        </div>

        <div class="md:w-4/12 m-2" v-if="enableClearForm">
          <horizontal-button
            :title="$t('actions.clearButton')"
            isDanger
            faIcon="trash"
            @click.prevent="clearForm('accountForm')"
          />
        </div>
      </div>
    </input-section>

    <!-- @submit errors -->
    <alert-badge
      isDanger
      :title="error.title"
      :body="error.body"
      @dismissed="
        () => {
          error.title = '';
          error.body = '';
        }
      "
    />

    <input-section>
      <h1>{{ t("section") }}</h1>

      <el-form-item :label="t('enName')" prop="enName" class="input-label">
        <el-input
          id="en-name"
          v-model="account.enName"
          :placeholder="t('enNamePlaceholder')"
          maxlength="255"
          show-word-limit
          clearable
          required
        >
        </el-input>
      </el-form-item>
      <el-form-item :label="t('arName')" prop="arName" class="input-label">
        <el-input
          id="ar-name"
          v-model="account.arName"
          :placeholder="t('arNamePlaceholder')"
          maxlength="255"
          show-word-limit
          clearable
          required
        >
        </el-input>
      </el-form-item>

      <el-form-item
        :label="t('enDescription')"
        prop="enDescription"
        class="input-label"
      >
        <el-input
          id="description"
          v-model="account.enDescription"
          :placeholder="t('enDescriptionPlaceholder')"
          maxlength="255"
          show-word-limit
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item
        :label="t('arDescription')"
        prop="arDescription"
        class="input-label"
      >
        <el-input
          id="description"
          v-model="account.arDescription"
          :placeholder="t('arDescriptionPlaceholder')"
          maxlength="255"
          show-word-limit
          clearable
        >
        </el-input>
      </el-form-item>

      <el-form-item
        :label="t('accountCategory')"
        class="input-label"
        prop="accountCategory"
      >
        <el-select
          id="account-category"
          v-model="account.accountCategory"
          :trigger-on-focus="true"
          class="w-full"
          filterable
        >
          <el-option
            v-for="option in accountCategories"
            :key="option"
            :label="option"
            :value="option"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="input-label" prop="isSystemAccount">
        <el-checkbox
          v-model="account.isSystemAccount"
          :label="t('isSystemAccount')"
          size="large"
        />
      </el-form-item>

      <el-form-item :label="t('code')" prop="code" class="input-label">
        <el-input-number
          id="code"
          v-model="account.code"
          :placeholder="t('code')"
          maxlength="10"
          show-word-limit
          clearable
        >
        </el-input-number>
      </el-form-item>

      <el-form-item
        :label="t('parentAccount')"
        class="input-label"
        prop="parentAccountID"
      >
        <el-select
          id="debited-acount"
          v-model="account.parentAccount.id"
          :trigger-on-focus="false"
          value-key="name"
          class="w-full"
          filterable
          remote
          :remote-method="searchAccounts"
        >
          <el-option
            v-for="option in accounts"
            :key="option.id"
            :label="option.enName"
            :value="option.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </input-section>
  </el-form>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// components
import HorizontalButton from "@/components/HorizontalButton.vue";
import InputSection from "@/components/form/InputSection.vue";
import AlertBadge from "@/components/AlertBadge.vue";

import { ElForm } from "element-plus";
import { Route } from "@/plugins/navigation";

// models
import { Account, accountI18nMessages } from "@/models/accounting/Account";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "account-form",

  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          ...accountI18nMessages.en,
          section: "Account Info",
          enNamePlaceholder: "For example, overhead expenses",
          arNamePlaceholder: "For example, overhead expenses",
          enDescriptionPlaceholder:
            "For example, this account is used to track overhead expenses",
          arDescriptionPlaceholder:
            "For example, this account is used to track overhead expenses",
          skipParent: "Skip",
          accountCategory: "Account Category"
        },
        ar: {
          ...accountI18nMessages.ar,
          section: "معلومات الحساب",
          enNamePlaceholder: "مثلا: النفقات العامة",
          arNamePlaceholder: "مثلا: النفقات العامة",
          enDescriptionPlaceholder:
            "For example, this account is used to track overhead expenses",
          arDescriptionPlaceholder:
            "For example, this account is used to track overhead expenses",
          skipParent: "تخطي",
          accountCategory: "فئة الحساب"
        }
      },
      unescape: "global"
    });

    return { t };
  },

  components: {
    AlertBadge,
    HorizontalButton,
    InputSection
  },
  props: {
    initAccount: {
      type: Account,
      required: true
    },
    submitAction: {
      type: Function,
      required: true
    },
    successMsg: {
      type: String,
      required: true
    },
    enableClearForm: {
      type: Boolean,
      required: false
    },
    clearFormOnSubmit: {
      type: Boolean,
      default: false
    }
  },

  beforeMount() {
    if (!this.account.parentAccount) {
      // create an empty parent
      this.account.parentAccount = new Account();
    }
  },

  data() {
    const accounts = new Array<Account>();
    const accountCategories = [
      "Assets",
      "Liabilities",
      "Equity",
      "Revenue",
      "Expenses"
    ];

    return {
      account: this.initAccount,
      accounts,
      error: { title: "", body: "" },
      accountCategories,
      rules: {
        enName: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          },
          {
            max: 255,
            message: this.$t("validation.maxLength", { len: 255 }),
            trigger: "blur"
          }
        ],
        arName: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          },
          {
            max: 255,
            message: this.$t("validation.maxLength", { len: 255 }),
            trigger: "blur"
          }
        ],
        isSystemAccount: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        code: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ],
        accountCategory: [
          {
            required: true,
            message: this.$t("validation.required"),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    async searchAccounts(query: string) {
      try {
        const url = `${Account.ENDPOINT}?q=${query}`;
        this.accounts = await this.$http.get<Account[]>(url);
      } catch (error) {
        this.$alertModal.showDanger({ title: error.title });
        this.accounts = [];
      }
    },

    clearForm(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form.resetFields();
    },

    async validateSubmit(formName: string) {
      const form = this.$refs[formName] as typeof ElForm;
      form?.validate(async (valid: boolean) => {
        if (!valid) {
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        }

        const resp = await this.submitAction(this.account);

        if (resp) {
          this.error.title = resp?.title;
          this.error.body = resp?.body;
          document.getElementById("main")?.scrollTo({ top: 0 });
          return false;
        } else {
          if (this.clearFormOnSubmit) {
            this.clearForm(formName);
          }

          this.$alertModal.showSuccess({ title: this.successMsg, body: "" });
          this.$goTo(Route.ACCOUNTING_ACCOUNTS);
        }
        return true;
      });
    }
  }
});
</script>

<style lang="postcss" scoped>
h1,
h2,
h3 {
  @apply text-lg  font-bold;
}

form {
  @apply flex flex-col items-center;
}
</style>
